import { MpeMgSupplierItemMarketingDTO } from '../../dto';

export class MgItemMarketing {
  imageUrl?: string;
  groupImageUrl?: string;
  actionImageUrl?: string;
  environmentImageUrl?: string;
  logoUrl?: string;
  productCatalogUrl?: string;

  constructor(
    imageUrl?: string,
    groupImageUrl?: string,
    actionImageUrl?: string,
    environmentImageUrl?: string,
    logoUrl?: string,
    productCatalogUrl?: string
  ) {
    this.imageUrl = imageUrl;
    this.groupImageUrl = groupImageUrl;
    this.actionImageUrl = actionImageUrl;
    this.environmentImageUrl = environmentImageUrl;
    this.logoUrl = logoUrl;
    this.productCatalogUrl = productCatalogUrl;
  }

  static fromDto = (dto: MpeMgSupplierItemMarketingDTO): MgItemMarketing =>
    new MgItemMarketing(
      dto.imageUrl,
      dto.groupImageUrl,
      dto.actionImageUrl,
      dto.environmentImageUrl,
      dto.logoUrl,
      dto.productCatalogUrl
    );
}
