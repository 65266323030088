import { forwardRef } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const PriceTagDiscountIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" ref={ref} {...props}>
      <g clipPath="url(#clip0_1238_167603)">
        <path
          d="M0 3.12501V8.96486C0 9.62892 0.261719 10.2656 0.730469 10.7344L7.29297 17.2969C8.26953 18.2735 9.85156 18.2735 10.8281 17.2969L16.043 12.082C17.0195 11.1055 17.0195 9.52345 16.043 8.54689L9.48047 1.98439C9.01172 1.51564 8.375 1.25392 7.71094 1.25392H1.875C0.839844 1.25001 0 2.08986 0 3.12501ZM4.375 4.37501C4.70652 4.37501 5.02446 4.50671 5.25888 4.74113C5.4933 4.97555 5.625 5.29349 5.625 5.62501C5.625 5.95653 5.4933 6.27448 5.25888 6.5089C5.02446 6.74332 4.70652 6.87501 4.375 6.87501C4.04348 6.87501 3.72554 6.74332 3.49112 6.5089C3.2567 6.27448 3.125 5.95653 3.125 5.62501C3.125 5.29349 3.2567 4.97555 3.49112 4.74113C3.72554 4.50671 4.04348 4.37501 4.375 4.37501Z"
          fill="#20A32D"
          fillOpacity="0.7"
        />
        <path
          opacity="0.4"
          d="M12.1528 1.51906C12.52 1.15578 13.1138 1.15969 13.4771 1.52687L18.4693 6.57765C20.5161 8.64797 20.5161 11.9761 18.4693 14.0464L14.0943 18.4722C13.731 18.8394 13.1372 18.8433 12.77 18.48C12.4028 18.1167 12.3989 17.523 12.7622 17.1558L17.1333 12.73C18.4575 11.3902 18.4575 9.23781 17.1333 7.89797L12.145 2.84719C11.7818 2.48 11.7857 1.88625 12.1528 1.52297V1.51906Z"
          fill="#20A32D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1238_167603">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});

export default PriceTagDiscountIcon;
