import { useDepartmentAssortments } from '@retail/my-assortment/context';
import {
  useFetchPurchaseCondition,
  useFetchPurchaseConditions,
  useMutateCreateOrEditConditions,
} from '@retail/purchase-agreements/data-access';
import { PurchaseConditions } from '@retail/purchase-agreements/types';

interface Props {
  supplierId: number;
  groupNumber?: string;
  includeSubConditions?: boolean;
  mgItemNumber?: number;
}

export const useConditions = ({
  supplierId,
  groupNumber,
  mgItemNumber,
  includeSubConditions = true,
}: Props) => {
  const { baseAssortmentId, draftAssortmentId, departmentId } = useDepartmentAssortments();

  const { data: baseConditionDto } = useFetchPurchaseCondition({
    assortmentId: baseAssortmentId,
    mgSupplierId: supplierId,
    groupNumber,
    mgItemNumber,
    includeSubConditions,
  });
  const baseConditions = baseConditionDto && PurchaseConditions.fromDto(baseConditionDto);

  const { data: draftConditionsDtos } = useFetchPurchaseConditions({
    mgSupplierId: supplierId,
    assortmentId: draftAssortmentId,
  });
  const draftConditions = draftConditionsDtos!.map(PurchaseConditions.fromDto);

  const { mutateAsync: postDraftConditions } = useMutateCreateOrEditConditions({
    assortmentId: draftAssortmentId,
    departmentId: departmentId,
  });

  return {
    baseAssortmentId,
    draftAssortmentId,
    baseConditions,
    draftConditions,
    postDraftConditions,
  };
};
