import { forwardRef } from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

const AlternativeIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  const { palette } = useTheme();

  return (
    <SvgIcon width="21" height="21" viewBox="0 0 21 21" fill="none" {...props} ref={ref}>
      <g clipPath="url(#clip0_1695_68577)">
        <path
          opacity="0.4"
          d="M14.0605 0.3125L10.9355 3.4375C10.3496 4.02344 10.7609 5.03906 11.5996 5.03906H13.1621L13.1621 6.28906L10.9531 8.35078L13.1852 10.434L16.1383 7.67813C16.1852 7.63427 16.2227 7.58123 16.2483 7.5223C16.2739 7.46337 16.2871 7.3998 16.2871 7.33555L16.2871 5.03906L17.8496 5.03906C18.6879 5.03906 19.0996 4.02344 18.5137 3.4375L15.3887 0.3125C15.3016 0.2251 15.1981 0.155752 15.0841 0.108435C14.9702 0.0611172 14.848 0.0367622 14.7246 0.0367622C14.6012 0.0367622 14.479 0.0611172 14.3651 0.108435C14.2511 0.155752 14.1476 0.2251 14.0605 0.3125ZM3.78711 15.2426L3.78711 19.5703C3.78711 19.6946 3.8365 19.8139 3.9244 19.9018C4.01231 19.9897 4.13154 20.0391 4.25586 20.0391H6.44336C6.56768 20.0391 6.68691 19.9897 6.77482 19.9018C6.86272 19.8139 6.91211 19.6946 6.91211 19.5703L6.91211 16.2891L9.12109 14.2273L6.88906 12.1441L3.93594 14.9C3.88898 14.9439 3.85153 14.9969 3.82593 15.0558C3.80033 15.1148 3.78711 15.1783 3.78711 15.2426Z"
          fill="url(#paint0_linear_1695_68577)"
        />
        <path
          d="M4.68476 0.3125C4.77185 0.2251 4.87535 0.155752 4.9893 0.108435C5.10326 0.0611172 5.22543 0.0367622 5.34882 0.0367622C5.47221 0.0367622 5.59438 0.0611172 5.70834 0.108435C5.82229 0.155752 5.92578 0.2251 6.01288 0.3125L9.13788 3.4375C9.72382 4.02344 9.31288 5.03906 8.47382 5.03906H6.91132V6.28906L16.1375 14.9C16.1845 14.9439 16.2219 14.9969 16.2475 15.0558C16.2731 15.1148 16.2863 15.1783 16.2863 15.2426V19.5703C16.2863 19.6946 16.2369 19.8139 16.149 19.9018C16.0611 19.9897 15.9419 20.0391 15.8176 20.0391H13.6301C13.5057 20.0391 13.3865 19.9897 13.2986 19.9018C13.2107 19.8139 13.1613 19.6946 13.1613 19.5703V16.2891L3.93515 7.67813C3.88814 7.63423 3.85066 7.58112 3.82506 7.52212C3.79946 7.46312 3.78627 7.39948 3.78632 7.33516L3.78632 5.03906H2.22382C1.38632 5.03906 0.973819 4.02578 1.55976 3.4375L4.68476 0.3125Z"
          fill="url(#paint1_linear_1695_68577)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1695_68577"
          x1="18.1931"
          y1="-0.118294"
          x2="11.5743"
          y2="22.0624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette.primary['700']} />
          <stop offset="0.327149" stopColor={palette.primary['600']} />
          <stop offset="0.636347" stopColor={palette.primary['500']} />
          <stop offset="1" stopColor={palette.primary['400']} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1695_68577"
          x1="15.691"
          y1="-0.118294"
          x2="9.07214"
          y2="22.0624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette.primary['700']} />
          <stop offset="0.327149" stopColor={palette.primary['600']} />
          <stop offset="0.636347" stopColor={palette.primary['500']} />
          <stop offset="1" stopColor={palette.primary['400']} />
        </linearGradient>
        <clipPath id="clip0_1695_68577">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="matrix(0 -1 1 0 0.0371094 20.0391)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});

export default AlternativeIcon;
