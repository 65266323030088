import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { membersRoute, useMpeAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { AssortmentItemDTO, ItemsDTO } from '@retail/my-assortment/types';
import { urlConstant } from '@retail/utils';
import { assortmentPath } from './assortmentsApi';
import { PaginationResultDTO } from '@retail/products/types';
import { useMPEFetch, useMutateMPEFetch } from '@retail/app/hooks';

export const itemsPath = '/items';
export const deleteItemsPath = '/delete-items';

interface Props extends QueryOptions {
  assortmentId: number;
  supplierIds?: number[];
  groupNumbers?: string[];
  query?: string;
  includeOwner?: boolean;
  page: number;
  size: number;
}

export function useFetchAssortmentItems({
  assortmentId,
  supplierIds,
  groupNumbers,
  query,
  includeOwner = false,
  page,
  size,
  ...queryOptions
}: Props) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<PaginationResultDTO<AssortmentItemDTO[]>>({
    method: 'GET',
    queryKey: [
      'assortmentItems',
      assortmentId,
      supplierIds,
      includeOwner,
      groupNumbers,
      query,
      page,
      size,
    ],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${assortmentPath}/${assortmentId}${itemsPath}`,
      searchParams: { supplierIds, groupNumbers, includeOwner, query, page, size },
    }),
    ...queryOptions,
  });
}

interface PostProps {
  memberId: string;
}

export function useUpdateAssortmentItems({
  assortmentId,
  memberId,
  ...queryOptions
}: Pick<Props, 'assortmentId'> & PostProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<void, ItemsDTO[]>({
    method: 'POST',
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${membersRoute}/${memberId}${assortmentPath}/${assortmentId}${itemsPath}`,
    }),
    token,
    ...queryOptions,
  });
}

export function useDeleteItemsFromDraft({
  assortmentId,
  memberId,
  ...queryOptions
}: Pick<Props, 'assortmentId'> & PostProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<void, number[]>({
    method: 'DELETE',
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${membersRoute}/${memberId}${assortmentPath}/${assortmentId}${deleteItemsPath}`,
    }),
    token,
    ...queryOptions,
  });
}
