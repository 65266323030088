import { Link, Stack, useTheme } from '@mui/material';
import { SalesPriceView } from './SalesPriceView';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ContainedButton, TextButton } from '@shared/components';
import { useDisclosure } from '@shared/hooks';
import { SalesPrice } from '@retail/my-assortment/types';
import { SalesCoverageView } from './SalesCoverageView';
import { Dispatch, SetStateAction } from 'react';
import { EditedMarkupField } from './ProductCard';
interface Props {
  prices?: SalesPrice;
  packageUnit: string;
  editSalesFactor: boolean;
  isPrimaryPackage?: boolean;
  costPrice?: number;
  editedMarkupField: EditedMarkupField;
  setEditedMarkupField: Dispatch<SetStateAction<EditedMarkupField>>;
  resetMarkupValues: () => void;
  toggleCreateConditionDialog: () => void;
}

export function SalesPriceSummary({
  prices,
  packageUnit,
  editSalesFactor,
  isPrimaryPackage,
  costPrice,
  editedMarkupField,
  setEditedMarkupField,
  resetMarkupValues,
  toggleCreateConditionDialog,
}: Props) {
  const t = useMyAssortmentTFunction();
  const { palette } = useTheme();

  const { isOpen: editSalesPrice, onToggle: toggleEditSalesPrice } = useDisclosure(false);
  const { isOpen: editDG, onToggle: toggleEditDG } = useDisclosure(false);

  const hasChanges = editedMarkupField !== undefined;

  return (
    <Stack px={2} py={1.5} borderTop={`1px solid ${palette.divider}`} bgcolor={palette.success[50]}>
      <SalesPriceView
        prices={prices}
        packageUnit={packageUnit}
        costPrice={costPrice}
        editSalesPrice={editSalesPrice}
        isPrimaryPackage={isPrimaryPackage}
        editedMarkupField={editedMarkupField}
        setEditedMarkupField={setEditedMarkupField}
        toggleEditSalesPrice={toggleEditSalesPrice}
        resetMarkupValues={resetMarkupValues}
      />

      <SalesCoverageView
        prices={prices}
        editPriceCoverage={editDG}
        isPrimaryPackage={isPrimaryPackage}
        editedMarkupField={editedMarkupField}
        setEditedMarkupField={setEditedMarkupField}
        toggleEditPriceCoverage={toggleEditDG}
        resetMarkupValues={resetMarkupValues}
      />

      <Stack height={2} bgcolor={palette.divider} my={1.5} />
      <Stack flexDirection="row" justifyContent="space-between" alignItems="baseline">
        <Link component="button">{t('myAssortment.seePriceHistory')}</Link>
        {hasChanges && (
          <Stack flexDirection="row" gap={1} flexWrap="nowrap">
            <TextButton onClick={resetMarkupValues}>{t('myAssortment.cancel')}</TextButton>
            <ContainedButton
              disabled={editSalesPrice || editDG || editSalesFactor}
              onClick={toggleCreateConditionDialog}
              color="secondary"
              size="small"
            >
              {t('myAssortment.createMarkup')}
            </ContainedButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
