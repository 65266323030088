import { useFetchMgAssortments } from '@retail/monitoring-assortment/data-access';
import { useCallback, useMemo } from 'react';
import { AssortmentBase, MgAssortmentBase } from '@retail/monitoring-assortment/types';
import { MgAssortmentContainer } from '@retail/monitoring-assortment/containers';
import {
  NotFoundError,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
  TagBreadcrumbs,
} from '@retail/components';
import { useSelectedOrgUnit, useUserBrands } from '@retail/app/stores/selected-context';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import { Chip, OutlinedButton, Paper } from '@shared/components';
import { AssortmentPicker, CentralAssortmentLogo } from '@retail/monitoring-assortment/components';
import { Box, Stack, useTheme } from '@mui/material';

export function MgAssortmentPage() {
  const t = useAppTFunction();

  const { palette } = useTheme();
  const navigate = useNavigate();

  const { selectedBrandLogos } = useUserBrands();
  const { selectedPPSContext } = useSelectedOrgUnit();
  const { assortmentId } = useParams<{ assortmentId: string }>();

  const { data: mgAssortmentDtos = [] } = useFetchMgAssortments();
  const selectedAssortment = mgAssortmentDtos.find(
    (assortment) => assortment.id === Number(assortmentId)
  );

  const otherAssortments = useMemo(
    () =>
      mgAssortmentDtos
        .filter((assortment) => assortment.id !== Number(assortmentId))
        .map(MgAssortmentBase.fromDto),
    [mgAssortmentDtos, assortmentId]
  );

  const selectAssortment = useCallback(
    (assortment: AssortmentBase | null) => {
      if (assortment) {
        navigate(
          mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath(assortment.id + '')
        );
      }
    },
    [navigate]
  );

  return (
    <PageLayout maxWidth="full" spacing={2}>
      <Paper
        paddingY="dense"
        sx={{ position: 'relative', flexGrow: 1, display: 'flex', flexDirection: 'column', pb: 1 }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            <TagBreadcrumbs
              items={[
                {
                  label: t('assortment.marketInsight'),
                  href: mgPrisRoutes.marketInsight.getFullLinkPath(),
                },
                { label: selectedAssortment?.title || assortmentId + '' },
              ]}
            />
            <Stack direction="row" gap={1.5}>
              {selectedPPSContext.id !== selectedAssortment?.contextId && selectedAssortment && (
                <CentralAssortmentLogo selectedBrandLogo={selectedBrandLogos.circularLogo} />
              )}
              <Chip
                label={t('assortment.items', { count: selectedAssortment?.size })}
                backgroundColor={palette.secondary[400]}
                sx={{ cursor: 'inherit' }}
              />
            </Stack>
          </Stack>

          {selectedAssortment && (
            <Stack gap={1.5} flexDirection="row" alignItems="center">
              <AssortmentPicker
                assortments={otherAssortments}
                selectedAssortment={null}
                selectAssortment={selectAssortment}
                width={260}
                alternativeLabel={t('assortment.changeAssortment')}
              />

              <OutlinedButton
                component={Link}
                to={mgPrisRoutes.marketInsight.competitors.fullRoutePath}
                sx={{ whiteSpace: 'nowrap' }}
                size="small"
              >
                {t('assortment.competitors.heading')}
              </OutlinedButton>
            </Stack>
          )}
        </Box>

        <SuspenseWithSentryErrorBoundary>
          {selectedAssortment ? (
            <MgAssortmentContainer selectedAssortmentId={Number(assortmentId)} />
          ) : (
            <NotFoundError
              title={t('assortment.mgAssortment.notFound.title')}
              subtitle={t('assortment.mgAssortment.notFound.subtitle', { assortmentId })}
              buttonText={t('assortment.mgAssortment.notFound.goBack')}
              navigateTo={mgPrisRoutes.marketInsight.fullRoutePath}
            />
          )}
        </SuspenseWithSentryErrorBoundary>
      </Paper>
    </PageLayout>
  );
}
