import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { assortmentsPath } from '@retail/calculus/data-access';
import { urlConstant } from '@retail/utils';
import { QueryOptions } from '@shared/fetch-utils';
import { itemsPath } from './assortmentItemsApi';
import { ItemsDTO } from '@retail/my-assortment/types';
import { useMutateMPEFetch } from '@retail/app/hooks';

const membersPath = '/members';

interface PostProps extends QueryOptions {
  memberId: string;
  assortmentId: number;
}

export function useMutateAddItemsToDraft({ memberId, assortmentId, ...queryOptions }: PostProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<void, ItemsDTO[]>({
    method: 'POST',
    url: `${mpe.url}${urlConstant}${membersPath}/${memberId}${assortmentsPath}/${assortmentId}${itemsPath}`,
    token,
    ...queryOptions,
  });
}
