import { MgSupplierItemSearchResultDTO } from '../../dto';
import { ProductGroup } from '../finfo';

interface ConstructorProps {
  finfoNumber: number;
  mgSupplierItemId: number;
  mgParticipantAccountId: number;
  mgParticipantAccountName: string;
  participantAccountName: string;
  mgItemNumber: number;
  bk04Varegruppe?: ProductGroup;
  primaryText: string;
  finfoSupplierId?: number;
  secondaryText?: string;
}

export class MgSupplierItemSearchResult {
  finfoNumber: number;
  mgSupplierItemId: number;
  mgParticipantAccountId: number;
  mgParticipantAccountName: string;
  participantAccountName: string;
  mgItemNumber: number;
  bk04Varegruppe?: ProductGroup;
  primaryText: string;
  finfoSupplierId?: number;
  secondaryText?: string;

  constructor({
    finfoNumber,
    mgSupplierItemId,
    mgParticipantAccountId,
    mgParticipantAccountName,
    participantAccountName,
    mgItemNumber,
    bk04Varegruppe,
    primaryText,
    finfoSupplierId,
    secondaryText,
  }: ConstructorProps) {
    this.finfoNumber = finfoNumber;
    this.mgSupplierItemId = mgSupplierItemId;
    this.mgParticipantAccountId = mgParticipantAccountId;
    this.mgParticipantAccountName = mgParticipantAccountName;
    this.participantAccountName = participantAccountName;
    this.mgItemNumber = mgItemNumber;
    this.bk04Varegruppe = bk04Varegruppe;
    this.primaryText = primaryText;
    this.finfoSupplierId = finfoSupplierId;
    this.secondaryText = secondaryText;
  }

  static fromSearchResultDTOs = (
    searchResultDtos: MgSupplierItemSearchResultDTO[],
    bk04Varegrupper: ProductGroup[]
  ): MgSupplierItemSearchResult[] =>
    searchResultDtos.map(
      (dto) =>
        new MgSupplierItemSearchResult({
          finfoNumber: dto.finfoNumber,
          mgSupplierItemId: dto.mgSupplierItemId,
          mgParticipantAccountId: dto.mgParticipantAccountId,
          mgParticipantAccountName: dto.mgParticipantAccountName,
          participantAccountName: dto.participantAccountName,
          mgItemNumber: dto.mgItemNumber,
          bk04Varegruppe: bk04Varegrupper.find(
            ({ groupNumber }) => dto.bk04ProductGroup === groupNumber
          ),
          primaryText: dto.primaryText,
          finfoSupplierId: dto.finfoSupplierId,
          secondaryText: dto.secondaryText,
        })
    );
}

interface MinimalConstructorProps {
  mgSupplierId: number;
  participantAccountName: string;
  bk04ProductGroup: string;
  finfoSupplierId?: number;
  finfoNumber: number;
  mgItemNumber: number;
  mgSupplierItemId: number;
  primaryText: string;
  secondaryText?: string;
}

export class MgSupplierItemSearch {
  mgSupplierId: number;
  participantAccountName: string;
  bk04ProductGroup: string;
  finfoSupplierId?: number;
  finfoNumber: number;
  mgItemNumber: number;
  mgSupplierItemId: number;
  primaryText: string;
  secondaryText?: string;

  constructor({
    mgSupplierId,
    participantAccountName,
    bk04ProductGroup,
    finfoSupplierId,
    finfoNumber,
    mgItemNumber,
    mgSupplierItemId,
    primaryText,
    secondaryText,
  }: MinimalConstructorProps) {
    this.mgSupplierId = mgSupplierId;
    this.participantAccountName = participantAccountName;
    this.finfoSupplierId = finfoSupplierId;
    this.finfoNumber = finfoNumber;
    this.bk04ProductGroup = bk04ProductGroup;
    this.mgItemNumber = mgItemNumber;
    this.mgSupplierItemId = mgSupplierItemId;
    this.primaryText = primaryText;

    this.secondaryText = secondaryText;
  }

  static fromDTO = (searchResultDtos: MgSupplierItemSearchResultDTO[]): MgSupplierItemSearch[] =>
    searchResultDtos.map(
      (dto) =>
        new MgSupplierItemSearch({
          mgSupplierId: dto.mgParticipantAccountId,
          participantAccountName: dto.participantAccountName,
          finfoSupplierId: dto.finfoSupplierId,
          finfoNumber: dto.finfoNumber,
          bk04ProductGroup: dto.bk04ProductGroup,
          mgItemNumber: dto.mgItemNumber,
          mgSupplierItemId: dto.mgSupplierItemId,
          primaryText: dto.primaryText,
          secondaryText: dto.secondaryText,
        })
    );
}
