import ContentLoader from 'react-content-loader';
import { Box, Link, useTheme } from '@mui/material';
import { useFreightTFunction } from '@retail/freight/i18n';
import { useAffectedItems } from '@retail/calculus/context';
import { CalculusAreas } from '@retail/calculus/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';

interface Props {
  productGroup: string;
  mgSupplierId: number;
  type: CalculusAreas;
  openItemsAffectedModal: () => void;
}

export const ItemsAffected = ({
  productGroup,
  mgSupplierId,
  type,
  openItemsAffectedModal,
}: Props) => {
  const t = useFreightTFunction();
  const { palette } = useTheme();
  const { baseAssortmentId } = useDepartmentAssortments();

  const { result, isLoading } = useAffectedItems({
    assortmentId: baseAssortmentId,
    productGroup,
    mgSupplierId,
    conditionType: type,
    initPageSize: 0,
  });

  return (
    <Box>
      {isLoading ? (
        <ContentLoader
          title={t('freight.itemsAffected.loading')}
          speed={1.5}
          width="100%"
          height="12px"
          backgroundColor={palette.grey[50]}
        >
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      ) : (
        <Link component="button" onClick={openItemsAffectedModal} fontWeight={700}>
          {result.totalItems}
        </Link>
      )}
    </Box>
  );
};
