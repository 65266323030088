import { Box, Stack, Typography, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Supplier } from '@retail/suppliers/types';
import { SupplierView } from './SupplierView';
import { SupplierSearchField } from './SupplierSearchField';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  suppliers: Supplier[];
  isAdmin: boolean;
  selectSupplier: (selectedSupplier: Supplier | null) => void;
  filterSuppliers: (search: string) => void;
}

export function AllSuppliersLayout({
  suppliers,
  selectSupplier,
  filterSuppliers,
}: PropsWithChildren<Props>) {
  const { palette } = useTheme();
  const t = useAppTFunction();

  return (
    <Stack width={550} bgcolor={palette.common.white} pb={1} alignItems="center">
      <Stack position="sticky" top={0} alignItems="center" zIndex={3} marginBottom="1px">
        <Box
          height={65}
          display="grid"
          bgcolor={palette.grey['600']}
          alignContent="center"
          width={550}
        >
          <Typography
            align="center"
            variant="h5"
            sx={{
              color: palette.common.white,
              backgroundColor: palette.grey['600'],
            }}
          >
            {t('common.supplierSelect.suppliers')}
          </Typography>
        </Box>

        <SupplierSearchField filterSuppliers={filterSuppliers} />
      </Stack>

      <Stack width={550}>
        {suppliers.map((supplier, index) => {
          return (
            <SupplierView
              key={supplier.mgSupplierId}
              index={index}
              supplier={supplier}
              selectSupplier={selectSupplier}
              hasBorderBottom={index < suppliers.length}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
