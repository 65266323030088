import { MgItem } from '@retail/products/types';
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useCarouselPreview } from './useCarouselPreview';
import { CarouselItem, MgCarousel } from './Carousel';

interface Props {
  mgItem: MgItem;
}

export function MgItemGallery({ mgItem }: Props) {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const items: CarouselItem[] = useMemo(() => {
    return mgItem.supplierItems.flatMap((supplierItem) => {
      return Object.entries(supplierItem.marketing || {})
        .map(([key, value]) => {
          if (value) {
            return {
              url: value,
              description: key,
            } as CarouselItem;
          }
          return null;
        })
        .filter((item): item is CarouselItem => item !== null);
    });
  }, [mgItem.supplierItems]);

  const { goToSnapItem } = useCarouselPreview(items.length);

  const handlePreviousClick = (num: number) => {
    if (num >= 0) {
      setActiveSlide(num);
      goToSnapItem(num);
    }
  };

  const handleNextClick = (num: number) => {
    if (num <= items.length - 1) {
      setActiveSlide(num);
      goToSnapItem(num);
    }
  };

  return (
    <Box display="flex" height="inherit">
      <Box sx={{ width: '100%' }}>
        <MgCarousel
          items={items}
          activeSlide={activeSlide}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
        />
      </Box>
    </Box>
  );
}
