import { Alert, Stack, Typography } from '@mui/material';
import { ImportedSpreadsheetTable } from './ImportedSpreadsheetTable';
import {
  getColumnHeader,
  IdentifiableColumn,
  useImportedAssortmentState,
  useSpreadsheetState,
} from './assortmentImportState';
import { useMemo } from 'react';
import { ImportSpreadsheetInput } from '@retail/components';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { replaceLastOccurrence } from '@shared/utils';

interface Props {
  columnsToRetrieve: Set<IdentifiableColumn>;
}

export const ImportAssortmentSpreadsheet: React.FC<Props> = ({ columnsToRetrieve }) => {
  const [spreadsheet, setSpreadsheet] = useSpreadsheetState();
  const importState = useImportedAssortmentState({ columnsToRetrieve });
  const t = useAssortmentTFunction();

  const retrievableColumnsText = useMemo(() => {
    const commaSeparated = [
      columnsToRetrieve.has('nobb') ? t('assortment.admin.create.fromFile.import.nobb') : null,
      columnsToRetrieve.has('finfo') ? t('assortment.admin.create.fromFile.import.finfo') : null,
      columnsToRetrieve.has('gtin') ? t('assortment.admin.create.fromFile.import.gtin') : null,
      columnsToRetrieve.has('mgItem') ? t('assortment.admin.create.fromFile.import.mgItem') : null,
    ]
      .filter((text) => !!text)
      .join(', ');
    return replaceLastOccurrence(commaSeparated, ',', ` ${t('assortment.common.or')}`);
  }, [t, columnsToRetrieve]);

  return (
    <>
      <ImportSpreadsheetInput
        label={
          !spreadsheet ? (
            <Typography>
              {t('assortment.admin.create.fromFile.import.uploadDescription', {
                numbers: retrievableColumnsText,
              })}
            </Typography>
          ) : (
            <Typography> {t('assortment.admin.create.fromFile.import.changeFile')}</Typography>
          )
        }
        onUpload={setSpreadsheet}
      />
      {spreadsheet && (
        <Stack mt={2}>
          {importState.status === 'success' && (
            <Alert severity="success">
              <Typography>{t('assortment.admin.create.fromFile.import.success.title')}</Typography>
            </Alert>
          )}
          {importState.status === 'error' && (
            <Alert severity="error">
              <Typography>{t('assortment.admin.create.fromFile.import.error.generic')}</Typography>
              {importState.columnsState && (
                <Typography>
                  {t(`assortment.admin.create.fromFile.import.error.${importState.columnsState}`, {
                    columnNames: retrievableColumnsText,
                  })}
                </Typography>
              )}
            </Alert>
          )}
          <ImportedSpreadsheetTable
            {...spreadsheet}
            shallHighlightColumn={(header) => !!getColumnHeader(header)}
          />
        </Stack>
      )}
    </>
  );
};
