// Convert JSON to CSV
const convertToCSV = (objArray: any, delimeter: string) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  // Create the CSV header row
  const keys = Object.keys(array[0]);
  str += keys.join(delimeter) + '\r\n'; // Use semicolon as delimiter

  // Create the CSV data row(s)
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line !== '') line += delimeter;
      line += array[i][index] !== null ? array[i][index] : ''; // Handle null values
    }
    str += line + '\r\n';
  }
  return str;
};

// Convert CSV to JSON
const convertCsvToJson = (csv: string, delimeter: string) => {
  const [header] = csv.split('\n');
  const keys = header.split(delimeter);

  // Create an array with a single object
  const jsonResult = keys.reduce((acc: any, key: any) => {
    acc[key] = null; // Assigning null as placeholder value for each key
    return acc;
  }, {});

  return [jsonResult];
};

export const generateTemplateCsv = (csvHeaders: string, delimeter: string) => {
  const jsonObject = convertCsvToJson(csvHeaders, delimeter);
  const csvString = convertToCSV(jsonObject, delimeter);

  return csvString;
};

export const downloadTemplateCsv = (csvHeaders: string, delimeter = ';') => {
  const csvContent = generateTemplateCsv(csvHeaders, delimeter);

  const blob = new Blob([csvContent], { type: 'text/csv' });
  const csvURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = 'itemsTemplate.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
