export const calculateSalesCoverage = (salesFactor: number) => {
  return ((100 * (salesFactor - 1)) / salesFactor).toFixed(2);
};

export function calculateToPercentage(number: string) {
  const num = Number(number);

  const decimalPart = num - 1;
  const percentage = decimalPart * 100;

  return percentage;
}

export function calculateFromPercentage(percentageValue: string) {
  const percentage = Number(percentageValue);

  return percentage / 100 + 1;
}

export function calculateVat(price: number, excludeVat: boolean) {
  return excludeVat ? price * 1.25 : price;
}

export function calculateDGFromSalesFactor(salesFactor: number) {
  return 100 * ((salesFactor - 1) / salesFactor);
}

export function calculateSalesFactorFromDG(DG: number) {
  return 1 / (1 - DG / 100);
}

export function calculateNewPriceFromDG(price: number, oldDG: number, DG: number) {
  const oldPriceWithoutDG = price / calculateSalesFactorFromDG(oldDG);
  return oldPriceWithoutDG * calculateSalesFactorFromDG(DG);
}

export function calculatePriceFromSalesFactor(purchasePrice: number, newSalesFactor: number) {
  return purchasePrice * newSalesFactor;
}

export function calculateNewSalesFactor(purchasePrice: number, newPrice: number) {
  return newPrice / purchasePrice;
}

export function calculateNewDG(purchasePrice: number, newPrice: number) {
  const newSalesFactor = calculateNewSalesFactor(purchasePrice, newPrice);
  return calculateDGFromSalesFactor(newSalesFactor);
}
