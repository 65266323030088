import { Menu, MenuItem } from '@mui/material';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { Actions } from '@retail/calculus/types';

interface Props {
  id: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  onlyOneAgreement: boolean;
  isActivated: boolean;
  isInherited: boolean;
  setAction: (action: Actions) => void;
  onClose: () => void;
}

export const ItemAgreementsTableActions = ({
  id,
  open,
  anchorEl,
  onlyOneAgreement,
  isActivated,
  isInherited,
  onClose,
  setAction,
}: Props) => {
  const t = usePurchaseAgreementsTFunction();

  return (
    <Menu open={open} id={id} anchorEl={anchorEl} onClose={onClose}>
      {onlyOneAgreement ? (
        isInherited && (
          <MenuItem
            disabled={isActivated}
            onClick={() => {
              setAction('activate');
            }}
          >
            {t('purchaseAgreements.agreement.actions.activateOnItemLevel')}
          </MenuItem>
        )
      ) : (
        <MenuItem
          onClick={() => {
            setAction('edit');
            onClose();
          }}
        >
          {t('purchaseAgreements.agreement.actions.edit')}
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          setAction('view');
          onClose();
        }}
      >
        {t('purchaseAgreements.agreement.actions.openItem')}
      </MenuItem>
    </Menu>
  );
};
