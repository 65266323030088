import { Stack, Typography } from '@mui/material';
import { AddItemsMenu, MgSupplierItemsTableWithCheckbox } from '@retail/products/components';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Pagination as PaginationType } from '@shared/hooks';
import { ToggleSwitch } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';
import { mgprisHeaderHeight } from '@shared/styles';
import { useMutateAddItemsToDraft } from '@retail/my-assortment/data-access';
import { useDepartmentAssortments, useMemberAssortments } from '@retail/my-assortment/context';
import { ItemsDTO } from '@retail/my-assortment/types';
import { useToast } from '@retail/hooks';
import { useRefetchDraftDrawer } from '@retail/context';

interface Props {
  totalPages: number;
  totalItems: number;
  supplierItems: MgSupplierItemSearchResult[];
  checkedItems: MgSupplierItemSearchResult[];
  currentPage: number;
  includeAddButton: boolean;
  clickableRow?: boolean;
  hasDraftDrawerValues?: boolean;
  onlyItemsWithAgreementPrices: boolean;
  toggleOnlyItemsWithAgreementPrices: () => void;
  setCheckedItems: Dispatch<SetStateAction<MgSupplierItemSearchResult[]>>;
}

export function MgSupplierItemSearchResults({
  totalPages,
  totalItems,
  supplierItems,
  checkedItems,
  pageSize,
  currentPage,
  includeAddButton,
  clickableRow,
  hasDraftDrawerValues,
  onlyItemsWithAgreementPrices,
  toggleOnlyItemsWithAgreementPrices,
  setCheckedItems,
  setPage,
  setPageSize,
}: Props & Omit<PaginationType, 'page'>) {
  const t = useProductsTFunction();
  const toast = useToast();

  const { draftAssortment, draftAssortmentId, memberId } = useMemberAssortments();
  const { baseAssortmentId: departmentBaseAssortmentId } = useDepartmentAssortments();

  const { invalidateAssortmentDraft } = useRefetchDraftDrawer({
    departmentBaseAssortmentId,
    memberDraftAssortment: draftAssortment,
  });

  const { mutateAsync: addItems, isLoading: isLoadingAdd } = useMutateAddItemsToDraft({
    memberId,
    assortmentId: draftAssortmentId,
  });

  const onAddItemsToAssortment = useCallback(() => {
    const items = checkedItems.map((item) => {
      return {
        mgSupplierId: item.mgParticipantAccountId,
        mgItemNumber: item.mgItemNumber,
        priority: 'ZERO',
      } as ItemsDTO;
    });

    addItems({ body: items })
      .catch((err) => {
        console.error(err);
        toast.error(t('products.errors.generic'));
      })
      .then(() => {
        invalidateAssortmentDraft();
        toast.success(t('products.mgItem.itemsAdded', { count: checkedItems.length }));
      });
  }, [checkedItems, addItems, toast, t, invalidateAssortmentDraft]);

  return (
    <Stack display="flex" flexGrow={1} flexDirection="column">
      <Stack
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        px={4}
        py={2}
        position={includeAddButton ? 'sticky' : 'inherit'}
        top={mgprisHeaderHeight}
        zIndex={1}
        bgcolor={(theme) => theme.palette.common.white}
      >
        <Typography>{t('products.search.result', { count: totalItems })}</Typography>
        <Typography flex={1} textAlign="center">
          {t('products.mgItem.selectedItems', {
            selected: checkedItems.length,
            total: supplierItems.length,
          })}
        </Typography>

        <Stack flexDirection="row" gap={1}>
          <ToggleSwitch
            text={t('products.search.itemsWithPurchasePrice')}
            toggle={onlyItemsWithAgreementPrices}
            setToggle={toggleOnlyItemsWithAgreementPrices}
          />

          {includeAddButton && (
            <AddItemsMenu
              onAddItemsToAssortment={onAddItemsToAssortment}
              disabled={checkedItems.length === 0}
              isLoading={isLoadingAdd}
            />
          )}
        </Stack>
      </Stack>

      <Stack
        display="flex"
        flexGrow={1}
        sx={{
          overflowY: 'auto',
        }}
      >
        <MgSupplierItemsTableWithCheckbox
          items={supplierItems}
          clickableRow={clickableRow}
          selection={{ selectedItems: checkedItems, selectItem: setCheckedItems }}
          hasDraftDrawerValues={hasDraftDrawerValues}
          pageSize={pageSize}
          page={currentPage}
          setPage={setPage}
          setPageSize={setPageSize}
          totalPages={totalPages}
        />
      </Stack>
    </Stack>
  );
}
