import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext } from '@retail/auth/data-access';
import { MgAssortmentDTO, MgAssortmentsPageDTO } from '@retail/monitoring-assortment/types';
import { buildUrl } from '@shared/utils';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

export const mgAssortmentsRoute = '/v1/mg-assortment';
export const validateItemsRoute = '/v1/mg-item/validate-item-codes';

export const getMgAssortmentsQueryKey = (mgItemNumbers: number[] = []) => [
  'mgAssortments',
  mgItemNumbers,
];

export const getMgAssortmentQueryKey = (assortmentId: number) => ['mgAssortments', assortmentId];

export function useFetchMgAssortments(
  mgItemNumbers: number[] = [],
  queryOptions: QueryOptions = {}
) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const { selectedPPSContext } = useSelectedOrgUnit();

  return useFetch<MgAssortmentDTO[]>({
    method: 'GET',
    queryKey: getMgAssortmentsQueryKey(mgItemNumbers),
    token,
    url: buildUrl({
      base: `${pps.url}${mgAssortmentsRoute}`,
      searchParams: {
        mgItemNumbers,
        excludedSources: ['SOLVE'], // exclude temporarily
      },
    }),
    context: { key: selectedPPSContext.type, value: selectedPPSContext.value },
    ...queryOptions,
  });
}

interface SearchParams {
  assortmentId: number;
  competitorStoreIds: number[];
  page?: number;
  pageSize?: number;
}

export function useFetchMgAssortment(
  { assortmentId, competitorStoreIds, page, pageSize }: SearchParams,
  queryOptions: QueryOptions = {}
) {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useFetch<MgAssortmentsPageDTO>({
    method: 'GET',
    queryKey: getMgAssortmentQueryKey(assortmentId),
    token,
    url: buildUrl({
      base: pps.url,
      route: `${mgAssortmentsRoute}/${assortmentId}`,
      searchParams: {
        storeIds: competitorStoreIds,
        page,
        pageSize,
        historicalPrices: false,
        includeByggmaterialpriserPrices: true,
        includeFinfoAgreementPrices: false,
        includeFinfoPrices: false,
        modelType: 'MINIMIZED',
      },
    }),
    ...queryOptions,
  });
}
