import { EnvironmentKeys } from '@retail/products/types';

export const environmentKeys: EnvironmentKeys[] = [
  'svhcSubstances',
  'betaRegisteredInBasta',
  'svaneMarked',
  'goodEnvironmentalChoiceMarked',
  'pefcCertified',
  'fscCertified',
  'bastaRegistered',
  'ecoLabeled',
  'reachRegulated',
  'standardizedEpd',
  'euRegulated',
  'endocrineDisruptingProperties',
  'moreThanFortyPercentChlorate',
  'twelvePercentHydrogenPeroxideOrThirtyPercentNitromethaneOrThreePercentNitricAcid',
  // 'environmentDeclarationUrl',
];
