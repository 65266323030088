import { useMemo, useState } from 'react';
import { useAppTranslation } from '@retail/app/i18n';
import { BreadcrumbsProps, Paper } from '@shared/components';
import {
  BreadcrumbsToolbar,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import {
  HasStandardAgreementOption,
  SuppliersTable,
  SuppliersTableToolbar,
} from '@retail/suppliers/components';
import { Supplier } from '@retail/suppliers/types';
import { useFetchAllSuppliers } from '@retail/products/data-access';
import { useDisclosure, useSelectedSearchParamState } from '@shared/hooks';
import { SupplierAgreementsDialogContainer } from '@retail/suppliers/containers';
import { useDepartmentAssortments } from '@retail/my-assortment/context';

export function SuppliersPage() {
  const { t } = useAppTranslation();
  const [searchInput, setSearchInput] = useState<string>('');
  const { isOpen: hasAgreements, onToggle: hasAgreementsToggle } = useDisclosure(true);
  const [hasStandardAgreement, setHasStandardAgreement] =
    useState<HasStandardAgreementOption>('UNSPECIFIED');

  const breadcrumbItems: BreadcrumbsProps['items'] = useMemo(() => {
    return [{ label: t('app.routes.suppliers') }];
  }, [t]);

  const { baseAssortmentId } = useDepartmentAssortments();
  const { data: suppliersDtos = [] } = useFetchAllSuppliers({
    withPurchaseAgreements: hasAgreements,
    assortmentId: baseAssortmentId,
    withStandardAgreement:
      hasStandardAgreement === 'UNSPECIFIED' ? undefined : hasStandardAgreement === 'YES',
  });
  const suppliers = useMemo(() => suppliersDtos.map(Supplier.fromMinimalDTO), [suppliersDtos]);
  const filteredSuppliers = useMemo(
    () =>
      suppliers.filter(({ name }) =>
        name.toLocaleLowerCase().startsWith(searchInput.toLocaleLowerCase())
      ),
    [searchInput, suppliers]
  );
  const [selectedSupplier, _, setSelectedSupplierId] = useSelectedSearchParamState({
    options: suppliers,
    getOptionId: ({ mgSupplierId }) => mgSupplierId,
    searchParamKey: 'supplier',
  });

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        paddingY="dense"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <BreadcrumbsToolbar items={breadcrumbItems} />

        <SuspenseWithSentryErrorBoundary>
          <SuppliersTableToolbar
            searchInput={searchInput}
            onSearchChange={setSearchInput}
            hasAgreements={hasAgreements}
            hasAgreementsToggle={hasAgreementsToggle}
            hasStandardAgreementOption={hasStandardAgreement}
            onChangeHasStandardAgreementOption={setHasStandardAgreement}
          />
          <SuppliersTable suppliers={filteredSuppliers} onSupplierSelect={setSelectedSupplierId} />
        </SuspenseWithSentryErrorBoundary>
      </Paper>
      {selectedSupplier && (
        <SupplierAgreementsDialogContainer
          supplier={selectedSupplier}
          onClose={() => setSelectedSupplierId(null)}
          open
        />
      )}
    </PageLayout>
  );
}
