import { AssortmentItem, SalesPrice } from '@retail/my-assortment/types';

export const getPrices = (item: AssortmentItem) => {
  if (item.calculatedPrices.length > 0) {
    return item.calculatedPrices;
  }
  const prices = item.simulatedPrices.length > 0 ? item.simulatedPrices[0].prices : undefined;
  return prices;
};

export const getPrice = (pricesForPackages: SalesPrice[], unit: string) => {
  const prices = pricesForPackages.find((prices) => prices.unit === unit);
  return prices;
};
