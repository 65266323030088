import { MpeMgSupplierItemEtimDTO } from '../../dto';

export interface MgItemEtimType {
  unnr?: string;
  minTradeLifespan?: number;
  minStorageTmp?: number;
  unspsc?: string;
  shelfLifeMarked?: boolean;
  shelfLifeDays?: number;
  etimClass?: string;
  brandName?: string;
  buildingProductDeclarationUrl?: string;
  informationSheetUrl?: string;
  safetyDataSheetUrl?: string;
  userManualUrl?: string;
  declarationOfPerformanceUrl?: string;
  assemblyInstructionsUrl?: string;
  spaceImageFrontUrl?: string;
  spaceImageAboveUrl?: string;
  spaceImageLeftUrl?: string;
  threeDimensionalImageUrl?: string;
  explodedViewImageUrl?: string;
  lineSketchDrawingUrl?: string;
}

export type EtimKeys = keyof MgItemEtimType;
export class MgItemEtim {
  unnr?: string;
  minTradeLifespan?: number;
  minStorageTmp?: number;
  unspsc?: string;
  shelfLifeMarked?: boolean;
  shelfLifeDays?: number;
  etimClass?: string;
  brandName?: string;
  buildingProductDeclarationUrl?: string;
  informationSheetUrl?: string;
  safetyDataSheetUrl?: string;
  userManualUrl?: string;
  declarationOfPerformanceUrl?: string;
  assemblyInstructionsUrl?: string;
  spaceImageFrontUrl?: string;
  spaceImageAboveUrl?: string;
  spaceImageLeftUrl?: string;
  threeDimensionalImageUrl?: string;
  explodedViewImageUrl?: string;
  lineSketchDrawingUrl?: string;

  constructor(
    unnr?: string,
    minTradeLifespan?: number,
    minStorageTmp?: number,
    unspsc?: string,
    shelfLifeMarked?: boolean,
    shelfLifeDays?: number,
    etimClass?: string,
    brandName?: string,
    buildingProductDeclarationUrl?: string,
    informationSheetUrl?: string,
    safetyDataSheetUrl?: string,
    userManualUrl?: string,
    declarationOfPerformanceUrl?: string,
    assemblyInstructionsUrl?: string,
    spaceImageFrontUrl?: string,
    spaceImageAboveUrl?: string,
    spaceImageLeftUrl?: string,
    threeDimensionalImageUrl?: string,
    explodedViewImageUrl?: string,
    lineSketchDrawingUrl?: string
  ) {
    this.unnr = unnr;
    this.minTradeLifespan = minTradeLifespan;
    this.minStorageTmp = minStorageTmp;
    this.unspsc = unspsc;
    this.shelfLifeMarked = shelfLifeMarked;
    this.shelfLifeDays = shelfLifeDays;
    this.etimClass = etimClass;
    this.brandName = brandName;
    this.buildingProductDeclarationUrl = buildingProductDeclarationUrl;
    this.informationSheetUrl = informationSheetUrl;
    this.safetyDataSheetUrl = safetyDataSheetUrl;
    this.userManualUrl = userManualUrl;
    this.declarationOfPerformanceUrl = declarationOfPerformanceUrl;
    this.assemblyInstructionsUrl = assemblyInstructionsUrl;
    this.spaceImageFrontUrl = spaceImageFrontUrl;
    this.spaceImageAboveUrl = spaceImageAboveUrl;
    this.spaceImageLeftUrl = spaceImageLeftUrl;
    this.threeDimensionalImageUrl = threeDimensionalImageUrl;
    this.explodedViewImageUrl = explodedViewImageUrl;
    this.lineSketchDrawingUrl = lineSketchDrawingUrl;
  }

  static fromDto = (dto: MpeMgSupplierItemEtimDTO): MgItemEtim =>
    new MgItemEtim(
      dto.unnr,
      dto.minTradeLifespan,
      dto.minStorageTmp,
      dto.unspsc,
      dto.shelfLifeMarked,
      dto.shelfLifeDays,
      dto.etimClass,
      dto.brandName,
      dto.buildingProductDeclarationUrl,
      dto.informationSheetUrl,
      dto.safetyDataSheetUrl,
      dto.userManualUrl,
      dto.declarationOfPerformanceUrl,
      dto.assemblyInstructionsUrl,
      dto.spaceImageFrontUrl,
      dto.spaceImageAboveUrl,
      dto.spaceImageLeftUrl,
      dto.threeDimensionalImageUrl,
      dto.explodedViewImageUrl,
      dto.lineSketchDrawingUrl
    );
}
