import { AssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import * as yup from 'yup';

export const deletionFromDateSchema = (t: AssortmentTFunction) =>
  yup.object({
    fromDate: yup
      .date()
      .default(new Date())
      .nullable()
      .required(t('assortment.deleteItems.error.addFromDate')),
  });

export type DeletionFromDateSchema = yup.InferType<ReturnType<typeof deletionFromDateSchema>>;
