import { HierarchyLevels } from '@retail/calculus/types';
import { useLevelSearchParams } from './useLevelSearchParams';
import { InheritedConditionNavigation } from '@retail/purchase-agreements/types';

export const useLevelNavigation = () => {
  const { selectLevel } = useLevelSearchParams();

  const navigateToInheritedCondition = ({
    bindingType,
    mgSupplierId,
    productGroupNumber,
    productGroupName,
    mgItemNumber,
    mgItemName,
  }: InheritedConditionNavigation) => {
    if (bindingType === HierarchyLevels.supplier) {
      selectLevel({
        supplierId: mgSupplierId,
        level: HierarchyLevels.supplier,
      });
    } else if (bindingType === HierarchyLevels.item) {
      selectLevel({
        mgSupplierItem: { id: mgItemNumber!, name: mgItemName! },
        level: HierarchyLevels.item,
      });
    } else if (
      (bindingType === HierarchyLevels.mainGroup ||
        bindingType === HierarchyLevels.overGroup ||
        bindingType === HierarchyLevels.productGroup) &&
      productGroupNumber &&
      productGroupName
    ) {
      selectLevel({
        productGroup: { groupNumber: productGroupNumber, name: productGroupName },
        level: bindingType,
      });
    }
  };

  return {
    navigateToInheritedCondition,
  };
};
