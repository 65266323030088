import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useMpeAuthContext } from './AuthContext';
import { urlConstant } from '@retail/utils';
import { brandsRoute } from './brandsApi';
import { MemberDTO } from './dto';

export const membersRoute = '/members';

export function useFetchMembers(brandId: string, queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useFetch<MemberDTO[]>({
    method: 'GET',
    queryKey: ['members', brandId, token],
    url: `${mpe.url}${urlConstant}${brandsRoute}${brandId}${membersRoute}`,
    token,
    ...queryOptions,
  });
}
