import { useProductsTFunction } from '@retail/products/i18n';
import { MgItem, MgSupplierItem, MgItemTabs } from '@retail/products/types';
import { useState } from 'react';
import { UnderlinedTabs } from '@shared/components';
import { Box } from '@mui/material';
import {
  Documentation,
  EnvironmentDocumentation,
  ProductInformation,
  SupplierItems,
  JSON,
} from '@retail/products/components';
import { MgItemCompetitorPrices } from './MgItemCompetitorPrices';

interface Props {
  mgItem: MgItem;
}

export function MgItemTabContainer({ mgItem }: Props) {
  const t = useProductsTFunction();

  const [currentTab, setCurrentTab] = useState<MgItemTabs>(MgItemTabs.PRODUCT_INFORMATION);
  const [selectedSupplierItem, setSelectedSupplierItem] = useState<MgSupplierItem>(
    mgItem.getPrimarySupplierItem()
  );

  const selectTab = (_: any, newTab: MgItemTabs) => setCurrentTab(newTab);

  const tabs = [
    { label: t('products.mgItem.details.title'), value: MgItemTabs.PRODUCT_INFORMATION },
    { label: t('products.mgItem.supplierItem.packages'), value: MgItemTabs.PACKAGING },
    { label: t('products.mgItem.documentation.title'), value: MgItemTabs.DOCUMENTATION },
    {
      label: t('products.mgItem.environmentDocumentation.title'),
      value: MgItemTabs.ENVIRONMENTAL_DOCUMENTATION,
    },
    {
      label: t('products.mgItem.json.title'),
      value: MgItemTabs.JSON,
    },
    { label: t('products.prices.competitors.title'), value: MgItemTabs.COMPETITOR_PRICES },
  ];

  const getTabContent = () => {
    switch (currentTab) {
      case MgItemTabs.PRODUCT_INFORMATION:
        return <ProductInformation mgItem={mgItem} />;
      case MgItemTabs.PACKAGING:
        return <SupplierItems mgItem={mgItem} />;
      case MgItemTabs.DOCUMENTATION:
        return <Documentation mgItem={mgItem} />;
      case MgItemTabs.ENVIRONMENTAL_DOCUMENTATION:
        return <EnvironmentDocumentation mgItem={mgItem} />;
      case MgItemTabs.JSON:
        return (
          <JSON
            mgItem={mgItem}
            selectedSupplierItem={selectedSupplierItem}
            setSelectedSupplierItem={setSelectedSupplierItem}
          />
        );
      case MgItemTabs.COMPETITOR_PRICES:
        return <MgItemCompetitorPrices mgItem={mgItem} />;
      default:
        return null;
    }
  };

  return (
    <Box>
      <UnderlinedTabs id="item" tabs={tabs} currentTab={currentTab} onChange={selectTab} />
      {getTabContent()}
    </Box>
  );
}
