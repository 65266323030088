import { ButtonProps, Typography, useTheme } from '@mui/material';
import { OutlinedButton } from '@shared/components';

interface Props {
  name: string;
}

export const OrgUnitHeading: React.FC<ButtonProps & Props> = ({ name, sx, ...buttonProps }) => {
  const { palette } = useTheme();

  return (
    <OutlinedButton
      sx={{
        whiteSpace: 'nowrap',
        cursor: 'default',
        '&:hover': { background: palette.common.white },
        ...sx,
      }}
      size="small"
      {...buttonProps}
    >
      <Typography variant="body1" fontWeight="500" color={palette.text.primary}>
        {name}
      </Typography>
    </OutlinedButton>
  );
};
