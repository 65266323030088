import { Bk04HovedgruppeDTO, Bk04OvergruppeDTO, Bk04VaregruppeDTO } from '../../dto';
export interface GenericProductGroup {
  name?: string;
  id: number;
  commonGroupId: string;
}
export class Bk04Varegruppe {
  id: number;
  groupNumber: string;
  name?: string;
  constructor(id: number, groupNumber: string, name: string | undefined) {
    this.id = id;
    this.groupNumber = groupNumber;
    this.name = name;
  }
  getLabel = (): string => (this.name ? `${this.groupNumber} ${this.name}` : this.groupNumber);
  static fromDto = (dto: Bk04VaregruppeDTO): Bk04Varegruppe =>
    new Bk04Varegruppe(dto.id, dto.number, dto.name);
}
export class Bk04Hovedgruppe {
  id: number;
  groupNumber: string;
  name?: string;
  varegrupper: Bk04Varegruppe[];
  constructor(
    id: number,
    groupNumber: string,
    name: string | undefined,
    varegrupper: Bk04Varegruppe[]
  ) {
    this.id = id;
    this.groupNumber = groupNumber;
    this.name = name;
    this.varegrupper = varegrupper;
  }
  getLabel = (): string => (this.name ? `${this.groupNumber} ${this.name}` : this.groupNumber);
  static fromDto = (dto: Bk04HovedgruppeDTO): Bk04Hovedgruppe =>
    new Bk04Hovedgruppe(dto.id, dto.number, dto.name, dto.varegrupper.map(Bk04Varegruppe.fromDto));
}
export class Bk04Overgruppe {
  id: number;
  groupNumber: string;
  name?: string;
  hovedgrupper: Bk04Hovedgruppe[];
  constructor(
    id: number,
    groupNumber: string,
    name: string | undefined,
    hovedgrupper: Bk04Hovedgruppe[]
  ) {
    this.id = id;
    this.groupNumber = groupNumber;
    this.name = name;
    this.hovedgrupper = hovedgrupper;
  }
  getLabel = (): string => (this.name ? `${this.groupNumber} ${this.name}` : this.groupNumber);

  static flatten = (overGroups: Bk04Overgruppe[]): Bk04Varegruppe[] =>
    overGroups
      .sort((o1, o2) => Number(o1.groupNumber) - Number(o2.groupNumber))
      .flatMap(({ id, groupNumber, name, getLabel, hovedgrupper }) => [
        { id, groupNumber, name, getLabel },
        ...hovedgrupper
          .sort((h1, h2) => Number(h1.groupNumber) - Number(h2.groupNumber))
          .flatMap(({ id, groupNumber, name, getLabel, varegrupper }) => [
            { id, groupNumber, name, getLabel },
            ...varegrupper
              .sort((v1, v2) => Number(v1.groupNumber) - Number(v2.groupNumber))
              .map((varegrupper) => ({ ...varegrupper })),
          ]),
      ]);
  static fromDto = (dto: Bk04OvergruppeDTO): Bk04Overgruppe =>
    new Bk04Overgruppe(dto.id, dto.number, dto.name, dto.hovedgrupper.map(Bk04Hovedgruppe.fromDto));
}
