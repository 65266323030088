import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgItem, MgSupplierItem } from '@retail/products/types';
import { ContainedButton, Dialog } from '@shared/components';
import { useEffect, useState } from 'react';

interface Props {
  open: boolean;
  mgItem: MgItem;
  onClose: () => void;
  addItemToMyAssortment: (selectedSupplier: MgSupplierItem | null) => void;
}

export const MonitoringItemSelectionDialog = ({
  open,
  mgItem,
  onClose,
  addItemToMyAssortment,
}: Props) => {
  const t = useProductsTFunction();

  const [inputItemId, setInputItemId] = useState<MgSupplierItem['id']>('');

  const handleSelection = () => {
    const supplierItem = mgItem.supplierItems.find((item) => item.id === inputItemId);
    addItemToMyAssortment(supplierItem ?? null);
  };

  useEffect(() => {
    if (!open) setInputItemId('');
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('products.mgItem.documentation.selectSupplier')}{' '}
        {t('products.mgItem.addItemsToAssortment.forSelectedItem')}
      </DialogTitle>

      <DialogContent
        sx={{
          paddingTop: (theme) => `${theme.spacing(0.5)} !important`,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="monitoring-item-select-label">
            {t('products.mgItem.addItemsToAssortment.suppliers')}
          </InputLabel>
          <Select
            labelId="monitoring-item-select-label"
            id="monitoring-item-select"
            value={inputItemId}
            label={t('products.mgItem.documentation.selectSupplier')}
            onChange={(event) => setInputItemId(event.target.value)}
          >
            {mgItem.supplierItems.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                <Stack>
                  <Typography fontWeight={600}>{item.mgSupplierName}</Typography>
                  <Typography fontWeight={400}>{item.primaryText}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <ContainedButton disabled={!inputItemId} onClick={handleSelection}>
          {t('products.mgItem.addToMy')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
