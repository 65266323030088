import { Theme } from '@mui/material';

export const componentsStyles: Theme['components'] = {
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
        boxShadow: 'none', // Flattens design
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none', // Flattens design
      },
      rounded: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius * 2,
      }),
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        '&.MuiContainer-maxWidthMd': {
          maxWidth: '800px',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        letterSpacing: 'inherit',
      }),
    },
  },
};
