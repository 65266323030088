import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import Tooltip from '@mui/material/Tooltip';
import { CopyToClipboard } from '@retail/components';
import { QuestionIcon } from '@shared/custom-icons';
import { Chip } from '@shared/components';
import { MgItemDetailsProductGroup } from './MgItemDetailsProductGroup';
import { MgItem } from '@retail/products/types';
import { MgItemDetailsPackaging } from './MgItemDetailsPackaging';
import { MgItemMonitoring } from './MgItemMonitoring';
import { usePopupElement } from '@shared/hooks';

export interface Props {
  mgItem: MgItem;
  isSuperUser: boolean;
}

export function MgItemView({ mgItem, isSuperUser }: Props) {
  const { id } = mgItem;

  const t = useProductsTFunction();
  const { palette } = useTheme();

  const primarySupplierItem = mgItem.getPrimarySupplierItem();
  const itemGroupLabel = primarySupplierItem.bk04Varegruppe?.getLabel();

  const { menuElementProps, triggerElementProps } = usePopupElement();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack alignItems="flex-end">
          <MgItemMonitoring
            mgItem={mgItem}
            menuElementProps={menuElementProps}
            triggerElementProps={triggerElementProps}
          />
        </Stack>
      </Grid>

      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.details.primaryText')}
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary}>
        {primarySupplierItem.primaryText ?? primarySupplierItem.supplierProductName}
      </Grid>

      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.number.label')}
      </Grid>

      <Grid item fontWeight="medium" xs={6} lg={8} display="flex" alignItems="center">
        <CopyToClipboard value={id}>
          <Chip backgroundColor={palette.info[400]} label={id} />
        </CopyToClipboard>

        <Tooltip arrow title={t('products.mgItem.number.description')}>
          <QuestionIcon sx={{ color: palette.text.secondary }} fontSize="small" />
        </Tooltip>
      </Grid>

      <MgItemDetailsProductGroup label={itemGroupLabel} />

      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.supplierItem.mainSupplier')}
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary}>
        {primarySupplierItem.mgSupplierName}
      </Grid>

      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.supplierItem.finfoNumber.label')}
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8}>
        {primarySupplierItem.finfoNumber ? (
          <CopyToClipboard value={primarySupplierItem.finfoNumber}>
            <Typography color={palette.text.secondary}>
              {primarySupplierItem.finfoNumber}
            </Typography>
          </CopyToClipboard>
        ) : (
          '-'
        )}
      </Grid>

      <Grid item fontWeight="bold" xs={6} lg={4}>
        {t('products.mgItem.supplierItem.secondaryText')}
      </Grid>
      <Grid item fontWeight="medium" xs={6} lg={8} color={palette.text.secondary}>
        {primarySupplierItem.secondaryText}
      </Grid>

      <Grid item sx={{ overflow: 'auto', width: '100%' }}>
        <MgItemDetailsPackaging uniquePackages={mgItem.getPackages()} isSuperUser={isSuperUser} />
      </Grid>
    </Grid>
  );
}
