import { useState } from 'react';
import { Supplier } from '@retail/suppliers/types';
import { ConditionsTable } from '@retail/purchase-agreements/components';
import { PurchaseCondition } from '@retail/calculus/types';
import { Stack } from '@mui/material';
import { ActivateSelectedAgreementDialog } from './ActivateAgreement';
import { UniversalFormProvider } from '@shared/components';
import { conditionDatesSchema } from '@retail/calculus/components';
import { DeactivateAgreementDialog } from './DeactivateAgreementDialog';
import ChangeSelectedAgreementDialog from './ActivateAgreement/ChangeSelectedAgreementDialog';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { useCalculusConditions } from '@retail/calculus/context';
import { DeleteDialog } from '@retail/components';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';

interface Props {
  supplier: Supplier;
  conditions: PurchaseCondition[];
  agreements: PurchaseAgreement[];
  setPriceListValues: (agreement?: number, productGroupId?: string) => void;
}

export const Conditions = ({ supplier, conditions, agreements, setPriceListValues }: Props) => {
  const t = usePurchaseAgreementsTFunction();
  const { handleDeleteConditions } = useCalculusConditions();

  const [agreementToActivate, setAgreementToActivate] = useState<PurchaseCondition | null>(null);
  const [conditionToDeactivate, setConditionToDeactivate] = useState<PurchaseCondition | null>(
    null
  );
  const [agreementToEdit, setAgreementToEdit] = useState<PurchaseCondition | null>(null);
  const [conditionIdToDelete, setConditionIdToDelete] = useState<PurchaseCondition['id'] | null>(
    null
  );

  const handleActivate = (agreement: PurchaseCondition) => setAgreementToActivate(agreement);
  const handleDectivate = (agreement: PurchaseCondition) => setConditionToDeactivate(agreement);
  const handleEdit = (agreement: PurchaseCondition) => setAgreementToEdit(agreement);
  const handleDelete = (id: number) => setConditionIdToDelete(id);

  return (
    <Stack flexGrow={1}>
      <ConditionsTable
        supplier={supplier}
        conditions={conditions}
        openPriceListModal={setPriceListValues}
        handleActivate={handleActivate}
        handleDeactivate={handleDectivate}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />

      {agreementToActivate && (
        <UniversalFormProvider
          schema={conditionDatesSchema()}
          defaultValues={conditionDatesSchema().getDefault()}
        >
          <ActivateSelectedAgreementDialog
            isOpen
            supplierId={supplier.mgSupplierId}
            agreementId={agreementToActivate.agreementId}
            agreementText={agreementToActivate.agreementText}
            onClose={() => setAgreementToActivate(null)}
          />
        </UniversalFormProvider>
      )}
      {conditionToDeactivate && (
        <DeactivateAgreementDialog
          isOpen
          onClose={() => setConditionToDeactivate(null)}
          supplierId={supplier.mgSupplierId}
          groupNumber={conditionToDeactivate.productGroup}
          mgItemNumber={conditionToDeactivate.mgItemNumber}
        />
      )}
      {agreementToEdit && (
        <ChangeSelectedAgreementDialog
          open
          onClose={() => setAgreementToEdit(null)}
          agreements={agreements}
          agreementToEdit={agreementToEdit}
          supplierId={supplier.mgSupplierId}
        />
      )}
      {conditionIdToDelete && (
        <DeleteDialog
          isOpen
          dialogTitle={t('purchaseAgreements.agreement.actions.deleteFromDraft')}
          message={t('purchaseAgreements.agreement.actions.confirmRemoval')}
          onDelete={() => handleDeleteConditions([conditionIdToDelete])}
          onClose={() => setConditionIdToDelete(null)}
        />
      )}
    </Stack>
  );
};
