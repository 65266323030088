import { Add } from '@mui/icons-material';
import { ContainedButton, ContainedButtonProps } from '@shared/components';

const AddButton: React.FC<ContainedButtonProps<'button'>> = ({
  sx = { whiteSpace: 'nowrap' },
  color = 'secondary',
  startIcon = <Add />,
  size = 'small',
  ...props
}) => <ContainedButton sx={sx} color={color} startIcon={startIcon} size={size} {...props} />;

export default AddButton;