import { DialogContent, DialogTitle } from '@mui/material';
import { MgItem } from '@retail/products/types';
import { Dialog, Paper, TagHeading } from '@shared/components';
import { ErrorFallback, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { MgItemDetails } from './MgItemDetails';
interface Props {
  mgItem: MgItem;
  isOpen: boolean;
  onClose: () => void;
}

export function MgItemDialog({ mgItem, isOpen, onClose }: Props) {
  const firstSupplierItem = mgItem.getPrimarySupplierItem();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle pb={0}>
        <TagHeading color="primary">{firstSupplierItem?.primaryText}</TagHeading>
      </DialogTitle>

      <DialogContent>
        <Paper sx={{ height: '100%', p: 0, display: 'flex', flexDirection: 'column' }}>
          <SuspenseWithSentryErrorBoundary errorFallback={(props) => <ErrorFallback {...props} />}>
            <MgItemDetails mgItem={mgItem} hiddenBreadcrumbs />
          </SuspenseWithSentryErrorBoundary>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
