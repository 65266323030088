import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { ItemAgreement, PriceType } from '@retail/purchase-agreements/types';
import { useController, useFormContext } from 'react-hook-form';
import { ActivateAgreementSchema } from './activateAgreementSchema';

interface Props {
  itemAgreements: ItemAgreement[];
  selectedAgreement: number;
  selectedPriceType: PriceType;
  selectAgreement: (agreementId: number, priceType: PriceType) => void;
}

export function ItemAgreementSelect({
  itemAgreements,
  selectedAgreement,
  selectedPriceType,
  selectAgreement,
}: Props) {
  const t = usePurchaseAgreementsTFunction();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{t('purchaseAgreements.agreement.columns.agreementText')}</TableCell>
            <TableCell>{t('purchaseAgreements.agreement.columns.agreementId')}</TableCell>
            <TableCell>{t('purchaseAgreements.agreement.columns.basePriceUnit')}</TableCell>
            <TableCell>{t('purchaseAgreements.agreement.columns.price')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemAgreements.map((agreement) => (
            <TableRow key={`${agreement.agreementId}${agreement.priceType}`}>
              <TableCell>
                <Checkbox
                  onClick={() => {
                    selectAgreement(agreement.agreementId, agreement.priceType);
                  }}
                  checked={
                    selectedAgreement === agreement.agreementId &&
                    selectedPriceType === agreement.priceType
                  }
                />
              </TableCell>
              <TableCell>{agreement.agreementText}</TableCell>
              <TableCell>{agreement.agreementId}</TableCell>
              <TableCell>{agreement.gtinPriceUnit ?? agreement.priceUnit}</TableCell>
              <TableCell>
                {t('purchaseAgreements.pricePerUnit', {
                  price: agreement.price,
                  unit: agreement.priceUnit,
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
