import { Percent } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { TextInputField } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { useFreightTFunction } from '@retail/freight/i18n';
import { CreateFreightConditionSchema, EditFreightConditionSchema } from './freightConditionSchema';
import { calculateFromPercentage, calculateToPercentage } from '@retail/utils';
import { ConditionDatesForm } from '@retail/calculus/components';

export function FreightConditionFormBase() {
  const t = useFreightTFunction();

  const { control } = useFormContext<CreateFreightConditionSchema, EditFreightConditionSchema>();
  const freightFactorController = useController({ name: 'freightFactor', control });
  const percentAddonController = useController({ name: 'percentAddon', control });

  return (
    <Stack gap={3}>
      <Stack flexDirection="row" gap={1}>
        <TextInputField
          {...percentAddonController.field}
          error={percentAddonController.fieldState.error?.message}
          margin="none"
          required
          label={t('freight.form.percent')}
          size="medium"
          fullWidth
          placeholder={t('freight.actions.writePercentAddonSurcharge')}
          endAdornment={<Percent />}
          onChange={(e) => {
            const value = e.target.value;
            if (Number(value)) {
              freightFactorController.field.onChange(calculateFromPercentage(value).toFixed(2));
            }
            percentAddonController.field.onChange(value);
          }}
        />

        <TextInputField
          {...freightFactorController.field}
          error={freightFactorController.fieldState.error?.message}
          margin="none"
          required
          label={t('freight.columns.freightSurcharge')}
          size="medium"
          fullWidth
          placeholder={t('freight.actions.writeFreightSurcharge')}
          onChange={(e) => {
            const value = e.target.value;
            if (Number(value)) {
              percentAddonController.field.onChange(calculateToPercentage(value).toFixed(2));
            }
            freightFactorController.field.onChange(value);
          }}
        />
      </Stack>
      <ConditionDatesForm />
    </Stack>
  );
}
