import { useCallback, useMemo } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { ContainedButton, Paper } from '@shared/components';
import { UploadIcon } from '@shared/custom-icons';
import * as XLSX from 'xlsx';
import {
  assortmentFileAtom,
  useResetImportedAssortmentState,
  useSpreadsheetState,
} from './assortmentImportState';
import { ImportStateValidation } from './ImportStateValidation';
import { useRecoilState } from 'recoil';
import { downloadTemplateCsv } from '@retail/my-assortment/utils';

interface Props {
  isLarge?: boolean;
}

export const UploadAssortmentContainer = ({ isLarge }: Props) => {
  const { palette } = useTheme();
  const { t } = useMyAssortmentTranslation();

  const [spreadsheet, setSpreadsheet] = useSpreadsheetState();
  const [acceptedFile, setAcceptedFile] = useRecoilState(assortmentFileAtom);
  const resetImportedAssortmentStates = useResetImportedAssortmentState();

  const spreadsheetFileToJsonReader = useMemo(() => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const readData = XLSX.read(event.target?.result, { type: 'binary' });
      const wsname = readData.SheetNames[0];
      const ws = readData.Sheets[wsname];

      const parsedData = XLSX.utils.sheet_to_json<(string | number)[]>(ws, {
        header: 1,
      });
      const [headerRow, ...dataRows] = parsedData;
      setSpreadsheet({ headerRow, dataRows });
    };

    return reader;
  }, [setSpreadsheet]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      resetImportedAssortmentStates();

      const acceptedFile = acceptedFiles[0];
      if (acceptedFile) {
        setAcceptedFile(acceptedFile);
        spreadsheetFileToJsonReader.readAsBinaryString(acceptedFile);
      } else if (rejectedFiles.length) {
        toast.error(t('myAssortment.uploadAssortment.error.fileType'));
      }
    },
    [resetImportedAssortmentStates, setAcceptedFile, spreadsheetFileToJsonReader, t]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'], // Optional: .xls files
    },
  });

  const handleDownloadTemplate = async () => {
    const csvData = 'MgItem-nummer;MgSupplier-id;Prioritized';

    try {
      downloadTemplateCsv(csvData);
    } catch (error) {
      console.error('Error downloading the template:', error);
    }
  };

  return (
    <Stack gap={2} alignItems="center">
      <Paper
        paddingY="dense"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: palette.background.default,
          border: `1px dashed ${isDragActive ? palette.primary.main : palette.grey[100]}`,
          width: isLarge ? 500 : undefined,
          height: isLarge ? 350 : undefined,
          justifyContent: isLarge ? 'center' : undefined,

          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Stack alignItems="center" {...getRootProps()}>
          <UploadIcon fontSize="large" color={palette.grey[300]} />

          <Typography pt={1}>{t('myAssortment.uploadAssortment.title')}</Typography>
          <Typography pb={2} variant="body2">
            {acceptedFile ? acceptedFile.name : t('myAssortment.uploadAssortment.acceptedFormats')}
          </Typography>

          <Stack flexDirection="row" gap={2}>
            <ContainedButton size="small">
              {spreadsheet
                ? t('myAssortment.uploadAssortment.change')
                : t('myAssortment.uploadAssortment.upload')}
            </ContainedButton>

            {spreadsheet && (
              <ContainedButton
                color="warning"
                onClick={(e: Event) => {
                  e.stopPropagation();
                  resetImportedAssortmentStates();
                }}
                size="small"
              >
                {t('myAssortment.uploadAssortment.removeFile')}
              </ContainedButton>
            )}
          </Stack>

          <Box component="input" flexGrow="1" {...getInputProps()} />
        </Stack>

        <Link
          mt={1}
          onClick={handleDownloadTemplate}
          variant="body2"
          whiteSpace="nowrap"
          sx={{
            cursor: 'pointer',
          }}
        >
          {t('myAssortment.uploadAssortment.downloadTemplate')}
        </Link>
      </Paper>

      {spreadsheet && <ImportStateValidation dataRowsLength={spreadsheet.dataRows.length} />}
    </Stack>
  );
};
