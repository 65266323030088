import { levelSelectSchema } from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import { numberWithOptionalDecimalRegex, percentageRegex } from '@retail/form/validation';
import * as yup from 'yup';

export const createMarkupSchema = (t: CalculusTFunction) =>
  levelSelectSchema(t).concat(
    yup.object({
      markupName: yup.string().default('').required(t('calculus.error.nameRequired')),
      salesFactor: yup
        .string()
        .default('')
        .required(t('calculus.error.salesFactor'))
        .matches(
          numberWithOptionalDecimalRegex,
          t('calculus.error.mustBeNumberBetweenZeroAndNine')
        ),
      salesCoverage: yup
        .string()
        .default('')
        .required(t('calculus.error.salesCoverage'))
        .matches(percentageRegex, t('calculus.error.mustBeNumberBetween')),
      fromDate: yup.date().default(new Date()).nullable().required(t('calculus.error.addFromDate')),
      toDate: yup.date().default(null).nullable().optional(),
    })
  );

export type CreateMarkupSchema = yup.InferType<ReturnType<typeof createMarkupSchema>>;
