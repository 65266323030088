import { useAppTFunction } from '@retail/app/i18n';
import {
  useDeleteCondition,
  useFetchConditions,
  useMutateActivateDraftedConditions,
} from '@retail/calculus/data-access';
import { CalculusAreas, Condition } from '@retail/calculus/types';
import { useToast } from '@retail/hooks';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';

export const useCalculusConditions = () => {
  const t = useAppTFunction();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();

  const [timeoutLoading, setTimeoutLoading] = useState<boolean>(false);

  // Agreements
  const { data: draftConditionsDTO, isLoading: conditionsIsLoading } = useFetchConditions({
    assortmentId: draftAssortmentId,
  });

  const draftConditions =
    draftConditionsDTO && draftConditionsDTO.map((condition) => Condition.fromDto(condition));

  const purchaseConditions = useMemo(
    () => draftConditions?.map((condition) => condition.purchaseConditions)?.flat() ?? [],
    [draftConditions]
  );
  const freightConditions = useMemo(
    () => draftConditions?.map((condition) => condition.freightConditions)?.flat() ?? [],
    [draftConditions]
  );
  const markupConditions = useMemo(
    () => draftConditions?.map((condition) => condition.markupConditions)?.flat() ?? [],
    [draftConditions]
  );

  // Invalidation functions
  const handleInvalidateAgreementQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({
      queryKey: ['purchase-conditions'],
    });

    // Backend has a pending delay, so frontend needs setTimeout
    setTimeoutLoading(true);
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: ['purchase-condition'],
      });
      queryClient.invalidateQueries({
        queryKey: ['active-item-prices'],
      });
      setTimeoutLoading(false);
    }, 2500);
  }, [queryClient]);

  const handleInvalidateFreightQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['freight'] });

    // Backend has a pending delay, so frontend needs setTimeout
    setTimeoutLoading(true);
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: ['freights'],
      });
      setTimeoutLoading(false);
    }, 2500);
  }, [queryClient]);

  const handleInvalidateMarkupQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({
      queryKey: ['mg-item'],
    });

    // Backend has a pending delay, so frontend needs setTimeout
    setTimeoutLoading(true);
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: ['markup-condition'],
      });
      setTimeoutLoading(false);
    }, 2500);
  }, [queryClient]);

  // Remove conditions from draft drawer
  const { mutateAsync: removeConditions, isLoading: removeConditionsIsLoading } =
    useDeleteCondition({
      assortmentId: draftAssortmentId,
      departmentId,
    });

  const handleDeleteConditions = (ids: number[]) => {
    removeConditions({ body: ids })
      .catch((err) => {
        console.error(err);
        toast.error(t('markup.error.generic'));
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
        toast.success(t('calculus.messages.conditionDeleted'));
      });
  };

  // Activate conditions in draft drawer
  const { mutateAsync: activateDraftCondition, isLoading: activateConditionIsLoading } =
    useMutateActivateDraftedConditions({
      assortmentId: draftAssortmentId,
      departmentId,
    });

  const activateDraftMarkups = useCallback(
    (conditionIds?: number[]) => {
      activateDraftCondition({ type: CalculusAreas.MARKUP, conditionIds })
        .catch((err) => {
          console.error(err);
          toast.error(t('markup.error.generic'));
        })
        .then(() => {
          toast.success(t('calculus.messages.markupSuccess'));
          handleInvalidateMarkupQueries();
        });
    },
    [activateDraftCondition, handleInvalidateMarkupQueries, t, toast]
  );

  const activateDraftFreights = useCallback(
    (conditionIds?: number[]) => {
      activateDraftCondition({ type: CalculusAreas.FREIGHT, conditionIds })
        .catch((err) => {
          console.error(err);
          toast.error(t('markup.error.generic'));
        })
        .then(() => {
          toast.success(t('calculus.messages.freightSuccess'));
          handleInvalidateFreightQueries();
        });
    },
    [activateDraftCondition, handleInvalidateFreightQueries, t, toast]
  );

  const activateDraftPurchaseAgreements = useCallback(
    (conditionIds?: number[]) => {
      activateDraftCondition({ type: CalculusAreas.PURCHASE, conditionIds })
        .catch((err) => {
          console.error(err);
          toast.error(t('markup.error.generic'));
        })
        .then(() => {
          toast.success(t('calculus.messages.agreementsSuccess'));
          handleInvalidateAgreementQueries();
        });
    },
    [activateDraftCondition, handleInvalidateAgreementQueries, t, toast]
  );

  return {
    timeoutLoading,
    draftPurchaseConditions: purchaseConditions,
    draftFreightConditions: freightConditions,
    draftMarkupConditions: markupConditions,
    draftConditionsIsLoading: conditionsIsLoading,
    activateConditionIsLoading,
    removeConditionsIsLoading,
    handleDeleteConditions,
    activateDraftMarkups,
    activateDraftFreights,
    activateDraftPurchaseAgreements,
  };
};
