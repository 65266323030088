import { Box, FormHelperText, FormLabel, Stack } from '@mui/material';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { useController, useFormContext } from 'react-hook-form';
import { ErrorOutlineRounded } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import dayjs from 'dayjs';
import { ConditionDatesSchema } from './conditionDatesSchema';

interface Props {
  excludeToDate?: boolean;
  alignment?: 'row' | 'column';
  inputFieldWidth?: number;
  availableMinDate?: string;
  availableMaxDate?: string;
}

export function ConditionDatesForm({
  excludeToDate = false,
  alignment = 'row',
  inputFieldWidth = 192,
  availableMinDate,
  availableMaxDate,
}: Props) {
  const t = useMarkupTFunction();
  const { control } = useFormContext<ConditionDatesSchema>();

  const fromDateController = useController({ name: 'fromDate', control });
  const toDateController = useController({ name: 'toDate', control });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack flexDirection={alignment} gap={1}>
        <Box display="flex" flexDirection="column" gap={1} width="100%">
          <FormLabel
            sx={{ fontWeight: 600 }}
            error={!!fromDateController.fieldState.error}
            required
          >
            {t('markup.fromDate')}
          </FormLabel>

          <Stack gap={1} overflow="hidden">
            <DatePicker
              {...fromDateController.field}
              minDate={
                availableMinDate && dayjs(availableMinDate).isAfter(new Date())
                  ? new Date(availableMinDate)
                  : new Date()
              }
              maxDate={
                availableMaxDate && dayjs(availableMaxDate).isAfter(new Date())
                  ? new Date(availableMaxDate)
                  : undefined
              }
              format="dd/MM/yyyy"
              slotProps={{
                textField: {
                  sx: { ...inputSx, width: 192 },
                  helperText: undefined,
                },
              }}
            />

            {!!fromDateController.fieldState.error && (
              <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                <ErrorOutlineRounded color="warning" fontSize="small" />
                <FormHelperText sx={{ color: ({ palette }) => palette.warning.main }}>
                  {fromDateController.fieldState.error.message}
                </FormHelperText>
              </Stack>
            )}
          </Stack>
        </Box>
        {!excludeToDate && (
          <Box display="flex" flexDirection="column" gap={1} width="100%">
            <FormLabel sx={{ fontWeight: 600 }}>{t('markup.fromDate')}</FormLabel>
            <DatePicker
              {...toDateController.field}
              minDate={
                availableMinDate && dayjs(availableMinDate).isAfter(new Date())
                  ? new Date(availableMinDate)
                  : new Date()
              }
              maxDate={
                availableMaxDate && dayjs(availableMaxDate).isAfter(new Date())
                  ? new Date(availableMaxDate)
                  : undefined
              }
              format="dd/MM/yyyy"
              slotProps={{
                field: { clearable: true },
                textField: {
                  sx: { ...inputSx, width: 192 },
                  helperText: undefined,
                },
              }}
            />
          </Box>
        )}
      </Stack>
    </LocalizationProvider>
  );
}

export const inputSx = {
  '& .MuiInputBase-root': {
    height: 40,
    borderRadius: 2,
  },
};
