import { RecommendedPriceDTO } from '../../dto';

interface RecommendedPriceProps {
  priceConservative: number;
  priceAggressive: number;
  priceModerate: number;
  priceSegment: string;
  priceUnit: string;
  incMva: boolean;
}

export class RecommendedPrice {
  priceConservative: number;
  priceAggressive: number;
  priceModerate: number;
  priceSegment: string;
  priceUnit: string;
  incMva: boolean;

  constructor({
    priceConservative,
    priceAggressive,
    priceModerate,
    priceSegment,
    priceUnit,
    incMva,
  }: RecommendedPriceProps) {
    this.priceConservative = priceConservative;
    this.priceAggressive = priceAggressive;
    this.priceModerate = priceModerate;
    this.priceSegment = priceSegment;
    this.priceUnit = priceUnit;
    this.incMva = incMva;
  }

  static fromDto = (dto: RecommendedPriceDTO): RecommendedPrice =>
    new RecommendedPrice({
      priceConservative: dto.priceConservative,
      priceAggressive: dto.priceAggressive,
      priceModerate: dto.priceModerate,
      priceSegment: dto.priceSegment,
      priceUnit: dto.priceUnit,
      incMva: dto.incMva,
    });
}
