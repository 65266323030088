import { Search } from '@mui/icons-material';
import { Stack, TextField, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';

type Props = {
  filterSuppliers: (search: string) => void;
};

export function SupplierSearchField({ filterSuppliers }: Props) {
  const t = useAppTFunction();
  const { palette } = useTheme();

  return (
    <TextField
      autoFocus
      InputProps={{
        startAdornment: (
          <Stack pr={1}>
            <Search />
          </Stack>
        ),
      }}
      fullWidth
      sx={{
        outlineWidth: 0,

        '&:hover': {
          borderColor: palette.grey['100'],
        },

        '.MuiOutlinedInput-notchedOutline': {
          borderRadius: 0,
          borderWidth: '1px',
          borderColor: palette.grey['50'],
        },

        '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: palette.grey['50'],
        },
      }}
      placeholder={t('common.searchSupplier')}
      onChange={(e) => filterSuppliers(e.target.value)}
    />
  );
}
