import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { MgPackagesButtonGroup } from '@retail/products/components';
import { MgItemPackages } from '@retail/products/types';
import { Chip } from '@shared/components';
import { ArmScaleIcon } from '@shared/custom-icons';

interface Props {
  packages: MgItemPackages;
  selectedPackage: string;
  basePriceUnit: string;
  setSelectedPackage: (selectedItem: string) => void;
}

export const Packages = ({
  packages,
  selectedPackage,
  basePriceUnit,
  setSelectedPackage,
}: Props) => {
  const { palette } = useTheme();
  const t = useMyAssortmentTFunction();

  const selectedMgPackage = packages[selectedPackage][0];

  return (
    <Stack p={2} gap={1} borderBottom={`2px solid ${palette.divider}`}>
      <Stack alignContent="center" flexDirection="row" alignItems="center" gap={2} flexWrap="wrap">
        <Typography fontWeight={500}>{t('myAssortment.productCard.priceUnit')}</Typography>
        <Chip
          rounded
          backgroundColor="inherit"
          sx={{ border: ({ palette }) => `2px solid ${palette.divider}` }}
          label={basePriceUnit}
        />
      </Stack>
      <Stack
        alignContent="center"
        flexDirection="row"
        alignItems="center"
        gap={2}
        flexWrap="wrap"
        pb={1}
      >
        <Typography fontWeight={500}>{t('myAssortment.productCard.packages')}</Typography>
        <MgPackagesButtonGroup
          isSuperUser={true}
          packages={packages}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
        />
      </Stack>
      <Stack alignContent="center" flexWrap="wrap">
        <Box
          display="flex"
          alignContent="center"
          key={selectedMgPackage.gtinCode ?? selectedMgPackage.description}
          mb={1}
        >
          <Box display="flex" mr={3}>
            {t('myAssortment.packages.unit')}:{' '}
            <Typography display="flex" alignItems="center" fontWeight={600} component={Box} ml={1}>
              <Chip
                rounded
                backgroundColor={palette.info[400]}
                label={<Typography fontWeight="bold">{selectedMgPackage?.description}</Typography>}
              />

              {!selectedMgPackage.gtinCode && <ArmScaleIcon sx={{ ml: 0.5 }} fontSize="small" />}
            </Typography>
          </Box>

          {selectedMgPackage.gtinCode && (
            <Box display="flex" alignItems="center">
              {t('myAssortment.packages.gtin')}:{' '}
              <Typography component={Box} ml={1}>
                {selectedMgPackage?.gtinCode}
              </Typography>
            </Box>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
