import * as yup from 'yup';

export const conditionDatesSchema = () =>
  yup.object({
    fromDate: yup.date().default(new Date()).required(),
    toDate: yup.date().default(null).nullable().optional(),
    costFactor: yup.number().default(1),
  });

export type ConditionDatesSchema = yup.InferType<ReturnType<typeof conditionDatesSchema>>;
