import { PropsWithChildren } from 'react';
import { Stack, useTheme } from '@mui/material';
import {
  APP_BAR_HEIGHT,
  COLLAPSED_SIDEBAR_WIDTH,
  EXPANDED_SIDEBAR_WIDTH,
  Paper,
} from '@shared/components';
import { useSidebarDisclosure } from '@shared/stores';

interface Props {
  isOpen: boolean;
  collapsedHeight?: number;
}

export function GlobalBottomDrawer({
  isOpen,
  collapsedHeight = 50,
  children,
}: PropsWithChildren<Props>) {
  const { spacing } = useTheme();

  const { isOpen: isSidebarOpen } = useSidebarDisclosure();
  const height = isOpen ? `calc(100vh - ${APP_BAR_HEIGHT + 16}px)` : collapsedHeight; // + 16px for theme.spacing(2)

  const leftSideSpacing = Number(spacing(4).slice(0, -2));
  const leftValue = isSidebarOpen
    ? EXPANDED_SIDEBAR_WIDTH + leftSideSpacing
    : COLLAPSED_SIDEBAR_WIDTH + leftSideSpacing;

  return (
    <Stack
      component={Paper}
      sx={{
        height,
        position: 'fixed',
        bottom: 0,
        left: leftValue,
        right: ({ spacing }) => spacing(4),
        zIndex: 100,
        transition: (theme) =>
          theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        p: 0,
        boxShadow: (theme) => theme.shadows[1],
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: ({ palette }) => `1px solid ${palette.primary.main}`,
        borderBottom: 'none',

        backgroundColor: ({ palette }) => palette.background.secondary,
      }}
    >
      {children}
    </Stack>
  );
}
