import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { MarkupResources } from '../types/MarkupResources';

export const useMarkupTranslation = () => useMessagesTranslation<MarkupResources>();

export type MarkupMessageKey = MessagePaths<MarkupResources>;

export const useMarkupTFunction = () => useMarkupTranslation().t;

export type MarkupTFunction = ReturnType<typeof useMarkupTFunction>;
