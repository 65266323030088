import { MgSupplierItemPackage } from '@retail/products/types';

export const getPackageTypeLabel = (t: any, type: MgSupplierItemPackage['type']) => {
  switch (type) {
    case 'PRIMARY':
      return t('products.mgItem.supplierItem.packageType.primary');
    case 'SECONDARY':
      return t('products.mgItem.supplierItem.packageType.secondary');
    case 'TERTIARY':
      return t('products.mgItem.supplierItem.packageType.tertiary');
    case 'PRICE_COMPARISON':
      return t('products.mgItem.supplierItem.packageType.priceComparison');
    case 'BASE_PRICE':
      return t('products.mgItem.supplierItem.packageType.basePrice');
    case 'ADDITIONAL_GTIN':
      return t('products.mgItem.supplierItem.packageType.additionalGTINs');
    default:
      return type;
  }
};

export const getClassCodeLabel = (type: MgSupplierItemPackage['type']) => {
  switch (type) {
    case 'PRIMARY':
      return 'F-PAK';
    case 'SECONDARY':
      return 'D-PAK';
    case 'TERTIARY':
      return 'T-PAK';
    case 'PRICE_COMPARISON':
      return 'PSE';
    case 'ADDITIONAL_GTIN':
      return '+';
    default:
      return type;
  }
};
