import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { RecommendedPriceValue } from './RecommendedPriceValue';
import { useCallback, useState } from 'react';

export enum PriceVariants {
  CONSERVATIVE = 'CONSERVATIVE',
  MODERATE = 'MODERATE',
  AGGRESSIVE = 'AGGRESSIVE',
}

interface Props {
  priceConservative: number;
  priceAggressive: number;
  priceModerate: number;
  priceSegment: string;
  priceUnit: string;
  incMva: boolean;
  setSelectedRecommendedPrice: (value: string) => void;
  resetPrice: () => void;
}

export function RecommendedPrices({
  priceConservative,
  priceModerate,
  priceAggressive,
  priceUnit,
  setSelectedRecommendedPrice,
  resetPrice,
}: Props) {
  const t = useMyAssortmentTFunction();
  const [checkPrice, setCheckPrice] = useState<PriceVariants | undefined>(undefined);

  const handleCheckPrice = useCallback(
    (priceVariant: PriceVariants, price: string) => {
      if (checkPrice === priceVariant) {
        resetPrice();
        setCheckPrice(undefined);
      } else {
        setSelectedRecommendedPrice(price);
        setCheckPrice(priceVariant);
      }
    },
    [checkPrice, setSelectedRecommendedPrice, resetPrice]
  );

  return (
    <Accordion>
      <AccordionSummary>{t('myAssortment.recommendedPrices.price')} </AccordionSummary>
      <AccordionDetails>
        <RadioGroup>
          <FormControlLabel
            value={PriceVariants.CONSERVATIVE}
            control={<Radio checked={checkPrice === PriceVariants.CONSERVATIVE} />}
            onClick={() => handleCheckPrice(PriceVariants.CONSERVATIVE, priceConservative + '')}
            label={
              <RecommendedPriceValue
                priceVariant={t('myAssortment.recommendedPrices.conservative')}
                price={priceConservative}
                unit={priceUnit}
              />
            }
          />
          <FormControlLabel
            value={PriceVariants.MODERATE}
            control={<Radio checked={checkPrice === PriceVariants.MODERATE} />}
            onClick={() => handleCheckPrice(PriceVariants.MODERATE, priceModerate + '')}
            label={
              <RecommendedPriceValue
                priceVariant={t('myAssortment.recommendedPrices.moderate')}
                price={priceModerate}
                unit={priceUnit}
              />
            }
          />
          <FormControlLabel
            value={PriceVariants.AGGRESSIVE}
            control={<Radio checked={checkPrice === PriceVariants.AGGRESSIVE} />}
            onClick={() => handleCheckPrice(PriceVariants.AGGRESSIVE, priceAggressive + '')}
            label={
              <RecommendedPriceValue
                priceVariant={t('myAssortment.recommendedPrices.aggressive')}
                price={priceAggressive}
                unit={priceUnit}
              />
            }
          />
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  );
}
