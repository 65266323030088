import { useState } from 'react';
import { InfoPanel } from './InfoPanel';
import { PropertiesPanel } from './PropertiesPanel';
import { MarketingPanel } from './MarketingPanel';
import { MgInfoPanel } from './MgInfoPanel';
import { MgSupplierItem } from '@retail/products/types';

export enum PANELS {
  INFO = 'INFO',
  PROPERTIES = 'PROPERTIES',
  ETIM = 'ETIM',
  MARKETING = 'MARKETING',
  MGINFO = 'MGINFO',
  OTHER = 'OTHER',
}

export interface ProductInformationAccordionsProps {
  selectedSupplierItem: MgSupplierItem;
}

export const ProductInformationAccordions = ({
  selectedSupplierItem,
}: ProductInformationAccordionsProps) => {
  const [expanded, setExpanded] = useState<PANELS[]>([]);

  const handleChange = (expandedPanel: PANELS) => {
    if (expanded.includes(expandedPanel))
      setExpanded(expanded.filter((panel) => panel !== expandedPanel));
    else setExpanded([...expanded, expandedPanel]);
  };

  return (
    <>
      <InfoPanel
        expanded={expanded.includes(PANELS.INFO)}
        handleChange={handleChange}
        selectedSupplierItem={selectedSupplierItem}
      />

      <PropertiesPanel
        expanded={expanded.includes(PANELS.PROPERTIES)}
        handleChange={handleChange}
        selectedSupplierItem={selectedSupplierItem}
      />

      <MarketingPanel
        expanded={expanded.includes(PANELS.MARKETING)}
        handleChange={handleChange}
        selectedSupplierItem={selectedSupplierItem}
      />

      <MgInfoPanel
        expanded={expanded.includes(PANELS.MGINFO)}
        handleChange={handleChange}
        selectedSupplierItem={selectedSupplierItem}
      />
    </>
  );
};
