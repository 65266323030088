import { SupplierDTO, SupplierMinimalDTO } from '../dto';

export interface SupplierProps {
  mgSupplierId: number;
  finfoSupplierId: number;
  name: string;
  gln?: number;
  purchaseAgreementCount: number;
  hasStandardAgreement: boolean;
}

export class Supplier {
  mgSupplierId: number;
  finfoSupplierId: number;
  name: string;
  gln?: number;
  purchaseAgreementCount: number;
  hasStandardAgreement: boolean;

  constructor({
    mgSupplierId,
    name,
    finfoSupplierId,
    gln,
    purchaseAgreementCount,
    hasStandardAgreement,
  }: SupplierProps) {
    this.mgSupplierId = mgSupplierId;
    this.finfoSupplierId = finfoSupplierId;
    this.name = name;
    this.gln = gln;
    this.purchaseAgreementCount = purchaseAgreementCount;
    this.hasStandardAgreement = hasStandardAgreement;
  }

  static fromMinimalDTO = (dto: SupplierMinimalDTO): Supplier =>
    new Supplier({
      mgSupplierId: dto.mgSupplierId,
      name: dto.participantAccountName,
      finfoSupplierId: dto.finfoSupplierId,
      gln: dto.gln,
      purchaseAgreementCount: dto.purchaseAgreementCount || 0,
      hasStandardAgreement: dto.hasStandardAgreement,
    });

  static fromDTO = (dto: SupplierDTO): Supplier => ({
    mgSupplierId: dto.mgSupplierId,
    name: dto.participantAccountName,
    finfoSupplierId: dto.finfoSupplierId,
    gln: dto.gln,
    purchaseAgreementCount: dto.purchaseAgreementCount || 0,
    hasStandardAgreement: dto.hasStandardAgreement,
  });
}
