import { Department } from '@retail/auth/types';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { ActivatedFreightConditionDTO, FreightConditionDTO } from '@retail/freight/types';
import { buildUrl } from '@shared/utils';
import { useMPEFetch, useMutateMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { assortmentsPath } from '@retail/calculus/data-access';
import { assortmentPath } from '@retail/my-assortment/data-access';

export const orgUnitPath = '/org-unit';
export const freightPath = 'freights';
export const freightConditionPath = 'freight-condition';
export const freightConditionsPath = 'freight-conditions';
export const createFreightConditionPath = 'create-freight-conditions';
export const activateFreightConditionPath = 'activate-freight-conditions';

export function useMutateCreateOrEditFreightCondition(
  assortmentId: number,
  departmentId: Department['departmentId'],
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<void, ActivatedFreightConditionDTO>({
    method: 'POST',
    url: () =>
      `${mpe.url}${urlConstant}${assortmentPath}/${assortmentId}/${createFreightConditionPath}?departmentId=${departmentId}`,
    token,
    ...queryOptions,
  });
}

interface SearchProps {
  assortmentId: number;
  mgSupplierId: number;
  groupNumber?: string;
  mgItemNumber?: number;
  includeSubConditions?: boolean;
  includeFutureConditions?: boolean;
}

export function useFetchFreightConditionForSupplier(
  {
    assortmentId,
    mgSupplierId,
    groupNumber,
    mgItemNumber,
    includeSubConditions = true,
    includeFutureConditions = true,
  }: SearchProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<FreightConditionDTO>({
    method: 'GET',
    queryKey: ['freight', assortmentId, mgSupplierId, groupNumber, mgItemNumber],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${assortmentsPath}/${assortmentId}/${freightConditionPath}/`,
      searchParams: {
        mgSupplierId,
        groupNumber,
        mgItemNumber,
        includeSubConditions,
        includeFutureConditions,
      },
    }),
    ...queryOptions,
  });
}

export function useFetchFreightConditionsForSupplier(
  { assortmentId, mgSupplierId }: SearchProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<FreightConditionDTO[]>({
    method: 'GET',
    queryKey: ['freights', assortmentId, mgSupplierId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${assortmentsPath}/${assortmentId}/${freightConditionsPath}/`,
      searchParams: {
        mgSupplierId,
      },
    }),
    ...queryOptions,
  });
}
