import { useSelectedOrgUnit, useUserBrands } from '@retail/app/stores/selected-context';
import {
  getMgAssortmentsQueryKey,
  useCreateMgAssortment,
  useFetchMgAssortments,
} from '@retail/monitoring-assortment/data-access';
import { Stack, Tooltip, Typography } from '@mui/material';
import { ContainedButton, TagHeading } from '@shared/components';
import {
  AssortmentsList,
  CreateAssortmentManuallyDialog,
  CreateAssortmentMenu,
  IdentifiableColumn,
  ImportAssortmentDialog,
  NewAssortment,
  SuccessCreatingAssortment,
} from '@retail/monitoring-assortment/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@retail/hooks';
import { useDisclosure, usePopupElement } from '@shared/hooks';
import { FetchError } from '@shared/fetch-utils';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMgAssortmentActions } from '@retail/monitoring-assortment/context';
import { MgAssortments } from '@retail/monitoring-assortment/types';

const columnsToRetrieve = new Set<IdentifiableColumn>(['finfo', 'gtin', 'mgItem']);

export function AllMgAssortmentsContainer() {
  const t = useAssortmentTFunction();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  const { selectedBrandLogos } = useUserBrands();
  const { selectedPPSContext } = useSelectedOrgUnit();

  const { menuElementProps, triggerElementProps } = usePopupElement();
  const { handleValidateItemsOnImportedAssortment } = useMgAssortmentActions();

  const { isOpen: isSuccessModalOpen, onToggle: toggleSuccessModal } = useDisclosure(false);
  const { isOpen: isImportModalOpen, onToggle: toggleImportModal } = useDisclosure(false);
  const { isOpen: isCreateManuallyModalOpen, onToggle: toggleCreateManuallyModal } =
    useDisclosure(false);

  const { data: mgAssortmentDtos = [] } = useFetchMgAssortments();
  const mgAssortments = mgAssortmentDtos.map(MgAssortments.fromDto);
  const { mutateAsync: createAssortmentAsync, isLoading } =
    useCreateMgAssortment(selectedPPSContext);

  const navigateToItemSearch = useCallback(() => {
    navigate(mgPrisRoutes.items.getFullLinkPath());
  }, [navigate]);

  const submitCreateAssortment = (newAssortment: NewAssortment) => {
    createAssortmentAsync({ body: newAssortment })
      .then(() => {
        queryClient.invalidateQueries(getMgAssortmentsQueryKey());
        menuElementProps.onClose();
        if (isImportModalOpen) {
          toast.success(t('assortment.admin.create.success', { title: newAssortment.title }));
          toggleImportModal();
        } else {
          toggleCreateManuallyModal();
          toggleSuccessModal();
        }
      })
      .catch((err: FetchError) => {
        console.error(err);
        toast.error(t('assortment.admin.create.error.generic'));
      });
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" whiteSpace="nowrap">
        <TagHeading color="primary">
          <Typography variant="h6" component="h1">
            {t('assortment.monitoringAssortment')}
          </Typography>
        </TagHeading>
        {selectedPPSContext.isAdmin ? (
          <ContainedButton {...triggerElementProps} color="secondary" size="small">
            {t('assortment.admin.create.options.title')}
          </ContainedButton>
        ) : (
          <Tooltip title={t('assortment.assortmentsOverview.onlyAdminAccess')} placement="top">
            <span>
              <ContainedButton disabled size="small">
                {t('assortment.admin.create.options.title')}
              </ContainedButton>
            </span>
          </Tooltip>
        )}
      </Stack>
      <AssortmentsList
        assortments={mgAssortments}
        linkTo={(asssortmentId: number) =>
          mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath(`${asssortmentId}`)
        }
        contextId={selectedPPSContext.id}
        brandLogoUri={selectedBrandLogos.circularLogo}
      />
      <CreateAssortmentMenu
        {...menuElementProps}
        openImportModal={toggleImportModal}
        openCreateManuallyModal={toggleCreateManuallyModal}
      />
      <ImportAssortmentDialog
        open={isImportModalOpen}
        onClose={() => {
          toggleImportModal();
          menuElementProps.onClose();
        }}
        onSubmit={submitCreateAssortment}
        getInvalidItemCodes={handleValidateItemsOnImportedAssortment}
        columnsToRetrieve={columnsToRetrieve}
        isLoading={isLoading}
        hideAssortmentType
      />
      <CreateAssortmentManuallyDialog
        open={isCreateManuallyModalOpen}
        onClose={() => {
          toggleCreateManuallyModal();
          menuElementProps.onClose();
        }}
        onSubmit={submitCreateAssortment}
        isLoading={isLoading}
        hideAssortmentType
      />
      <SuccessCreatingAssortment
        isOpen={isSuccessModalOpen}
        closeDialog={toggleSuccessModal}
        navigateToItemSearch={navigateToItemSearch}
      />
    </Stack>
  );
}
