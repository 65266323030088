import { HierarchyLevels } from '@retail/calculus/types';
import { FreightConditionDTO } from '../dto';

export interface CreateOrEditFreightCondition {
  validFrom?: string;
  mgItemNumber?: number;
  groupNumber?: string;
  freight: {
    percentAddon: number;
    freightFactor?: number;
  };
}
interface FreightConditionProps {
  id: number;
  mgSupplierId?: number;
  bindingType?: HierarchyLevels;
  productGroup?: string;
  productGroupName?: string;
  mgItemNumber?: number;
  mgSupplierItemId?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom?: string;
  validTo?: string;
  active?: boolean;
  agreementId?: number;
  agreementText?: string;
  percentAddon?: number;
  freightFactor?: number;
  fixedAddOn?: number;
  fixedAddOnUnit?: string;
  inherited?: boolean;
  subConditions?: FreightCondition[];
}

export class FreightCondition {
  id: number;
  mgSupplierId?: number;
  bindingType?: HierarchyLevels;
  productGroup?: string;
  productGroupName?: string;
  mgItemNumber?: number;
  mgSupplierItemId?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom?: string;
  validTo?: string;
  active?: boolean;
  agreementId?: number;
  agreementText?: string;
  percentAddon?: number;
  freightFactor?: number;
  fixedAddOn?: number;
  fixedAddOnUnit?: string;
  inherited?: boolean;
  subConditions?: FreightCondition[];

  constructor({
    id,
    mgSupplierId,
    bindingType,
    productGroup,
    productGroupName,
    mgItemNumber,
    mgSupplierItemId,
    primaryText,
    secondaryText,
    validFrom,
    validTo,
    active,
    agreementId,
    agreementText,
    percentAddon,
    freightFactor,
    fixedAddOn,
    fixedAddOnUnit,
    inherited,
    subConditions,
  }: FreightConditionProps) {
    this.id = id;
    this.mgSupplierId = mgSupplierId;
    this.bindingType = bindingType;
    this.productGroup = productGroup;
    this.productGroupName = productGroupName;
    this.mgItemNumber = mgItemNumber;
    this.mgSupplierItemId = mgSupplierItemId;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.active = active;
    this.agreementId = agreementId;
    this.agreementText = agreementText;
    this.percentAddon = percentAddon;
    this.freightFactor = freightFactor;
    this.fixedAddOn = fixedAddOn;
    this.fixedAddOnUnit = fixedAddOnUnit;
    this.inherited = inherited;
    this.subConditions = subConditions;
  }

  static fromDto = (dto: FreightConditionDTO): FreightCondition =>
    new FreightCondition({
      id: dto.id,
      mgSupplierId: dto.mgSupplierId,
      bindingType: dto.bindingType,
      productGroup: dto.productGroup,
      productGroupName: dto.productGroupName,
      mgItemNumber: dto.mgItemNumber,
      mgSupplierItemId: dto.mgSupplierItemId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: dto.validFrom,
      validTo: dto.validTo,
      active: dto.active,
      agreementId: dto.agreementId,
      agreementText: dto.agreementText,
      percentAddon: dto.percentAddon,
      freightFactor: dto.freightFactor,
      fixedAddOn: dto.fixedAddOn,
      fixedAddOnUnit: dto.fixedAddOnUnit,
      inherited: dto.inherited,
      subConditions: dto.subConditions
        ? dto.subConditions.map((condition) => FreightCondition.fromDto(condition))
        : undefined,
    });
}
