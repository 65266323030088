import { FormLabel, Stack } from '@mui/material';
import { TextInputField } from '@shared/components';
import { useController, useFormContext } from 'react-hook-form';
import { PatchMarkupSchema } from './patchMarkupSchema';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useMarkupTFunction } from '@retail/markup/i18n';

export function EditMarkupForm() {
  const t = useMarkupTFunction();

  const { control } = useFormContext<PatchMarkupSchema>();
  const nameController = useController({ name: 'markupName', control });
  const toDateController = useController({ name: 'toDate', control });

  return (
    <form>
      <Stack width={343} gap={2}>
        <TextInputField
          {...nameController.field}
          error={nameController.fieldState.error?.message}
          required
          fullWidth
          labelWidth={145}
          labelDirection="row"
          margin="none"
          label={t('markup.markupName')}
          size="small"
          placeholder={t('markup.markupName')}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormLabel sx={{ width: 152 }}>{t('markup.toDate')}</FormLabel>
          <Stack gap={1} overflow="hidden">
            <DatePicker
              {...toDateController.field}
              minDate={new Date()}
              format="dd/MM/yyyy"
              slotProps={{
                field: { clearable: true },
                textField: {
                  helperText: undefined,
                },
              }}
            />
          </Stack>
        </LocalizationProvider>
      </Stack>
    </form>
  );
}
