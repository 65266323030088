import { useProductsTFunction } from '@retail/products/i18n';
import { NotFoundError } from '@retail/components';
import { Paper } from '@shared/components';

interface Props {
  mgItemNr?: string;
}

export function MgItemNotFound({ mgItemNr }: Props) {
  const t = useProductsTFunction();

  return (
    <Paper sx={{ height: '100%', display: 'flex', justifyContent: 'center', pb: 14 }}>
      <NotFoundError
        title={t('products.mgItem.notFound.title')}
        subtitle={t('products.mgItem.notFound.subtitle', { itemId: mgItemNr })}
        buttonText={t('products.mgItem.notFound.goBack')}
      />
    </Paper>
  );
}
