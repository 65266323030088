import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { ProductGroup } from '@retail/products/types';

interface Props {
  productGroups: ProductGroup[];
  selectedproductGroup: ProductGroup | null;
  selectproductGroup: (supplierId: ProductGroup | null) => void;
}

export function SelectProductGroup({
  productGroups,
  selectedproductGroup,
  selectproductGroup,
}: Props) {
  const t = useProductsTFunction();
  return (
    <Stack gap={0.5}>
      <Typography
        component="label"
        htmlFor="supplierSelect"
        variant="h6"
        color={({ palette }) => palette.grey.A400}
      >
        {t('products.search.productGroup.label')}
      </Typography>
      <Autocomplete
        id="supplierSelect"
        size="small"
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={
          productGroups.find(
            (productGroup) => productGroup.groupNumber === selectedproductGroup?.groupNumber
          ) || null
        }
        onChange={(_, newValue) => {
          selectproductGroup(newValue ? newValue : null);
        }}
        options={productGroups}
        sx={{ width: 320 }}
        getOptionLabel={({ name, groupNumber }) => `${groupNumber} ${name}`}
        renderOption={(props, option) => (
          <Stack
            {...props}
            component="li"
            key={option.groupNumber}
            borderBottom={({ palette }) => `1px solid ${palette.divider}`}
            flexDirection="row"
            gap={1}
          >
            <Typography>{option.groupNumber}</Typography> {option.name}
          </Stack>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('products.search.productGroup.searchInProductgroup')}
          />
        )}
      />
    </Stack>
  );
}
