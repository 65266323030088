import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Header } from './Header';
import { AppSidebar } from './AppSidebar';
import { mgprisHeaderHeight } from '@shared/styles';
import { GlobalDrawer } from '../GlobalDrawer';

interface Props {
  showDrawer?: boolean;
}

export function AppLayout({ children, showDrawer = true }: PropsWithChildren<Props>) {
  return (
    <Box display="flex" flexGrow="1">
      <Header />
      <AppSidebar />

      <Box display="flex" flexDirection="column" flexGrow="1" marginTop={mgprisHeaderHeight}>
        <SuspenseWithSentryErrorBoundary>
          {showDrawer ? <GlobalDrawer>{children}</GlobalDrawer> : children}
        </SuspenseWithSentryErrorBoundary>
      </Box>
    </Box>
  );
}
