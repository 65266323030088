import { SvgIconProps } from '@mui/material';
import { red } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import { PriceTagDiscountIcon } from '@shared/custom-icons';
import { useProductsTFunction } from '@retail/products/i18n';

const OnDiscountTooltip: React.FC<SvgIconProps> = (props) => {
  const t = useProductsTFunction();

  return (
    <Tooltip title={t('products.productList.campaignPrice.isCampaignPrice')}>
      <PriceTagDiscountIcon htmlColor={red[600]} sx={{ fontSize: 20, mr: 0.5 }} {...props} />
    </Tooltip>
  );
};

export default OnDiscountTooltip;
