import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { ContainedButton, Dialog, OutlinedButton } from '@shared/components';

interface Props {
  isOpen: boolean;
  dialogTitle: string;
  message: string;
  onDelete: () => void;
  onClose: () => void;
}

export function DeleteDialog({ isOpen, dialogTitle, message, onDelete, onClose }: Props) {
  const t = useAppTFunction();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>

      <DialogContent>
        <Typography variant="h6" fontWeight="medium">
          {message}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', alignSelf: 'flex-end' }}>
        <OutlinedButton size="small" onClick={onClose}>
          {t('common.cancel')}
        </OutlinedButton>

        <ContainedButton
          color="warning"
          size="small"
          sx={{ alignSelf: 'center' }}
          onClick={() => {
            onDelete();
            onClose();
          }}
        >
          {t('common.confirm')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
