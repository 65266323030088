import { useCallback, useMemo } from 'react';
import { NavigationTreeView, TreeNode } from '@shared/components';
import {
  CalculusTabOptions,
  HierarchyLevels,
  SupplierWithProductGroups,
} from '@retail/calculus/types';
import { NodeLabel } from './NodeLabel';
import { SelectedLevel } from '@retail/calculus/types';
import { Stack, Typography } from '@mui/material';

interface Props {
  supplierWithProductGroups: SupplierWithProductGroups;
  selectedNodeId: string;
  calculusArea: CalculusTabOptions;
  shouldDefaultExpand: (productGroupId: string) => boolean;
  selectLevel: (level: SelectedLevel) => void;
}

export const ProductGroupsTreeView = ({
  supplierWithProductGroups,
  selectedNodeId,
  calculusArea,
  shouldDefaultExpand,
  selectLevel,
}: Props) => {
  const numberOfConditions = useCallback(
    (purchase: number, freight: number, markup: number) => {
      switch (calculusArea) {
        case CalculusTabOptions.PURCHASE:
          return purchase;
        case CalculusTabOptions.FREIGHT:
          return freight;
        case CalculusTabOptions.MARKUP:
          return markup;
        default:
          return undefined;
      }
    },
    [calculusArea]
  );

  const supplierConditionCount = numberOfConditions(
    supplierWithProductGroups.numberOfPurchaseConditions,
    supplierWithProductGroups.numberOfFreightConditions,
    supplierWithProductGroups.numberOfMarkupConditions
  );

  const treeNodes: TreeNode[] = useMemo(() => {
    const overGroupsNodes = supplierWithProductGroups.productGroups.map(
      ({
        groupNumber,
        name,
        numberOfPurchaseConditions,
        numberOfFreightConditions,
        numberOfMarkupConditions,
        subGroups,
      }) => ({
        label: (
          <NodeLabel
            {...{
              groupNumber,
              name,
              numberOfConditions: numberOfConditions(
                numberOfPurchaseConditions,
                numberOfFreightConditions,
                numberOfMarkupConditions
              ),
            }}
          />
        ),
        id: groupNumber,
        onSelect: () =>
          selectLevel({
            productGroup: { groupNumber, name },
            level: HierarchyLevels.overGroup,
          }),
        defaultExpanded: shouldDefaultExpand(groupNumber),
        childNodes: subGroups?.map(
          ({
            groupNumber,
            name,
            numberOfPurchaseConditions,
            numberOfFreightConditions,
            numberOfMarkupConditions,
            subGroups,
          }) => ({
            label: (
              <NodeLabel
                {...{
                  groupNumber,
                  name,
                  numberOfConditions: numberOfConditions(
                    numberOfPurchaseConditions,
                    numberOfFreightConditions,
                    numberOfMarkupConditions
                  ),
                }}
              />
            ),
            id: groupNumber,
            onSelect: () =>
              selectLevel({
                productGroup: { groupNumber, name },
                level: HierarchyLevels.mainGroup,
              }),
            defaultExpanded: shouldDefaultExpand(groupNumber),

            childNodes: subGroups?.map(
              ({
                groupNumber,
                name,
                numberOfPurchaseConditions,
                numberOfFreightConditions,
                numberOfMarkupConditions,
              }) => ({
                label: (
                  <NodeLabel
                    {...{
                      groupNumber,
                      name,
                      numberOfConditions: numberOfConditions(
                        numberOfPurchaseConditions,
                        numberOfFreightConditions,
                        numberOfMarkupConditions
                      ),
                    }}
                  />
                ),
                id: groupNumber,
                onSelect: () => {
                  selectLevel({
                    productGroup: { groupNumber, name },
                    level: HierarchyLevels.productGroup,
                  });
                },
                defaultExpanded: shouldDefaultExpand(groupNumber),
              })
            ),
          })
        ),
      })
    );

    const supplierRootNode: TreeNode = {
      label: (
        <Stack direction="row" alignItems="center" justifyContent="space-between" py={0.5} pr={0.5}>
          <Typography
            variant="h6"
            fontWeight={600}
          >{`${supplierWithProductGroups.mgSupplierName}`}</Typography>
          <Typography variant="body1">
            {supplierConditionCount ? `(${supplierConditionCount})` : ''}
          </Typography>
        </Stack>
      ),
      id: String(supplierWithProductGroups.mgSupplierId),
      onSelect: () =>
        selectLevel({
          supplierId: supplierWithProductGroups.mgSupplierId,
          level: HierarchyLevels.supplier,
        }),
      childNodes: overGroupsNodes,
      defaultExpanded: true,
    };
    return [supplierRootNode];
  }, [
    supplierWithProductGroups.productGroups,
    supplierWithProductGroups.mgSupplierName,
    supplierWithProductGroups.mgSupplierId,
    supplierConditionCount,
    numberOfConditions,
    shouldDefaultExpand,
    selectLevel,
  ]);

  return <NavigationTreeView nodes={treeNodes} selectedNodeId={selectedNodeId} />;
};
