import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';

interface Props {
  agreements: PurchaseAgreement[];
  selectedAgreement: number;
  selectAgreement: (agreementId: number) => void;
}

export function AgreementSelect({ agreements, selectedAgreement, selectAgreement }: Props) {
  const t = usePurchaseAgreementsTFunction();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{t('purchaseAgreements.agreement.columns.agreementText')}</TableCell>
            <TableCell>{t('purchaseAgreements.agreement.columns.agreementId')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agreements.map((agreement) => (
            <TableRow key={agreement.id}>
              <TableCell size="small">
                <Checkbox
                  onClick={() => selectAgreement(agreement.id)}
                  checked={selectedAgreement === agreement.id}
                />
              </TableCell>
              <TableCell size="small">{agreement.name}</TableCell>
              <TableCell size="small">{agreement.id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
