import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { useMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { ConditionDTO, ConditionState } from '@retail/calculus/types';

export const conditionsPath = '/conditions/';
export const assortmentPath = '/assortments';

export interface ConditionsParams extends QueryOptions {
  assortmentId: number;
  conditionState?: ConditionState;
  mgSupplierIds?: number[];
  groupNumbers?: string[];
  mgItemNumbers?: number[];
  conditionTypes?: 'PURCHASE' | 'FREIGHT' | 'MARKUP';
  includeOwnerConditions?: boolean;
  replacesConditions?: boolean;
  includeAgreements?: boolean;
}

export function useFetchConditions({
  assortmentId,
  conditionState = ConditionState.ALL,
  mgSupplierIds,
  groupNumbers,
  mgItemNumbers,
  conditionTypes,
  includeOwnerConditions = false,
  replacesConditions,
  includeAgreements = false,
  ...queryOptions
}: ConditionsParams) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<ConditionDTO[]>({
    method: 'GET',
    queryKey: [
      'draft-conditions',
      assortmentId,
      conditionState,
      mgSupplierIds,
      groupNumbers,
      mgItemNumbers,
      conditionTypes,
      includeOwnerConditions,
      replacesConditions,
      includeAgreements,
    ],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${assortmentPath}/${assortmentId}${conditionsPath}`,
      searchParams: {
        conditionState,
        mgSupplierIds,
        groupNumbers,
        mgItemNumbers,
        conditionTypes,
        includeOwnerConditions,
        replacesConditions,
        includeAgreements,
      },
      ...queryOptions,
    }),
  });
}
