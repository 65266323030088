import * as React from 'react';
import { useCallback, useId } from 'react';
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';

type StringOrNumberOrNull = string | number | null;

export interface ToggleGropOption<T extends StringOrNumberOrNull> {
  label: string;
  value: T;
}

interface Props<T extends StringOrNumberOrNull> {
  label: string;
  options: ToggleGropOption<Exclude<T, null>>[];
  value: T;
  onChange: (val: T) => void;
  size?: ToggleButtonGroupProps['size'];
  nullable?: boolean;
}

export function ToggleGroup<T extends StringOrNumberOrNull>({
  label,
  options,
  onChange,
  value,
  size,
  nullable = false,
}: Props<T>) {
  const groupId = useId();
  const handleOnChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, val: T) => {
      if (val || nullable) onChange(val);
    },
    [nullable, onChange]
  );

  return (
    <Stack gap={1} flexDirection="row" alignItems="center">
      <Typography component="label" htmlFor={groupId} variant="body2" fontWeight={600}>
        {label}
      </Typography>
      <ToggleButtonGroup id={groupId} size={size} exclusive value={value} onChange={handleOnChange}>
        {options.map(({ label, value }) => (
          <ToggleButton value={value} key={value}>
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
