import { Stack } from '@mui/material';
import { MgItem, MgSupplierItem } from '@retail/products/types';
import { Paper } from '@shared/components';
import { SupplierTabSelect } from '../../Supplier';
import { useState } from 'react';
import { ProductInformationAccordions } from './Accordions/ProductInformationAccordions';

interface Props {
  mgItem: MgItem;
}

export const ProductInformation = ({ mgItem }: Props) => {
  const [selectedSupplierItem, setSelectedSupplierItem] = useState<MgSupplierItem>(
    mgItem.getPrimarySupplierItem()
  );

  return (
    <Paper paddingY="dense">
      <Stack alignItems="center" justifyContent="space-between" flexDirection="row" pb={2}>
        <SupplierTabSelect
          suppliers={mgItem.supplierItems}
          selectedSupplier={selectedSupplierItem}
          selectSupplier={setSelectedSupplierItem}
          getSupplierName={(supplier) => supplier.mgParticipant.name}
          getSupplierId={(supplier) => supplier.mgParticipant.id + ''}
        />
      </Stack>

      <ProductInformationAccordions selectedSupplierItem={selectedSupplierItem} />
    </Paper>
  );
};
