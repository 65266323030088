import {
  BodyLoader,
  draftActionsRowPortalId,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import { useDisclosure } from '@shared/hooks';
import { OutlinedButton, Paper, UnderlinedTabs } from '@shared/components';
import { DrawerIcon } from '@shared/custom-icons';
import { Box, Stack, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAssortmentDraftDrawer } from '@retail/my-assortment/context';
import { GlobalBottomDrawer } from './GlobalBottomDrawer';
import { DraftDrawerTabs, useCurrentDraftDrawerTab } from './globalDrawerState';
import { useAppTFunction } from '@retail/app/i18n';
import { useCalculusConditions } from '@retail/calculus/context';
import { useSearchParams } from 'react-router-dom';
import { DraftAssortmentItemsContainer } from '@retail/my-assortment/containers';
import {
  DraftFreightsContainer,
  DraftMarkupsContainer,
  DraftPurchaseAgreementsContainer,
} from '@retail/calculus/containers';
import { CalculusTabOptions } from '@retail/calculus/types';

const calcTabToDraftTabMap: Record<CalculusTabOptions, DraftDrawerTabs> = {
  ITEMS: DraftDrawerTabs.ASSORTMENT,
  FREIGHT: DraftDrawerTabs.FREIGHT,
  MARKUP: DraftDrawerTabs.MARKUP,
  PURCHASE: DraftDrawerTabs.AGREEMENTS,
};

// Summary drawer uses member draft, table uses department draft
export function DraftDrawer() {
  const t = useAppTFunction();
  const { transitions } = useTheme();
  const [showContent, setShowContent] = useState(false);

  const [searchParams] = useSearchParams();
  const calculusTab = searchParams.get('calculusTab');

  const [selectedTab, setSelectedTab] = useCurrentDraftDrawerTab();
  const { isOpen, onToggle } = useDisclosure(false);

  const {
    totalItems,
    draftAssortmentItems,
    timeoutLoading: assortmentTimeoutLoading,
    activateIsLoading: activateAssortmentIsLoading,
  } = useAssortmentDraftDrawer();

  const {
    draftPurchaseConditions,
    draftFreightConditions,
    draftMarkupConditions,
    draftConditionsIsLoading,
  } = useCalculusConditions();

  const showDraftDrawer =
    (draftAssortmentItems && draftAssortmentItems.length > 0) ||
    (draftPurchaseConditions && draftPurchaseConditions.length > 0) ||
    (draftFreightConditions && draftFreightConditions.length > 0) ||
    (draftMarkupConditions && draftMarkupConditions.length > 0) ||
    assortmentTimeoutLoading ||
    activateAssortmentIsLoading;

  const tabs = useMemo(
    () => [
      {
        label: t('calculus.tabs.myAssortment', { count: totalItems }),
        value: DraftDrawerTabs.ASSORTMENT,
        isHighlighted: Boolean(totalItems && totalItems > 0),
      },
      {
        label: t('calculus.tabs.agreements', { count: draftPurchaseConditions.length }),
        value: DraftDrawerTabs.AGREEMENTS,
        isHighlighted: Boolean(draftPurchaseConditions?.length > 0),
      },
      {
        label: t('calculus.tabs.freight', { count: draftFreightConditions.length }),
        value: DraftDrawerTabs.FREIGHT,
        isHighlighted: Boolean(draftFreightConditions.length > 0),
      },
      {
        label: t('calculus.tabs.markup', { count: draftMarkupConditions.length }),
        value: DraftDrawerTabs.MARKUP,
        isHighlighted: Boolean(draftMarkupConditions.length > 0),
      },
    ],
    [draftFreightConditions, draftMarkupConditions, draftPurchaseConditions, t, totalItems]
  );

  useEffect(() => {
    if (
      !!calculusTab &&
      calculusTab !== selectedTab &&
      Object.keys(CalculusTabOptions).includes(calculusTab)
    ) {
      setSelectedTab(calcTabToDraftTabMap[calculusTab as CalculusTabOptions]);
    } else {
      setSelectedTab(DraftDrawerTabs.ASSORTMENT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculusTab]);

  useEffect(() => {
    let timeoutId: number | undefined;
    if (isOpen) {
      timeoutId = window.setTimeout(
        () => setShowContent(true),
        transitions.duration.leavingScreen + 200
      );
    } else {
      setShowContent(false);
    }
    return () => {
      timeoutId && window.clearTimeout(timeoutId);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    showDraftDrawer && (
      <GlobalBottomDrawer
        isOpen={isOpen || assortmentTimeoutLoading || activateAssortmentIsLoading}
        collapsedHeight={38}
      >
        <OutlinedButton
          sx={{ height: 28, alignSelf: 'center', my: '5px' }}
          size="small"
          onClick={onToggle}
          startIcon={
            <DrawerIcon
              orientation={isOpen ? 'down' : 'up'}
              sx={{ width: '16px', height: '16px' }}
            />
          }
        >
          {t('calculus.hasCalculusUpdates')}
        </OutlinedButton>

        {isOpen && (
          <>
            <Paper
              paddingY="dense"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mx: 2,
                my: 0,
                p: 1,
                overflow: 'auto',
                height: '100%',
              }}
            >
              <Box my={2}>
                <UnderlinedTabs
                  id="draft"
                  tabs={tabs}
                  currentTab={selectedTab}
                  onChange={(_, value) => setSelectedTab(value)}
                />
              </Box>

              {assortmentTimeoutLoading ||
              activateAssortmentIsLoading ||
              draftConditionsIsLoading ||
              !showContent ? (
                <BodyLoader />
              ) : (
                <>
                  {selectedTab === DraftDrawerTabs.ASSORTMENT && (
                    <SuspenseWithSentryErrorBoundary>
                      <DraftAssortmentItemsContainer />
                    </SuspenseWithSentryErrorBoundary>
                  )}

                  {selectedTab === DraftDrawerTabs.AGREEMENTS && (
                    <SuspenseWithSentryErrorBoundary>
                      <DraftPurchaseAgreementsContainer />
                    </SuspenseWithSentryErrorBoundary>
                  )}

                  {selectedTab === DraftDrawerTabs.FREIGHT && (
                    <SuspenseWithSentryErrorBoundary>
                      <DraftFreightsContainer />
                    </SuspenseWithSentryErrorBoundary>
                  )}

                  {selectedTab === DraftDrawerTabs.MARKUP && (
                    <SuspenseWithSentryErrorBoundary>
                      <DraftMarkupsContainer />
                    </SuspenseWithSentryErrorBoundary>
                  )}
                </>
              )}
            </Paper>

            <Stack
              m={2}
              spacing={2}
              justifyContent="flex-end"
              id={draftActionsRowPortalId}
              direction="row"
            />
          </>
        )}
      </GlobalBottomDrawer>
    )
  );
}
