import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Image } from '@retail/components';

interface Props {
  retailerName: string;
  retailerUrlName: string;
  storeName?: string;
  logoUrl?: string;
}

const RetailerColumnHeader: React.FC<Props> = ({
  retailerName,
  retailerUrlName,
  storeName,
  logoUrl = `https://static.mestergruppen.cloud/external/retailers/${retailerUrlName}_logo.png`,
}) => {
  if (storeName) {
    return (
      <Tooltip arrow title={storeName}>
        <Box display="flex" flexDirection="column" overflow="hidden" alignItems="center">
          <Image src={logoUrl} fallback={() => <ImageFallback retailerName={retailerName} />} />
          <Stack alignSelf="flex-start" fontSize={14}>
            {storeName}
          </Stack>
        </Box>
      </Tooltip>
    );
  }
  return <Image src={logoUrl} fallback={() => <ImageFallback retailerName={retailerName} />} />;
};

interface ImageFallbackProps {
  retailerName: string;
}

const ImageFallback: React.FC<ImageFallbackProps> = ({ retailerName }) => (
  <Typography>{retailerName}</Typography>
);

export default RetailerColumnHeader;
