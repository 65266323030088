import { useCallback, useMemo } from 'react';
import { Box, IconButton, Stack, styled, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { dataGridBoldClass } from '@shared/styles';
import { AssortmentItem } from '@retail/my-assortment/types';
import { StackedTextCell } from '@retail/components';
import { RedCrossIcon } from '@shared/custom-icons';
import { TrashIcon } from '@sanity/icons';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { useMountEffect } from '@shared/hooks';

interface Props {
  items: AssortmentItem[];
  isLoading: boolean;
  onDeleteDraftItem: (ids: number[]) => void;
  onSelectItems: (itemIds: number[]) => void;
}

type GridColAssortmentItem = GridColDef<AssortmentItem>;

const baseColumnProps: Partial<GridColAssortmentItem> = {
  width: 150,
  sortable: false,
  headerName: '',
  headerAlign: 'center',
  align: 'center',
};

export const DraftAssortmentItemsTable = ({
  items,
  isLoading,
  onDeleteDraftItem,
  onSelectItems,
}: Props) => {
  const t = useAppTFunction();
  const apiRef = useGridApiRef();

  useMountEffect(() => {
    apiRef.current.selectRows(items.map(({ id }) => id));
  });

  const columns: GridColAssortmentItem[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'supplier',
        width: 180,
        headerName: t('myAssortment.columns.supplier'),
        renderCell: ({ row }) => {
          const topLine = !row.priority ? (
            <Box display="flex" alignItems="center">
              <RedCrossIcon />
              {row.mgSupplierName}
            </Box>
          ) : (
            row.mgSupplierName
          );

          return (
            <Box display="flex" alignItems="center">
              <StackedTextCell
                centerAlign
                topLine={topLine}
                lineThroughTopLine={!row.priority}
                lineThroughBottomLine={!row.priority}
                bottomLine={row.mgSupplierId}
              />
            </Box>
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'itemText',
        cellClassName: () => dataGridBoldClass,
        headerName: t('myAssortment.columns.itemText'),
        headerAlign: 'left',
        align: 'left',
        width: 300,
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={row.primaryText}
            bottomLine={row.secondaryText}
            lineThroughTopLine={!row.priority}
            lineThroughBottomLine={!row.priority}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'priorityValidFrom',
        headerName: t('myAssortment.columns.priorityValidFrom'),
        renderCell: ({ row }) => dayjs(row.priorityValidFrom).format('L'),
      },
      {
        ...baseColumnProps,
        field: 'mgItemFinfoNumber',
        headerName: t('myAssortment.columns.mgItemFinfoNumber'),
        renderCell: ({ row }) => (
          <StackedTextCell
            centerAlign
            topLine={row.mgItemNumber}
            bottomLine={row.finfoNumber}
            lineThroughTopLine={!row.priority}
            lineThroughBottomLine={!row.priority}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'itemGroup',
        width: 170,
        headerName: t('myAssortment.columns.itemGroup'),
        renderCell: ({ row }) => (
          <StackedTextCell
            centerAlign
            topLine={row.groupNumber}
            bottomLine={row.groupDescription}
            lineThroughTopLine={!row.priority}
            lineThroughBottomLine={!row.priority}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'currentCalculatedPurchasePrice',
        headerName: t('myAssortment.columns.purchasePrice'),
        renderCell: ({ row, value }) =>
          value ? (
            <DeletedTypography variant="subtitle2" isDeleted={!row.priority}>
              {value + ',-'}
            </DeletedTypography>
          ) : (
            '-'
          ),
      },
      {
        ...baseColumnProps,
        field: 'currentCalculatedCostPrice',
        headerName: t('myAssortment.columns.costPriceInBasePriceUnit'),
        renderCell: ({ row, value }) =>
          value ? (
            <DeletedTypography variant="subtitle2" isDeleted={!row.priority}>
              {value + ' ' + t('products.prices.currency.NOK.short')}
            </DeletedTypography>
          ) : (
            '-'
          ),
      },
      {
        ...baseColumnProps,
        field: 'currentCalculatedSalesPrice',
        headerName: t('myAssortment.columns.salesPrice'),
        renderCell: ({ row, value }) =>
          value ? (
            <DeletedTypography variant="subtitle2" isDeleted={!row.priority}>
              {value + ' ' + t('products.prices.currency.NOK.short')}
            </DeletedTypography>
          ) : (
            '-'
          ),
      },
      {
        field: 'actions',
        headerName: '',
        renderCell: ({ row }) => (
          <IconButton disabled={isLoading} onClick={() => onDeleteDraftItem([row.id])}>
            <TrashIcon fontSize="25px" />
          </IconButton>
        ),
      },
    ],
    [isLoading, onDeleteDraftItem, t]
  );

  const onRowsSelectionHandler = useCallback(
    (selections: GridSelectionModel) => {
      onSelectItems(selections as number[]);
    },
    [onSelectItems]
  );

  return (
    <Box height="100%">
      <DataGridPro
        apiRef={apiRef}
        getRowId={(row) => row.id}
        columns={columns}
        rows={items}
        rowHeight={70}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        hideFooter
        checkboxSelection
        onSelectionModelChange={onRowsSelectionHandler}
        components={{
          NoRowsOverlay: () => (
            <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
              <Typography variant="h3" color={(theme) => theme.palette.grey[200]}>
                {t('myAssortment.noItems')}
              </Typography>
            </Stack>
          ),
        }}
      />
    </Box>
  );
};

interface DeletedTypographyProps {
  isDeleted: boolean;
}

const DeletedTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isDeleted',
})<DeletedTypographyProps>(({ isDeleted }) => ({
  textDecoration: isDeleted ? 'line-through' : 'none',
}));
