import { useCalculusTFunction } from '@retail/calculus/i18n';
import { BodyLoader } from '@retail/components';
import {
  CreateItemLevelMarkupSchema,
  createItemLevelMarkupSchema,
} from '@retail/markup/components';
import dayjs from 'dayjs';
import {
  EditedMarkupField,
  MgItemInfo,
  PrioritizeSupplierDialog,
  ProductCard,
  SupplierTabs,
} from '@retail/my-assortment/components';
import { AssortmentItem, ItemsDTO } from '@retail/my-assortment/types';
import { MgItemDialog } from '@retail/products/containers';
import { MgItem, RecommendedPrice } from '@retail/products/types';
import { UniversalFormProvider } from '@shared/components';
import { useDisclosure } from '@shared/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchMpeMgItem } from '@retail/products/data-access';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useMutateCreateOrEditMarkupCondition } from '@retail/markup/data-access';
import { SupplierWithPriority } from '@retail/suppliers/types';
import { Paper } from '@mui/material';
import { useToast } from '@retail/hooks';
import { useQueryClient } from '@tanstack/react-query';

export interface Props {
  assortmentItem: AssortmentItem;
  isLoading: boolean;
  includeVat: boolean;
  closeProductCard: () => void;
  updatePriority: (items: ItemsDTO[]) => void;
}

export function ProductCardContainer({
  assortmentItem,
  isLoading,
  includeVat,
  closeProductCard,
  updatePriority,
}: Props) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const t = useCalculusTFunction();
  const { baseAssortmentId, draftAssortmentId, departmentId } = useDepartmentAssortments();

  const { data: mgItemDto } = useFetchMpeMgItem(
    { mgItemNumber: assortmentItem.mgItemNumber, assortmentId: baseAssortmentId },
    { suspense: true }
  );
  const mgItem = MgItem.fromDto(mgItemDto!);

  const { isOpen: isPrioritizeDialogOpen, onToggle: togglePrioritizeDialog } = useDisclosure();
  const { isOpen: openProductCard, onToggle: toggleProductCard } = useDisclosure();
  const { isOpen: createConditionDialogIsOpen, onToggle: toggleCreateConditionDialog } =
    useDisclosure();

  const suppliers: SupplierWithPriority[] = useMemo(
    () =>
      mgItem.supplierItems.map((supplierItem) => {
        return {
          id: supplierItem.mgSupplierId,
          name: supplierItem.mgSupplierName,
          priority: supplierItem.priority,
        };
      }),
    [mgItem.supplierItems]
  );

  const [selectedSupplierId, setSelectedSupplierId] = useState(() => assortmentItem.mgSupplierId);

  const selectSupplier = useCallback(
    (supplierId: number) =>
      setSelectedSupplierId(suppliers.find(({ id }) => id === supplierId)!.id),
    [setSelectedSupplierId, suppliers]
  );

  useEffect(() => setSelectedSupplierId(assortmentItem.mgSupplierId), [assortmentItem]);

  const selectedSupplierItem = mgItem.supplierItems.find(
    (supplierItem) => supplierItem.mgSupplierId === selectedSupplierId
  );

  const recommendedPrice = mgItem.recommendedPrice
    ? RecommendedPrice.fromDto(mgItem.recommendedPrice)
    : undefined;

  const { mutateAsync: mutateCreateMarkupCondition, isLoading: isLoadingCreate } =
    useMutateCreateOrEditMarkupCondition(draftAssortmentId, departmentId);

  const createCondition = useCallback(
    (data: CreateItemLevelMarkupSchema, editedMarkupField: EditedMarkupField) => {
      mutateCreateMarkupCondition({
        body: {
          mgSupplierId: selectedSupplierId,
          mgSupplierItemConditions: [
            {
              validFrom: data.fromDate ?? dayjs(new Date()).format('YYYY-MM-DD'),
              mgItemNumber: mgItem.id,
              salesPrice: editedMarkupField === 'price' ? Number(data.salesPrice) : undefined,
              markup: {
                templateCreate: {
                  templateName: data.name,
                  salesFactor: editedMarkupField !== 'price' ? data.salesFactor : undefined,
                },
              },
            },
          ],
        },
      })
        .catch((err) => {
          console.error(err);
          toast.error(t('calculus.error.generic'));
        })
        .then(() => {
          toggleCreateConditionDialog();
          toast.success(t('calculus.actions.conditionCreateInDrawer'));
          queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
        });
    },
    [
      mgItem.id,
      mutateCreateMarkupCondition,
      queryClient,
      selectedSupplierId,
      t,
      toast,
      toggleCreateConditionDialog,
    ]
  );

  const handlePostPriorityChanges = useCallback(
    (suppliers: SupplierWithPriority[]) => {
      const supplierItems = suppliers.map((supplier) => {
        return {
          mgSupplierId: supplier.id,
          mgItemNumber: mgItem.id,
          priority: supplier.priority,
        } as ItemsDTO;
      });
      updatePriority(supplierItems);
    },
    [mgItem.id, updatePriority]
  );

  return (
    <>
      <PrioritizeSupplierDialog
        suppliers={suppliers}
        isOpen={isPrioritizeDialogOpen}
        onClose={togglePrioritizeDialog}
        handlePostPriorityChanges={handlePostPriorityChanges}
        isLoading={isLoading}
      />

      <MgItemDialog isOpen={openProductCard} onClose={toggleProductCard} mgItem={mgItem} />

      {isLoading || !selectedSupplierItem ? (
        <BodyLoader />
      ) : (
        <UniversalFormProvider
          schema={createItemLevelMarkupSchema(t)}
          defaultValues={createItemLevelMarkupSchema(t).getDefault()}
        >
          <Paper variant="outlined" sx={{ borderRadius: 0, overflow: 'auto', width: 500 }}>
            <MgItemInfo
              {...assortmentItem}
              closeProductCard={closeProductCard}
              openProductCardModal={toggleProductCard}
            />
            <SupplierTabs
              suppliers={suppliers}
              clickedSupplier={assortmentItem.mgSupplierId}
              selectedSupplier={selectedSupplierId}
              selectSupplier={selectSupplier}
              openPrioritizeDialog={togglePrioritizeDialog}
            />
            <ProductCard
              assortmentItem={assortmentItem}
              selectedSupplierItem={selectedSupplierItem}
              recommendedPrice={recommendedPrice}
              includeVat={includeVat}
              isLoadingCreate={isLoadingCreate}
              createConditionDialogIsOpen={createConditionDialogIsOpen}
              toggleCreateConditionDialog={toggleCreateConditionDialog}
              createCondition={createCondition}
            />
          </Paper>
        </UniversalFormProvider>
      )}
    </>
  );
}
