import { User } from '@retail/auth/types';
import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMemo } from 'react';
import { urlConstant } from '@retail/utils';
import { useMpeAuthContext } from './MpeAuthContext';

export const meRoute = '/users/me';
export const meContextsRoute = '/v1/users/me/contexts';

export function useFetchMe(queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useFetch<User>({
    method: 'GET',
    queryKey: ['me'],
    url: `${mpe.url}${urlConstant}${meRoute}`,
    token,
    ...queryOptions,
  });
}

export const useIsSuperUser = () => {
  const { data: me } = useFetchMe({ refetchOnWindowFocus: false });

  return useMemo(() => {
    return !!me?.isSuperUser;
  }, [me]);
};
