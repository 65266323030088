import { SvgIcon, SvgIconProps } from '@mui/material';

export const DeactivateIcon = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M4.77199 5.6957C4.87278 5.59018 4.99356 5.50576 5.12729 5.44737C5.26102 5.38898 5.40504 5.35778 5.55095 5.35559C5.69685 5.3534 5.84174 5.38026 5.97716 5.43462C6.11258 5.48897 6.23584 5.56972 6.33975 5.67217C6.44366 5.77462 6.52615 5.89672 6.58241 6.03137C6.63867 6.16601 6.66758 6.3105 6.66745 6.45642C6.66733 6.60234 6.63817 6.74678 6.58168 6.88133C6.52518 7.01587 6.44248 7.13783 6.3384 7.2401C5.09177 8.50617 4.39524 10.2131 4.4002 11.9899C4.4002 15.698 7.35919 18.6988 11.0002 18.6988C14.6412 18.6988 17.6002 15.698 17.6002 11.9888C17.6002 10.1815 16.8951 8.4908 15.662 7.2401C15.5579 7.13783 15.4752 7.01587 15.4187 6.88133C15.3622 6.74678 15.3331 6.60234 15.3329 6.45642C15.3328 6.3105 15.3617 6.16601 15.418 6.03137C15.4742 5.89672 15.5567 5.77462 15.6606 5.67217C15.7645 5.56972 15.8878 5.48897 16.0232 5.43462C16.1587 5.38026 16.3035 5.3534 16.4494 5.35559C16.5954 5.35778 16.7394 5.38898 16.8731 5.44737C17.0068 5.50576 17.1276 5.59018 17.2284 5.6957C18.8806 7.37307 19.8048 9.63435 19.8002 11.9888C19.8002 16.9058 15.8644 20.8988 11.0002 20.8988C6.13709 20.8988 2.2002 16.9058 2.2002 11.9888C2.2002 9.5985 3.1352 7.3545 4.77199 5.6957Z"
          fill="currentColor"
        />
        <path
          d="M12.0994 2.19961C12.0994 1.5921 11.6069 1.09961 10.9994 1.09961C10.3919 1.09961 9.89941 1.5921 9.89941 2.19961V11.5496C9.89941 12.1571 10.3919 12.6496 10.9994 12.6496C11.6069 12.6496 12.0994 12.1571 12.0994 11.5496V2.19961Z"
          fill="currentColor"
        />
        <path
          d="M1.42206 2.97746C1.22169 2.77 1.11082 2.49214 1.11332 2.20372C1.11583 1.91531 1.23151 1.63941 1.43546 1.43546C1.63941 1.23151 1.91531 1.11583 2.20372 1.11332C2.49214 1.11082 2.77 1.22169 2.97746 1.42206L20.5775 19.0221C20.7778 19.2295 20.8887 19.5074 20.8862 19.7958C20.8837 20.0842 20.768 20.3601 20.5641 20.5641C20.3601 20.768 20.0842 20.8837 19.7958 20.8862C19.5074 20.8887 19.2295 20.7778 19.0221 20.5775L1.42206 2.97746Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
