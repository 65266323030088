export type AgreementStatusDTO = 'active' | 'activeInherited' | 'inactive';

export enum PriceType {
  GTIN = 'GTIN',
  NET = 'NET',
  REBATE = 'REBATE',
}

export enum PriceUnit {
  STK = 'ST',
  BNT = 'BNT',
  PKT = 'PKT',
  PAL = 'PAL',
  RLE = 'RLE',
  FRP = 'FRP',
  BRK = 'BRK',
}

export interface ItemAgreementDTO {
  mgSupplierItemId: number;
  mgItemNumber: number;
  finfoNumber: number;
  mgSupplierId: number;
  primaryText: string;
  secondaryText?: string;
  supplierItemId?: string;
  highestSupplierItemPrice?: boolean;
  active: boolean;
  inherited: boolean;
  price: number;
  priceUnit: PriceUnit;
  gtinPrice: number;
  gtinPriceUnit: string;
  priceType: PriceType;
  validFrom: Date;
  validTo: Date;
  agreementId: number;
  agreementText: string;
  conditionId: number;
  multiplePrices: boolean;
}
