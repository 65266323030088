import { MgSupplierItem, VilmaUnit } from '@retail/products/types';
import TableRow from '@mui/material/TableRow';
import TableCell, { TableCellBaseProps } from '@mui/material/TableCell';
import { CopyToClipboard } from '@retail/components';
import { Box, Stack, useTheme } from '@mui/material';
import { UnitTooltip } from '../../Units/UnitTooltip';
import Tooltip from '@mui/material/Tooltip';
import { ArmScaleIcon } from '@shared/custom-icons';
import {
  formatPrice,
  getClassCodeLabel,
  getPackageTypeLabel,
  toFixedIfNecessary,
} from '@retail/products/utils';
import { useProductsTFunction } from '@retail/products/i18n';
import { useTranslation } from 'react-i18next';
import { StarIcon } from '@sanity/icons';
import { SupplierItemPackage } from './SupplierItems';

interface Props extends SupplierItemPackage {
  index: number;
  basePackageUnit?: VilmaUnit;
  selectedSupplier: Partial<MgSupplierItem> | null;
}

export function MgSupplierItemPackagesTableRow({
  index,
  mgPackage,
  vilmaUnit,
  basePriceUnit,
  priceComparisonUnit,
  calculatedCount,
  basePrice,
  basePackageUnit,
  recommendedSalesPrice,
  type,
  mgSupplierName,
  mgSupplierId,
  selectedSupplier,
}: Props) {
  const t = useProductsTFunction();
  const { i18n } = useTranslation();
  const { palette } = useTheme();

  const unselected = selectedSupplier ? selectedSupplier.mgSupplierId !== mgSupplierId : false;
  const tableCellProps: Pick<TableCellBaseProps, 'color'> = {
    color: unselected ? palette.grey[200] : undefined,
  };

  return (
    <TableRow
      key={calculatedCount}
      sx={{
        backgroundColor: (theme) => (index % 2 === 0 ? theme.palette.info[200] : undefined),
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <Tooltip title={getPackageTypeLabel(t, type ?? '')}>
        <TableCell sx={{ ...tableCellProps }}>{getClassCodeLabel(type ?? '')}</TableCell>
      </Tooltip>
      <TableCell sx={{ ...tableCellProps }}>{mgSupplierName}</TableCell>

      <TableCell sx={{ ...tableCellProps }}>
        {mgPackage?.gtinCode ? (
          <CopyToClipboard
            value={mgPackage.gtinCode}
            CopyIconProps={unselected ? { fontSize: 'small', color: 'disabled' } : undefined}
          >
            {mgPackage.gtinCode}
          </CopyToClipboard>
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell sx={{ ...tableCellProps }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <UnitTooltip {...vilmaUnit} />
          {!!priceComparisonUnit && (
            <Tooltip title={t('products.mgItem.supplierItem.priceComparisonUnit')}>
              <div>
                <ArmScaleIcon fontSize="small" color={unselected ? 'disabled' : undefined} />
              </div>
            </Tooltip>
          )}
          {!!basePriceUnit && (
            <Tooltip title={t('products.mgItem.supplierItem.basePriceUnit')}>
              <StarIcon fontSize="20px" />
            </Tooltip>
          )}
        </Stack>
      </TableCell>

      <TableCell sx={{ ...tableCellProps }}>
        {mgPackage?.vilmaUnit ? (
          <Box display="flex">
            <UnitTooltip {...mgPackage?.vilmaUnit} />
          </Box>
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell sx={{ ...tableCellProps }}>
        <Stack direction="row" alignItems="center">
          {toFixedIfNecessary(calculatedCount, 2)}

          {basePackageUnit && (
            <UnitTooltip {...basePackageUnit} typographyProps={{ sx: { ml: 1 } }} />
          )}
        </Stack>
      </TableCell>

      <TableCell align="right" sx={{ ...tableCellProps }}>
        {basePrice ? `${formatPrice(basePrice, i18n.language)}` : '-'}
      </TableCell>

      <TableCell align="right" sx={{ ...tableCellProps }}>
        {recommendedSalesPrice ? `${formatPrice(recommendedSalesPrice, i18n.language)}` : '-'}
      </TableCell>
    </TableRow>
  );
}
