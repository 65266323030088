import { useProductsTFunction } from '@retail/products/i18n';
import { TableBody, TableRow } from '@mui/material';
import { MgSupplierItem } from '@retail/products/types';
import { etimKeys } from './helpers';
import { DocumentationKeyTableCell, DocumentationTableCell } from '../styles';
import { DownloadDocumentationLink } from '../DownloadDocumentation/DownloadDocumentationLink';

interface Props {
  suppliers: Partial<MgSupplierItem>[];
}

export const DocumentationTableBody = ({ suppliers }: Props) => {
  const t = useProductsTFunction();

  return (
    <TableBody>
      {etimKeys.map((key, rowIndex) => (
        <TableRow key={key}>
          <DocumentationKeyTableCell index={rowIndex}>
            {/* @ts-ignore */}
            {t(`products.mgItem.etim.${key as keyof typeof etimKeys}`)}
          </DocumentationKeyTableCell>

          {suppliers.map((supplier, supplierIndex) => {
            return (
              <DocumentationTableCell
                key={`${rowIndex}-${supplier.id || supplierIndex}`}
                align="center"
                index={rowIndex}
              >
                {supplier.etim?.[key] ? (
                  <DownloadDocumentationLink url={String(supplier.etim?.[key])} />
                ) : (
                  '-'
                )}
              </DocumentationTableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
};
