import { Menu, MenuItem } from '@mui/material';
import { Actions } from '@retail/calculus/types';
import { useFreightTFunction } from '@retail/freight/i18n';

interface Props {
  open: boolean;
  id: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  setAction: (action: Actions) => void;
}

export const FreightTableActions = ({ open, id, anchorEl, onClose, setAction }: Props) => {
  const t = useFreightTFunction();

  return (
    <Menu open={open} id={id} anchorEl={anchorEl} onClose={onClose}>
      <MenuItem
        onClick={() => {
          setAction('edit');
          onClose();
        }}
      >
        {t('freight.actions.edit')}
      </MenuItem>

      <MenuItem
        onClick={() => {
          setAction('delete');
          onClose();
        }}
      >
        {t('freight.actions.delete')}
      </MenuItem>
    </Menu>
  );
};
