import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useFetchDepartmentAssortments } from '@retail/my-assortment/data-access';
import { Type } from '@retail/my-assortment/types';
import { useMemo } from 'react';

export const useDepartmentAssortments = () => {
  const { selectedDepartment } = useSelectedOrgUnit();
  const departmentId = selectedDepartment!.departmentId;

  const { data: assortments = [] } = useFetchDepartmentAssortments({ departmentId });

  const baseAssortment = useMemo(
    () => assortments.find((assortment) => assortment.type === Type.BASE)!,
    [assortments]
  );
  const draftAssortment = useMemo(
    () => assortments.find((assortment) => assortment.type === Type.DRAFT)!,
    [assortments]
  );
  return {
    draftAssortment,
    baseAssortment,
    departmentId,
    draftAssortmentId: draftAssortment.id,
    baseAssortmentId: baseAssortment.id,
  };
};
