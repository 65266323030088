import { MgItemMinimalDTO } from '../../../dto';
import { CompetitorWithPrice } from '../CompetitorWithPrice';
import { getUniqueStoreIds } from '../mgItemUtils';
import { PpsMgSupplierItemBase } from './PpsMgSupplierItem';
import { PpsMgPackage } from './PpsMgPackage';

interface ConstructorProps {
  id: number;
  supplierItems: PpsMgSupplierItemBase[];
  mgPackages: PpsMgPackage[];
  competitors: CompetitorWithPrice[];
}

export class MgItemMinimal {
  id: number;
  supplierItems: PpsMgSupplierItemBase[];
  mgPackages: PpsMgPackage[];
  competitors: CompetitorWithPrice[];

  constructor({ id, supplierItems, mgPackages, competitors }: ConstructorProps) {
    this.id = id;
    this.supplierItems = supplierItems;
    this.mgPackages = mgPackages;
    this.competitors = competitors;
  }
  getPrimarySupplierItem = (): PpsMgSupplierItemBase => {
    const mainSupplier = this.supplierItems.find(({ isMainSupplier }) => isMainSupplier);
    if (mainSupplier) {
      return mainSupplier;
    }
    const supplierItemsWithFinfoNumber = this.supplierItems.filter(
      ({ finfoNumber }) => finfoNumber
    );
    if (supplierItemsWithFinfoNumber.length) {
      return this.supplierItems.sort((a, b) => (b.finfoNumber || 0) - (a.finfoNumber || 0))[0];
    }
    return this.supplierItems[0];
  };

  getPrimaryPackage = (): PpsMgPackage | undefined => {
    return (
      this.mgPackages.find(({ packageType }) => packageType === 'PRIMARY') ||
      this.mgPackages
        .filter(({ gtinCode }) => gtinCode)
        .sort((a, b) => (a.packageNumber! > b.packageNumber! ? 1 : -1))[0] ||
      this.mgPackages.sort((a, b) => (a.packageNumber! > b.packageNumber! ? 1 : -1))[0]
    );
  };

  capitalizeFirstLetter = (text?: string) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  static fromDto(mgItemDto: MgItemMinimalDTO): MgItemMinimal {
    const supplierItems: PpsMgSupplierItemBase[] = mgItemDto.mgSupplierItems?.map(
      ({ finfoNumber, primaryText, mainSupplier }) => ({
        finfoNumber,
        primaryText,
        isMainSupplier: !!mainSupplier,
      })
    );
    const mgPackages = mgItemDto.mgPackages.map(PpsMgPackage.fromDto);
    const uniqueStoreIds = getUniqueStoreIds(mgItemDto);

    const competitors: CompetitorWithPrice[] = uniqueStoreIds.map((storeId) => {
      return CompetitorWithPrice.getCompetitorWithPrice(mgItemDto, storeId);
    });

    return new MgItemMinimal({
      id: mgItemDto.mgItemNumber,
      supplierItems,
      mgPackages,
      competitors,
    });
  }
}
