import { forwardRef } from 'react';
import { RotatableSvgIcon, RotatableSvgIconProps } from './RotatableSvgIcon';

const DrawerIcon = forwardRef<SVGSVGElement, RotatableSvgIconProps>((props, ref) => {
  return (
    <RotatableSvgIcon {...props} ref={ref} width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M7.54343 6.38642L8.31525 5.61514L6.00089 3.30078L3.68652 5.61514L4.45834 6.38642L6.00089 4.84442L7.54343 6.38696"
        fill="currentColor"
      />
      <path
        d="M7.54343 8.70087L8.31525 7.9296L6.00089 5.61523L3.68652 7.9296L4.45834 8.70087L6.00089 7.15723L7.54343 8.70033"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 9.31364 2.68636 12 6 12C9.31364 12 12 9.31364 12 6C12 2.68636 9.31364 0 6 0C2.68636 0 0 2.68636 0 6ZM1.09091 6C1.09091 6.64467 1.21789 7.28303 1.46459 7.87863C1.7113 8.47423 2.0729 9.0154 2.52875 9.47125C2.9846 9.9271 3.52577 10.2887 4.12137 10.5354C4.71697 10.7821 5.35533 10.9091 6 10.9091C6.64467 10.9091 7.28303 10.7821 7.87863 10.5354C8.47423 10.2887 9.0154 9.9271 9.47125 9.47125C9.9271 9.0154 10.2887 8.47423 10.5354 7.87863C10.7821 7.28303 10.9091 6.64467 10.9091 6C10.9091 4.69803 10.3919 3.44938 9.47125 2.52875C8.55062 1.60812 7.30197 1.09091 6 1.09091C4.69803 1.09091 3.44938 1.60812 2.52875 2.52875C1.60812 3.44938 1.09091 4.69803 1.09091 6Z"
        fill="currentColor"
      />
    </RotatableSvgIcon>
  );
});

export default DrawerIcon;
