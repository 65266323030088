import { SupplierItemAutocomplete } from '@retail/components';
import { AutocompleteInput, SelectInputField } from '@shared/components';
import { LevelSelectSchema } from './levelSelectSchema';
import { useController, useFormContext } from 'react-hook-form';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { SelectChangeEvent, Stack } from '@mui/material';
import { MgSupplierItemSearchResult, ProductGroup } from '@retail/products/types';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  productGroups: ProductGroup[];
  mgSupplierItems?: MgSupplierItemSearchResult[];
  isLoadingFetchItems: boolean;
  handleLevelSelectChange: (value: SelectChangeEvent<unknown>) => void;
  setMgSupplierItemSearch: Dispatch<SetStateAction<string>>;
}

export function LevelSelectForm({
  productGroups,
  mgSupplierItems,
  isLoadingFetchItems,
  handleLevelSelectChange,
  setMgSupplierItemSearch,
}: Props) {
  const t = useCalculusTFunction();
  const { control } = useFormContext<LevelSelectSchema>();

  const levelController = useController({ name: 'level', control });
  const productGroupController = useController({ name: 'productGroup', control });
  const itemController = useController({ name: 'item', control });

  const menuItems = [
    { value: 'supplier', name: t('calculus.level.supplier') },
    { value: 'productGroup', name: t('calculus.level.productGroup') },
    { value: 'item', name: t('calculus.level.item') },
  ];

  return (
    <Stack>
      <SelectInputField
        {...levelController.field}
        error={levelController.fieldState.error?.message}
        size="small"
        width={400}
        label={t('calculus.level.level')}
        placeholder={t('calculus.actions.chooseLevel')}
        menuItems={menuItems}
        onChange={handleLevelSelectChange}
        required
      />

      {levelController.field.value === 'productGroup' && (
        <AutocompleteInput
          {...productGroupController.field}
          error={productGroupController.fieldState.error?.message}
          required
          width={400}
          size="small"
          label={t('calculus.level.productGroup')}
          placeholder={t('calculus.actions.chooseProductGroup')}
          showPopupIcon
          options={productGroups}
          getOptionLabel={(option) => option.getLabel()}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, value) => productGroupController.field.onChange(value)}
        />
      )}

      {levelController.field.value === 'item' && (
        <SupplierItemAutocomplete
          {...itemController.field}
          error={itemController.fieldState.error?.message}
          label={t('calculus.level.item')}
          required
          width={400}
          size="small"
          supplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          setSupplierItemSearch={setMgSupplierItemSearch}
          onChange={(value) => itemController.field.onChange(value)}
        />
      )}
    </Stack>
  );
}
