import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useProductsTFunction } from '@retail/products/i18n';
import { Chip } from '@shared/components';
import { MgPackagesButtonGroup } from './MgPackagesButtonGroup';
import { useState } from 'react';
import { ArmScaleIcon } from '@shared/custom-icons';
import { MgItemPackages, MgSupplierItemPackage } from '@retail/products/types';

interface Props {
  uniquePackages: MgItemPackages;
  isSuperUser: boolean;
}

export const MgItemDetailsPackaging = ({ uniquePackages, isSuperUser }: Props) => {
  const t = useProductsTFunction();

  const { palette } = useTheme();

  const [selectedItem, setSelectedItem] = useState<MgSupplierItemPackage['type']>('PRIMARY');
  const selectedMgPackages = uniquePackages[selectedItem];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      border={`1px solid ${palette.grey[100]}`}
      borderRadius={1}
      px={2}
    >
      <Typography fontWeight="bold" my={1}>
        {t('products.mgItem.supplierItem.packages')}
      </Typography>

      <MgPackagesButtonGroup
        packages={uniquePackages}
        isSuperUser={isSuperUser}
        selectedPackage={selectedItem}
        setSelectedPackage={setSelectedItem}
      />

      <Box my={2}>
        {selectedMgPackages?.map((selectedMgPackage, index) => {
          return (
            <Box
              display="flex"
              key={selectedMgPackage.gtinCode ?? selectedMgPackage.description}
              mb={index < selectedMgPackages.length - 1 ? 1 : 0}
            >
              <Box display="flex" alignItems="center" mr={3}>
                {t('products.mgItem.supplierItem.unit')}:{' '}
                <Typography
                  display="flex"
                  alignItems="center"
                  fontWeight={600}
                  component={Box}
                  ml={1}
                >
                  <Chip
                    rounded
                    backgroundColor={palette.info[400]}
                    label={
                      <Typography fontWeight="bold">{selectedMgPackage?.description}</Typography>
                    }
                  />

                  {!selectedMgPackage.gtinCode && (
                    <ArmScaleIcon sx={{ ml: 0.5 }} fontSize="small" />
                  )}
                </Typography>
              </Box>

              {selectedMgPackage.gtinCode && (
                <Box display="flex" alignItems="center">
                  {t('products.mgItem.supplierItem.gtin.label')}:{' '}
                  <Typography component={Box} ml={1}>
                    {selectedMgPackage?.gtinCode}
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
