import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { Department } from '@retail/auth/types';
import { ActivatedConditionsDTO, CalculusAreas } from '@retail/calculus/types';
import { urlConstant } from '@retail/utils';
import { fetcher, QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { useMutation } from '@tanstack/react-query';
import { useSelectedOrgUnitHeader } from '@retail/app/hooks';

const assortementsPath = '/assortments';
const activateConditionsPath = '/activate-conditions';

interface PostProps {
  assortmentId: number;
  departmentId: Department['departmentId'];
}

interface MutationProps {
  type: CalculusAreas;
  mgSupplierId?: number;
  conditionIds?: number[];
}

export function useMutateActivateDraftedConditions(
  { assortmentId, departmentId }: PostProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  return useMutation(({ type, mgSupplierId, conditionIds }: MutationProps) => {
    return fetcher<ActivatedConditionsDTO>({
      url: buildUrl({
        base: mpe.url,
        route: `${urlConstant}${assortementsPath}/${assortmentId}${activateConditionsPath}`,
        searchParams: {
          departmentId,
          mgSupplierId,
          type,
        },
      }),
      body: conditionIds && JSON.stringify(conditionIds),
      token,
      method: 'POST',
      headers: selectedOrgUnitHeader,
      ...queryOptions,
    });
  });
}
