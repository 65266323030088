import { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import {
  dataGridBoldClass,
  dataGridCellHoverClass,
  dataGridCellNoFocusOutlineClass,
} from '@shared/styles';
import { useSuppliersTFunction } from '@retail/suppliers/i18n';
import { Supplier } from '@retail/suppliers/types';
import clsx from 'clsx';
import { CheckIcon } from '@shared/custom-icons';
import { useAppTFunction } from '@retail/app/i18n';

type Props = {
  suppliers: Supplier[];
  onSupplierSelect: (supplierId: number) => void;
};

type GridColSupplier = GridColDef<Supplier>;

const baseColumnProps: Partial<GridColSupplier> = {
  width: 150,
  sortable: false,
  headerName: '',
};

export const SuppliersTable = ({ suppliers, onSupplierSelect }: Props) => {
  const t = useSuppliersTFunction();
  const appT = useAppTFunction();

  const columns: GridColSupplier[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'name',
        cellClassName: () => dataGridBoldClass,
        headerName: t('suppliers.columns.participantAccountName'),
        headerAlign: 'left',
        align: 'left',
        width: 250,
      },
      {
        ...baseColumnProps,
        field: 'mgSupplierId',
        headerName: t('suppliers.columns.mgParticipantAccountId'),
      },
      {
        ...baseColumnProps,
        field: 'finfoSupplierId',
        headerName: t('suppliers.columns.finfoSupplierId'),
      },
      {
        ...baseColumnProps,
        field: 'gln',
        headerName: t('suppliers.columns.gln'),
      },
      {
        ...baseColumnProps,
        field: 'purchaseAgreementCount',
        headerName: t('suppliers.columns.purchaseAgreementCount'),
      },
      {
        ...baseColumnProps,
        width: 160,
        field: 'hasStandardAgreement',
        headerName: t('suppliers.columns.hasStandardAgreement'),
        renderCell: ({ row }) =>
          row.hasStandardAgreement ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>{appT('common.yes')}</Typography>
              <CheckIcon />
            </Stack>
          ) : (
            appT('common.no')
          ),
      },
      {
        field: 'fillWidth',
        headerName: '',
        minWidth: 0,
        flex: 1,
      },
    ],
    [t]
  );

  return (
    <Box height="100%">
      <DataGridPro
        getRowId={(row) => row.mgSupplierId}
        columns={columns}
        rows={suppliers}
        rowHeight={65}
        getCellClassName={() => clsx(dataGridCellNoFocusOutlineClass, dataGridCellHoverClass)}
        onCellClick={({ id }) => {
          onSupplierSelect(Number(id));
        }}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        hideFooter
      />
    </Box>
  );
};
