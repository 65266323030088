import { fetchFile } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useAuthContext, useMpeAuthContext } from '@retail/auth/data-access';
import {
  ItemCodes,
  ItemCodesDTO,
  MgAssortmentCreateDTO,
  MgAssortmentDetailsDTO,
  MgAssortmentDTO,
} from '@retail/monitoring-assortment/types';
import { useMutation } from '@tanstack/react-query';
import { mgAssortmentsRoute, validateItemsRoute } from './mgAssortmentApi';
import { SelectedPPSContext } from '@retail/app/stores/selected-context';
import { useMutateMPEFetch, useSelectedOrgUnitHeader } from '@retail/app/hooks';

export function useValidateImportedItems() {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useMutateMPEFetch<ItemCodesDTO, ItemCodes>({
    method: 'POST',
    token,
    url: `${pps.url}${validateItemsRoute}`,
  });
}

export function useCreateMgAssortment(context: SelectedPPSContext) {
  const { token } = useAuthContext();
  const { pps } = useMgPrisConfig();

  return useMutateMPEFetch<MgAssortmentDTO, MgAssortmentCreateDTO>({
    url: `${pps.url}${mgAssortmentsRoute}`,
    method: 'POST',
    context: { key: context.type, value: context.value },
    token,
  });
}

interface PostProps {
  mgItemNumbers: number[];
  finfoNumbers?: number[];
  gtinCodes?: number[];
}

interface PatchProps {
  itemCodes: ItemCodes;
  title?: string;
  description?: string;
}

export const useAddItemsToMgAssortment = (context: SelectedPPSContext) => {
  const { pps } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<MgAssortmentDetailsDTO, PostProps>({
    url: (assortmentId: number) => `${pps.url}${mgAssortmentsRoute}/${assortmentId}/items`,
    method: 'POST',
    context: { key: context.type, value: context.value },
    token,
  });
};

export const useDeleteItemsFromMgAssortment = (context: SelectedPPSContext) => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void>({
    url: (assortmentId: number) => `${pps.url}${mgAssortmentsRoute}/${assortmentId}/items`,
    method: 'DELETE',
    context: { key: context.type, value: context.value },
    token,
  });
};

export const usePatchTitleOrItemsOfMgAssortment = (context: SelectedPPSContext) => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  return useMutateMPEFetch<MgAssortmentDetailsDTO, PatchProps>({
    url: (assortmentId: number) => `${pps.url}${mgAssortmentsRoute}/${assortmentId}`,
    method: 'PATCH',
    context: { key: context.type, value: context.value },
    token,
  });
};

export const useDeleteMgAssortment = (context: SelectedPPSContext) => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();

  return useMutateMPEFetch<void>({
    url: (assortmentId: number) => `${pps.url}${mgAssortmentsRoute}/${assortmentId}`,
    method: 'DELETE',
    context: { key: context.type, value: context.value },
    token,
  });
};

export const useExportAssortmentToExcel = () => {
  const { pps } = useMgPrisConfig();
  const { token } = useAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  return useMutation((assortmentId: number) =>
    fetchFile({
      url: `${pps.url}${mgAssortmentsRoute}/${assortmentId}/export-excel`,
      token,
      method: 'GET',
      headers: selectedOrgUnitHeader,
    })
  );
};
