import { AssortmentDTO, Source, Type } from '../dto';
export interface AssortmentProps {
  id: number;
  title: string;
  source: Source;
  updatedAt: string;
  createdAt: string;
  type: Type;
  readOnly: boolean;
  parent?: number;
  orgUnitId: string;
}
export class Assortment {
  id: number;
  title: string;
  source: Source;
  updatedAt: string;
  createdAt: string;
  type: Type;
  readOnly: boolean;
  parent?: number;
  orgUnitId: string;

  constructor({
    id,
    title,
    source,
    updatedAt,
    createdAt,
    type,
    readOnly,
    parent,
    orgUnitId,
  }: AssortmentProps) {
    this.id = id;
    this.title = title;
    this.source = source;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.type = type;
    this.readOnly = readOnly;
    this.parent = parent;
    this.orgUnitId = orgUnitId;
  }

  static fromDTO = (dto: AssortmentDTO): Assortment =>
    new Assortment({
      id: dto.id,
      title: dto.title,
      source: dto.source,
      updatedAt: dto.updatedAt,
      createdAt: dto.createdAt,
      type: dto.type,
      readOnly: dto.readOnly,
      parent: dto.parent,
      orgUnitId: dto.orgUnitId,
    });
}
