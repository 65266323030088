import { HierarchyLevels } from '@retail/calculus/types';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { MarkupCondition } from '@retail/markup/types';

export const getBindingValue = (bindingType: MarkupCondition['bindingType']) => {
  const t = useMarkupTFunction();

  let translationKey = '';

  switch (bindingType) {
    case HierarchyLevels.supplier:
      translationKey = 'supplier';
      break;
    case HierarchyLevels.overGroup:
      translationKey = 'overGroup';
      break;
    case HierarchyLevels.mainGroup:
      translationKey = 'mainGroup';
      break;
    case HierarchyLevels.productGroup:
      translationKey = 'productGroup';
      break;
    case HierarchyLevels.item:
      translationKey = 'item';
      break;
    default:
      break;
  }

  // @ts-ignore line
  return t(`markup.levels.${translationKey}`);
};
