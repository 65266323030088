import { ItemAgreementSelect } from '@retail/purchase-agreements/components';
import { useFetchItemAgreements } from '@retail/purchase-agreements/data-access';
import { ItemAgreement, PriceType } from '@retail/purchase-agreements/types';

interface Props {
  mgItemNumber: number;
  supplierId: number;
  selectedAgreement: number;
  selectedPriceType: PriceType;
  selectAgreement: (agreementId: number, priceType: PriceType) => void;
}

export function SelectItemAgreements({
  mgItemNumber,
  supplierId,
  selectedAgreement,
  selectedPriceType,
  selectAgreement,
}: Props) {
  const { data: itemAgreementsPageDto } = useFetchItemAgreements({
    supplierId,
    mgItemId: mgItemNumber,
  });
  const itemAgreementDtos = itemAgreementsPageDto?.result ?? [];
  const itemAgreements = itemAgreementDtos.map(ItemAgreement.fromDto);

  return (
    <ItemAgreementSelect
      itemAgreements={itemAgreements}
      selectedAgreement={selectedAgreement}
      selectedPriceType={selectedPriceType}
      selectAgreement={selectAgreement}
    />
  );
}
