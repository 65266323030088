import { Stack, Typography, alpha, useTheme } from '@mui/material';
import { Chip } from '@shared/components';
import { PriceListTable } from './PriceListTable';
import { Dispatch, SetStateAction } from 'react';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { DialogLayout } from '@retail/components';
import { PriceListPageResult } from '@retail/purchase-agreements/types';
import { MgSupplierItemSearchField } from '@retail/products/components';

interface Props {
  open: boolean;
  onClose: () => void;
  result: PriceListPageResult;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

export function PriceListDialog({
  open,
  onClose,
  result,
  setSearchQuery,
  updatePage,
  updatePageSize,
}: Props) {
  const t = usePurchaseAgreementsTFunction();
  const { palette } = useTheme();

  return (
    <DialogLayout
      open={open}
      onClose={onClose}
      width="md"
      header={t('purchaseAgreements.priceList.priceList')}
    >
      <Stack minHeight={900}>
        <Stack px={3} flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack flexDirection="row" py={2} gap={1}>
            <Typography fontWeight={700}>{t('purchaseAgreements.priceList.nrOfItems')}:</Typography>
            <Chip
              size="small"
              backgroundColor={alpha(palette.info.main, 0.6)}
              label={result.totalItems}
            />
          </Stack>
          <MgSupplierItemSearchField
            isDisabled={false}
            onSubmitSearch={setSearchQuery}
            includeLabel={false}
            size="small"
          />
        </Stack>

        <PriceListTable result={result} updatePage={updatePage} updatePageSize={updatePageSize} />
      </Stack>
    </DialogLayout>
  );
}
