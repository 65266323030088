import { SyncLock } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { Status } from '@retail/my-assortment/types';
import { CheckIcon, WarningIcon } from '@shared/custom-icons';

interface Props {
  status?: Status;
}

export const ItemStatus = ({ status }: Props) => {
  const t = useMyAssortmentTFunction();
  switch (status) {
    case Status.ACTION_REQUIRED:
      return (
        <Stack alignItems="center" gap={0.5}>
          <WarningIcon color="warning" />
          <Typography variant="body3">{t('myAssortment.status.actionNeeded')}</Typography>
        </Stack>
      );
    case Status.DRAFT:
      return (
        <Stack alignItems="center" gap={0.5}>
          <CheckIcon color="#E8AE58" />
          <Typography variant="body3">{t('myAssortment.status.toApproval')}</Typography>
        </Stack>
      );
    case Status.FOR_APPROVAL:
      return (
        <Stack alignItems="center" gap={0.5}>
          <CheckIcon color="#E8AE58" />
          <Typography variant="body3">{t('myAssortment.status.toApproval')}</Typography>
        </Stack>
      );
    case Status.CURRENT:
      return (
        <Stack alignItems="center" gap={0.5}>
          <CheckIcon />
          <Typography variant="body3">{t('myAssortment.status.inAssortment')}</Typography>
        </Stack>
      );
    case Status.IN_ASSORTMENT:
      return (
        <Stack alignItems="center" gap={0.5}>
          <CheckIcon />
          <Typography variant="body3">{t('myAssortment.status.inAssortment')}</Typography>
        </Stack>
      );
    case Status.PENDING:
      return (
        <Stack alignItems="center" gap={0.5}>
          <SyncLock />
          <Typography variant="body3">{t('myAssortment.status.toCalculation')}</Typography>
        </Stack>
      );
    default:
      return <Typography>{status}</Typography>;
  }
};
