import { useMemo, useRef } from 'react';
import { useScroll, useVisibleElements } from 'react-snaplist-carousel';

export function useCarouselPreview(numItems: number) {
  const snapListRef = useRef(null);
  const visibleElements = useVisibleElements(
    { debounce: 10, ref: snapListRef },
    (elements) => elements
  );

  const itemInFocus = useMemo(() => {
    // the "main" item in focus is the middle element of all visible elements
    const itemInFocus = visibleElements[Math.floor(visibleElements.length / 2)];

    return itemInFocus;
  }, [visibleElements]);

  const goToSnapItem = useScroll({ ref: snapListRef });

  const scrollBack = () => {
    if (itemInFocus > 0) {
      goToSnapItem(itemInFocus - 1);
    }
  };

  const scrollForward = () => {
    if (itemInFocus < numItems - 1) {
      goToSnapItem(itemInFocus + 1);
    }
  };

  return {
    snapListRef,
    visibleElements,
    goToSnapItem,
    scrollForward,
    scrollBack,
  };
}
