import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { FreightCondition } from '@retail/freight/types';
import { CalculationEntry } from './CalculationEntry';
import { ActiveTagPaper } from '@retail/components';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { useMemo } from 'react';

interface Props {
  freightCondition?: FreightCondition;
}

export function FreightAccordion({ freightCondition }: Props) {
  const t = useAppTFunction();

  const freightLabel = useMemo(() => {
    if (freightCondition?.percentAddon) {
      return `${freightCondition.percentAddon.toFixed(2)} %`;
    }
    if (freightCondition?.freightFactor) {
      return freightCondition.freightFactor;
    }
    return 0;
  }, [freightCondition?.freightFactor, freightCondition?.percentAddon]);

  return (
    <Accordion sx={{ borderBottom: ({ palette }) => `1px solid ${palette.divider}`, px: 0 }}>
      <AccordionSummary>
        <CalculationEntry label={t('myAssortment.freightFactor')} chipLabel={freightLabel} />
      </AccordionSummary>
      <AccordionDetails>
        <ActiveTagPaper>
          {freightCondition ? (
            <Grid container gap={2} flexDirection="column">
              <Stack gap={3} flexDirection="row" fontSize={14}>
                <Grid item fontWeight={600}>
                  {t('freight.columns.percentAddon')}
                </Grid>
                <Grid item>{freightCondition.percentAddon}</Grid>
              </Stack>
              <Stack gap={4} flexDirection="row" fontSize={14}>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('freight.columns.fromDate')}
                  </Grid>
                  <Grid item>{dayjs(freightCondition.validFrom).format('ll')}</Grid>
                </Stack>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('freight.columns.toDate')}
                  </Grid>
                  <Grid item>
                    {freightCondition.validTo ? dayjs(freightCondition.validTo).format('ll') : '->'}
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Typography>{t('myAssortment.productCard.noActiveFreightCondition')}</Typography>
          )}
        </ActiveTagPaper>
      </AccordionDetails>
    </Accordion>
  );
}
