import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { DataGridPagination, StackedTextCell } from '@retail/components';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { alpha } from '@mui/material';
import { SupplierItemConditions } from '@retail/calculus/types';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { SalesPrice } from '@retail/my-assortment/types';

interface Props {
  items: SupplierItemConditions[];
  page: number;
  pageSize: number;
  totalItems: number;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

export function AffectedItemsTable({
  items,
  page,
  pageSize,
  totalItems,
  updatePage,
  updatePageSize,
}: Props) {
  const t = useCalculusTFunction();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'primaryText',
        width: 280,
        headerName: t('calculus.itemsAffected.columns.itemName'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />
        ),
      },
      {
        field: 'mgItemAndFinfo',
        width: 160,
        headerName: t('calculus.itemsAffected.columns.mgItemAndFinfo'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.mgItemNumber} bottomLine={row.finfoNumber} />
        ),
      },
      {
        field: 'productGroup',
        width: 160,
        headerName: t('calculus.itemsAffected.columns.productGroup'),
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.groupDescription} bottomLine={row.groupNumber} />
        ),
      },
      {
        field: 'purchasePrice',
        width: 160,
        headerName: t('calculus.itemsAffected.columns.purchasePrice'),
        renderCell: ({ row }) => {
          const prices = row.simulatedPrices[0]?.prices as SalesPrice[];
          const purchasePrice = prices.find((price) => price.primaryUnit)?.purchasePrice;

          return purchasePrice
            ? t('calculus.itemsAffected.columns.price', { price: purchasePrice })
            : '-';
        },
      },
      {
        field: 'costPrice',
        width: 160,
        headerName: t('calculus.itemsAffected.columns.costPrice'),
        renderCell: ({ row }) => {
          const prices = row.simulatedPrices[0]?.prices as SalesPrice[];
          const costPrice = prices.find((price) => price.basePriceUnit)?.costPrice;

          return costPrice ? t('calculus.itemsAffected.columns.price', { price: costPrice }) : '-';
        },
      },
      {
        field: 'salesPrice',
        width: 160,
        headerName: t('calculus.itemsAffected.columns.salesPrice'),
        renderCell: ({ row }) => {
          const prices = row.simulatedPrices[0]?.prices as SalesPrice[];
          const salesPrice = prices.find((price) => price.primaryUnit)?.salesPrice;

          return salesPrice
            ? t('calculus.itemsAffected.columns.price', { price: salesPrice })
            : '-';
        },
      },
    ],
    [t]
  );

  return (
    <DataGridPro
      autoHeight
      disableSelectionOnClick
      rows={items}
      getRowId={(row) => row.mgItemNumber + row.finfoNumber}
      columns={columns}
      pagination={true}
      paginationMode="server"
      components={{
        Pagination: DataGridPagination,
      }}
      rowCount={totalItems}
      page={page}
      pageSize={pageSize}
      onPageChange={updatePage}
      onPageSizeChange={updatePageSize}
      sx={{
        '& .MuiDataGrid-row': {
          ':last-of-type': { borderBottomLeftRadius: 24, borderBottomRightRadius: 24 },
          ':nth-of-type(odd)': {
            backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
            ':hover': {
              backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
            },
          },
        },
      }}
    />
  );
}
