import { Stack } from '@mui/material';
import { EnvironmentDocumentationTable } from './EnvironmentDocumentationTable';
import { MgItem } from '@retail/products/types';

interface Props {
  mgItem: MgItem;
}

export const EnvironmentDocumentation = ({ mgItem }: Props) => {
  return (
    <Stack>
      {/* <DownloadDocumentation mgItem={mgItem} isEnvironmental /> */}
      <EnvironmentDocumentationTable mgItem={mgItem} />
    </Stack>
  );
};
