import { ExportButton } from '@retail/components';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { FetchAssortmentProps, useExportAssortment } from '@retail/my-assortment/data-access';
import { downloadResponseFile } from '@shared/fetch-utils';

interface Props {
  noItems: boolean;
  selectedSupplier?: number;
  selectedProductGroup?: string;
  query: string;
}

export const ExportAssortment = ({
  noItems,
  selectedSupplier,
  selectedProductGroup,
  query,
}: Props) => {
  const { baseAssortmentId } = useDepartmentAssortments();

  const { mutateAsync: mutateExportAssortmentToExcel, isLoading: isLoadingExcel } =
    useExportAssortment();

  const exportAssortmentToExcel = async ({
    assortmentId,
    includeOwner,
    supplierIds,
    groupNumbers,
    query,
  }: FetchAssortmentProps) => {
    return mutateExportAssortmentToExcel({
      assortmentId,
      includeOwner,
      supplierIds,
      groupNumbers,
      query,
    }).then(downloadResponseFile({ filenameFallback: 'my-assortment.xlsx' }));
  };

  return (
    <ExportButton
      disabled={isLoadingExcel || noItems}
      onClick={() => {
        exportAssortmentToExcel({
          assortmentId: baseAssortmentId,
          includeOwner: true,
          supplierIds: selectedSupplier ? [selectedSupplier] : undefined,
          groupNumbers: selectedProductGroup ? [selectedProductGroup] : undefined,
          query,
        });
      }}
    />
  );
};
