import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { CalculationEntry } from './CalculationEntry';
import { ActiveTagPaper } from '@retail/components';
import { PurchaseCondition } from '@retail/purchase-agreements/types';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';

interface Props {
  purchaseCondition?: PurchaseCondition;
  primaryUnit?: string;
  purchasePrice?: number;
}

export function PurchaseAccordion({ purchaseCondition, primaryUnit, purchasePrice }: Props) {
  const t = useAppTFunction();
  return (
    <Accordion sx={{ borderBottom: ({ palette }) => `1px solid ${palette.divider}` }}>
      <AccordionSummary>
        <CalculationEntry
          label={t('myAssortment.columns.purchasePrice')}
          chipLabel={purchasePrice?.toFixed(2) ?? '-'}
          primaryUnit={primaryUnit}
        />
      </AccordionSummary>
      <AccordionDetails>
        <ActiveTagPaper>
          {purchaseCondition ? (
            <Grid container gap={2} flexDirection="column">
              <Stack gap={4} flexDirection="row" fontSize={14}>
                <Stack gap={3} flexDirection="row" fontSize={14}>
                  <Grid item fontWeight={600}>
                    {t('purchaseAgreements.agreement.columns.agreementText')}
                  </Grid>
                  <Grid item>{purchaseCondition.agreementText}</Grid>
                </Stack>
                <Stack gap={3} flexDirection="row" fontSize={14}>
                  <Grid item fontWeight={600}>
                    {t('purchaseAgreements.agreement.columns.agreementId')}
                  </Grid>
                  <Grid item>{purchaseCondition.agreementId}</Grid>
                </Stack>
              </Stack>
              <Stack gap={4} flexDirection="row" fontSize={14}>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('purchaseAgreements.agreement.columns.fromDate')}
                  </Grid>
                  <Grid item>{dayjs(purchaseCondition.validFrom).format('ll')}</Grid>
                </Stack>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('purchaseAgreements.agreement.columns.toDate')}
                  </Grid>
                  <Grid item>
                    {purchaseCondition.validTo
                      ? dayjs(purchaseCondition.validTo).format('ll')
                      : '->'}
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Typography>{t('myAssortment.productCard.noActivePurchaseCondition')}</Typography>
          )}
        </ActiveTagPaper>
      </AccordionDetails>
    </Accordion>
  );
}
