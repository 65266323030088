import { QueryOptions } from '@shared/fetch-utils';
import { OverGroupDTO } from '@retail/products/types';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { useMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';

export const bk04ProductGroupsRoute = '/product-groups';
export const suppliersPath = '/suppliers';

export function useFetchBk04ProductGroups(queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<OverGroupDTO[]>({
    method: 'GET',
    queryKey: ['bk04'],
    token,
    url: `${mpe.url}${urlConstant}${bk04ProductGroupsRoute}/`,
    ...queryOptions,
  });
}

interface SearchProps {
  supplierId: number;
}

export function useFetchBk04ProductGroupsForSupplier(
  queryOptions: QueryOptions = {},
  { supplierId }: SearchProps
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<OverGroupDTO[]>({
    method: 'GET',
    queryKey: ['bk04'],
    token,
    url: `${mpe.url}${urlConstant}${suppliersPath}/${supplierId}${bk04ProductGroupsRoute}`,
    ...queryOptions,
  });
}
