import { SvgIcon } from '@mui/material';

interface Props {
  color?: string;
  fontSize?: 'small' | 'inherit' | 'large' | 'medium';
}

export const UploadIcon = ({ color = 'currentColor', fontSize = 'inherit' }: Props) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="79"
        viewBox="0 0 79 79"
        fill="none"
      >
        <g clipPath="url(#clip0_8943_102581)">
          <path
            d="M45.6719 59.2501H33.3281C31.276 59.2501 29.625 57.5992 29.625 55.547V29.6251H16.0932C13.3467 29.6251 11.9734 26.3078 13.9176 24.3636L37.3861 0.879639C38.5434 -0.277588 40.4412 -0.277588 41.5984 0.879639L65.0824 24.3636C67.0266 26.3078 65.6533 29.6251 62.9068 29.6251H49.375V55.547C49.375 57.5992 47.724 59.2501 45.6719 59.2501ZM79 58.0158V75.297C79 77.3492 77.349 79.0001 75.2969 79.0001H3.70312C1.65098 79.0001 0 77.3492 0 75.297V58.0158C0 55.9636 1.65098 54.3126 3.70312 54.3126H24.6875V55.547C24.6875 60.3148 28.5604 64.1876 33.3281 64.1876H45.6719C50.4396 64.1876 54.3125 60.3148 54.3125 55.547V54.3126H75.2969C77.349 54.3126 79 55.9636 79 58.0158ZM59.8672 71.5939C59.8672 69.8966 58.4785 68.508 56.7812 68.508C55.084 68.508 53.6953 69.8966 53.6953 71.5939C53.6953 73.2912 55.084 74.6798 56.7812 74.6798C58.4785 74.6798 59.8672 73.2912 59.8672 71.5939ZM69.7422 71.5939C69.7422 69.8966 68.3535 68.508 66.6562 68.508C64.959 68.508 63.5703 69.8966 63.5703 71.5939C63.5703 73.2912 64.959 74.6798 66.6562 74.6798C68.3535 74.6798 69.7422 73.2912 69.7422 71.5939Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_8943_102581">
            <rect width="79" height="79" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
