import { Supplier } from '@retail/suppliers/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useCallback, useMemo, useState } from 'react';
import { createMarkupSchema, MarkupsTable } from '@retail/markup/components';
import { Actions, ConditionState, MarkupCondition } from '@retail/calculus/types';
import { Paper, UniversalFormProvider } from '@shared/components';
import { CreateMarkupConditionDialog } from './CreateMarkupCondition';
import { DeleteOrDeactivateMarkupDialog } from './DeleteOrDeactivateMarkupDialog';
import { EditMarkupDialog } from './MarkupTemplates/EditMarkupDialog';
import { useFetchConditions } from '@retail/calculus/data-access';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useLevelSearchParams } from '@retail/calculus/hooks';

interface Props {
  supplier: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  closeCreate: () => void;
}

export function DepartmentMarkupContainer({
  supplier,
  typesToShow,
  isCreateOpen,
  closeCreate,
}: Props) {
  const t = useCalculusTFunction();
  const { draftAssortmentId } = useDepartmentAssortments();
  const { selectedProductGroupId, selectedMgItemId } = useLevelSearchParams();
  const { data: conditionDtos = [] } = useFetchConditions({
    conditionTypes: 'MARKUP',
    conditionState: typesToShow !== ConditionState.DRAFT ? typesToShow : ConditionState.ALL,
    assortmentId: draftAssortmentId,
    mgSupplierIds: [supplier.mgSupplierId],
    groupNumbers: selectedProductGroupId ? [selectedProductGroupId] : [],
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : [],
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
  });
  const markupCondition = useMemo(
    () => (conditionDtos?.length > 0 ? conditionDtos[0].markupConditions : []),
    [conditionDtos]
  );

  const markupConditions = useMemo(
    () => markupCondition.map(MarkupCondition.fromDto),
    [markupCondition]
  );

  const existingMarkupConditions = markupConditions.filter(
    (markup) => markup.operation !== 'DELETE'
  );

  const [selectedMarkup, setSelectedMarkup] = useState<MarkupCondition | undefined>(undefined);
  const handleSetSelectedMarkup = (markup: MarkupCondition) => setSelectedMarkup(markup);
  const [action, setAction] = useState<Actions>('none');
  const handleSetAction = (action: Actions) => setAction(action);

  const closeAction = useCallback(() => {
    setAction('none');
    setSelectedMarkup(undefined);
  }, [setAction]);

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'auto',
        borderRadius: 0,
      }}
    >
      <UniversalFormProvider
        schema={createMarkupSchema(t)}
        defaultValues={createMarkupSchema(t).getDefault()}
      >
        <CreateMarkupConditionDialog
          isOpen={isCreateOpen}
          onClose={closeCreate}
          supplier={supplier}
        />
      </UniversalFormProvider>

      <MarkupsTable
        supplier={supplier}
        markups={existingMarkupConditions}
        handleSetSelectedMarkup={handleSetSelectedMarkup}
        handleSetAction={handleSetAction}
      />

      {selectedMarkup && action === 'delete' && (
        <DeleteOrDeactivateMarkupDialog
          activeMarkupCondition={selectedMarkup}
          onClose={closeAction}
          isOpen
        />
      )}

      {selectedMarkup && action === 'edit' && (
        <EditMarkupDialog markupToEdit={selectedMarkup} isOpen onClose={closeAction} />
      )}
    </Paper>
  );
}
