import { ByggmaterialpriserStoreDetailedDTO, StoreCompetitorDTO } from '../dto';
import { Coordinates } from './Competitor';

export class Store {
  id: number;
  name: string;
  isSelected: boolean;
  city?: string;
  coordinates?: Coordinates;

  constructor(
    id: number,
    name: string,
    isSelected: boolean,
    city?: string,
    coordinates?: Coordinates
  ) {
    this.id = id;
    this.name = name;
    this.isSelected = isSelected;
    this.city = city;
    this.coordinates = coordinates;
  }

  static fromBmpStoreDto =
    (competitors: StoreCompetitorDTO[]) =>
    ({ id, name, city, latitude, longitude }: ByggmaterialpriserStoreDetailedDTO): Store => ({
      id,
      city,
      name,
      coordinates: latitude && longitude ? { lat: latitude, long: longitude } : undefined,
      isSelected: competitors.some((storeComp) => storeComp.id === id),
    });
}
