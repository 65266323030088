import { Box, Stack, Typography } from '@mui/material';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';

interface Props {
  title: string;
}

export function TableCellWithActiveTag({ title }: Props) {
  const t = usePurchaseAgreementsTFunction();
  return (
    <Stack flexDirection="row" pr={1}>
      <Box position="relative" pb={2}>
        <Typography
          variant="body2"
          position="absolute"
          color={({ palette }) => palette.common.white}
          textAlign="center"
          width={80}
          fontWeight={500}
          top={-20}
          right={-28}
          sx={{
            background: ({ palette }) => palette.gradient.tertiary,
            transform: 'rotate(320deg)',
          }}
        >
          {t('purchaseAgreements.active')}
        </Typography>
      </Box>
      {title}
    </Stack>
  );
}
