import { Close } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { Chip } from '@shared/components';

interface Props {
  label: string;
  onClick: () => void;
}

export const SelectedFilterChip = ({ label, onClick }: Props) => {
  return (
    <Chip
      sx={{ width: 'fit-content' }}
      size="small"
      label={
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          {label}
          <IconButton onClick={onClick} sx={{ pr: 0 }}>
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      }
    />
  );
};
