import { Assortment } from '@retail/my-assortment/types';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  departmentBaseAssortmentId: number;
  memberDraftAssortment: Assortment;
}

export const useRefetchDraftDrawer = ({
  departmentBaseAssortmentId,
  memberDraftAssortment,
}: Props) => {
  const queryClient = useQueryClient();

  const invalidateAssortmentBase = () => {
    queryClient.invalidateQueries({
      queryKey: ['assortmentItems', departmentBaseAssortmentId],
    });
  };

  const invalidateAssortmentDraft = () => {
    queryClient.invalidateQueries({
      queryKey: ['assortmentItems', memberDraftAssortment.id],
    });
    queryClient.invalidateQueries({ queryKey: ['mg-item'] });
  };
   

  const invalidateAgreements = () =>
    queryClient.invalidateQueries({ queryKey: ['purchase-condition'] });

  const invalidateFreight = () => queryClient.invalidateQueries({ queryKey: ['freights'] });
  const invalidateMarkup = () => queryClient.invalidateQueries({ queryKey: ['markups'] });

  const invalidateDraftDrawer = () => {
    invalidateAssortmentDraft();
    invalidateAgreements();
    invalidateFreight();
    invalidateMarkup();
  };

  return {
    invalidateDraftDrawer,
    invalidateAssortmentBase,
    invalidateAssortmentDraft,
    invalidateAgreements,
    invalidateFreight,
    invalidateMarkup,
  };
};
