import { useCallback, useMemo } from 'react';
import { alpha, Tooltip, IconButton } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { ItemAgreement, PriceType, PurchaseCondition } from '@retail/purchase-agreements/types';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import dayjs from 'dayjs';
import { ColumnHeaderWithHelpText } from '@retail/components';
import { dataGridBoldClass } from '@shared/styles';
import { AgreementStatusIcon, TableCellWithActiveTag } from '@retail/calculus/components';
import { useLevelNavigation } from '@retail/calculus/hooks';
import { Supplier } from '@retail/suppliers/types';
import { MGPrisIcon } from '@shared/custom-icons';
import { usePopupElement } from '@shared/hooks';
import { Actions, HierarchyLevels } from '@retail/calculus/types';
import { DotMenuIcon } from '@shared/custom-icons';
import { AgreementTableActions } from './AgreementTableActions';

interface Props {
  supplier: Supplier;
  itemAgreements: ItemAgreement[];
  agreementCondition: PurchaseCondition;
  itemAgreementClicked?: ItemAgreement;
  variant?: 'default' | 'secondary';
  standardAgreement?: number;
  handleItemAgreementClicked: (agreement: ItemAgreement) => void;
  setAction: (action: Actions) => void;
}

export const ItemAgreementsTable = ({
  supplier,
  itemAgreements,
  agreementCondition,
  itemAgreementClicked,
  variant = 'default',
  standardAgreement,
  setAction,
  handleItemAgreementClicked,
}: Props) => {
  const { t } = usePurchaseAgreementsTranslation();

  const { navigateToInheritedCondition } = useLevelNavigation();
  const { menuElementProps, triggerElementProps } = usePopupElement();

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      width: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    }),
    []
  );

  const isActive = useCallback(
    (item: ItemAgreement) => {
      const isActive =
        item.agreementId === agreementCondition.agreementId &&
        agreementCondition.priceType === item.priceType &&
        agreementCondition.bindingType === HierarchyLevels.item;
      const isInherited =
        item.agreementId === agreementCondition.agreementId &&
        agreementCondition.inherited &&
        item.priceType === PriceType.NET;
      return isActive || isInherited;
    },
    [agreementCondition]
  );

  const isStandardAgreement = useCallback(
    (item: ItemAgreement) =>
      standardAgreement === item.agreementId && item.priceType === PriceType.NET,
    [standardAgreement]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'agreementId',
        headerName: t('purchaseAgreements.agreement.columns.agreementId'),
        headerAlign: 'left',
        align: 'left',
        cellClassName: () => dataGridBoldClass,
        renderCell: ({ row }) =>
          isActive(row) || (!agreementCondition.agreementId && isStandardAgreement(row)) ? (
            <TableCellWithActiveTag title={row.agreementId} />
          ) : (
            row.agreementId
          ),
      },
      {
        ...baseColumnProps,
        field: 'name',
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
        headerAlign: 'left',
        align: 'left',
        width: 230,
        cellClassName: () => dataGridBoldClass,
        renderCell: ({ row }) => row.agreementText,
      },
      {
        ...baseColumnProps,
        field: 'status',
        headerName: '',
        width: 50,
        renderCell: ({ row }) => {
          if (isActive(row)) {
            return (
              <AgreementStatusIcon
                inherited={agreementCondition?.inherited ?? false}
                bindingType={agreementCondition.bindingType}
                productGroup={agreementCondition.productGroupName}
                supplierName={supplier.name}
                navigateToLevel={() =>
                  navigateToInheritedCondition({
                    bindingType: agreementCondition.bindingType,
                    mgSupplierId: supplier.mgSupplierId,
                    productGroupNumber: agreementCondition.productGroup,
                    productGroupName: agreementCondition.productGroupName,
                  })
                }
              />
            );
          } else if (isStandardAgreement(row)) {
            return (
              <Tooltip title={t('purchaseAgreements.agreement.standardAgreement')}>
                <div>
                  <MGPrisIcon fontSize="small" />
                </div>
              </Tooltip>
            );
          }
          return null;
        },
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        width: 140,
        headerName: t('purchaseAgreements.agreement.columns.fromDate'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        width: 140,
        headerName: t('purchaseAgreements.agreement.columns.toDate'),
        //If year in validTo is 9999, it has no end-date
        renderCell: ({ value }) =>
          value.year === '9999' ? (value ? dayjs(value).format('ll') : '-') : '->',
      },
      {
        ...baseColumnProps,
        field: 'price',
        headerName: t('purchaseAgreements.agreement.columns.price'),
        renderCell: ({ row }) => {
          const value = row.price.toFixed(2) ?? '-';
          const priceUnit = row.priceUnit ?? '-';

          return t('purchaseAgreements.pricePerUnit', {
            price: value,
            unit: priceUnit,
          });
        },
      },
      {
        ...baseColumnProps,
        field: 'priceUnit',
        renderHeader: () => (
          <ColumnHeaderWithHelpText
            header={t('purchaseAgreements.agreement.columns.basePriceUnit')}
            tooltipContent={t('purchaseAgreements.agreement.columns.basePriceUnitDesc')}
          />
        ),
        width: 120,
        renderCell: ({ row }) => row.gtinPriceUnit ?? row.priceUnit ?? '-',
      },
      {
        ...baseColumnProps,
        field: 'actions',
        headerName: '',
        width: 50,
        renderCell: ({ row }) => (
          <IconButton
            {...triggerElementProps}
            onClick={(event) => {
              triggerElementProps.onClick(event);
              handleItemAgreementClicked(row);
            }}
          >
            <DotMenuIcon fontSize="small" />
          </IconButton>
        ),
      },
    ],
    [
      baseColumnProps,
      t,
      isActive,
      agreementCondition.agreementId,
      agreementCondition?.inherited,
      agreementCondition.bindingType,
      agreementCondition.productGroupName,
      agreementCondition.productGroup,
      isStandardAgreement,
      supplier.name,
      supplier.mgSupplierId,
      navigateToInheritedCondition,
      triggerElementProps,
      handleItemAgreementClicked,
    ]
  );

  return (
    <>
      <DataGridPro
        autoHeight
        getRowId={(row) => `${row.priceType}${row.price}${row.agreementId}`}
        columns={columns}
        rows={itemAgreements}
        rowHeight={65}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        hideFooter
        initialState={{
          pinnedColumns: { right: ['actions'] },
        }}
        sx={
          variant === 'secondary'
            ? {
                '& .MuiDataGrid-row': {
                  ':last-of-type': { borderBottomLeftRadius: 24, borderBottomRightRadius: 24 },
                  ':nth-of-type(odd)': {
                    backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
                    ':hover': {
                      backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
                    },
                  },
                },
              }
            : undefined
        }
      />

      <AgreementTableActions
        {...menuElementProps}
        isInherited={Boolean(
          itemAgreementClicked &&
            itemAgreementClicked.agreementId === agreementCondition.agreementId &&
            agreementCondition.inherited &&
            itemAgreementClicked.priceType === PriceType.NET
        )}
        isActivated={Boolean(itemAgreementClicked && isActive(itemAgreementClicked))}
        setAction={setAction}
      />
    </>
  );
};
