import {
  getMgAssortmentQueryKey,
  getMgAssortmentsQueryKey,
  useAddItemsToMgAssortment,
  useDeleteItemsFromMgAssortment,
  useDeleteMgAssortment,
  useExportAssortmentToExcel,
  usePatchTitleOrItemsOfMgAssortment,
  useValidateImportedItems,
} from '@retail/monitoring-assortment/data-access';
import { useQueryClient } from '@tanstack/react-query';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { ItemCodes } from '@retail/monitoring-assortment/types';
import { downloadResponseFile } from '@shared/fetch-utils';

export interface ItemsMgAssortmentArgs {
  assortmentId: number;
  mgItemNumbers: number[];
  finfoNumbers?: number[];
  gtinCodes?: number[];
}

export interface ReplaceMgAssortmentArgs {
  assortmentId: number;
  itemCodes: ItemCodes;
  title?: string;
  description?: string;
}

export function useMgAssortmentActions() {
  const queryClient = useQueryClient();
  const { selectedPPSContext } = useSelectedOrgUnit();
  const allAssortmentsQueryKey = getMgAssortmentsQueryKey();

  const { mutateAsync: mutatePostAsync, isLoading: isLoadingPost } =
    useAddItemsToMgAssortment(selectedPPSContext);

  const { mutateAsync: mutateDeleteItemsAsync, isLoading: isLoadingDeleteItems } =
    useDeleteItemsFromMgAssortment(selectedPPSContext);

  const { mutateAsync: mutateDeleteAsync, isLoading: isLoadingDelete } =
    useDeleteMgAssortment(selectedPPSContext);

  const { mutateAsync: mutateChangeTitleAsync, isLoading: isLoadingChangeTitle } =
    usePatchTitleOrItemsOfMgAssortment(selectedPPSContext);

  const { mutateAsync: mutateValidateItemsAsync } = useValidateImportedItems();

  const { mutateAsync: mutateExportAssortmentToExcel, isLoading: isLoadingExcel } =
    useExportAssortmentToExcel();

  const handleAddItemsToMgAssortment = async ({
    mgItemNumbers,
    finfoNumbers,
    gtinCodes,
    assortmentId,
  }: ItemsMgAssortmentArgs) => {
    const updatedAssortment = await mutatePostAsync({
      body: {
        mgItemNumbers,
        finfoNumbers,
        gtinCodes,
      },
      urlVariables: assortmentId,
    });
    await queryClient.invalidateQueries(getMgAssortmentQueryKey(assortmentId));

    return updatedAssortment;
  };

  const handleDeleteItemsFromMgAssortment = async ({
    mgItemNumbers,
    finfoNumbers,
    gtinCodes,
    assortmentId,
  }: ItemsMgAssortmentArgs) => {
    const updatedAssortment = await mutateDeleteItemsAsync({
      body: {
        mgItemNumbers,
        finfoNumbers,
        gtinCodes,
      },
      urlVariables: assortmentId,
    });
    await queryClient.invalidateQueries(getMgAssortmentQueryKey(assortmentId));

    return updatedAssortment;
  };

  const handlePatchTitleOrItemsOfMgAssortment = async ({
    itemCodes,
    title,
    description,
    assortmentId,
  }: ReplaceMgAssortmentArgs) => {
    await mutateChangeTitleAsync({
      body: { title, itemCodes, description },
      urlVariables: assortmentId,
    });
    queryClient.invalidateQueries(getMgAssortmentQueryKey(assortmentId));
    queryClient.invalidateQueries(allAssortmentsQueryKey);
  };

  const handleDeleteMgAssortment = async (assortmentId: number) => {
    await mutateDeleteAsync({ urlVariables: assortmentId });
    queryClient.invalidateQueries(allAssortmentsQueryKey);
  };

  const handleValidateItemsOnImportedAssortment = async (itemCodes: ItemCodes) => {
    return await mutateValidateItemsAsync({
      body: {
        finfoNumbers: itemCodes.finfoNumbers,
        gtinCodes: itemCodes.gtinCodes,
        mgItemNumbers: itemCodes.mgItemNumbers,
      },
    });
  };

  const exportAssortmentToExcel = async (assortmentId: number) => {
    return mutateExportAssortmentToExcel(assortmentId).then(
      downloadResponseFile({ filenameFallback: 'mgpris-export.xlsx' })
    );
  };

  return {
    handleAddItemsToMgAssortment,
    handleDeleteItemsFromMgAssortment,
    handleDeleteMgAssortment,
    handlePatchTitleOrItemsOfMgAssortment,
    handleValidateItemsOnImportedAssortment,
    exportAssortmentToExcel,
    isLoading: isLoadingPost || isLoadingDeleteItems || isLoadingDelete || isLoadingChangeTitle,
    isLoadingExcel,
  };
}
