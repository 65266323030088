import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  elementId: string;
}

export default function Portal({ elementId, children }: PropsWithChildren<Props>) {
  const ref = useRef<Element | null | undefined>();
  const [mount, setMount] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(elementId);
    setMount(true);

    return () => {
      setMount(false);
      ref.current = null;
    };
  }, [elementId]);

  return mount ? createPortal(children, document.getElementById(elementId) as HTMLElement) : null;
}
