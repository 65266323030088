import { SuppliersSearchParams, useFetchAllSuppliers } from '@retail/products/data-access';
import { Supplier } from '@retail/suppliers/types';
import { useMultipleSelectedSearchParamState } from '@shared/hooks';
import { useMemo } from 'react';

const mgSupplierQueryKey = 'mgSupplierId';

export const useSelectSuppliers = (searchParams?: SuppliersSearchParams) => {
  const { data: suppliersDtos } = useFetchAllSuppliers(searchParams);
  const suppliers = useMemo(
    () => suppliersDtos?.map(Supplier.fromMinimalDTO) || [],
    [suppliersDtos]
  );

  const [selectedSuppliers, selectSuppliers] = useMultipleSelectedSearchParamState({
    options: suppliers,
    getOptionId: ({ mgSupplierId }) => `${mgSupplierId}`,
    searchParamKey: mgSupplierQueryKey,
  });

  return {
    suppliers,
    selectedSuppliers,
    selectSuppliers,
  };
};
