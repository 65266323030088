import { FC, useMemo } from 'react';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { Dialog, TagHeading } from '@shared/components';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { useFetchPurchaseAgreements } from '@retail/purchase-agreements/data-access';
import { Supplier } from '@retail/suppliers/types';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { PurchaseAgreementsTable } from '@retail/purchase-agreements/components';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';

interface Props {
  supplier: Supplier;
  open: boolean;
  onClose: () => void;
}

const SupplierAgreementsDialogContainer: FC<Props> = ({ supplier, open, onClose }) => {
  const t = usePurchaseAgreementsTFunction();
  const { data: agreementDtos = [] } = useFetchPurchaseAgreements({
    supplierId: supplier.mgSupplierId,
  });
  const agreements = useMemo(
    () =>
      agreementDtos.map(PurchaseAgreement.fromDto).sort((a1, a2) => a1.name.localeCompare(a2.name)),
    [agreementDtos]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle display="flex" justifyContent="space-between">
        <TagHeading color="primary">
          <Typography variant="h4" component="span">
            {t('purchaseAgreements.agreement.agreementsFor', { supplier: supplier.name })}
          </Typography>
        </TagHeading>
      </DialogTitle>
      <DialogContent>
        <PurchaseAgreementsTable agreements={agreements} />
      </DialogContent>
    </Dialog>
  );
};

export default withDialogSuspenseErrorBoundary(SupplierAgreementsDialogContainer);
