import { Grid, Typography, styled } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  title: string;
  children?: ReactNode;
}

export const ProductInformationAccordionRow = ({ title, children }: Props) => {
  return (
    <AccordionContentGrid container>
      <Grid item xs={6} md={5} lg={3}>
        <Typography
          variant="subtitle2"
          fontWeight={600}
          sx={{ color: (theme) => theme.palette.grey[500] }}
        >
          {title}
        </Typography>
      </Grid>

      <Grid item xs={6} md={7} lg={9}>
        {children}
      </Grid>
    </AccordionContentGrid>
  );
};

export const AccordionContentGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1, 3),

  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
  },
}));
