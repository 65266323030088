import { PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Paper } from '@shared/components';
import { AssortmentForDepartment } from '@retail/my-assortment/containers';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

export function MyAssortmentPage() {
  const { selectedDepartment, selectedMember, selectedBrandContext } = useSelectedOrgUnit();

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        paddingY="dense"
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}
      >
        {selectedDepartment ? (
          <SuspenseWithSentryErrorBoundary>
            <AssortmentForDepartment
              memberId={selectedDepartment.memberId}
              departmentId={selectedDepartment.departmentId}
            />
          </SuspenseWithSentryErrorBoundary>
        ) : (
          <div>
            {(selectedMember && `${selectedMember.name} - ${selectedMember.memberId}`) ||
              (selectedBrandContext && selectedBrandContext.name)}
          </div>
        )}
      </Paper>
    </PageLayout>
  );
}
