import { AssortmentType } from '@retail/monitoring-assortment/types';
import * as yup from 'yup';

export interface NewAssortmentForm {
  title: string;
  assortmentType: AssortmentType;
}

const assortmentNameMinChars = 2;
const assortmentNameMaxChars = 40;

export const newAssortmentSchema = yup.object().shape({
  title: yup
    .string()
    .min(assortmentNameMinChars, {
      message: {
        key: 'assortment.admin.create.form.title.validation.minChars',
        values: { amount: assortmentNameMinChars },
      },
    })
    .max(assortmentNameMaxChars, {
      message: {
        key: 'assortment.admin.create.form.title.validation.maxChars',
        values: { amount: assortmentNameMaxChars },
      },
    })
    .matches(/^[a-zA-Z0-9 _\-æøåäö]+$/i, {
      message: {
        key: 'assortment.admin.create.form.title.validation.illegalChars',
      },
    }),
  assortmentType: yup.string().required({
    message: { key: 'assortment.admin.create.form.assortmentType.validation.required' },
  }),
});

