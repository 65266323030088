import { Grid, Stack } from '@mui/material';

import { MgItem } from '@retail/products/types';
import { Paper } from '@shared/components';

import { TagBreadcrumbs } from '@retail/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useProductsTFunction } from '@retail/products/i18n';
import { useRecoilValue } from 'recoil';
import { isSuperUserAtom } from '@retail/auth/data-access';
import { MgItemGallery, MgItemView } from '@retail/products/components';
import { MgItemTabContainer } from './MgItemTabContainer';

interface Props {
  mgItem: MgItem;
  hiddenBreadcrumbs?: boolean;
}

export function MgItemDetails({ mgItem, hiddenBreadcrumbs = false }: Props) {
  const t = useProductsTFunction();
  const isSuperUser = useRecoilValue(isSuperUserAtom);

  const primarySupplierItem = mgItem.getPrimarySupplierItem();

  return (
    <Stack gap={3}>
      <Stack flexDirection="row" alignItems="flex-start" justifyContent="space-between">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {!hiddenBreadcrumbs && (
              <Paper
                paddingY="dense"
                sx={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}
              >
                <TagBreadcrumbs
                  items={[
                    {
                      label: t('products.search.title'),
                      href: mgPrisRoutes.items.getFullLinkPath(),
                    },
                    {
                      label: primarySupplierItem.primaryText,
                    },
                  ]}
                />
              </Paper>
            )}

            <Paper
              paddingY="dense"
              sx={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}
            >
              <MgItemView mgItem={mgItem} isSuperUser={isSuperUser} />
            </Paper>

            <Paper paddingY="dense" sx={{ display: 'flex', flexDirection: 'column' }}>
              <MgItemGallery mgItem={mgItem} />
            </Paper>
          </Grid>

          <Grid item xs={8}>
            <Paper paddingY="dense">
              <MgItemTabContainer mgItem={mgItem} />
            </Paper>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
