import { SvgIcon, SvgIconProps } from '@mui/material';

const AuthorizeAppsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="33" height="32" viewBox="0 0 33 32">
      <path
        d="M31.499 -0.000854492H24.2027C23.6505 -0.000854492 23.2027 0.44686 23.2027 0.999145V8.29543C23.2027 8.84772 23.6505 9.29543 24.2027 9.29543H31.499C32.0513 9.29543 32.499 8.84772 32.499 8.29543V0.999145C32.499 0.446861 32.0513 -0.000854492 31.499 -0.000854492Z"
        fill="currentColor"
      />
      <path
        d="M20.1472 -0.000854492H12.8509C12.2986 -0.000854492 11.8509 0.44686 11.8509 0.999145V8.29543C11.8509 8.84772 12.2986 9.29543 12.8509 9.29543H20.1472C20.6995 9.29543 21.1472 8.84772 21.1472 8.29543V0.999145C21.1472 0.446861 20.6995 -0.000854492 20.1472 -0.000854492Z"
        fill="currentColor"
      />
      <path
        d="M8.79537 -0.000854492H1.49908C0.9468 -0.000854492 0.499084 0.44686 0.499084 0.999145V8.29543C0.499084 8.84772 0.946799 9.29543 1.49908 9.29543H8.79537C9.34765 9.29543 9.79537 8.84772 9.79537 8.29543V0.999145C9.79537 0.446861 9.34766 -0.000854492 8.79537 -0.000854492Z"
        fill="currentColor"
      />
      <path
        d="M8.79537 11.351H1.49908C0.9468 11.351 0.499084 11.7987 0.499084 12.351V19.6473C0.499084 20.1996 0.946799 20.6473 1.49908 20.6473H8.79537C9.34765 20.6473 9.79537 20.1996 9.79537 19.6473V12.351C9.79537 11.7987 9.34766 11.351 8.79537 11.351Z"
        fill="currentColor"
      />
      <path
        d="M8.79537 22.7029H1.49908C0.9468 22.7029 0.499084 23.1506 0.499084 23.7029V30.9992C0.499084 31.5515 0.946799 31.9992 1.49908 31.9992H8.79537C9.34765 31.9992 9.79537 31.5515 9.79537 30.9992V23.7029C9.79537 23.1506 9.34766 22.7029 8.79537 22.7029Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3681 20.6473C16.0566 19.2535 16.1134 17.7944 16.5442 16.4167C17.1074 14.6159 18.275 13.0648 19.8497 12.0255C20.1289 11.8413 20.4178 11.6752 20.7147 11.5275C20.5535 11.4162 20.358 11.351 20.1472 11.351H12.8509C12.2986 11.351 11.8509 11.7987 11.8509 12.351V19.6473C11.8509 20.1996 12.2986 20.6473 12.8509 20.6473H16.3681Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2027 26.8366C24.4023 27.0079 25.6333 26.9121 26.8053 26.5456C28.6061 25.9825 30.1572 24.8149 31.1965 23.2401C31.3121 23.0648 31.4207 22.8857 31.522 22.7032C32.0637 22.7154 32.499 23.1583 32.499 23.7029V30.9992C32.499 31.5515 32.0513 31.9992 31.499 31.9992H24.2027C23.6505 31.9992 23.2027 31.5515 23.2027 30.9992V26.8366Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4411 19.9836C32.4786 19.8785 32.499 19.7653 32.499 19.6473V19.0282C32.4984 19.3483 32.4789 19.6673 32.4411 19.9836ZM32.499 18.9961V12.351C32.499 11.7987 32.0513 11.351 31.499 11.351H27.1701C28.2652 11.7574 29.2683 12.3967 30.1057 13.2341C31.4368 14.5652 32.2675 16.3151 32.4574 18.1879C32.4847 18.4575 32.4985 18.7272 32.499 18.9961Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.149 22.7029H12.8509C12.2986 22.7029 11.8509 23.1506 11.8509 23.7029V26.001L15.149 22.7029ZM16.2997 31.9992H20.1472C20.6995 31.9992 21.1472 31.5515 21.1472 30.9992V27.9148L21.0195 28.0905H18.8603L18.8249 30.5254H16.2997V31.9992Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3531 16.9004C16.8533 18.4989 16.9206 20.2209 17.5439 21.7754L11.3726 27.9568V31.9992H15.4049V29.5738H17.8302V27.1484H20.2556L21.5863 25.8178C23.1408 26.441 24.8628 26.5084 26.4613 26.0086C28.0597 25.5087 29.4365 24.4723 30.359 23.0745C31.2816 21.6767 31.6931 20.0032 31.5242 18.337C31.3553 16.6707 30.6163 15.1139 29.432 13.9297C28.2478 12.7454 26.691 12.0064 25.0247 11.8375C23.3585 11.6686 21.685 12.0801 20.2872 13.0026C18.8894 13.9251 17.853 15.302 17.3531 16.9004ZM28.4368 16.9608C28.4368 18.0035 27.5914 18.8489 26.5487 18.8489C25.5059 18.8489 24.6606 18.0035 24.6606 16.9608C24.6606 15.918 25.5059 15.0727 26.5487 15.0727C27.5914 15.0727 28.4368 15.918 28.4368 16.9608Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AuthorizeAppsIcon;
