import { useSelectedCompetitors } from '@retail/app/hooks';
import { CompetitorViewForMgItem } from '@retail/products/components';
import { useFetchMgItem } from '@retail/products/data-access';
import { MgItem, MgItemCompetitorsWithPrices } from '@retail/products/types';
import { Paper } from '@shared/components';
import {
  BodyLoader,
  DefaultPpsErrorFallback,
  TanstackQueryLoadingWrapper,
} from '@retail/components';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

interface Props {
  mgItem: MgItem;
}

export const MgItemCompetitorPrices = ({ mgItem }: Props) => {
  const { selectedPPSContext } = useSelectedOrgUnit();
  const competitors = useSelectedCompetitors(selectedPPSContext.id);

  const mpeMgItemLoadingState = useFetchMgItem(
    {
      mgItemNumber: mgItem.id,
      includeByggmaterialpriserPrices: true,
      competitorStoreIds: competitors.map((competitor) => competitor.id),
    },
    { suspense: false }
  );

  return (
    <Paper paddingY="dense">
      <TanstackQueryLoadingWrapper
        loadingState={mpeMgItemLoadingState}
        LoadingComponent={BodyLoader}
        ErrorComponent={DefaultPpsErrorFallback}
      >
        {(mgItemDto) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const ppsMgItem = MgItemCompetitorsWithPrices.fromDto(mgItemDto!);

          return (
            <CompetitorViewForMgItem
              competitors={ppsMgItem.competitors}
              contextCompetitors={competitors}
            />
          );
        }}
      </TanstackQueryLoadingWrapper>
    </Paper>
  );
};
