import { TanstackQueryLoadingWrapper } from '@retail/components';
import { useMgSupplierItemsSearch } from '@retail/products/data-access';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { useDisclosure, usePagination } from '@shared/hooks';
import {
  ArticleIdSearchField,
  MgSupplierItemSearchField,
  SelectProductGroupsAutocomplete,
  SelectSuppliersAutocomplete,
} from '@retail/products/components';
import { OutlinedButton, Paper } from '@shared/components';
import { MgSupplierItemSearchResults } from './MgSupplierItemSearchResults';
import { useSelectProductGroups, useSelectSuppliers } from '@retail/products/context';
import { ChevronLeft } from '@mui/icons-material';
import { useDepartmentAssortments } from '@retail/my-assortment/context';

interface Props {
  checkedItems: MgSupplierItemSearchResult[];
  includeAddButton?: boolean;
  clickableRow?: boolean;
  hasDraftDrawerValues?: boolean;
  setCheckedItems: Dispatch<SetStateAction<MgSupplierItemSearchResult[]>>;
}

export function MgSupplierItemSearchContainer({
  checkedItems,
  includeAddButton = false,
  clickableRow,
  hasDraftDrawerValues,
  setCheckedItems,
}: Props) {
  const t = useProductsTFunction();

  const { pageSize, page, setPageSize, setPage } = usePagination({ initPageSize: 200 });
  const { isOpen: onlyItemsWithAgreementPrice, onToggle: toggleOnlyItemsWithAgreementPrice } =
    useDisclosure(true);
  const { draftAssortmentId } = useDepartmentAssortments();

  const { suppliers, selectedSuppliers, selectSuppliers } = useSelectSuppliers({
    assortmentId: draftAssortmentId,
  });
  const { productGroups, selectedProductGroups, selectProductGroups } = useSelectProductGroups();

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [articleIdSearch, setArticleIdSearch] = useState<string[]>([]);

  const { isOpen: showFilters, onToggle: toggleShowFilters } = useDisclosure(true);

  const onSubmitArticleIdSearch = useCallback(
    (id: string) => {
      setArticleIdSearch((prev) => (prev.includes(id) ? prev : [...prev, id]));
    },
    [setArticleIdSearch]
  );

  const resetFilters = useCallback(() => {
    setArticleIdSearch([]);
    setSearchTerm(undefined);
    selectProductGroups([]);
    selectSuppliers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingState = useMgSupplierItemsSearch(
    {
      size: pageSize,
      page: page,
      query: searchTerm,
      articleIds: articleIdSearch,
      withAgreementPrices: onlyItemsWithAgreementPrice,
      supplierIds: selectedSuppliers?.map(({ mgSupplierId }) => mgSupplierId),
      groupNumbers: selectedProductGroups?.map(({ groupNumber }) => groupNumber),
    },
    {
      enabled:
        !!selectedSuppliers ||
        !!selectedProductGroups ||
        !!searchTerm ||
        articleIdSearch.length > 0,
      suspense: false,
    }
  );

  return (
    <Paper
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 0, pt: 2 }}
      variant="outlined"
    >
      {showFilters && (
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" px={2}>
          <Stack direction="row" justifyContent="space-evenly" gap={2} flexWrap="wrap">
            <SelectSuppliersAutocomplete
              suppliers={suppliers}
              selectedSuppliers={selectedSuppliers || []}
              isFetching={loadingState.isFetching}
              setPage={setPage}
              selectSuppliers={selectSuppliers}
            />
            <SelectProductGroupsAutocomplete
              productGroups={productGroups}
              selectedProductGroups={selectedProductGroups || []}
              isFetching={loadingState.isFetching}
              setPage={setPage}
              selectProductGroups={selectProductGroups}
            />
            <ArticleIdSearchField
              articleIdSearch={articleIdSearch}
              isDisabled={loadingState.isFetching}
              setArticleIdSearch={setArticleIdSearch}
              setPage={setPage}
              onSubmitSearch={onSubmitArticleIdSearch}
            />
            <MgSupplierItemSearchField
              searchTerm={searchTerm}
              isDisabled={loadingState.isFetching}
              setPage={setPage}
              onSubmitSearch={setSearchTerm}
            />
          </Stack>
          <OutlinedButton size="small" sx={{ whiteSpace: 'nowrap' }} onClick={resetFilters}>
            {t('products.search.resetFilters')}
          </OutlinedButton>
        </Stack>
      )}

      <Divider textAlign="right">
        <Button onClick={toggleShowFilters}>
          <Stack flexDirection="row" alignItems="center">
            {showFilters ? t('products.search.hideFilters') : t('products.search.showFilters')}
            <ChevronLeft
              sx={{
                transform: showFilters ? 'rotate(90deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </Stack>
        </Button>
      </Divider>

      {!loadingState.isFetching && !loadingState.data && !loadingState.error && (
        <Stack py={8}>
          <Typography variant="h5" color={({ palette }) => palette.grey[200]} alignSelf="center">
            {t('products.mgItem.search.infoText')}
          </Typography>
        </Stack>
      )}

      <TanstackQueryLoadingWrapper loadingState={loadingState} showReloadButtonOnError>
        {(searchResults) => {
          const supplierItems = MgSupplierItemSearchResult.fromSearchResultDTOs(
            searchResults.result,
            productGroups
          );

          return (
            <MgSupplierItemSearchResults
              totalPages={searchResults.totalPages}
              totalItems={searchResults.totalCount}
              supplierItems={supplierItems}
              checkedItems={checkedItems}
              currentPage={page}
              pageSize={pageSize}
              includeAddButton={includeAddButton}
              clickableRow={clickableRow}
              hasDraftDrawerValues={hasDraftDrawerValues}
              onlyItemsWithAgreementPrices={onlyItemsWithAgreementPrice}
              toggleOnlyItemsWithAgreementPrices={toggleOnlyItemsWithAgreementPrice}
              setCheckedItems={setCheckedItems}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          );
        }}
      </TanstackQueryLoadingWrapper>
    </Paper>
  );
}
