import { useTableActions } from '@retail/calculus/hooks';
import { DialogLayout, withDialogSuspenseErrorBoundary } from '@retail/components';
import { Supplier } from '@retail/suppliers/types';
import { ItemAgreementsTable } from '@retail/purchase-agreements/components';
import { useConditions } from '@retail/purchase-agreements/context';
import { useFetchItemAgreements } from '@retail/purchase-agreements/data-access';
import { ItemAgreement, PurchaseCondition } from '@retail/purchase-agreements/types';

import { useCallback, useState } from 'react';
import { DeactivateAgreementDialog } from '../DeactivateAgreementDialog';
import { UniversalFormProvider } from '@shared/components';
import { conditionDatesSchema } from '@retail/calculus/components';
import { ActivateSelectedAgreementDialog } from '../ActivateAgreement';

interface Props {
  open: boolean;
  onClose: () => void;
  mgItemNr: number;
  supplier: Supplier;
}

function ChangeSelectedItemAgreementDialog({ onClose, mgItemNr, supplier }: Props) {
  const { action, setAction } = useTableActions();

  const [clickedItemAgreement, setClickedItemAgreement] = useState<ItemAgreement | undefined>(
    undefined
  );

  const { baseConditions: agreementCondition } = useConditions({
    supplierId: supplier.mgSupplierId,
    mgItemNumber: mgItemNr,
  });
  const purchaseCondition = { ...agreementCondition } as PurchaseCondition;

  const { data: itemAgreementPricePageDto } = useFetchItemAgreements({
    supplierId: supplier.mgSupplierId,
    mgItemId: mgItemNr,
  });
  const itemAgreementPriceDtos = itemAgreementPricePageDto?.result || [];
  const itemAgreementPrices = itemAgreementPriceDtos.map(ItemAgreement.fromDto);

  const closeAction = useCallback(() => {
    setAction('none');
    setClickedItemAgreement(undefined);
  }, [setAction]);

  const handleItemAgreementClicked = useCallback((agreement: ItemAgreement) => {
    setClickedItemAgreement(agreement);
  }, []);

  return (
    <DialogLayout open onClose={onClose} header={String(mgItemNr)} width="lg">
      <ItemAgreementsTable
        variant="secondary"
        supplier={supplier}
        itemAgreements={itemAgreementPrices}
        agreementCondition={purchaseCondition}
        setAction={setAction}
        itemAgreementClicked={clickedItemAgreement}
        handleItemAgreementClicked={handleItemAgreementClicked}
      />

      {clickedItemAgreement && action === 'deactivate' && (
        <DeactivateAgreementDialog
          agreementText={clickedItemAgreement.agreementText}
          supplierId={clickedItemAgreement.mgSupplierId}
          mgItemNumber={clickedItemAgreement.mgItemNumber}
          onClose={closeAction}
          isOpen
        />
      )}
      {clickedItemAgreement && action === 'activate' && (
        <UniversalFormProvider
          schema={conditionDatesSchema()}
          defaultValues={conditionDatesSchema().getDefault()}
        >
          <ActivateSelectedAgreementDialog
            isOpen={true}
            onClose={closeAction}
            agreementId={clickedItemAgreement.agreementId}
            agreementText={clickedItemAgreement.agreementText}
            supplierId={supplier.mgSupplierId}
            priceType={clickedItemAgreement.priceType}
          />
        </UniversalFormProvider>
      )}
    </DialogLayout>
  );
}

export default withDialogSuspenseErrorBoundary(ChangeSelectedItemAgreementDialog);
