import { MainGroupDTO, OverGroupDTO, SupplierWithProductGroupsDTO, UnderGroupDTO } from '../dtos';
import { FreightCondition } from './FreightCondition';
import { MarkupCondition } from './MarkupCondition';
import { PurchaseCondition } from './PurchaseCondition';

export interface ProductGroupProps {
  id: number;
  type: string;
  groupNumber: string;
  name: string;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  numberOfPurchaseConditions: number;
  numberOfFreightConditions: number;
  numberOfMarkupConditions: number;
}

export class ProductGroup {
  id: number;
  type: string;
  groupNumber: string;
  name: string;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  numberOfPurchaseConditions: number;
  numberOfFreightConditions: number;
  numberOfMarkupConditions: number;

  constructor({
    id,
    type,
    groupNumber,
    name,
    purchaseCondition,
    freightCondition,
    markupCondition,
    numberOfPurchaseConditions,
    numberOfFreightConditions,
    numberOfMarkupConditions,
  }: ProductGroupProps) {
    this.id = id;
    this.type = type;
    this.groupNumber = groupNumber;
    this.name = name;
    this.purchaseCondition = purchaseCondition;
    this.freightCondition = freightCondition;
    this.markupCondition = markupCondition;
    this.numberOfPurchaseConditions = numberOfPurchaseConditions;
    this.numberOfFreightConditions = numberOfFreightConditions;
    this.numberOfMarkupConditions = numberOfMarkupConditions;
  }
}

export class UnderGroup extends ProductGroup {
  static fromDto = (dto: UnderGroupDTO): UnderGroup => ({
    ...dto,
    freightCondition: dto.freightCondition && FreightCondition.fromDto(dto.freightCondition),
    markupCondition: dto.markupCondition && MarkupCondition.fromDto(dto.markupCondition),
    purchaseCondition: dto.purchaseCondition && PurchaseCondition.fromDto(dto.purchaseCondition),
  });
}

interface MainGroupProps extends ProductGroupProps {
  subGroups: UnderGroup[];
}

export class MainGroup extends ProductGroup {
  subGroups: UnderGroup[];

  constructor({ subGroups, ...superProps }: MainGroupProps) {
    super(superProps);
    this.subGroups = subGroups;
  }

  static fromDto = (dto: MainGroupDTO): MainGroup => ({
    ...dto,
    subGroups: dto.subGroups.map(UnderGroup.fromDto),
    freightCondition: dto.freightCondition && FreightCondition.fromDto(dto.freightCondition),
    markupCondition: dto.markupCondition && MarkupCondition.fromDto(dto.markupCondition),
    purchaseCondition: dto.purchaseCondition && PurchaseCondition.fromDto(dto.purchaseCondition),
  });
}

export interface OverGroupProps extends ProductGroup {
  subGroups: MainGroup[];
}

export class OverGroup extends ProductGroup {
  subGroups: MainGroup[];

  constructor({ subGroups, ...superProps }: OverGroupProps) {
    super(superProps);
    this.subGroups = subGroups;
  }

  static fromDto = (dto: OverGroupDTO): OverGroup => ({
    ...dto,
    subGroups: dto.subGroups.map(MainGroup.fromDto),
    freightCondition: dto.freightCondition && FreightCondition.fromDto(dto.freightCondition),
    markupCondition: dto.markupCondition && MarkupCondition.fromDto(dto.markupCondition),
    purchaseCondition: dto.purchaseCondition && PurchaseCondition.fromDto(dto.purchaseCondition),
  });
}

export interface SupplierWithProductGroupsProps {
  mgSupplierId: number;
  mgSupplierName: string;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  numberOfPurchaseConditions: number;
  numberOfFreightConditions: number;
  numberOfMarkupConditions: number;
  productGroups: OverGroup[];
}

export class SupplierWithProductGroups {
  mgSupplierId: number;
  mgSupplierName: string;
  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  numberOfPurchaseConditions: number;
  numberOfFreightConditions: number;
  numberOfMarkupConditions: number;
  productGroups: OverGroup[];

  constructor({
    mgSupplierId,
    mgSupplierName,
    purchaseCondition,
    freightCondition,
    markupCondition,
    numberOfPurchaseConditions,
    numberOfFreightConditions,
    numberOfMarkupConditions,
    productGroups,
  }: SupplierWithProductGroupsProps) {
    this.mgSupplierId = mgSupplierId;
    this.mgSupplierName = mgSupplierName;
    this.purchaseCondition = purchaseCondition;
    this.freightCondition = freightCondition;
    this.markupCondition = markupCondition;
    this.numberOfPurchaseConditions = numberOfPurchaseConditions;
    this.numberOfFreightConditions = numberOfFreightConditions;
    this.numberOfMarkupConditions = numberOfMarkupConditions;
    this.productGroups = productGroups;
  }

  static fromDto = (dto: SupplierWithProductGroupsDTO): SupplierWithProductGroups =>
    new SupplierWithProductGroups({
      mgSupplierId: dto.mgSupplierId,
      mgSupplierName: dto.mgSupplierName,
      purchaseCondition: dto.purchaseCondition && PurchaseCondition.fromDto(dto.purchaseCondition),
      freightCondition: dto.freightCondition && FreightCondition.fromDto(dto.freightCondition),
      markupCondition: dto.markupCondition && MarkupCondition.fromDto(dto.markupCondition),
      numberOfPurchaseConditions: dto.numberOfPurchaseConditions,
      numberOfFreightConditions: dto.numberOfFreightConditions,
      numberOfMarkupConditions: dto.numberOfMarkupConditions,
      productGroups: dto.productGroups.map(OverGroup.fromDto),
    });
}
