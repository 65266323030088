import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { Department } from '@retail/auth/types';
import {
  CreateMarkupTemplateDTO,
  MarkupTemplate,
  PatchMarkupTemplateDTO,
} from '@retail/markup/types';
import { urlConstant } from '@retail/utils';
import { fetcher, QueryOptions } from '@shared/fetch-utils';
import { useMutation } from '@tanstack/react-query';
import { useMutateMPEFetch, useSelectedOrgUnitHeader } from '@retail/app/hooks';

export const markupPath = 'markups';
export const markupTemplatePath = 'markup-templates';

export function useMutateCreateMarkupTemplate(
  departmentId: Department['departmentId'],
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<void, CreateMarkupTemplateDTO>({
    method: 'POST',
    url: () => `${mpe.url}${urlConstant}/${markupTemplatePath}/?departmentId=${departmentId}`,
    token,
    ...queryOptions,
  });
}

export function useMutateDeleteMarkupTemplate(
  departmentId: Department['departmentId'],
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  return useMutation(async (markupTemplateId?: MarkupTemplate['templateId']) => {
    return fetcher<MarkupTemplate>({
      url: `${mpe.url}${urlConstant}/${markupTemplatePath}/${markupTemplateId}/?departmentId=${departmentId}`,
      method: 'DELETE',
      token,
      headers: selectedOrgUnitHeader,
      ...queryOptions,
    });
  });
}

export function useMutatePatchMarkupTemplate(
  markupTemplateId: MarkupTemplate['templateId'] | string,
  departmentId: Department['departmentId'],
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<void, PatchMarkupTemplateDTO>({
    method: 'PATCH',
    url: () =>
      `${mpe.url}${urlConstant}/${markupTemplatePath}/${markupTemplateId}/?departmentId=${departmentId}`,
    token,
    ...queryOptions,
  });
}
