import { SvgIconProps } from '@mui/material';

const ImageIconLarge = (props: SvgIconProps) => {
  const { color, ...remainingProps } = props;

  return (
    <svg
      {...remainingProps}
      width="193"
      height="145"
      viewBox="0 0 193 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M174.906 144.875H18.0938C8.10449 144.875 0 136.771 0 126.781V18.2188C0 8.22949 8.10449 0.125 18.0938 0.125H174.906C184.896 0.125 193 8.22949 193 18.2188V126.781C193 136.771 184.896 144.875 174.906 144.875ZM42.2188 21.2344C30.5709 21.2344 21.1094 30.6959 21.1094 42.3438C21.1094 53.9916 30.5709 63.4531 42.2188 63.4531C53.8666 63.4531 63.3281 53.9916 63.3281 42.3438C63.3281 30.6959 53.8666 21.2344 42.2188 21.2344ZM24.125 120.75H168.875V78.5312L135.892 45.5479C134.12 43.7762 131.255 43.7762 129.483 45.5479L78.4062 96.625L57.4854 75.7041C55.7137 73.9324 52.8488 73.9324 51.0771 75.7041L24.125 102.656V120.75Z"
        fill="#D4D4DB"
      />
    </svg>
  );
};

export default ImageIconLarge;
