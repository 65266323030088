import { Search } from '@mui/icons-material';
import { Popper, Stack, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { AutocompleteInput } from '@shared/components';
import { forwardRef } from 'react';
import { DefaultLoadingFallback } from '../LoadingWrappers/DefaultLoadingFallback';

interface Props {
  supplierItems?: MgSupplierItemSearchResult[];
  isLoadingFetchItems: boolean;
  error?: string;
  label?: string;
  required?: boolean;
  width?: number;
  size?: 'small' | 'medium';
  setSupplierItemSearch: (search: string) => void;
  onChange?: (value: MgSupplierItemSearchResult | null) => void;
}

export const SupplierItemAutocomplete = forwardRef(
  (
    {
      supplierItems = [],
      isLoadingFetchItems,
      error,
      label,
      required,
      width,
      size = 'medium',
      setSupplierItemSearch,
      onChange,
      ...rest
    }: Props,
    ref
  ) => {
    const t = useProductsTFunction();
    return (
      <AutocompleteInput
        {...rest}
        name="findItem"
        required={required}
        error={error}
        width={width}
        size={size}
        clearOnBlur={false}
        label={label}
        placeholder={t('products.search.searchItems')}
        startIcon={<Search />}
        options={supplierItems}
        getOptionLabel={({ primaryText }) => primaryText}
        noOptionsText={
          isLoadingFetchItems ? <DefaultLoadingFallback /> : t('products.search.noResults')
        }
        renderOption={(props, option) => {
          return (
            <Stack component={'li'} {...props} key={option.mgItemNumber}>
              <Typography>{option.primaryText}</Typography>
              <Typography>
                {t('products.mgItem.supplierItem.finfoNumber.label')}: {option.finfoNumber}
              </Typography>
            </Stack>
          );
        }}
        PopperComponent={!supplierItems ? NullComponent : Popper}
        onInputChange={(_, value) => setSupplierItemSearch(value)}
        onChange={onChange ? (_, value) => onChange(value) : () => null}
        isOptionEqualToValue={(option, value) => option.finfoSupplierId === value.finfoSupplierId}
        ListboxProps={{
          sx: {
            '& li': {
              borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
              '&:last-child': { borderBottom: '0px' },
            },
          },
        }}
      />
    );
  }
);

function NullComponent() {
  return null;
}
