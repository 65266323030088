import { Stack, Typography } from '@mui/material';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import {
  AllSuppliersLayout,
  SuspenseWithSentryErrorBoundary,
  TagBreadcrumbs,
} from '@retail/components';
import { Supplier } from '@retail/suppliers/types';
import { Paper } from '@shared/components';
import { useCallback, useState } from 'react';

interface Props {
  label: string;
  breadcrumb: string;
  suppliers: Supplier[];
  selectSupplier: (selectedSupplier: Supplier | null) => void;
}

export function SelectSupplierContainer({ label, breadcrumb, suppliers, selectSupplier }: Props) {
  const { selectedPPSContext } = useSelectedOrgUnit();

  const [filteredSuppliers, setFilteredSuppliers] = useState(suppliers);

  const filterSuppliers = useCallback(
    (search: string) => {
      setFilteredSuppliers(() =>
        suppliers.filter((supplier) => supplier.name.toLowerCase().includes(search.toLowerCase()))
      );
    },
    [suppliers]
  );

  return (
    <Paper paddingY="dense" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Stack overflow="auto">
        <SuspenseWithSentryErrorBoundary>
          <Stack gap={4} pt={6} alignItems="center" alignSelf="center">
            <Typography align="center" variant="h4">
              {label}
            </Typography>

            <AllSuppliersLayout
              selectSupplier={selectSupplier}
              isAdmin={selectedPPSContext.isAdmin}
              suppliers={filteredSuppliers}
              filterSuppliers={filterSuppliers}
            />
          </Stack>
        </SuspenseWithSentryErrorBoundary>
      </Stack>
    </Paper>
  );
}
