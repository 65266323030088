import { ItemsDTO } from '@retail/my-assortment/types';
import { zip } from 'lodash';

export type ImportStatus = 'none' | 'success' | 'error';
export interface AssortmentImportState {
  status: ImportStatus;
  mgItemNumbers?: number[];
  mgSupplierItemNumbers?: number[];
  prioritization?: number[];
}

export const getImportedItems = (importState: AssortmentImportState) => {
  const columns = zip(
    importState.mgItemNumbers,
    importState.mgSupplierItemNumbers,
    importState.prioritization
  );

  const items = columns.map((item) => {
    return {
      mgItemNumber: item[0],
      mgSupplierId: item[1],
      priority: item[2] === 0 ? 'ZERO' : 'TEN',
    } as ItemsDTO;
  });

  return items;
};
