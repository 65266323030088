import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog, Paper, TagHeading, TextButton } from '@shared/components';
import { DeletionFromDateForm } from './DeletionFromDateForm';
import { useFormContext } from 'react-hook-form';
import { DeletionFromDateSchema } from './deletionFromDateSchema';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  numberOfItems: number;
  onDelete: (validFrom: string) => void;
  isDeleting: boolean;
}

export function DeleteItemsDialog({ numberOfItems, isOpen, onClose, isDeleting, onDelete }: Props) {
  const { handleSubmit } = useFormContext<DeletionFromDateSchema>();
  const onSubmit = handleSubmit(({ fromDate }) => {
    onDelete(fromDate.toISOString());
  });
  const t = useAppTFunction();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle pb={0}>
        <TagHeading color="primary">
          {t('assortment.deleteItems.title', { numItems: numberOfItems })}
        </TagHeading>
      </DialogTitle>

      <DialogContent>
        <Paper sx={{ height: '100%', p: 0, display: 'flex', flexDirection: 'column' }}>
          <DeletionFromDateForm />
        </Paper>
      </DialogContent>
      <DialogActions>
        <TextButton onClick={onClose} disabled={isDeleting}>
          {t('common.close')}
        </TextButton>
        <ContainedButton loading={isDeleting} onClick={onSubmit}>
          {t('assortment.deleteItems.confirm')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
