import { useFetchPurchaseAgreements } from '@retail/purchase-agreements/data-access';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { useMemo } from 'react';

interface Props {
  supplierId: number;
}

export const useAgreements = ({ supplierId }: Props) => {
  const { data: agreementDtos = [] } = useFetchPurchaseAgreements({ supplierId, suspense: true });

  const agreements = useMemo(() => agreementDtos.map(PurchaseAgreement.fromDto), [agreementDtos]);


  const standardAgreement = useMemo(
    () => agreements.find((agreement) => !!agreement.standardAgreement),
    [agreements]
  );

  return {
    agreements,
    standardAgreement,
    nrOfAgreements: agreements.length,
  };
};
