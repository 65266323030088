import { PageLayout, TagBreadcrumbs } from '@retail/components';

import { useProductsTFunction } from '@retail/products/i18n';
import { Paper } from '@shared/components';
import { MgSupplierItemSearchContainer } from '@retail/products/containers';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { useState } from 'react';
import { useHasDraftDrawerValues } from '../GlobalDrawer';

export function MgSupplierItemSearchPage() {
  const t = useProductsTFunction();

  const [hasDraftDrawerValues] = useHasDraftDrawerValues();
  const [checkedItems, setCheckedItems] = useState<MgSupplierItemSearchResult[]>([]);

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <Paper
        paddingY="dense"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderBottom: 0,
          height: '100%',
          position: 'relative',
        }}
      >
        <TagBreadcrumbs items={[{ label: t('products.search.title') }]} marginBottom={2} />

        <MgSupplierItemSearchContainer
          includeAddButton
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          hasDraftDrawerValues={hasDraftDrawerValues}
        />
      </Paper>
    </PageLayout>
  );
}
