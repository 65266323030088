import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridSelectionModel } from '@mui/x-data-grid-pro';
import {
  dataGridBoldClass,
  dataGridCellHoverClass,
  dataGridCellNoFocusOutlineClass,
  dataGridColorRowClass,
  dataGridRowHoverClass,
  dataGridSelectedRowClass,
  dataGridWhiteRowClass,
} from '@shared/styles';
import { AssortmentItem } from '@retail/my-assortment/types';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { AddItemsButton } from '../AddItemsButton';
import { DataGridPagination, FormattedPrice, StackedTextCell } from '@retail/components';
import { ItemStatus } from './ItemStatus';
import { getPrice, getPrices } from '@retail/my-assortment/utils';
import { AssortmentTableActions } from './AssortmentTableActions';
import clsx from 'clsx';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params';
import dayjs from 'dayjs';

const baseColumnProps: Partial<GridColDef> = {
  width: 120,
  headerName: '',
  headerAlign: 'center',
  align: 'center',
};

interface Props {
  items: AssortmentItem[];
  selectedItem: AssortmentItem | null;
  page: number;
  pageSize: number;
  totalItems: number;
  includeVat: boolean;
  viewItemProductCard: (itemId: number) => void;
  openWarehouseAssortmentsDialog: () => void;
  setPage: (newPage: number) => void;
  setPageSize: (newPageSize: number) => void;
  setItemsChecked: Dispatch<SetStateAction<number[]>>;
  itemsChecked: number[];
  deleteItem: (itemId: number) => void;
  getSupplierCalculusLink: (supplierId: number) => string;
}

type GridColAssortmentItem = GridColDef<AssortmentItem>;

export const AssortmentItemsTable = ({
  items,
  selectedItem,
  page,
  pageSize,
  totalItems,
  includeVat,
  openWarehouseAssortmentsDialog,
  setPage,
  setPageSize,
  setItemsChecked,
  deleteItem,
  itemsChecked,
  viewItemProductCard,
  getSupplierCalculusLink,
}: Props) => {
  const { t } = useMyAssortmentTranslation();

  const lastMgItemNumber = useRef<number | undefined>(items[0]?.mgItemNumber);
  const rowColor = useRef<string>(dataGridColorRowClass);

  const onRowsSelectionHandler = (selections: GridSelectionModel) => {
    setItemsChecked(selections as number[]);
  };

  const columns: GridColAssortmentItem[] = useMemo(() => {
    const itemFinfoColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      field: 'mgItemFinfoNumber',
      headerName: t('myAssortment.columns.mgItemFinfoNumber'),
      renderCell: ({ row }) => (
        <StackedTextCell centerAlign topLine={row.mgItemNumber} bottomLine={row.finfoNumber} />
      ),
    };
    const itemTextColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      field: 'itemText',
      cellClassName: () => dataGridBoldClass,
      headerName: t('myAssortment.columns.itemText'),
      headerAlign: 'left',
      align: 'left',
      width: 280,
      renderCell: ({ row }) => (
        <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />
      ),
    };
    const statusColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      field: 'status',
      headerName: t('myAssortment.columns.itemStatus'),
      renderCell: ({ value }) => <ItemStatus status={value} />,
    };
    const supplierColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      width: 180,
      field: 'supplier',
      headerName: t('myAssortment.columns.supplier'),
      renderCell: ({ row }) => (
        <StackedTextCell centerAlign topLine={row.mgSupplierName} bottomLine={row.mgSupplierId} />
      ),
    };
    const productGroupColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      width: 180,
      field: 'itemGroup',
      headerName: t('myAssortment.columns.itemGroup'),
      renderCell: ({ row }) => (
        <StackedTextCell centerAlign topLine={row.groupNumber} bottomLine={row.groupDescription} />
      ),
    };
    const priceStatusColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      field: 'priceStatus',
      headerName: t('myAssortment.columns.priceStatus'),
      renderCell: ({ row }) => {
        const prices = getPrices(row);
        const unit = row.primaryPackageUnit;
        const price = prices ? getPrice(prices, unit) : undefined;
        return <ItemStatus status={price?.status} />;
      },
    };
    const purchasePriceColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      field: 'purchasePrice',
      headerName: t('myAssortment.columns.purchasePrice'),
      renderCell: ({ row }) => {
        const prices = getPrices(row);
        const unit = row.primaryPackageUnit;
        const price = prices ? getPrice(prices, unit) : undefined;

        return (
          <StackedTextCell
            centerAlign
            topLine={<FormattedPrice price={price?.purchasePrice} />}
            bottomLine={unit}
          />
        );
      },
    };
    const costPriceInSalesUnitColDef: GridColDef = {
      ...baseColumnProps,
      field: 'costPriceInSalesUnit',
      headerName: t('myAssortment.columns.costPriceInSalesUnit'),
      renderCell: ({ row }) => {
        const prices = getPrices(row);
        const unit = row.primaryPackageUnit;
        const price = prices ? getPrice(prices, unit) : undefined;
        return (
          <StackedTextCell
            centerAlign
            topLine={<FormattedPrice price={price?.costPrice} />}
            bottomLine={unit}
          />
        );
      },
    };
    const costPriceInBasePriceUnitColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      field: 'costPrice',
      headerName: t('myAssortment.columns.costPriceInBasePriceUnit'),
      renderCell: ({ row }) => {
        const prices = getPrices(row);
        const unit = row.basePriceUnit;
        const price = prices ? getPrice(prices, unit) : undefined;

        return (
          <StackedTextCell
            centerAlign
            topLine={<FormattedPrice price={price?.costPrice} />}
            bottomLine={unit}
          />
        );
      },
    };
    const salesPriceColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      field: 'salesPrice',
      headerName: t('myAssortment.columns.salesPrice'),
      renderCell: ({ row }) => {
        const prices = getPrices(row);
        const unit = row.primaryPackageUnit;
        const price = prices ? getPrice(prices, unit) : undefined;

        return (
          <StackedTextCell
            centerAlign
            topLine={<FormattedPrice price={price?.salesPrice} includeVat={includeVat} />}
            bottomLine={unit}
          />
        );
      },
    };
    const salesCoverageColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      cellClassName: () => dataGridBoldClass,
      width: 100,
      field: 'salesPriceCoverage',
      headerName: t('myAssortment.columns.salesCoverage'),
      renderCell: ({ row }) => {
        const prices = getPrices(row);
        const unit = row.primaryPackageUnit;
        const price = prices ? getPrice(prices, unit) : undefined;
        return price?.salesPriceCoverage ? `${price?.salesPriceCoverage.toFixed(2)} %` : '-';
      },
    };
    const addedDateColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      width: 150,
      field: 'addedToAssortmentDate',
      headerName: t('myAssortment.columns.addedToAssortmentDate'),
      renderCell: ({ row }) =>
        row.addedToAssortmentDate ? dayjs(row.addedToAssortmentDate).format('L') : '-',
    };
    const fillRemainingWidthColumnDef: GridColAssortmentItem = {
      minWidth: 0,
      flex: 1,
      headerName: '',
      field: 'fillWidth',
    };

    const actionsColDef: GridColAssortmentItem = {
      ...baseColumnProps,
      field: 'actions',
      width: 80,
      sortable: false,
      renderCell: ({ row }) => (
        <AssortmentTableActions
          deleteItem={() => deleteItem(row.id)}
          calculationLink={getSupplierCalculusLink(row.mgSupplierId)}
        />
      ),
    };

    return [
      itemFinfoColDef,
      itemTextColDef,
      statusColDef,
      supplierColDef,
      productGroupColDef,
      priceStatusColDef,
      purchasePriceColDef,
      costPriceInSalesUnitColDef,
      costPriceInBasePriceUnitColDef,
      salesPriceColDef,
      salesCoverageColDef,
      addedDateColDef,
      fillRemainingWidthColumnDef,
      actionsColDef,
    ];
  }, [t, includeVat, getSupplierCalculusLink, deleteItem]);

  const getRowClassName = useCallback(
    ({ row }: GridRowClassNameParams<AssortmentItem>) => {
      if (row.mgItemNumber !== lastMgItemNumber.current) {
        rowColor.current =
          rowColor.current === dataGridWhiteRowClass
            ? dataGridColorRowClass
            : dataGridWhiteRowClass;
        lastMgItemNumber.current = row.mgItemNumber;
      }

      return clsx(rowColor.current, dataGridRowHoverClass, {
        [dataGridSelectedRowClass]: selectedItem?.id === row.id,
      });
    },
    [selectedItem?.id]
  );

  return (
    <Box height="100%">
      <DataGridPro<AssortmentItem>
        columns={columns}
        rows={items}
        getRowId={(row) => row.id}
        // @ts-ignore
        getRowClassName={getRowClassName}
        getCellClassName={({ field }) =>
          clsx(dataGridCellNoFocusOutlineClass, {
            [dataGridCellHoverClass]: !['actions', '__check__'].includes(field),
          })
        }
        rowHeight={65}
        checkboxSelection
        disableSelectionOnClick
        disableColumnMenu
        initialState={{
          pinnedColumns: { right: ['actions'] },
        }}
        pagination={true}
        paginationMode="server"
        rowCount={totalItems}
        onCellClick={({ id, field }) => {
          if (!['actions', '__check__'].includes(field)) {
            viewItemProductCard(Number(id));
          }
        }}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        selectionModel={itemsChecked}
        onSelectionModelChange={onRowsSelectionHandler}
        components={{
          Pagination: DataGridPagination,
          NoRowsOverlay: () => (
            <Stack
              alignItems="center"
              justifyContent="center"
              gap={2}
              height="100%"
              sx={{ position: 'relative', zIndex: 1, pointerEvents: 'all' }} // https://github.com/mui/mui-x/issues/5725
            >
              <Typography variant="h3" color={(theme) => theme.palette.grey[100]}>
                {t('myAssortment.noItems')}
              </Typography>

              <AddItemsButton onClick={openWarehouseAssortmentsDialog} />
            </Stack>
          ),
        }}
      />
    </Box>
  );
};
