import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import {
  AppLayout,
  AuthContainerPage,
  CalculusForSupplierPage,
  CalculusPage,
  MgAssortmentPage,
  MgItemPage,
  MgSupplierItemSearchPage,
  MyAssortmentPage,
  NotFoundPage,
  OrgUnitAccessChecker,
  SuppliersPage,
} from '@retail/app/pages';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import {
  BrandsProvider,
  MyOrgUnitsProvider,
  SelectedOrgUnitProvider,
} from '@retail/app/stores/selected-context';
import * as Sentry from '@sentry/react';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes: React.FC = () => (
  <SentryRoutes>
    <Route
      path={mgPrisRoutes.root.fullRoutePath}
      element={
        <AuthContainerPage>
          <SuspenseWithSentryErrorBoundary>
            <BrandsProvider>
              <MyOrgUnitsProvider>
                <OrgUnitAccessChecker>
                  <SelectedOrgUnitProvider>
                    <AppLayout>
                      <Outlet />
                    </AppLayout>
                  </SelectedOrgUnitProvider>
                </OrgUnitAccessChecker>
              </MyOrgUnitsProvider>
            </BrandsProvider>
          </SuspenseWithSentryErrorBoundary>
        </AuthContainerPage>
      }
    >
      {/* All following routes are only shown to authorized users, due to AuthContainerPage */}
      <Route path="" element={<Navigate to={mgPrisRoutes.myAssortment.fullRoutePath} replace />} />

      <Route
        path={mgPrisRoutes.marketInsight.assortments.fullRoutePath}
        element={<Navigate to={mgPrisRoutes.marketInsight.fullRoutePath} replace />}
      />

      <Route
        path={mgPrisRoutes.marketInsight.assortments.assortment.fullRoutePath}
        element={<MgAssortmentPage />}
      />

      <Route path={mgPrisRoutes.items.fullRoutePath} element={<MgSupplierItemSearchPage />} />

      <Route path={mgPrisRoutes.items.item.fullRoutePath} element={<MgItemPage />} />

      <Route path={mgPrisRoutes.myAssortment.fullRoutePath} element={<MyAssortmentPage />} />
      <Route path={mgPrisRoutes.calculus.fullRoutePath} element={<CalculusPage />} />
      <Route
        path={mgPrisRoutes.calculus.supplier.fullRoutePath}
        element={<CalculusForSupplierPage />}
      />

      <Route path={mgPrisRoutes.suppliers.fullRoutePath} element={<SuppliersPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  </SentryRoutes>
);
