import { ItemAgreementDTO, PriceType, PriceUnit } from '../dto';

export interface ItemAgreementProps {
  mgSupplierItemId: number;
  mgItemNumber: number;
  finfoNumber: number;
  mgSupplierId: number;
  primaryText: string;
  secondaryText?: string;
  supplierItemId?: string;
  highestSupplierItemPrice?: boolean;
  active: boolean;
  inherited: boolean;
  price: number;
  priceUnit: PriceUnit;
  gtinPrice: number;
  gtinPriceUnit: string;
  priceType: PriceType;
  validFrom: Date;
  validTo: Date;
  agreementId: number;
  agreementText: string;
  conditionId: number;
  multiplePrices: boolean;
}

export class ItemAgreement {
  mgSupplierItemId: number;
  mgItemNumber: number;
  finfoNumber: number;
  mgSupplierId: number;
  primaryText: string;
  secondaryText?: string;
  supplierItemId?: string;
  highestSupplierItemPrice?: boolean;
  active: boolean;
  inherited: boolean;
  price: number;
  priceUnit: PriceUnit;
  gtinPrice: number;
  gtinPriceUnit: string;
  priceType: PriceType;
  validFrom: Date;
  validTo: Date;
  agreementId: number;
  agreementText: string;
  conditionId: number;
  multiplePrices: boolean;

  constructor({
    mgSupplierItemId,
    mgItemNumber,
    finfoNumber,
    mgSupplierId,
    primaryText,
    secondaryText,
    highestSupplierItemPrice,
    active,
    inherited,
    price,
    priceUnit,
    gtinPrice,
    gtinPriceUnit,
    priceType,
    validFrom,
    validTo,
    agreementId,
    agreementText,
    conditionId,
    multiplePrices,
  }: ItemAgreementProps) {
    this.mgSupplierItemId = mgSupplierItemId;
    this.mgItemNumber = mgItemNumber;
    this.finfoNumber = finfoNumber;
    this.mgSupplierId = mgSupplierId;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.highestSupplierItemPrice = highestSupplierItemPrice;
    this.active = active;
    this.inherited = inherited;
    this.price = price;
    this.priceUnit = priceUnit;
    this.gtinPrice = gtinPrice;
    this.gtinPriceUnit = gtinPriceUnit;
    this.priceType = priceType;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.agreementId = agreementId;
    this.agreementText = agreementText;
    this.conditionId = conditionId;
    this.multiplePrices = multiplePrices;
  }

  static fromDto = (dto: ItemAgreementDTO): ItemAgreement =>
    new ItemAgreement({
      mgSupplierItemId: dto.mgSupplierItemId,
      mgItemNumber: dto.mgItemNumber,
      finfoNumber: dto.finfoNumber,
      mgSupplierId: dto.mgSupplierId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      highestSupplierItemPrice: dto.highestSupplierItemPrice,
      active: dto.active,
      inherited: dto.inherited,
      price: dto.price,
      priceUnit: dto.priceUnit,
      gtinPrice: dto.gtinPrice,
      gtinPriceUnit: dto.gtinPriceUnit,
      priceType: dto.priceType,
      validFrom: dto.validFrom,
      validTo: dto.validTo,
      agreementId: dto.agreementId,
      agreementText: dto.agreementText,
      conditionId: dto.conditionId,
      multiplePrices: dto.multiplePrices,
    });
}
