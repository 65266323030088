import { MpeMgSupplierItemDTO } from '../../dto';

export class MgItemBk04Varegruppe {
  id: string;
  name?: string;

  constructor(id: string, name: string | undefined) {
    this.id = id;
    this.name = name;
  }

  getLabel = (): string => `${this.id} ${this.name}`;

  static fromDto = (
    dto: Pick<MpeMgSupplierItemDTO, 'bk04VaregruppeName' | 'bk04VaregruppeNumber'>
  ): MgItemBk04Varegruppe =>
    new MgItemBk04Varegruppe(dto.bk04VaregruppeNumber, dto.bk04VaregruppeName);
}
