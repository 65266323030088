export enum Priority {
  ZERO = 'ZERO',
  TEN = 'TEN',
}

export interface ItemsDTO {
  mgSupplierId: number;
  mgItemNumber: number;
  priority?: Priority | null;
  validFrom?: string;
}
