import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDTODate } from '@retail/utils';
import { useAppTFunction } from '@retail/app/i18n';
import { useToast } from '@retail/hooks';

import {
  CreateFreightConditionDTO,
  CreateOrEditFreightCondition,
  MgSupplierItemFreightConditionDTO,
  ProductGroupFreightConditionDTO,
} from '@retail/freight/types';
import { useMutateCreateOrEditFreightCondition } from '@retail/freight/data-access';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { EditFreightConditionForm } from './EditFreightConditionForm';
import { EditFreightConditionSchema, editFreightConditionSchema } from '@retail/freight/components';
import { FreightCondition } from '@retail/calculus/types';

interface Props {
  isOpen: boolean;
  supplierId: number;
  freightConditionClicked: FreightCondition;
  onClose: () => void;
}

export function EditFreightConditionDialog({
  supplierId,
  freightConditionClicked,
  isOpen,
  onClose,
}: Props) {
  const toast = useToast();
  const t = useAppTFunction();

  const queryClient = useQueryClient();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();

  const formMethods = useForm<EditFreightConditionSchema>({
    defaultValues: {
      percentAddon: String(freightConditionClicked.percentAddon),
      fromDate: freightConditionClicked.validFrom
        ? new Date(freightConditionClicked.validFrom)
        : undefined,
      toDate: freightConditionClicked.validTo
        ? new Date(freightConditionClicked.validTo)
        : undefined,
    },
    resolver: yupResolver(editFreightConditionSchema(t)),
  });

  const { mutateAsync: patchMarkupTemplate } = useMutateCreateOrEditFreightCondition(
    draftAssortmentId,
    departmentId
  );

  const onEdit = useCallback(
    (data: EditFreightConditionSchema) => {
      const freightCondition: CreateOrEditFreightCondition = {
        validFrom: getDTODate(data.fromDate) ?? '',
        freight: {
          percentAddon: Number(data.percentAddon),
        },
        mgItemNumber: freightConditionClicked.mgItemNumber,
        groupNumber: freightConditionClicked.productGroup,
      };

      let mgSupplierConditions = null;
      let productGroupConditions = null;
      let mgSupplierItemConditions = null;

      if (freightCondition.mgItemNumber) {
        mgSupplierItemConditions = [freightCondition as MgSupplierItemFreightConditionDTO];
      } else if (freightCondition.groupNumber) {
        productGroupConditions = [freightCondition as ProductGroupFreightConditionDTO];
      } else {
        mgSupplierConditions = [freightCondition as CreateFreightConditionDTO];
      }

      patchMarkupTemplate({
        body: {
          mgSupplierId: supplierId,
          mgSupplierConditions,
          productGroupConditions,
          mgSupplierItemConditions,
        },
      })
        .catch((err) => {
          console.error(err);
          toast.error(t('markup.error.generic'));
        })
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
          queryClient.invalidateQueries({ queryKey: ['freight'] });
          onClose();
        });
    },
    [
      freightConditionClicked.mgItemNumber,
      freightConditionClicked.productGroup,
      patchMarkupTemplate,
      supplierId,
      toast,
      t,
      queryClient,
      onClose,
    ]
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>
        {t('freight.form.editFreightConditionFor', {
          condition: freightConditionClicked.primaryText,
        })}
      </DialogTitle>

      <DialogContent>
        <FormProvider {...formMethods}>
          <EditFreightConditionForm />
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', alignSelf: 'center', mt: 1 }}>
        <TextButton
          sx={{ textDecoration: 'none', color: ({ palette }) => palette.common.black }}
          size="small"
          onClick={onClose}
        >
          {t('common.close')}
        </TextButton>

        <ContainedButton
          disabled={Object.keys(formMethods?.formState?.errors).length > 0}
          onClick={formMethods.handleSubmit(onEdit)}
        >
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
