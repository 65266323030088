import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ContainedButton, Dialog, OutlinedButton } from '@shared/components';
import { useAppTFunction } from '@retail/app/i18n';
import { decode } from 'he';
import { usePurchaseActions } from '@retail/purchase-agreements/context';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  supplierId: number;
  mgItemNumber?: number;
  groupNumber?: string;
  agreementText?: string;
}

export function DeactivateAgreementDialog({
  supplierId,
  isOpen,
  mgItemNumber,
  groupNumber,
  agreementText,
  onClose,
}: Props) {
  const t = useAppTFunction();

  const { deactivatePurchaseCondition, isLoadingPostChanges } = usePurchaseActions({
    mgSupplierId: supplierId,
  });

  const handleDeactivateCondition = () => {
    deactivatePurchaseCondition({
      supplierLevel: !mgItemNumber && !groupNumber,
      groupNumber,
      mgItemNumber,
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {decode(
          t('purchaseAgreements.agreement.actions.deactivateDialogTitle', {
            agreementName: agreementText,
          })
        )}
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6" fontWeight="medium">
          {t('markup.actions.confirmDeactivate')}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', alignSelf: 'flex-end' }}>
        <OutlinedButton size="small" onClick={onClose}>
          {t('markup.actions.cancel')}
        </OutlinedButton>

        <ContainedButton
          color="warning"
          size="small"
          loading={isLoadingPostChanges}
          sx={{ alignSelf: 'center' }}
          onClick={handleDeactivateCondition}
        >
          {t('markup.actions.deactivate')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
