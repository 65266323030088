import { Stack } from '@mui/material';
import {
  AssortmentItemsTable,
  DeleteItemsDialog,
  deletionFromDateSchema,
} from '@retail/my-assortment/components';
import { Paper, UniversalFormProvider } from '@shared/components';
import { AssortmentItemsTableToolbar } from './AssortmentItemsTableToolbar';
import { AssortmentItem, ItemsDTO } from '@retail/my-assortment/types';
import { useCallback, useState } from 'react';
import { ProductCardContainer } from '../ProductCard';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Pagination, useSelectedSearchParamState } from '@shared/hooks';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { mgPrisRoutes } from '@retail/mgpris/config';

const getSupplierLinkPath = (supplierId: number) =>
  mgPrisRoutes.calculus.supplier.getFullLinkPath(`${supplierId}`);

interface Props {
  assortmentItems: AssortmentItem[];
  totalItems: number;
  pagination: Pagination;
  includeVat: boolean;
  updateItemsIsLoading: boolean;
  updatePriority: (items: ItemsDTO[]) => void;
  toggleBrandAssortmentsView: () => void;
  deleteItemsByDate: (itemIds: number[], date: string) => Promise<void>;
}

export function AssortmentItems({
  assortmentItems,
  totalItems,
  pagination,
  includeVat,
  updateItemsIsLoading,
  toggleBrandAssortmentsView,
  updatePriority,
  deleteItemsByDate,
}: Props) {
  const t = useAssortmentTFunction();

  const [selectedItem, setSelectedItem, setSelectedItemId] = useSelectedSearchParamState({
    options: assortmentItems,
    searchParamKey: 'item',
    getOptionId: ({ id }) => id,
  });

  const viewItem = useCallback(
    (itemId: number) => {
      if (selectedItem?.id === itemId) setSelectedItemId(null);
      else setSelectedItemId(itemId);
    },
    [selectedItem?.id, setSelectedItemId]
  );

  const [itemsChecked, setItemsChecked] = useState<number[]>([]);
  const [itemsForDeletion, setItemsForDeletion] = useState<number[]>([]);

  const confirmDeleteCheckedItemsFromDate = useCallback(
    (date: string) => {
      if (itemsForDeletion.length) {
        deleteItemsByDate(itemsForDeletion, date).then(() => setItemsForDeletion([]));
      }
    },
    [deleteItemsByDate, itemsForDeletion]
  );

  const setCheckedItemsForDeletion = useCallback(
    () => setItemsForDeletion(itemsChecked),
    [itemsChecked]
  );
  const setItemForDeletion = useCallback((itemId: number) => {
    setItemsForDeletion([itemId]);
  }, []);
  const onCloseDeleteItemsDialog = useCallback(() => setItemsForDeletion([]), []);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        p: 0,
        borderRadius: 0,
        overflow: 'hidden',
      }}
    >
      <Stack flexDirection="row" flexGrow={1}>
        <Stack flexGrow={1}>
          <AssortmentItemsTableToolbar
            totalItems={totalItems}
            noItemsChecked={!itemsChecked.length}
            deleteCheckedItems={setCheckedItemsForDeletion}
            deletionIsLoading={updateItemsIsLoading}
          />

          <AssortmentItemsTable
            {...pagination}
            items={assortmentItems}
            selectedItem={selectedItem}
            totalItems={totalItems}
            includeVat={includeVat}
            viewItemProductCard={viewItem}
            openWarehouseAssortmentsDialog={toggleBrandAssortmentsView}
            setItemsChecked={setItemsChecked}
            itemsChecked={itemsChecked}
            deleteItem={setItemForDeletion}
            getSupplierCalculusLink={getSupplierLinkPath}
          />
        </Stack>

        {selectedItem && (
          <SuspenseWithSentryErrorBoundary>
            <ProductCardContainer
              includeVat={includeVat}
              assortmentItem={selectedItem}
              isLoading={updateItemsIsLoading}
              closeProductCard={() => setSelectedItem(null)}
              updatePriority={updatePriority}
            />
          </SuspenseWithSentryErrorBoundary>
        )}
        {itemsForDeletion.length > 0 && (
          <UniversalFormProvider
            schema={deletionFromDateSchema(t)}
            defaultValues={deletionFromDateSchema(t).getDefault()}
          >
            <DeleteItemsDialog
              numberOfItems={itemsForDeletion.length}
              onClose={onCloseDeleteItemsDialog}
              isOpen={true}
              onDelete={confirmDeleteCheckedItemsFromDate}
              isDeleting={updateItemsIsLoading}
            />
          </UniversalFormProvider>
        )}
      </Stack>
    </Paper>
  );
}
