import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import {
  AddButton,
  NotFoundError,
  PageLayout,
  SuspenseWithSentryErrorBoundary,
} from '@retail/components';
import { Paper } from '@shared/components';
import { useSelectSupplier } from '@retail/calculus/context';
import {
  CalculusTabs,
  SelectSupplierAutocomplete,
  TypesToggleButton,
} from '@retail/calculus/components';
import { ProductGroupsTreeViewContainer } from '@retail/calculus/containers';
import { useRecoilValue } from 'recoil';
import { currentCalculusAreaAtom } from './calculusState';
import { CalculusTabOptions, ConditionState } from '@retail/calculus/types';
import {
  ItemAgreementsForProductGroup,
  PurchaseContainer,
} from '@retail/purchase-agreements/containers';
import { FreightConditions } from '@retail/freight/containers';
import { MarkupContainer } from '@retail/markup/containers';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useDisclosure } from '@shared/hooks';

export function CalculusForSupplierPage() {
  const t = useAppTFunction();

  const [conditionState, setConditionState] = useState<ConditionState>(ConditionState.ACTIVE);

  const { isOpen: isCreateOpen, onToggle: openCreate, onClose: closeCreate } = useDisclosure();

  const { supplierId } = useParams<{ supplierId: string }>();
  const { suppliers, selectedSupplier, navigateToSupplier } = useSelectSupplier({
    supplierId: Number(supplierId),
  });
  const { baseAssortmentId } = useDepartmentAssortments();
  const currentCalculusArea = useRecoilValue(currentCalculusAreaAtom);

  return (
    <PageLayout maxWidth="full" fixedHeight>
      <Paper
        paddingY="dense"
        sx={{
          p: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {selectedSupplier ? (
          <Stack overflow="hidden" flexGrow={1}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-end"
              p={1}
              pb={0}
              borderBottom={({ palette }) => `1px solid ${palette.divider}`}
            >
              <CalculusTabs currentCalculusAreaAtom={currentCalculusAreaAtom} />
              {selectedSupplier && (
                <SelectSupplierAutocomplete
                  suppliers={suppliers}
                  onSelectSupplier={navigateToSupplier}
                />
              )}
            </Stack>
            <Stack m={2} flexDirection="row" justifyContent="space-between">
              <TypesToggleButton typesToShow={conditionState} setTypesToShow={setConditionState} />
              <AddButton onClick={openCreate} sx={{ mr: 1 }}>
                {currentCalculusArea === CalculusTabOptions.MARKUP
                  ? t('markup.create.newMarkup')
                  : t('calculus.newCondition')}
              </AddButton>
            </Stack>
            <SuspenseWithSentryErrorBoundary>
              <Stack direction="row" flexGrow={1} overflow="hidden">
                {currentCalculusArea === CalculusTabOptions.PURCHASE && (
                  <PurchaseContainer
                    typesToShow={conditionState}
                    selectedSupplier={selectedSupplier}
                    isCreateOpen={isCreateOpen}
                    closeCreate={closeCreate}
                  />
                )}

                {currentCalculusArea === CalculusTabOptions.FREIGHT && (
                  <FreightConditions
                    supplier={selectedSupplier}
                    typesToShow={conditionState}
                    isCreateOpen={isCreateOpen}
                    closeCreate={closeCreate}
                  />
                )}

                {currentCalculusArea === CalculusTabOptions.MARKUP && (
                  <MarkupContainer
                    selectedSupplier={selectedSupplier}
                    typesToShow={conditionState}
                    isCreateOpen={isCreateOpen}
                    closeCreate={closeCreate}
                  />
                )}
                {currentCalculusArea === CalculusTabOptions.ITEMS && (
                  //TODO: ItemAgreements her until new call from backend is ready
                  <ItemAgreementsForProductGroup
                    baseAssortmentId={baseAssortmentId}
                    supplier={selectedSupplier}
                  />
                )}

                <ProductGroupsTreeViewContainer
                  supplierId={selectedSupplier.mgSupplierId}
                  calculusArea={currentCalculusArea}
                />
              </Stack>
            </SuspenseWithSentryErrorBoundary>
          </Stack>
        ) : (
          <NotFoundError
            title={t('freight.supplier.notFound.title')}
            subtitle={t('freight.supplier.notFound.subtitle', { supplierId })}
            buttonText={t('freight.supplier.notFound.goBack')}
          />
        )}
      </Paper>
    </PageLayout>
  );
}
