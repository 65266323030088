import { UnderlinedTabs } from '@shared/components';
import { CalculusTabOptions } from '@retail/calculus/types';
import { RecoilState } from 'recoil';
import { useCalculusTabs } from '@retail/calculus/hooks';
import { Stack } from '@mui/material';

interface Props {
  currentCalculusAreaAtom: RecoilState<CalculusTabOptions>;
}

export const CalculusTabs = ({ currentCalculusAreaAtom }: Props) => {
  const { tabs, selectedTab, selectTab } = useCalculusTabs({ currentCalculusAreaAtom });

  return (
    <Stack pt={2}>
      <UnderlinedTabs
        id="item"
        tabs={tabs}
        currentTab={selectedTab}
        onChange={(_, value) => selectTab(value)}
      />
    </Stack>
  );
};
