import { useTheme } from '@mui/material';
import { HierarchyLevels } from '@retail/calculus/types';
import { Chip } from '@shared/components';
import React, { useMemo } from 'react';

interface Props {
  level: HierarchyLevels;
  label: string | React.ReactNode;
  active: boolean;
  width?: number;
  height?: number;
}

export const LevelChip = ({ level, label, active, width = 144, height = 36 }: Props) => {
  const { palette } = useTheme();

  const chipColor = useMemo(() => {
    if (active) {
      switch (level) {
        case HierarchyLevels.supplier:
          return '#C0B6F4';
        case HierarchyLevels.overGroup:
          return '#E9E1A0';
        case HierarchyLevels.mainGroup:
          return palette.info.main;
        case HierarchyLevels.productGroup:
          return palette.warning.light;
        case HierarchyLevels.item:
          return palette.success[100];
        default:
          return palette.divider;
      }
    } else {
      return palette.divider;
    }
  }, [active, level, palette]);

  return <Chip sx={{ width: width, height: height }} backgroundColor={chipColor} label={label} />;
};
