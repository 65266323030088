import { ProductGroupsTreeView } from '@retail/components';
import { CalculusTabOptions, SupplierWithProductGroups } from '@retail/calculus/types';
import { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { useFetchProductGroupsForSupplier } from '@retail/calculus/data-access';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { Paper } from '@shared/components';
import { useLevelSelect } from '@retail/calculus/context';
import { SelectMgItem } from '@retail/calculus/components';
import { useLevelSearchParams } from '@retail/calculus/hooks';
interface Props {
  supplierId: number;
  calculusArea: CalculusTabOptions;
}

export function ProductGroupsTreeViewContainer({ supplierId, calculusArea }: Props) {
  const { selectLevel, selectedProductGroupId, selectedMgItemId } = useLevelSearchParams();
  const { draftAssortmentId } = useDepartmentAssortments();

  const filterConditionList = () => {
    switch (calculusArea) {
      case CalculusTabOptions.PURCHASE:
        return [CalculusTabOptions.PURCHASE];
      case CalculusTabOptions.FREIGHT:
        return [CalculusTabOptions.FREIGHT];
      case CalculusTabOptions.MARKUP:
        return [CalculusTabOptions.MARKUP];
      default:
        return [];
    }
  };

  const { data: supplierWithProductGroupsDto } = useFetchProductGroupsForSupplier(
    supplierId,
    {
      assortmentId: draftAssortmentId,
      filterConditions: filterConditionList(),
    },
    { suspense: true }
  );

  const supplierWithProductGroups = useMemo(
    () => SupplierWithProductGroups.fromDto(supplierWithProductGroupsDto!),
    [supplierWithProductGroupsDto]
  );

  const defaultExpanded = useCallback(
    (productGroupId: string) =>
      !!selectedProductGroupId &&
      ((selectedProductGroupId.length > 3 &&
        selectedProductGroupId.slice(0, 3) === productGroupId) ||
        (selectedProductGroupId.length > 2 &&
          selectedProductGroupId.slice(0, 2) === productGroupId)),
    [selectedProductGroupId]
  );

  const { mgSupplierItems, setMgSupplierItemSearch, isLoadingFetchItems } = useLevelSelect({
    supplierId,
  });

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Paper
        variant="outlined"
        sx={{
          overflow: 'auto',
          p: 2.5,
          width: 348,
          boxShadow: ({ shadows }) => shadows[4],
          borderRadius: 0,
        }}
      >
        <SelectMgItem
          supplierId={supplierId}
          mgItemId={selectedMgItemId}
          mgItems={mgSupplierItems || []}
          isLoading={isLoadingFetchItems}
          selectLevel={selectLevel}
          setSearchTerm={setMgSupplierItemSearch}
        />

        <ProductGroupsTreeView
          supplierWithProductGroups={supplierWithProductGroups}
          selectedNodeId={`${selectedProductGroupId || selectedMgItemId || supplierId}`}
          calculusArea={calculusArea}
          shouldDefaultExpand={defaultExpanded}
          selectLevel={selectLevel}
        />
      </Paper>
    </Box>
  );
}
