import { PricingModel } from './PricingModel';
import { Store } from './Store';
import { ByggmaterialpriserRetailerDTO, ContextCompetitorDTO } from '../dto';

export class Retailer {
  name: string;
  id: number;
  pricingModel: PricingModel;
  stores: Store[];
  urlName: string;
  baseUrl: string;

  constructor(
    name: string,
    id: number,
    pricingModel: PricingModel,
    stores: Store[],
    urlName: string,
    baseUrl: string
  ) {
    this.name = name;
    this.id = id;
    this.pricingModel = pricingModel;
    this.stores = stores;
    this.urlName = urlName;
    this.baseUrl = baseUrl;
  }

  static getLogoUrl = (urlName: string, baseLogoUrl: string): string => {
    return `${baseLogoUrl}/${urlName}_logo.webp`;
  };

  logoUrl = (): string => Retailer.getLogoUrl(this.urlName, this.baseUrl);

  static getCircularLogoUrl = (urlName: string, baseLogoUrl: string): string => {
    return `${baseLogoUrl}/${urlName}_circle_64_clr.png`;
  };

  circularLogoUrl = (): string => Retailer.getCircularLogoUrl(this.urlName, this.baseUrl);

  static fromBmpRetailerDto =
    (competitors: ContextCompetitorDTO, retailerLogoBaseUrl: string) =>
    ({ id, name, pricingModel, stores, urlName }: ByggmaterialpriserRetailerDTO): Retailer => {
      const fromStoreDto = Store.fromBmpStoreDto(
        competitors.stores.filter((storeComp) => storeComp.retailer.id === id)
      );

      return new Retailer(
        name,
        id,
        pricingModel === 'UNDEFINED' ? 'NATIONAL' : (pricingModel as PricingModel),
        stores.map(fromStoreDto),
        urlName,
        retailerLogoBaseUrl
      );
    };
}
