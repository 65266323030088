import { createFreightConditionSchema } from '@retail/freight/components';
import { UniversalFormProvider } from '@shared/components';
import CreateFreightConditionDialog from './CreateFreightConditionDialog';
import { useCalculusTFunction } from '@retail/calculus/i18n';

interface Props {
  supplierId: number;
  closeCreateDialog: () => void;
}

export function CreateFreightConditionContainer({ supplierId, closeCreateDialog }: Props) {
  const t = useCalculusTFunction();

  return (
    <UniversalFormProvider
      schema={createFreightConditionSchema(t)}
      defaultValues={createFreightConditionSchema(t).getDefault()}
    >
      <CreateFreightConditionDialog
        supplierId={supplierId}
        onClose={closeCreateDialog}
        open={true}
      />
    </UniversalFormProvider>
  );
}
