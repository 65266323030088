import { IconButton, Tooltip } from '@mui/material';
import { HierarchyLevels } from '@retail/calculus/types';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import { CheckIcon, InheritedIcon } from '@shared/custom-icons';

interface Props {
  inherited: boolean;
  bindingType: HierarchyLevels;
  supplierName?: string;
  productGroup?: string;
  navigateToLevel?: () => void;
}

export const AgreementStatusIcon = ({
  inherited,
  bindingType,
  supplierName,
  productGroup,
  navigateToLevel,
}: Props) => {
  const { t } = usePurchaseAgreementsTranslation();

  if (!inherited) return <CheckIcon fontSize="small" />;

  const level = productGroup ?? supplierName;

  return (
    <Tooltip title={`${t('purchaseAgreements.agreement.inheritedFrom')} ${level}`}>
      <div>
        <IconButton sx={{ padding: 0 }} onClick={navigateToLevel}>
          <InheritedIcon fontSize="small" />
        </IconButton>
      </div>
    </Tooltip>
  );
};
