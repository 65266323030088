import { Stack } from '@mui/material';
import { ContainedButton, OutlinedButton, Popover } from '@shared/components';
import { SelectProductGroup, SelectSupplier } from '../AssortmentTable';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ProductGroup } from '@retail/products/types';
import { Supplier } from '@retail/suppliers/types';

interface Props {
  id: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  suppliers: Supplier[];
  productGroups: ProductGroup[];
  selectedSupplier: Supplier | null;
  selectedProductGroup: ProductGroup | null;
  selectSupplier: (id: Supplier | null) => void;
  selectProductGroup: (groupNumber: ProductGroup | null) => void;
  onClose: () => void;
  resetFilters: () => void;
  saveFilters: () => void;
}

export function FiltersPopover({
  id,
  anchorEl,
  open,
  suppliers,
  productGroups,
  selectedSupplier,
  selectedProductGroup,
  selectSupplier,
  selectProductGroup,
  onClose,
  resetFilters,
  saveFilters,
}: Props) {
  const t = useMyAssortmentTFunction();
  return (
    <Popover id={id} anchorEl={anchorEl} open={open} handleClose={onClose}>
      <Stack gap={2}>
        <SelectSupplier
          suppliers={suppliers}
          selectedSupplier={selectedSupplier}
          selectSupplier={selectSupplier}
        />
        <SelectProductGroup
          productGroups={productGroups}
          selectedproductGroup={selectedProductGroup}
          selectproductGroup={selectProductGroup}
        />
        <Stack flexDirection="row" alignSelf="flex-end" gap={1}>
          <OutlinedButton onClick={resetFilters} size="small">
            {t('myAssortment.filters.clearFilters')}
          </OutlinedButton>
          <ContainedButton onClick={saveFilters} size="small">
            {t('myAssortment.filters.saveFilters')}
          </ContainedButton>
        </Stack>
      </Stack>
    </Popover>
  );
}
