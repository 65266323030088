import {
  bolistCircularLogoUri,
  bolistIconImgUri,
  xlbyggCircularLogoUri,
  xlByggIconImgUri,
} from '@assets/images';

export interface Logos {
  circularLogo: string;
  logo: string;
}

export const brandLogo = (brandId: string) => {
  switch (brandId) {
    case 'bolist':
      return { circularLogo: bolistCircularLogoUri, logo: bolistIconImgUri } as Logos;
    case 'xlbygg':
      return { circularLogo: xlbyggCircularLogoUri, logo: xlByggIconImgUri } as Logos;
    default:
      return { circularLogo: '', logo: '' } as Logos;
  }
};
