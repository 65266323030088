import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher, PPSFetchError, RequestContext, RequestMethods } from './fetcher';

export interface UseMutateFetchOptions<URLVariables> {
  url: string | ((variables: URLVariables | undefined) => string);
  method: RequestMethods;
  token?: string;
  context?: RequestContext;
  headers?: HeadersInit;
}

interface VariablesT<TBody, TURLVariables> {
  body?: TBody;
  urlVariables?: TURLVariables;
}

export type UseMutateFetchProps<ResultT, BodyT, URLVariables> = (Omit<
  UseMutationOptions<ResultT, PPSFetchError, VariablesT<BodyT, URLVariables> | undefined>,
  'mutationFn' | 'context'
>
  | undefined
) &
UseMutateFetchOptions<URLVariables>

export function useMutateFetch<ResultT, BodyT = unknown, URLVariables = any>({
  url,
  method,
  context,
  token,
  headers,
  ...mutationOptions
}: UseMutateFetchProps<ResultT, BodyT, URLVariables>) {
  const fetch = async (fetchVariables: VariablesT<BodyT, URLVariables> = {}) =>
    fetcher<ResultT>({
      url: typeof url === 'function' ? url(fetchVariables?.urlVariables) : url,
      method,
      token,
      body: fetchVariables?.body ? JSON.stringify(fetchVariables?.body) : undefined,
      context,
      headers,
    });

  return useMutation<ResultT, PPSFetchError, VariablesT<BodyT, URLVariables>>(
    fetch,
    mutationOptions
  );
}
