import { SvgIcon, SvgIconProps } from '@mui/material';

export const StorageUnitIcon = ({ fontSize }: Pick<SvgIconProps, 'fontSize'>) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M19.0624 6.75316C18.9464 6.75365 18.8317 6.72675 18.7273 6.67451L9.92299 2.03461L1.11869 6.64306C0.930837 6.73483 0.71537 6.74579 0.519692 6.67352C0.324013 6.60125 0.164151 6.45168 0.0752742 6.25771C-0.013603 6.06374 -0.0242151 5.84125 0.0457721 5.6392C0.115759 5.43715 0.260613 5.27208 0.448467 5.18031L9.92299 0.335938L19.3975 5.18031C19.5972 5.22849 19.7705 5.35595 19.8799 5.53511C19.9892 5.71427 20.0258 5.93069 19.9818 6.13752C19.9377 6.34434 19.8165 6.52492 19.6444 6.64015C19.4724 6.75537 19.2633 6.79597 19.0624 6.75316ZM6.1149 14.6174H1.5452V19.3359H6.1149V14.6174ZM12.2078 14.6174H7.63814V19.3359H12.2078V14.6174ZM18.3008 14.6174H13.7311V19.3359H18.3008V14.6174ZM15.2543 8.326H10.6846V13.0445H15.2543V8.326ZM9.16138 8.326H4.59167V13.0445H9.16138V8.326Z"
          fill="currentColor"
        />
      </svg>
      ;
    </SvgIcon>
  );
};
