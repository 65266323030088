import { MpeMgSupplierItemEnvironmentDTO } from '../../dto';

export interface MgItemEnvironmentType {
  svhcSubstances?: boolean;
  betaRegisteredInBasta?: boolean;
  svaneMarked?: boolean;
  goodEnvironmentalChoiceMarked?: boolean;
  pefcCertified?: boolean;
  fscCertified?: boolean;
  bastaRegistered?: boolean;
  ecoLabeled?: boolean;
  reachRegulated?: boolean;
  standardizedEpd?: boolean;
  euRegulated?: boolean;
  endocrineDisruptingProperties?: boolean;
  moreThanFortyPercentChlorate?: boolean;
  twelvePercentHydrogenPeroxideOrThirtyPercentNitromethaneOrThreePercentNitricAcid?: boolean;
  environmentDeclarationUrl?: string;
}

export type EnvironmentKeys = keyof MgItemEnvironmentType;

export class MgItemEnvironment {
  svhcSubstances?: boolean;
  betaRegisteredInBasta?: boolean;
  svaneMarked?: boolean;
  goodEnvironmentalChoiceMarked?: boolean;
  pefcCertified?: boolean;
  fscCertified?: boolean;
  bastaRegistered?: boolean;
  ecoLabeled?: boolean;
  reachRegulated?: boolean;
  standardizedEpd?: boolean;
  euRegulated?: boolean;
  endocrineDisruptingProperties?: boolean;
  moreThanFortyPercentChlorate?: boolean;
  twelvePercentHydrogenPeroxideOrThirtyPercentNitromethaneOrThreePercentNitricAcid?: boolean;
  environmentDeclarationUrl?: string;

  constructor(
    svhcSubstances?: boolean,
    betaRegisteredInBasta?: boolean,
    svaneMarked?: boolean,
    goodEnvironmentalChoiceMarked?: boolean,
    pefcCertified?: boolean,
    fscCertified?: boolean,
    bastaRegistered?: boolean,
    ecoLabeled?: boolean,
    reachRegulated?: boolean,
    standardizedEpd?: boolean,
    euRegulated?: boolean,
    endocrineDisruptingProperties?: boolean,
    moreThanFortyPercentChlorate?: boolean,
    twelvePercentHydrogenPeroxideOrThirtyPercentNitromethaneOrThreePercentNitricAcid?: boolean,
    environmentDeclarationUrl?: string
  ) {
    this.svhcSubstances = svhcSubstances;
    this.betaRegisteredInBasta = betaRegisteredInBasta;
    this.svaneMarked = svaneMarked;
    this.goodEnvironmentalChoiceMarked = goodEnvironmentalChoiceMarked;
    this.pefcCertified = pefcCertified;
    this.fscCertified = fscCertified;
    this.bastaRegistered = bastaRegistered;
    this.ecoLabeled = ecoLabeled;
    this.reachRegulated = reachRegulated;
    this.standardizedEpd = standardizedEpd;
    this.euRegulated = euRegulated;
    this.endocrineDisruptingProperties = endocrineDisruptingProperties;
    this.moreThanFortyPercentChlorate = moreThanFortyPercentChlorate;
    this.twelvePercentHydrogenPeroxideOrThirtyPercentNitromethaneOrThreePercentNitricAcid =
      twelvePercentHydrogenPeroxideOrThirtyPercentNitromethaneOrThreePercentNitricAcid;
    this.environmentDeclarationUrl = environmentDeclarationUrl;
  }

  static fromDto = (dto: MpeMgSupplierItemEnvironmentDTO): MgItemEnvironment =>
    new MgItemEnvironment(
      dto.svhcSubstances,
      dto.betaRegisteredInBasta,
      dto.svaneMarked,
      dto.goodEnvironmentalChoiceMarked,
      dto.pefcCertified,
      dto.fscCertified,
      dto.bastaRegistered,
      dto.ecoLabeled,
      dto.reachRegulated,
      dto.standardizedEpd,
      dto.euRegulated,
      dto.endocrineDisruptingProperties,
      dto.moreThanFortyPercentChlorate,
      dto.twelvePercentHydrogenPeroxideOrThirtyPercentNitromethaneOrThreePercentNitricAcid,
      dto.environmentDeclarationUrl
    );
}
