import { useFetchBk04ProductGroupsForSupplier } from '@retail/products/data-access';
import { OverGroup, MgSupplierItemSearchResult } from '@retail/products/types';
import { useState } from 'react';
import { useFetchMgItemsForSupplier } from '@retail/calculus/data-access';
import { useDebounce } from '@retail/hooks';

interface Props {
  supplierId: number;
  selectedProductGroup?: boolean;
}

export const useLevelSelect = ({ supplierId, selectedProductGroup }: Props) => {
  const { data: productGroupsDto = [] } = useFetchBk04ProductGroupsForSupplier(
    { suspense: true, enabled: !!selectedProductGroup },
    { supplierId }
  );

  const productGroups = productGroupsDto.map(OverGroup.fromDto);
  const flattenedProductGroups = OverGroup.flatten(productGroups);

  const [mgSupplierItemSearch, setMgSupplierItemSearch] = useState('');
  const debouncedSearch = useDebounce(mgSupplierItemSearch, 400);

  const { data: itemsDto, isLoading: isLoadingFetchItems } = useFetchMgItemsForSupplier({
    supplierId,
    size: 80,
    query: debouncedSearch,
    page: 0,
    suspense: false,
    retry: 0,
  });

  const items = itemsDto?.result || [];
  const mgSupplierItems = items
    ? MgSupplierItemSearchResult.fromSearchResultDTOs(items, [])
    : undefined;

  return {
    flattenedProductGroups,
    mgSupplierItems,
    setMgSupplierItemSearch,
    isLoadingFetchItems,
  };
};
