import { useMemo } from 'react';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { ColumnHeaderWithHelpText, DataGridPagination, StackedTextCell } from '@retail/components';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import { dataGridBoldClass } from '@shared/styles';
import { ItemAgreement } from '@retail/purchase-agreements/types';
import dayjs from 'dayjs';
import { DynamicFeed } from '@mui/icons-material';
import { AgreementStatusIcon } from '@retail/calculus/components';
import { DotMenuIcon } from '@shared/custom-icons';
import { MenuElementProps, Pagination, TriggerElementProps } from '@shared/hooks';
import { ItemAgreementsTableActions } from './ItemAgreementsTableActions';
import { useLevelNavigation } from '@retail/calculus/hooks';
import { Supplier } from '@retail/suppliers/types';
import { Actions } from '@retail/calculus/types';

interface Props {
  itemConditions: ItemAgreement[];
  totalItems: number;
  supplier: Supplier;
  groupNumber?: string;
  onlyOneAgreement: boolean;
  mgItemClicked?: ItemAgreement;
  menuElementProps: MenuElementProps;
  triggerElementProps: TriggerElementProps;
  isInherited: boolean;
  setAction: (action: Actions) => void;
  handleMgItemClicked: (mgItem: ItemAgreement) => void;
}

export const ItemAgreementsForProductGroupTable = ({
  itemConditions,
  totalItems,
  page,
  pageSize,
  supplier,
  onlyOneAgreement,
  mgItemClicked,
  setPage,
  setPageSize,
  menuElementProps,
  triggerElementProps,
  isInherited,
  setAction,
  handleMgItemClicked,
}: Props & Pagination) => {
  const { t } = usePurchaseAgreementsTranslation();

  const { navigateToInheritedCondition } = useLevelNavigation();

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      sortable: false,
      headerName: '',
      headerAlign: 'center',
      align: 'center',
    }),
    []
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'status',
        cellClassName: () => dataGridBoldClass,
        width: 0,
        renderCell: ({ row }) => (
          <AgreementStatusIcon
            bindingType={row.bindingType}
            inherited={row.inherited}
            supplierName={supplier.name}
            productGroup={row.productGroupName}
            navigateToLevel={() =>
              navigateToInheritedCondition({
                bindingType: row.bindingType,
                mgSupplierId: supplier.mgSupplierId,
                productGroupNumber: row.productGroup,
                productGroupName: row.productGroupName,
              })
            }
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'primaryText',
        cellClassName: () => dataGridBoldClass,
        headerName: t('purchaseAgreements.agreement.columns.primaryText'),
        headerAlign: 'left',
        align: 'left',
        width: 280,
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />
        ),
      },
      {
        ...baseColumnProps,
        field: 'multiplePrices',
        cellClassName: () => dataGridBoldClass,
        headerName: '',
        width: 0,
        renderCell: ({ row }) =>
          row.multiplePrices && (
            <Tooltip title={t('purchaseAgreements.priceList.morePrices')}>
              <DynamicFeed fontSize="small" />
            </Tooltip>
          ),
      },
      {
        ...baseColumnProps,
        field: 'mgItemId',
        width: 170,
        headerName: `
        ${t('purchaseAgreements.agreement.columns.mgItemNr')} /
        ${t('purchaseAgreements.agreement.columns.finfoNr')}`,
        renderCell: ({ row }) => (
          <StackedTextCell topLine={row.mgItemNumber} bottomLine={row.finfoNumber} />
        ),
      },
      {
        ...baseColumnProps,
        field: 'agreementId',
        width: 150,
        headerName: t('purchaseAgreements.agreement.columns.agreementId'),
      },
      {
        ...baseColumnProps,
        field: 'agreementText',
        width: 150,
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
      },
      {
        ...baseColumnProps,
        field: 'price',
        width: 150,
        headerName: t('purchaseAgreements.agreement.columns.price'),
        renderCell: ({ row: { price, priceUnit } }) =>
          t('purchaseAgreements.pricePerUnit', {
            price: price.toFixed(2),
            unit: priceUnit,
          }),
      },
      {
        ...baseColumnProps,
        field: 'priceUnit',
        renderHeader: () => (
          <ColumnHeaderWithHelpText
            header={t('purchaseAgreements.agreement.columns.basePriceUnit')}
            tooltipContent={t('purchaseAgreements.agreement.columns.basePriceUnitDesc')}
          />
        ),
        width: 120,
        renderCell: ({ row }) => row.gtinPriceUnit ?? row.priceUnit ?? '-',
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        width: 110,
        headerName: t('purchaseAgreements.agreement.columns.fromDate'),
        renderCell: ({ value }) => dayjs(value).format('ll'),
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        width: 110,
        headerName: t('purchaseAgreements.agreement.columns.toDate'),
        renderCell: ({ value }) => {
          const date = dayjs(value);
          //Agreements that has no end date is set to year 9999
          return date.year() === 9999 ? '->' : date.format('ll');
        },
      },

      {
        ...baseColumnProps,
        field: 'actions',
        width: 60,
        renderCell: ({ row }) => (
          <IconButton
            {...triggerElementProps}
            onClick={(event) => {
              triggerElementProps.onClick(event);
              handleMgItemClicked(row);
            }}
          >
            <DotMenuIcon fontSize="small" />
          </IconButton>
        ),
      },
    ],
    [
      baseColumnProps,
      t,
      supplier.name,
      supplier.mgSupplierId,
      navigateToInheritedCondition,
      triggerElementProps,
      handleMgItemClicked,
    ]
  );

  return (
    <Box display="flex" flexGrow={1}>
      <DataGridPro
        getRowId={(row) => row.mgItemNumber}
        columns={columns}
        rows={itemConditions}
        rowHeight={65}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        pagination={true}
        paginationMode="server"
        components={{
          Pagination: DataGridPagination,
        }}
        rowCount={totalItems}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
      />

      <ItemAgreementsTableActions
        {...menuElementProps}
        onlyOneAgreement={onlyOneAgreement}
        isInherited={isInherited}
        setAction={setAction}
        isActivated={Boolean(mgItemClicked && !mgItemClicked.inherited)}
      />
    </Box>
  );
};
