import { ErrorOutlineRounded } from '@mui/icons-material';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormLabel,
  Stack,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { CreateItemLevelMarkupSchema } from '@retail/markup/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ContainedButton, Dialog, TextButton, TextInputField } from '@shared/components';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  mgItem: string;
  open: boolean;
  isLoadingCreate: boolean;
  onClose: () => void;
  resetMarkupValues: () => void;
  handleCreateCondition: () => void;
}

function CreateConditionDialog({
  mgItem,
  open,
  isLoadingCreate,
  onClose,
  resetMarkupValues,
  handleCreateCondition,
}: Props) {
  const t = useMyAssortmentTFunction();
  const { control, handleSubmit } = useFormContext<CreateItemLevelMarkupSchema>();

  const fromDateController = useController({ name: 'fromDate', control });
  const toDateController = useController({ name: 'toDate', control });
  const nameController = useController({ name: 'name', control });

  useEffect(() => {
    if (nameController.field.value === '') {
      nameController.field.onChange(mgItem);
    }
  }, [mgItem, nameController.field]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('myAssortment.createMarkupFor', { name: mgItem })}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <TextInputField
            {...nameController.field}
            required
            fullWidth
            labelWidth={142}
            labelDirection="row"
            margin="none"
            label={t('myAssortment.productCard.markupName')}
            size="small"
            onChange={(e) => {
              const value = e.target.value;
              nameController.field.onChange(value);
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" alignItems="center">
              <FormLabel error={!!fromDateController.fieldState.error} sx={{ width: 150 }} required>
                {t('myAssortment.productCard.fromDate')}
              </FormLabel>

              <Stack gap={1}>
                <DatePicker
                  {...fromDateController.field}
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                />
                {!!fromDateController.fieldState.error && (
                  <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                    <ErrorOutlineRounded color="warning" fontSize="small" />
                    <FormHelperText sx={{ color: ({ palette }) => palette.warning.main }}>
                      {fromDateController.fieldState.error.message}
                    </FormHelperText>
                  </Stack>
                )}
              </Stack>
            </Box>

            <Box display="flex" alignItems="center">
              <FormLabel sx={{ width: 150 }}>{t('myAssortment.productCard.toDate')}</FormLabel>
              <DatePicker
                {...toDateController.field}
                minDate={new Date()}
                format="dd/MM/yyyy"
                slotProps={{ field: { clearable: true } }}
              />
            </Box>
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" gap={1} flexWrap="nowrap">
          <TextButton onClick={resetMarkupValues}>{t('myAssortment.cancel')}</TextButton>
          <ContainedButton
            disabled={isLoadingCreate}
            onClick={handleSubmit(handleCreateCondition)}
            color="secondary"
            size="small"
          >
            {t('myAssortment.createMarkup')}
          </ContainedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(CreateConditionDialog);
