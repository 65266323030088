import { GroupTypes } from '../../GroupTypes';
import { ProductGroupDTO } from '../../dto';

export interface ProductGroupProps {
  id: number;
  type: GroupTypes;
  groupNumber: string;
  name: string;
  active?: boolean;
  numberOfItems?: number;
}

export class ProductGroup {
  id: number;
  type: GroupTypes;
  groupNumber: string;
  name: string;
  active?: boolean;
  numberOfItems?: number;

  constructor({ id, type, groupNumber, name, active, numberOfItems }: ProductGroupProps) {
    this.id = id;
    this.type = type;
    this.groupNumber = groupNumber;
    this.name = name;
    this.active = active;
    this.numberOfItems = numberOfItems;
  }

  getLabel = (): string => (this.name ? `${this.groupNumber} ${this.name}` : this.groupNumber);

  static fromDto = (dto: ProductGroupDTO): ProductGroup =>
    new ProductGroup({
      id: dto.id,
      type: dto.type,
      groupNumber: dto.groupNumber,
      name: dto.name,
      active: dto.active,
      numberOfItems: dto.numberOfItems,
    });
}
