import { SvgIconProps } from '@mui/material';

const ImageIconSmall = (props: SvgIconProps) => {
  const { color, ...remainingProps } = props;

  return (
    <svg
      {...remainingProps}
      width="45"
      height="34"
      viewBox="0 0 45 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.7812 33.75H4.21875C1.88965 33.75 0 31.8604 0 29.5312V4.21875C0 1.88965 1.88965 0 4.21875 0H40.7812C43.1104 0 45 1.88965 45 4.21875V29.5312C45 31.8604 43.1104 33.75 40.7812 33.75ZM9.84375 4.92188C7.12793 4.92188 4.92188 7.12793 4.92188 9.84375C4.92188 12.5596 7.12793 14.7656 9.84375 14.7656C12.5596 14.7656 14.7656 12.5596 14.7656 9.84375C14.7656 7.12793 12.5596 4.92188 9.84375 4.92188ZM5.625 28.125H39.375V18.2812L31.6846 10.5908C31.2715 10.1777 30.6035 10.1777 30.1904 10.5908L18.2812 22.5L13.4033 17.6221C12.9902 17.209 12.3223 17.209 11.9092 17.6221L5.625 23.9062V28.125Z"
        fill="#BFBFC8"
      />
    </svg>
  );
};

export default ImageIconSmall;
