import { Stack } from '@mui/material';
import { FreightConditionFormBase } from '@retail/freight/components';

export const EditFreightConditionForm = () => {
  return (
    <Stack>
      <FreightConditionFormBase />
    </Stack>
  );
};
