import { StarBorder } from '@mui/icons-material';
import { alpha, Link, Stack, Tab, Tabs, Tooltip, useTheme } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { Priority } from '@retail/my-assortment/types';
import { SupplierWithPriority } from '@retail/suppliers/types';
import { TabsClass } from '@shared/styles';
import { useMemo } from 'react';
import { moveToStart } from '@retail/utils';

interface Props {
  suppliers: SupplierWithPriority[];
  clickedSupplier: number;
  selectedSupplier: number;
  selectSupplier: (supplierId: number) => void;
  openPrioritizeDialog: () => void;
}

const className = TabsClass.underlined;

export function SupplierTabs({
  suppliers,
  clickedSupplier,
  selectedSupplier,
  selectSupplier,
  openPrioritizeDialog,
}: Props) {
  const { palette } = useTheme();
  const t = useMyAssortmentTFunction();

  const tabs = useMemo(() => {
    const sortedSuppliersByPriority = suppliers
      .filter(({ priority }) => !!priority)
      .sort(SupplierWithPriority.sortByPriority);

    const sortedWithSelectedFirst = moveToStart(
      sortedSuppliersByPriority,
      ({ id }) => id === clickedSupplier
    );

    return sortedWithSelectedFirst.map(({ name, id, priority }) => ({
      label:
        priority === Priority.TEN ? (
          <Stack alignItems="baseline" flexDirection="row">
            {name}
            <Tooltip title={t('myAssortment.priority.mainPrio')} placement="top" arrow>
              <StarBorder color="success" fontSize="small" />
            </Tooltip>
          </Stack>
        ) : (
          name
        ),
      value: id,
    }));
  }, [clickedSupplier, suppliers, t]);

  const unprioritizedTab = suppliers.filter((supplier) => !supplier.priority) || [];

  const selectTab = (_: any, newTab: number) => selectSupplier(newTab);

  return (
    <Stack
      borderTop={`1px solid ${palette.divider}`}
      borderBottom={`1px solid ${palette.divider}`}
      bgcolor={alpha(palette.grey.A100, 0.4)}
      py={2}
    >
      <Link
        sx={{ pb: 1, pr: 2 }}
        alignSelf="flex-end"
        component="button"
        onClick={openPrioritizeDialog}
      >
        {t('myAssortment.prioritize')}
      </Link>
      <Tabs
        value={selectedSupplier}
        variant="scrollable"
        scrollButtons={false}
        sx={{ pl: 0 }}
        onChange={selectTab}
        className={className}
      >
        {tabs.map(({ label, value }) => (
          <Tab
            key={value}
            sx={{ background: 'inherit' }}
            label={label}
            value={value}
            className={className}
          />
        ))}
        {unprioritizedTab.map(({ id, name }) => (
          <Tab
            key={id}
            sx={{ background: 'inherit' }}
            label={name}
            value={id}
            className={className}
            disabled
          />
        ))}
      </Tabs>
    </Stack>
  );
}
