import { SvgIcon } from '@mui/material';

interface Props {
  color?: string;
  fontSize?: 'small' | 'inherit' | 'large' | 'medium';
}

export const CheckIcon = ({ color, fontSize }: Props) => {
  return (
    <SvgIcon fontSize={fontSize || 'small'}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z"
          fill="inherit"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C12.3582 2 14.478 3.02032 15.9422 4.64358L9 11.5858L6.20711 8.79289C5.81658 8.40237 5.18342 8.40237 4.79289 8.79289C4.40237 9.18342 4.40237 9.81658 4.79289 10.2071L7.58579 13C8.36684 13.7811 9.63317 13.781 10.4142 13L17.1015 6.31276C17.6755 7.41616 18 8.67019 18 10Z"
          fill={color || '#83BF6E'}
        />
      </svg>
    </SvgIcon>
  );
};
