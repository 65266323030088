import { useCallback, useEffect, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import {
  Navigation,
  NavRoute,
  NavWithSubRoutes,
  Sidebar,
  SidebarHeader,
  SidebarUserMenu,
} from '@shared/components';
import { useSidebarDisclosure } from '@shared/stores';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useAppTFunction } from '@retail/app/i18n';
import {
  isSuperUserAtom,
  useFetchMe,
  useIsSuperUser,
  useMpeAuthContext,
} from '@retail/auth/data-access';
import {
  BriefcaseIcon,
  CalculatorIcon,
  ShoppingCartIcon,
  StorageUnitIcon,
} from '@shared/custom-icons';
import { useSharedTranslation } from '@shared/i18n';
import { useSetRecoilState } from 'recoil';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import dayjs from 'dayjs';

type Route = NavRoute | NavWithSubRoutes;

export function AppSidebar() {
  const t = useAppTFunction();
  const { t: sharedT } = useSharedTranslation();
  const { version, buildTime } = useMgPrisConfig();

  const {
    isOpen: isSidebarOpen,
    onToggle: toggleSidebar,
    onOpen: openSidebar,
  } = useSidebarDisclosure();

  const { data: user } = useFetchMe({ refetchOnWindowFocus: false });
  const userName = useMemo(() => `${user?.givenName} ${user?.familyName}`, [user]);

  const isSuperUser = useIsSuperUser();
  const setIsSuperUser = useSetRecoilState(isSuperUserAtom);

  //const { selectedContext } = useMembers();

  const { logout } = useMpeAuthContext();
  const logoutDefault = useCallback(() => logout(), [logout]);

  //const productsRouteMatch = useMatch(`${mgPrisRoutes.marketInsight.fullRoutePath}/*`);
  const itemsRouteMatch = useMatch(`${mgPrisRoutes.items.fullRoutePath}/*`);
  const myAssortmentRouteMatch = useMatch(`${mgPrisRoutes.myAssortment.fullRoutePath}/*`);
  const suppliersRouteMatch = useMatch(`${mgPrisRoutes.suppliers.fullRoutePath}/*`);
  //const userRouteMatch = useMatch(`${mgPrisRoutes.users.fullRoutePath}/*`);
  const pricesRouteMatch = useMatch(`${mgPrisRoutes.calculus.fullRoutePath}/*`);

  const navRoutes: Route[] = useMemo(() => {
    const routes: Route[] = [
      {
        title: t('app.routes.myAssortment'),
        to: mgPrisRoutes.myAssortment.getFullLinkPath(),
        Icon: StorageUnitIcon,
        isActive: !!myAssortmentRouteMatch,
      },
      {
        title: t('app.routes.calculus'),
        Icon: CalculatorIcon,
        to: mgPrisRoutes.calculus.getFullLinkPath(),
        isActive: !!pricesRouteMatch,
      },
      {
        title: t('app.routes.products'),
        to: mgPrisRoutes.items.getFullLinkPath(),
        Icon: ShoppingCartIcon,
        isActive: !!itemsRouteMatch,
      },
      /*
      {
        title: t('app.routes.marketInsight'),
        to: mgPrisRoutes.marketInsight.getFullLinkPath(),
        Icon: MarketIcon,
        isActive: !!productsRouteMatch,
      },
      */
      {
        title: t('app.routes.suppliers'),
        to: mgPrisRoutes.suppliers.getFullLinkPath(),
        Icon: BriefcaseIcon,
        isActive: !!suppliersRouteMatch,
      },
    ];
    /*
    if (selectedContext.isAdmin) {
      routes.push({
        title: t('app.routes.users'),
        Icon: UsersIcon,
        to: `${mgPrisRoutes.users.getFullLinkPath()}`,
        isActive: !!userRouteMatch,
      });
    }
*/

    return routes;
  }, [t, itemsRouteMatch, myAssortmentRouteMatch, pricesRouteMatch, suppliersRouteMatch]);

  useEffect(() => {
    setIsSuperUser(isSuperUser);
  }, [isSuperUser, setIsSuperUser]);

  const { buildDate, buildTimeFormatted } = useMemo(
    () => ({
      buildDate: buildTime && dayjs.unix(buildTime).format('l'),
      buildTimeFormatted: buildTime && dayjs.unix(buildTime).format('hh:mm:ss'),
    }),
    [buildTime]
  );

  return (
    <Sidebar isOpen={isSidebarOpen} toggleOpen={toggleSidebar}>
      <SidebarHeader title={sharedT('shared.navigation')} expandedView={isSidebarOpen} />
      <Navigation navRoutes={navRoutes} expandedView={isSidebarOpen} expandView={openSidebar} />

      <Box marginTop="auto">
        <Stack px={2} alignContent="center">
          <Typography variant="smallBold">{sharedT('shared.version')}</Typography>
          <Typography variant="body2">{version.slice(-6)}</Typography>
          {!!buildDate && <Typography variant="body2">{buildDate}</Typography>}
          {!!buildTimeFormatted && <Typography variant="body2">{buildTimeFormatted}</Typography>}
        </Stack>
        <Box display="flex" justifyContent="center" p={3}>
          <SidebarUserMenu
            userName={userName}
            logOut={logoutDefault}
            expandedView={isSidebarOpen}
          />
        </Box>
      </Box>
    </Sidebar>
  );
}
