import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { ContainedButton, Dialog, OutlinedButton } from '@shared/components';
import { useCallback } from 'react';
import {
  useMutateCreateOrEditMarkupCondition,
  useMutateDeleteMarkupTemplate,
} from '@retail/markup/data-access';
import { useQueryClient } from '@tanstack/react-query';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import dayjs from 'dayjs';
import { HierarchyLevels, MarkupCondition } from '@retail/calculus/types';
import { useToast } from '@retail/hooks';
import { DeleteDialog } from '@retail/components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activeMarkupCondition: MarkupCondition;
}

interface MarkupDeactivateCondition {
  validFrom?: string;
  mgItemNumber?: number;
  groupNumber?: string;
  markup: null;
}

// Delete templates and deactivate templates
export function DeleteOrDeactivateMarkupDialog({ activeMarkupCondition, isOpen, onClose }: Props) {
  const t = useMarkupTFunction();

  const toast = useToast();
  const queryClient = useQueryClient();

  const isCondition = !!activeMarkupCondition.id;

  const { departmentId, draftAssortmentId, baseAssortmentId } = useDepartmentAssortments();

  const { mutateAsync: deleteMarkupTemplate } = useMutateDeleteMarkupTemplate(departmentId);
  const { mutateAsync: deleteMarkupCondition } = useMutateCreateOrEditMarkupCondition(
    draftAssortmentId,
    departmentId
  );

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['markup-templates'] });
    queryClient.invalidateQueries({ queryKey: ['markup-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });

    toast.success(t('markup.actions.deletedTemplate'));
    onClose();
  }, [onClose, queryClient, t, toast]);

  const onError = useCallback(
    (err: any) => {
      console.error(err);
      toast.error(t('markup.error.generic'));
    },
    [t, toast]
  );

  const handleDeleteTemplate = useCallback(() => {
    deleteMarkupTemplate(activeMarkupCondition.markupTemplateId)
      .catch((err) => {
        onError(err);
      })
      .then(() => {
        onSuccess();
      });
  }, [deleteMarkupTemplate, activeMarkupCondition, onError, onSuccess]);

  const handleDeactivateCondition = async () => {
    if (activeMarkupCondition) {
      const condition: MarkupDeactivateCondition = {
        validFrom: dayjs(new Date()).format('YYYY-MM-DD'),
        markup: null,
      };

      if (activeMarkupCondition.bindingType === HierarchyLevels.item) {
        condition.mgItemNumber = activeMarkupCondition.mgItemNumber;
      } else if (
        activeMarkupCondition.bindingType === HierarchyLevels.mainGroup ||
        activeMarkupCondition.bindingType === HierarchyLevels.overGroup ||
        activeMarkupCondition.bindingType === HierarchyLevels.productGroup
      ) {
        condition.groupNumber = activeMarkupCondition.productGroup;
      }

      let mgSupplierConditions = null;
      let productGroupConditions = null;
      let mgSupplierItemConditions = null;

      if (condition?.mgItemNumber) mgSupplierItemConditions = [condition];
      else if (condition?.groupNumber) productGroupConditions = [condition];
      else mgSupplierConditions = [condition];

      await deleteMarkupCondition({
        body: {
          mgSupplierId: activeMarkupCondition.mgSupplierId,
          mgSupplierConditions,
          productGroupConditions,
          mgSupplierItemConditions,
        },
      })
        .catch((err) => {
          onError(err);
        })
        .then(() => {
          onSuccess();
          queryClient.invalidateQueries({ queryKey: ['markup-condition', baseAssortmentId] });
        });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {isCondition
          ? t('markup.actions.deactivateDialogTitle', {
              markupName: activeMarkupCondition.markupTemplateName,
            })
          : t('markup.actions.deleteDialogTitle', {
              markupName: activeMarkupCondition.markupTemplateName,
            })}
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6" fontWeight="medium">
          {isCondition
            ? t('markup.actions.confirmDeactivate')
            : t('markup.actions.confirmDeletion')}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', alignSelf: 'flex-end' }}>
        <OutlinedButton size="small" onClick={onClose}>
          {t('markup.actions.cancel')}
        </OutlinedButton>

        <ContainedButton
          color="warning"
          size="small"
          sx={{ alignSelf: 'center' }}
          onClick={() => {
            isCondition ? handleDeactivateCondition() : handleDeleteTemplate();
          }}
        >
          {t(isCondition ? 'markup.actions.deactivate' : 'markup.actions.delete')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
