import { PropsWithChildren } from 'react';
import { NotAuthorizedPage } from '../NotAuthorized';
import { useMyOrgUnits } from '@retail/app/stores/selected-context';

export const OrgUnitAccessChecker: React.FC<PropsWithChildren> = ({ children }) => {
  const { departments, members, brand } = useMyOrgUnits();

  if (!departments.length && !members.length && !brand) {
    return <NotAuthorizedPage reason="noOrgUnits" />;
  }

  return children;
};
