import { AffectedItemsDialog } from '@retail/calculus/components';
import { useAffectedItems } from '@retail/calculus/context';
import { CalculusAreas } from '@retail/calculus/types';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useDisclosure } from '@shared/hooks';

interface Props {
  open: boolean;
  onClose: () => void;
  mgSupplierId: number;
  conditionType: CalculusAreas;
  agreementId?: number;
  productGroup?: string;
  useDraft?: boolean;
}

function AffectedItemsContainer({
  open,
  onClose,
  mgSupplierId,
  conditionType,
  agreementId,
  productGroup,
  useDraft,
}: Props) {
  const { baseAssortmentId, draftAssortmentId } = useDepartmentAssortments();
  const { isOpen: onlyItemsInAssortment, onToggle: toggleOnlyItemsInAssortment } =
    useDisclosure(true);

  const assortmentId = useDraft ? draftAssortmentId : baseAssortmentId;
  const { result, setPage, setPageSize } = useAffectedItems({
    assortmentId,
    mgSupplierId,
    onlyBaseAssortment: onlyItemsInAssortment,
    agreementId,
    productGroup,
    conditionType,
    initPageSize: 200,
  });

  return (
    <AffectedItemsDialog
      open={open}
      onClose={onClose}
      items={result}
      onlyItemsInAssortment={onlyItemsInAssortment}
      toggleOnlyItemsInAssortment={toggleOnlyItemsInAssortment}
      updatePage={setPage}
      updatePageSize={setPageSize}
    />
  );
}
export default withDialogSuspenseErrorBoundary(AffectedItemsContainer);
