import { useMemo } from 'react';
import { Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { OutlinedButton } from '@shared/components';
import { usePopupElement } from '@shared/hooks';
import { brandLogo } from '@retail/utils';
import { Image } from '../Image';

export interface BrandSelectOption {
  name: string;
  key: string;
  logoSrc?: string;
}

interface Props {
  brands: BrandSelectOption[];
  selectedBrandKey: string;
  onBrandSelect: (brandKey: string) => void;
}

export function BrandSelect({ brands, selectedBrandKey, onBrandSelect }: Props) {
  const { palette } = useTheme();
  const selectedBrand = useMemo(
    () => brands.find((b) => b.key === selectedBrandKey) as BrandSelectOption,
    [brands, selectedBrandKey]
  );

  const { menuElementProps, triggerElementProps } = usePopupElement();
  const enableMenu = useMemo(() => brands.length > 1, [brands]);

  return (
    <>
      <OutlinedButton
        sx={{
          cursor: enableMenu ? 'pointer' : 'default',
          width: 200,
          height: 45,
          '&:hover': { background: palette.common.white },
        }}
        size="small"
        endIcon={
          enableMenu && (
            <ChevronLeft
              sx={{
                transform: menuElementProps.open ? 'rotate(90deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
                color: palette.common.black,
              }}
            />
          )
        }
        {...triggerElementProps}
      >
        <Stack direction="row" alignItems="center" whiteSpace="nowrap" gap={2}>
          <Image src={brandLogo(selectedBrand.key).logo} alt={selectedBrand.name} />
          <Typography variant="body1" fontWeight="500" color={palette.text.primary}>
            {selectedBrand.name}
          </Typography>
        </Stack>
      </OutlinedButton>

      {enableMenu && (
        <Menu
          {...menuElementProps}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {brands.map((brand) => (
            <MenuItem
              key={brand.key}
              value={brand.key}
              onClick={() => {
                onBrandSelect(brand.key);
              }}
              selected={brand.key === selectedBrand.key}
              sx={{ justifyContent: 'start' }}
            >
              <Image src={brandLogo(brand.key).logo} alt={brand.name} height={25} />
              {brand.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}
