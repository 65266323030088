import { forwardRef } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const QuestionIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99935 16.667C13.6812 16.667 16.666 13.6822 16.666 10.0003C16.666 6.31843 13.6812 3.33366 9.99935 3.33366C6.31745 3.33366 3.33268 6.31843 3.33268 10.0003C3.33268 13.6822 6.31745 16.667 9.99935 16.667ZM9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.27747 7.91695C9.04701 8.31533 8.53724 8.45147 8.13886 8.22101C7.74047 7.99056 7.60434 7.48079 7.83479 7.0824C8.26579 6.33733 9.07342 5.83301 9.99982 5.83301C11.3805 5.83301 12.4998 6.9523 12.4998 8.33301C12.4998 9.24778 11.9387 9.99466 11.2498 10.4163C10.6965 10.7551 11.0415 11.6663 9.99764 11.6663C9.5374 11.6663 9.16431 11.2932 9.16431 10.833C9.16431 10.8038 9.16581 10.7748 9.16876 10.7464C9.19187 10.3056 9.38839 9.94704 9.60236 9.68629C9.82833 9.4109 10.1254 9.1861 10.3526 9.05105C11.6165 8.29967 10.075 6.76251 9.27747 7.91695ZM9.99764 12.4997C9.5374 12.4997 9.16431 12.8728 9.16431 13.333C9.16431 13.7932 9.5374 14.1663 9.99764 14.1663C10.4579 14.1663 10.831 13.7932 10.831 13.333C10.831 12.8728 10.4579 12.4997 9.99764 12.4997Z"
      fill="currentColor"
    />
  </SvgIcon>
));

export default QuestionIcon;
