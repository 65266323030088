import { Link } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  handleClick: () => void;
  children: ReactNode;
  isBold: boolean;
  hasMarginRight?: boolean;
}

export const TextButton = ({ handleClick, children, isBold, hasMarginRight }: Props) => {
  return (
    <Link
      onClick={handleClick}
      component="button"
      sx={{
        textDecoration: 'none',
        fontWeight: isBold ? 'bold' : 'none',
        color: (theme) => (isBold ? undefined : theme.palette.text.secondary),
        '&:hover': {
          fontWeight: 'bold',
        },
        marginRight: (theme) => (hasMarginRight ? theme.spacing(1) : undefined),
      }}
    >
      {children}
    </Link>
  );
};
