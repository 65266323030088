import { useNavigate } from 'react-router-dom';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useFetchBrands } from '@retail/auth/data-access';
import { Brand } from '@retail/auth/types';
import { brandLogo, Logos } from '@retail/utils';

export const selectedBrandKey = 'selectedBrandKey';

const setInitialBrand = (brands: Brand[]) => {
  if (!brands.length)
    throw new Error('Expected at least one brand. Should not reach this point without a brand.');

  //const storedBrandKey: string = localStorage.getItem(selectedBrandKey) || '';
  //const selectedBrand = brands.find(({ brandId }) => brandId === storedBrandKey);

  return brands.find((brand) => brand.brandId === 'bolist')!;
};

type BrandsState = {
  availableBrands: Brand[];
  selectedBrand: Brand;
  selectedBrandLogos: Logos;
  switchBrand: (brand: Brand) => void;
};

const context = createContext<BrandsState>({} as BrandsState);

const BrandsProvider = (props: { children: ReactNode }) => {
  const navigate = useNavigate();

  const { data: availableBrands = [] } = useFetchBrands();

  const [selectedBrand] = useState<Brand>(() => setInitialBrand(availableBrands));

  const selectedBrandLogos = brandLogo(selectedBrand.brandId);

  const switchBrand = useCallback(
    (brand: Brand) => {
      localStorage.setItem(selectedBrandKey, JSON.stringify(brand.brandId));

      navigate(mgPrisRoutes.root.fullRoutePath);
      window.location.reload();
    },
    [navigate]
  );

  return (
    <context.Provider
      value={{
        availableBrands,
        selectedBrand,
        selectedBrandLogos,
        switchBrand,
      }}
    >
      {props.children}
    </context.Provider>
  );
};

const useUserBrands = () => {
  return useContext(context);
};

export { BrandsProvider, useUserBrands };
