import { SvgIcon, SvgIconProps } from '@mui/material';

export default function InheritedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="20"
        viewBox="0 0 25 20"
        fill="none"
      >
        <g clipPath="url(#clip0_8669_16174)">
          <path
            d="M5 13.75H1.25C0.558594 13.75 0 14.3086 0 15V18.75C0 19.4414 0.558594 20 1.25 20H5C5.69141 20 6.25 19.4414 6.25 18.75V15C6.25 14.3086 5.69141 13.75 5 13.75ZM4.0625 10.625H11.5625V12.5H13.4375V10.625H20.9375V12.5H22.8125V10.25C22.8125 9.42188 22.1406 8.75 21.3125 8.75H13.4375V6.25H15C15.6914 6.25 16.25 5.69141 16.25 5V1.25C16.25 0.558594 15.6914 0 15 0H10C9.30859 0 8.75 0.558594 8.75 1.25V5C8.75 5.69141 9.30859 6.25 10 6.25H11.5625V8.75H3.6875C2.85938 8.75 2.1875 9.42188 2.1875 10.25V12.5H4.0625V10.625ZM14.375 13.75H10.625C9.93359 13.75 9.375 14.3086 9.375 15V18.75C9.375 19.4414 9.93359 20 10.625 20H14.375C15.0664 20 15.625 19.4414 15.625 18.75V15C15.625 14.3086 15.0664 13.75 14.375 13.75ZM23.75 13.75H20C19.3086 13.75 18.75 14.3086 18.75 15V18.75C18.75 19.4414 19.3086 20 20 20H23.75C24.4414 20 25 19.4414 25 18.75V15C25 14.3086 24.4414 13.75 23.75 13.75Z"
            fill="#29C538"
          />
        </g>
        <defs>
          <clipPath id="clip0_8669_16174">
            <rect width="25" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
