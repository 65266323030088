import { Stack } from '@mui/material';
import { MgItem } from '@retail/products/types';
import { DocumentationTable } from './DocumentationTable';

interface Props {
  mgItem: MgItem;
}

export const Documentation = ({ mgItem }: Props) => {
  return (
    <Stack>
      {/* <DownloadDocumentation mgItem={mgItem} isEnvironmental /> */}
      <DocumentationTable mgItem={mgItem} />
    </Stack>
  );
};
