import { Box, IconButton as MuiIconButton, Stack, styled } from '@mui/material';
import { IndicatorButtons } from './IndicatorButtons';
import { PreviousButton } from './PreviousButton';
import { NextButton } from './NextButton';
import { CarouselPreviewProps } from './CarouselPreview';
import { NoImageElement } from './NoImageElement';
import { motion, AnimatePresence } from 'framer-motion';
import { EmptyCircularFallbackImage, Image } from '@retail/components';

export interface CarouselItem {
  url: string;
  description: string;
}

interface Props
  extends Pick<
    CarouselPreviewProps,
    'activeSlide' | 'items' | 'handlePreviousClick' | 'handleNextClick'
  > {
  height?: number;
  maxWidth?: number;
}

export function MgCarousel({
  activeSlide,
  handlePreviousClick,
  handleNextClick,
  items,
  height = 295,
  maxWidth = 600,
}: Props) {
  const hasItems = items?.length > 0;
  const imageHeight = items?.length > 0 ? height : 200;

  return (
    <Stack display="flex" height="calc(100% - 40px)" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          position: 'relative',
          maxWidth: maxWidth,
          height: imageHeight,
          maxHeight: imageHeight,
          padding: 0,
          margin: 0,
          overflowX: 'hidden',
        }}
      >
        {hasItems ? (
          <AnimatePresence custom={activeSlide}>
            <motion.div
              key={items[activeSlide].url}
              animate={{ baseFrequency: 0.5 } as any}
              transition={{ type: 'spring', duration: 1.2 }}
            >
              <Image
                alt={items[activeSlide].description}
                height={imageHeight}
                sx={{
                  height: imageHeight,
                  maxHeight: imageHeight,
                  maxWidth: '100%',
                  objectFit: 'contain',
                  display: 'block',
                }}
                src={items[activeSlide].url}
                fallback={() => <EmptyCircularFallbackImage size="25px" />}
              />
            </motion.div>
          </AnimatePresence>
        ) : (
          <NoImageElement isPreview={false} />
        )}

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            width: '100%',
            transform: 'translateY(-50%)',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 10px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              transform: 'translateY(-50%)',
            }}
          >
            <PreviousButton
              activeSlide={activeSlide}
              setActiveSlide={handlePreviousClick}
              disabled={!hasItems}
            />
          </Box>

          <Box
            sx={{
              position: 'absolute',
              right: 0,
              transform: 'translateY(-50%)',
            }}
          >
            <NextButton
              items={items}
              activeSlide={activeSlide}
              setActiveSlide={handleNextClick}
              disabled={!hasItems}
            />
          </Box>
        </Box>
      </Box>

      <Box mb={1}>
        <IndicatorButtons
          items={items}
          activeSlide={activeSlide}
          setActiveSlide={handlePreviousClick}
        />
      </Box>
    </Stack>
  );
}

export const IconButton = styled(MuiIconButton)(() => ({
  '&:disabled': {
    opacity: 0.25,
  },
  color: 'inherit',
  fontSize: '3rem',
}));
