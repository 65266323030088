import { Stack, Tooltip, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { SalesPrice } from '@retail/my-assortment/types';
import { CreateItemLevelMarkupSchema } from '@retail/markup/components';
import { useController, useFormContext } from 'react-hook-form';
import { EditableField } from './EditableField';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { calculateNewDG, calculateNewSalesFactor } from '@retail/utils';
import { EditIconButton } from '@retail/components';
import { HelperText } from '@shared/components';
import { EditedMarkupField } from './ProductCard';

interface Props {
  prices?: SalesPrice;
  packageUnit: string;
  editSalesPrice: boolean;
  costPrice?: number;
  isPrimaryPackage?: boolean;
  editedMarkupField: EditedMarkupField;
  setEditedMarkupField: Dispatch<SetStateAction<EditedMarkupField>>;
  toggleEditSalesPrice: () => void;
  resetMarkupValues: () => void;
}

export function SalesPriceView({
  prices,
  packageUnit,
  editSalesPrice,
  costPrice,
  isPrimaryPackage,
  editedMarkupField,
  setEditedMarkupField,
  toggleEditSalesPrice,
  resetMarkupValues,
}: Props) {
  const t = useMyAssortmentTFunction();

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateItemLevelMarkupSchema>();
  const salesFactorController = useController({ name: 'salesFactor', control });
  const salesPriceController = useController({ name: 'salesPrice', control });
  const salesCoverageController = useController({ name: 'salesCoverage', control });

  const onChangeSalesPrice = useCallback(
    (value: string) => {
      salesPriceController.field.onChange(value);
      setEditedMarkupField('price');
      costPrice &&
        salesCoverageController.field.onChange(calculateNewDG(costPrice, Number(value)).toFixed(2));
      costPrice &&
          salesFactorController.field.onChange(
            calculateNewSalesFactor(costPrice, Number(value)).toFixed(2)
          );
    },
    [
      salesPriceController.field,
      setEditedMarkupField,
      costPrice,
      salesCoverageController.field,
      salesFactorController.field,
    ]
  );

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography fontWeight={600}>
        {t('myAssortment.salesPricePer', { unit: packageUnit })}
      </Typography>
      <Stack alignItems="flex-end">
        {prices?.salesPrice && isPrimaryPackage ? (
          <EditableField
            editMode={editSalesPrice}
            chipLabel={prices.salesPrice}
            controller={salesPriceController}
            approximatelyEqual={editedMarkupField !== 'price'}
            valueType="price"
            resetMarkupValues={resetMarkupValues}
            toggleEditMode={toggleEditSalesPrice}
            onSumbitValue={onChangeSalesPrice}
          />
        ) : (
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Typography variant="body2" fontWeight={600}>
              {t('myAssortment.recommendedPrices.value', { value: prices?.salesPrice ?? '-' })}
            </Typography>
            {prices?.salesPrice ? (
              <Tooltip
                title={t('myAssortment.productCard.editOnlyBasePrice')}
                placement="top"
                arrow
              >
                <div>
                  <EditIconButton />
                </div>
              </Tooltip>
            ) : (
              <EditIconButton />
            )}
          </Stack>
        )}
        {errors.salesPrice?.message && (
          <HelperText id="salesCoverage" status="error">
            {errors.salesPrice?.message}
          </HelperText>
        )}
      </Stack>
    </Stack>
  );
}
