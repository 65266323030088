import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ContainedButton, Dialog } from '@shared/components';
import { FreightCondition } from '@retail/calculus/types';
import { useFreightTFunction } from '@retail/freight/i18n';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  conditionToDelete: FreightCondition;
}

export function DeleteFreightDialog({ conditionToDelete, isOpen, onClose, onDelete }: Props) {
  const t = useFreightTFunction();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t('freight.actions.deleteDialogTitle')}</DialogTitle>

      <DialogContent>
        <Typography variant="h6" fontWeight="medium">
          {t('freight.actions.deleteDialogDesc', {
            selectedLevel:
              conditionToDelete.primaryText ??
              conditionToDelete.productGroupName ??
              t('freight.level.supplier').toLowerCase(),
          })}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', alignSelf: 'flex-end' }}>
        <ContainedButton
          color="warning"
          size="small"
          sx={{ alignSelf: 'center' }}
          onClick={onDelete}
        >
          {t('freight.actions.delete')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
