import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { useToast } from '@retail/hooks';
import { useDepartmentAssortments, useMemberAssortments } from './';
import {
  useDeleteItemsFromDraft,
  useFetchAssortmentItems,
  useMutateActivateItemChanges,
} from '@retail/my-assortment/data-access';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { AssortmentItem, ItemsDTO } from '@retail/my-assortment/types';
import { usePagination } from '@shared/hooks';
import { useCallback, useState } from 'react';
import { useRefetchDraftDrawer } from '@retail/context';
import { useQueryClient } from '@tanstack/react-query';

// Summary drawer uses member draft, table uses department base
export const useAssortmentDraftDrawer = () => {
  const t = useMyAssortmentTFunction();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { selectedDepartment } = useSelectedOrgUnit();
  const memberId = selectedDepartment?.memberId ?? '';

  // Everything that includes conditions will use departmentId
  const { baseAssortmentId: departmentBaseAssortmentId } = useDepartmentAssortments();
  const { draftAssortment: memberDraftAssortment } = useMemberAssortments();

  const { invalidateAssortmentDraft, invalidateAssortmentBase } = useRefetchDraftDrawer({
    departmentBaseAssortmentId,
    memberDraftAssortment: memberDraftAssortment,
  });

  const { pageSize, page } = usePagination({ initPageSize: 200, initPage: 0 });

  const [timeoutLoading, setTimeoutLoading] = useState<boolean>(false);

  const { data: draftAssortmentItemDtos } = useFetchAssortmentItems({
    assortmentId: memberDraftAssortment.id,
    page,
    size: pageSize,
  });
  const draftAssortmentItems = draftAssortmentItemDtos?.result.map(AssortmentItem.fromDTO);

  const { mutateAsync: activateItems, isLoading: activateIsLoading } = useMutateActivateItemChanges(
    {
      memberId,
      assortmentId: memberDraftAssortment.id,
    }
  );

  const { mutateAsync: mutateDeleteDraftItem, isLoading: deleteDraftItemIsLoading } =
    useDeleteItemsFromDraft({
      assortmentId: memberDraftAssortment.id,
      memberId,
    });

  const handleInvalidateAssortments = useCallback(() => {
    setTimeoutLoading(true);
    invalidateAssortmentDraft();

    // Backend has a pending delay, so frontend needs setTimeout
    setTimeout(() => {
      invalidateAssortmentBase();

      // Invalidate filters in my assortment
      queryClient.invalidateQueries({
        queryKey: ['assortmentProductGroups', departmentBaseAssortmentId],
      });
      queryClient.invalidateQueries({
        queryKey: ['assortmentSuppliers', departmentBaseAssortmentId],
      });

      setTimeoutLoading(false);
    }, 2500);
  }, [
    departmentBaseAssortmentId,
    invalidateAssortmentBase,
    invalidateAssortmentDraft,
    queryClient,
  ]);

  const onSubmitAssortmentDraft = useCallback(
    (items: ItemsDTO[] | undefined) => {
      setTimeoutLoading(true);
      activateItems({
        body: items,
      })
        .then(() => {
          handleInvalidateAssortments();
          toast.success(t('myAssortment.actions.assortmentUpdated'));
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('myAssortment.actions.genericActionError'));
        });
      setTimeoutLoading(false);
    },
    [activateItems, handleInvalidateAssortments, t, toast]
  );

  const onDeleteAssortmentDraftItems = useCallback(
    (ids: number[]) => {
      setTimeoutLoading(true);
      mutateDeleteDraftItem({ body: ids })
        .then(() => {
          handleInvalidateAssortments();
          toast.success(t('myAssortment.actions.deletedItem'));
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('myAssortment.actions.genericActionError'));
        });
      setTimeoutLoading(false);
    },
    [handleInvalidateAssortments, mutateDeleteDraftItem, t, toast]
  );

  return {
    draftAssortmentItems,
    totalItems: draftAssortmentItemDtos?.totalCount,
    timeoutLoading,
    activateIsLoading,
    deleteDraftItemIsLoading,
    onDeleteAssortmentDraftItems,
    onSubmitAssortmentDraft,
  };
};
