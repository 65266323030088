import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useMpeAuthContext } from './AuthContext';
import { urlConstant } from '@retail/utils';
import { Brand } from '@retail/auth/types';

export const brandsRoute = '/brands/';

export function useFetchBrands(queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useFetch<Brand[]>({
    method: 'GET',
    queryKey: ['brands', token],
    url: `${mpe.url}${urlConstant}${brandsRoute}`,
    token,
    ...queryOptions,
  });
}
