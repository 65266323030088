import { Button, Stack, Typography, useTheme } from '@mui/material';
import { Supplier } from '@retail/suppliers/types';

interface Props {
  index: number;
  supplier: Supplier;
  hasBorderBottom: boolean;
  selectSupplier: (supplier: Supplier | null) => void;
}

export function SupplierView({ index, supplier, hasBorderBottom, selectSupplier }: Props) {
  const { palette } = useTheme();

  return (
    <Stack
      component={Button}
      onClick={() => selectSupplier(supplier)}
      borderRadius={0}
      border={0}
      py={2}
      px={3}
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        backgroundColor: index % 2 === 0 ? palette.info[50] : undefined,
        border: `1px solid ${palette.grey[50]}`,
        borderBottom: hasBorderBottom ? `1px solid ${palette.grey[50]}` : 'none',
        borderTop: hasBorderBottom ? 'none' : `1px solid ${palette.grey[50]}`,
        textDecoration: 'none',
        boxShadow: 'inherit',

        '&:hover': {
          border: `1px solid ${palette.grey[100]}`,
          bgcolor: palette.info[100],
          cursor: 'pointer',
          boxShadow: 'inherit',
          marginTop: '-1px',
        },

        '&:focus-visible': {
          boxShadow: 'inherit',
          border: `1px solid ${palette.grey[100]}`,
          marginTop: '-1px',
          zIndex: 2,
        },
      }}
    >
      <Typography color="text.primary" variant="h6">
        {supplier.name}
      </Typography>
    </Stack>
  );
}
