import { useSelectedOrgUnit, useMyOrgUnits } from '@retail/app/stores/selected-context';
import { useMemo } from 'react';
import { OrgUnitHeading, OrgUnitSelect } from '@retail/components';

export const AvailableOrgUnitsContainer: React.FC = () => {
  const { departments, members, brand } = useMyOrgUnits();
  const {
    selectedMember,
    selectMember,
    selectedDepartment,
    selectDepartment,
    selectedBrandContext,
    selectBrand,
  } = useSelectedOrgUnit();

  const hasMultipleOrgUnits = useMemo(() => {
    return departments.length + members.length > 1;
  }, []);

  if (hasMultipleOrgUnits) {
    return (
      <OrgUnitSelect
        brand={brand}
        members={members}
        departments={departments}
        selectedDepartment={selectedDepartment}
        selectedMember={selectedMember}
        selectedBrand={selectedBrandContext}
        selectBrand={selectBrand}
        selectMember={selectMember}
        selectDepartment={selectDepartment}
      />
    );
  }

  if (selectedBrandContext) {
    return <OrgUnitHeading name={selectedBrandContext.name} />;
  }
  if (selectedMember) {
    return <OrgUnitHeading name={selectedMember.name} />;
  }
  if (selectedDepartment) {
    return <OrgUnitHeading name={selectedDepartment.name} />;
  }

  return <OrgUnitHeading name="" />;
};
