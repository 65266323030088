import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { ConditionState } from '@retail/calculus/types';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

interface Props {
  typesToShow: ConditionState;
  setTypesToShow: (newView: ConditionState) => void;
}

export const TypesToggleButton = ({ typesToShow, setTypesToShow }: Props) => {
  const t = useMyAssortmentTFunction();

  return (
    <Stack gap={1} flexDirection="row" alignItems="center">
      <Typography variant="body2" fontWeight={600}>
        {t('myAssortment.filter.types')}
      </Typography>
      <ToggleButtonGroup
        size="small"
        exclusive
        value={typesToShow ?? ConditionState.ALL}
        onChange={(_, newView) => newView !== null && setTypesToShow(newView)}
      >
        <ToggleButton value={ConditionState.ACTIVE}>{t('myAssortment.filter.active')}</ToggleButton>
        <ToggleButton value={ConditionState.CURRENT}>
          {t('myAssortment.filter.current')}
        </ToggleButton>
        <ToggleButton value={ConditionState.FUTURE}>{t('myAssortment.filter.future')}</ToggleButton>
        <ToggleButton value={ConditionState.DRAFT}>{t('myAssortment.filter.drafted')}</ToggleButton>
        <ToggleButton value={ConditionState.ALL}>{t('myAssortment.filter.all')}</ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
