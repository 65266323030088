import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { PriceListDialog } from '@retail/purchase-agreements/components';
import { usePriceList } from '@retail/purchase-agreements/context';
import { useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  agreementId: number;
  productGroupId?: string;
}

function PriceListDialogContainer({ open, onClose, agreementId, productGroupId }: Props) {
  const [searchQuery, setSearchQuery] = useState('');

  const { result, setPage, setPageSize } = usePriceList({
    agreementId,
    productGroupId,
    query: searchQuery,
    initPageSize: 200,
  });

  return (
    <PriceListDialog
      result={result}
      open={open}
      setSearchQuery={setSearchQuery}
      onClose={onClose}
      updatePage={setPage}
      updatePageSize={setPageSize}
    />
  );
}

export default withDialogSuspenseErrorBoundary(PriceListDialogContainer);
