import { CheckIcon } from '@shared/custom-icons';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props {
  url?: string;
}

export const DownloadDocumentationLink = ({ url = '' }: Props) => {
  const t = useProductsTFunction();

  return (
    <Link to={url} target="_blank" download>
      <Stack sx={{ alignItems: 'center' }}>
        <CheckIcon fontSize="small" />

        <Box display="flex" alignItems="center">
          <OpenInNewIcon fontSize="small" />
          <Typography variant="subtitle1">{t('products.mgItem.documentation.download')}</Typography>
        </Box>
      </Stack>
    </Link>
  );
};
