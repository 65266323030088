import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { ContainedButton, Dialog } from '@shared/components';
import { useCallback } from 'react';
import {
  useFetchActiveMarkupCondition,
  useMutateCreateOrEditMarkupCondition,
} from '@retail/markup/data-access';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useController, useFormContext } from 'react-hook-form';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { CreateMarkupForm, CreateMarkupSchema } from '@retail/markup/components';
import { useLevelSelect } from '@retail/calculus/context';
import { MarkupCondition } from '@retail/markup/types';
import { Supplier } from '@retail/suppliers/types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  supplier: Supplier;
}

export function CreateMarkupConditionDialog({ supplier, isOpen, onClose }: Props) {
  const t = useMarkupTFunction();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useFormContext<CreateMarkupSchema>();

  const productGroup = useController({ name: 'productGroup', control }).field.value;
  const item = useController({ name: 'item', control }).field.value;
  const level = useController({ name: 'level', control }).field.value;

  const { flattenedProductGroups, mgSupplierItems, setMgSupplierItemSearch, isLoadingFetchItems } =
    useLevelSelect({ supplierId: supplier.mgSupplierId, selectedProductGroup: level === 'productGroup' });

  const { draftAssortmentId, baseAssortmentId, departmentId } = useDepartmentAssortments();

  const { mutateAsync: createMarkupCondition } = useMutateCreateOrEditMarkupCondition(
    draftAssortmentId,
    departmentId
  );

  const { data: activeMarkupConditionDto } = useFetchActiveMarkupCondition({
    assortmentId: baseAssortmentId,
    mgSupplierId: supplier.mgSupplierId,
    groupNumber: productGroup?.groupNumber,
    mgItemNumber: item?.mgItemNumber,
  });

  const activeMarkupCondition = MarkupCondition.fromDto(activeMarkupConditionDto!);

  const onActivate = useCallback(
    (data: CreateMarkupSchema) => {
      createMarkupCondition({
        body: {
          mgSupplierId: supplier.mgSupplierId,
          mgSupplierConditions:
            data.level === 'supplier'
              ? [
                  {
                    validFrom: data.fromDate,
                    markup: {
                      templateCreate: {
                        templateName: data.markupName,
                        salesFactor: data.salesFactor,
                      },
                    },
                  },
                ]
              : null,
          productGroupConditions:
            data.level === 'productGroup'
              ? [
                  {
                    validFrom: data.fromDate,
                    groupNumber: data.productGroup.groupNumber,
                    markup: {
                      templateCreate: {
                        templateName: data.markupName,
                        salesFactor: data.salesFactor,
                      },
                    },
                  },
                ]
              : null,
          mgSupplierItemConditions:
            data.level === 'item'
              ? [
                  {
                    validFrom: data.fromDate,
                    mgItemNumber: data.item.mgItemNumber,
                    markup: {
                      templateCreate: {
                        templateName: data.markupName,
                        salesFactor: data.salesFactor,
                      },
                    },
                  },
                ]
              : null,
        },
      })
        .then(() => {
          reset();
          onClose();
          queryClient.invalidateQueries({
            queryKey: ['markup-conditions'],
          });
          queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
        })
        .catch((err) => {
          console.error(err);
          toast.error(t('markup.error.generic'));
        });
    },
    [createMarkupCondition, onClose, queryClient, reset, supplier.mgSupplierId, t]
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>{t('markup.create.newMarkup')}</DialogTitle>

      <DialogContent>
        <CreateMarkupForm
          productGroups={flattenedProductGroups}
          activeCondition={activeMarkupCondition}
          mgSupplierItems={mgSupplierItems}
          setMgSupplierItemSearch={setMgSupplierItemSearch}
          isLoadingFetchItems={isLoadingFetchItems}
          supplierName={supplier.name}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', mt: 1 }}>
        <ContainedButton
          disabled={Object.keys(errors).length > 0}
          onClick={handleSubmit(onActivate)}
        >
          {t('markup.actions.activate')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
