import { Stack, Typography } from '@mui/material';
interface Props {
  groupNumber: string;
  name: string | undefined;
  numberOfConditions?: number;
}

export function NodeLabel({ groupNumber, name, numberOfConditions }: Props) {
  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
      {`${groupNumber} ${name ? name : ''}`}
      <Typography variant="body1">{numberOfConditions ? `(${numberOfConditions})` : ''}</Typography>
    </Stack>
  );
}
