import { MgItemDetailedDTO } from '../../dto';
import { getUniqueStoreIds } from './mgItemUtils';
import { CompetitorWithPrice } from './CompetitorWithPrice';

interface ConstructorProps {
  id: number;
  competitors: CompetitorWithPrice[];
}

export class MgItemCompetitorsWithPrices {
  id: number;
  competitors: CompetitorWithPrice[];

  constructor({ id, competitors }: ConstructorProps) {
    this.id = id;
    this.competitors = competitors;
  }

  static fromDto = (mgItemDto: MgItemDetailedDTO) => {
    const uniqueStoreIds = getUniqueStoreIds(mgItemDto);

    const competitors: CompetitorWithPrice[] = uniqueStoreIds.map((storeId) => {
      return CompetitorWithPrice.getCompetitorWithPrice(mgItemDto, storeId);
    });

    return new MgItemCompetitorsWithPrices({ id: mgItemDto.mgItemNumber, competitors });
  };
}
