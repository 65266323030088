import { fetcher, QueryOptions, useQueryFetch } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { MgSupplierItemSearchResultDTO, PaginationResultDTO } from '@retail/products/types';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { useCallback } from 'react';
import { urlConstant } from '@retail/utils';
import { useSelectedOrgUnitHeader } from '@retail/app/hooks';

export const mgItemApiRoute = '/mg-items';
export const supplierItemRoute = '/supplier-items';

interface SearchProps {
  query?: string;
  articleIds?: string[];
  supplierIds?: number[];
  groupNumbers?: string[];
  withAgreementPrices?: boolean;
  page: number;
  size: number;
}

interface FetchProps extends SearchProps {
  baseUrl: string;
  token: string;
  headers?: HeadersInit;
}

export async function searchMgSupplierItems({
  baseUrl,
  groupNumbers,
  supplierIds,
  query,
  articleIds,
  withAgreementPrices = true,
  page,
  size,
  token,
  headers,
}: FetchProps) {
  return fetcher<PaginationResultDTO<MgSupplierItemSearchResultDTO[]>>({
    url: buildUrl({
      base: baseUrl,
      route: `${urlConstant}${mgItemApiRoute}${supplierItemRoute}`,
      searchParams: {
        query,
        articleIds,
        groupNumbers,
        supplierIds,
        withAgreementPrices,
        page,
        size,
      },
    }),
    token,
    headers,
  });
}

type Props = SearchProps;

export function useMgSupplierItemsSearch(searchProps: Props, options?: QueryOptions) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  const fetcher = useCallback(
    () =>
      searchMgSupplierItems({
        baseUrl: mpe.url,
        token,
        headers: selectedOrgUnitHeader,
        ...searchProps,
      }),
    [mpe.url, token, selectedOrgUnitHeader, searchProps]
  );
  return useQueryFetch({
    fetcher,
    queryKey: ['mg-supplier-item', 'search', searchProps],
    ...options,
  });
}
