import { useMemo } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { calculateSalesCoverage } from '@retail/utils';
import { dataGridBoldClass } from '@shared/styles';
import dayjs from 'dayjs';
import { getBindingValue } from '@retail/calculus/utils';
import { Box } from '@mui/material';
import { Supplier } from '@retail/suppliers/types';
import { Actions, AssortmentType, HierarchyLevels, MarkupCondition } from '@retail/calculus/types';
import { StackedTextCell } from '@retail/components';
import { LevelChip } from './LevelChip';
import { MarkupTableActions } from './MarkupTableActions';

interface Props {
  supplier: Supplier;
  groupNumber?: string;
  markups: MarkupCondition[];
  handleSetSelectedMarkup: (markup: MarkupCondition) => void;
  handleSetAction: (action: Actions) => void;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 120,
  sortable: false,
  headerAlign: 'center',
  align: 'center',
};

export function MarkupsTable({ supplier, markups, handleSetSelectedMarkup, handleSetAction }: Props) {
  const t = useMarkupTFunction();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        field: 'level',
        headerName: t('markup.level'),
        width: 180,
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }) => {
          const label = getBindingValue(row.bindingType);

          return (
            <LevelChip
              level={row.bindingType}
              label={label}
              active={row.assortmentType === AssortmentType.BASE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        cellClassName: () => dataGridBoldClass,
        field: 'name',
        headerName: t('markup.levelOrItemName'),
        width: 240,
        headerAlign: 'left',
        align: 'left',
        renderCell: ({ row }) => {
          const name =
            row.bindingType === HierarchyLevels.item
              ? row.primaryText
              : row.bindingType === HierarchyLevels.supplier
              ? supplier.name
              : row.productGroupName;

          const id =
            row.bindingType === HierarchyLevels.item
              ? row.mgItemNumber
              : row.bindingType === HierarchyLevels.supplier
              ? supplier.mgSupplierId
              : row.productGroup;
          return <StackedTextCell topLine={name} bottomLine={id} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'markupName',
        headerAlign: 'left',
        align: 'left',
        headerName: t('markup.markupName'),
        width: 150,
        renderCell: ({ row }) => row.markupTemplateName,
      },
      {
        ...baseColumnProps,
        field: 'salesFactor',
        headerName: t('markup.salesFactor'),
        renderCell: ({ row }) => row.salesFactor,
      },
      {
        ...baseColumnProps,
        field: 'salesCoverage',
        width: 100,
        headerName: t('markup.salesCoverage'),
        renderCell: ({ row }) => calculateSalesCoverage(row.salesFactor),
      },
      {
        ...baseColumnProps,
        field: 'seeItemPrices',
        headerName: t('markup.salesPrice'),
        renderCell: ({ row }) => '-',
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        width: 140,
        headerName: t('markup.fromDate'),
        renderCell: ({ row }) => dayjs(row.validFrom).format('ll'),
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        width: 140,
        headerName: t('markup.toDate'),
        renderCell: ({ row }) => (row.validTo ? dayjs(row.validTo).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'source',
        width: 100,
        headerName: t('markup.source'),
        renderCell: ({ row }) => row.source ?? '-',
      },

      {
        ...baseColumnProps,
        field: 'actions',
        headerName: '',
        width: 50,
        renderCell: ({ row }) => (
          <MarkupTableActions
            setAction={handleSetAction}
            setMarkupClicked={() => handleSetSelectedMarkup(row)}
            markupClicked={row}
          />
        ),
      },
    ],
    [t, supplier.name, supplier.mgSupplierId, handleSetSelectedMarkup, handleSetAction]
  );

  return (
    <Box height="100%">
      <DataGridPro
        autoHeight
        getRowId={(row) => `${row.id}${row.markupTemplateId}`}
        columns={columns}
        rows={markups}
        rowHeight={70}
        initialState={{
          pinnedColumns: { right: ['actions'] },
        }}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        hideFooter
      />
    </Box>
  );
}
