import { VilmaUnit } from './VilmaUnit';
import { MgPackageDTO } from '../../dto';

export type MgPackageType =
  | 'PRIMARY'
  | 'SECONDARY'
  | 'TERTIARY'
  | 'PRICE_COMPARISON'
  | 'BASE_PRICE'
  | 'ADDITIONAL_GTIN';

export type MgPackageClassCode = 'F-PAK' | 'D-PAK' | 'T-PACK' | 'PSE' | 'ADDITIONAL_GTIN';

interface ConstructorProps {
  calculatedCount: number;
  gtinCode?: string;
  packageNumber?: number;
  packageType: MgPackageType;
  vilmaUnit?: VilmaUnit;
  basePriceUnit?: boolean;
  classCode?: MgPackageClassCode;
  priceComparisonUnit?: boolean;
  blacklisted?: boolean;
  createdAt?: string;
  updatedAt?: string;
}
export class MgPackage {
  calculatedCount: number;
  gtinCode?: string;
  packageNumber?: number;
  packageType: MgPackageType;
  vilmaUnit?: VilmaUnit;
  basePriceUnit?: boolean;
  classCode?: MgPackageClassCode;
  priceComparisonUnit?: boolean;
  blacklisted?: boolean;
  createdAt?: string;
  updatedAt?: string;

  constructor({
    calculatedCount,
    gtinCode,
    packageNumber,
    packageType,
    vilmaUnit,
    basePriceUnit,
    classCode,
    priceComparisonUnit,
    blacklisted,
    createdAt,
    updatedAt,
  }: ConstructorProps) {
    this.calculatedCount = calculatedCount;
    this.gtinCode = gtinCode;
    this.packageNumber = packageNumber;
    this.packageType = packageType;
    this.vilmaUnit = vilmaUnit;
    this.basePriceUnit = basePriceUnit;
    this.classCode = classCode;
    this.priceComparisonUnit = priceComparisonUnit;
    this.blacklisted = blacklisted;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromDto = (dto: MgPackageDTO): MgPackage => {
    return new MgPackage({
      calculatedCount: dto.calculatedCount,
      gtinCode: dto.gtinCode,
      packageNumber: dto.packageNumber,
      packageType: dto.packageType as MgPackageType,
      vilmaUnit: dto.vilmaUnit,
      basePriceUnit: dto.basePriceUnit,
      classCode: dto.classCode as MgPackageClassCode,
      priceComparisonUnit: dto.priceComparisonUnit,
      blacklisted: dto.blacklisted,
      createdAt: dto.createdAt,
      updatedAt: dto.updatedAt,
    });
  };
}
