import { Paper } from '@shared/components';

interface Props {
  children: React.ReactNode;
}

export const StandardPaper = ({ children }: Props) => {
  return (
    <Paper
      paddingY="dense"
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}
    >
      {children}
    </Paper>
  );
};
