import { useProductsTFunction } from '@retail/products/i18n';
import { PanelProps, ProductInformationAccordion } from './ProductInformationAccordion';
import { ProductInformationAccordionRow } from './ProductInformationAccordionRow';
import { PANELS, ProductInformationAccordionsProps } from './ProductInformationAccordions';

export const PropertiesPanel = ({
  expanded,
  selectedSupplierItem,
  handleChange,
}: PanelProps & ProductInformationAccordionsProps) => {
  const t = useProductsTFunction();

  return (
    <ProductInformationAccordion
      panelType={PANELS.PROPERTIES}
      expanded={expanded}
      handleChange={handleChange}
    >
      <ProductInformationAccordionRow title={t('products.mgItem.details.commodityCode')}>
        {selectedSupplierItem.commodityCode}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.originCountry')}>
        {selectedSupplierItem.originCountry}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.dangerousGoods')}>
        {selectedSupplierItem.dangerousGoods}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.fscPefcClaim')}>
        {selectedSupplierItem.fscPefcClaim}
      </ProductInformationAccordionRow>
    </ProductInformationAccordion>
  );
};
