import { useEffect, useMemo, useState } from 'react';
import { Paper, UnderlinedTabs } from '@shared/components';
import { useAppTFunction } from '@retail/app/i18n';
import { AssortmentItems } from './AssortmentItems';
import { Box } from '@mui/material';
import { Pagination, useDisclosure } from '@shared/hooks';
import WelcomeToMyAssortmentDialogContainer from './WelcomeToMyAssortmentDialogContainer';
import { useAssortmentItems } from '@retail/my-assortment/context';

export enum AssortmentTab {
  MY_ASSORTMENT,
}

interface Props {
  selectedSupplier?: number;
  selectedProductGroup?: string;
  searchQuery: string;
  departmentDraftAssortmentId: number;
  departmentId: string;
  pagination: Pagination;
  includeVat: boolean;
  handleSetIsNoItems: (noItems: boolean) => void;
}

export function AssortmentTabs({
  selectedSupplier,
  selectedProductGroup,
  searchQuery,
  departmentDraftAssortmentId,
  departmentId,
  pagination,
  includeVat,
  handleSetIsNoItems,
}: Props) {
  const t = useAppTFunction();

  const tabs = useMemo(
    () => [{ label: t('myAssortment.tabs.myAssortment'), value: AssortmentTab.MY_ASSORTMENT }],
    [t]
  );

  const [selectedTab, setSelectedTab] = useState<AssortmentTab>(AssortmentTab.MY_ASSORTMENT);
  const selectTab = (_: any, newTab: AssortmentTab) => setSelectedTab(newTab);

  const { isOpen: isBrandAssortmentsDialogOpen, onToggle: toggleBrandAssortmentsView } =
    useDisclosure();

  const { assortmentItems, totalItems, deleteItemsByDate, updatePriority, updateItemsIsLoading } =
    useAssortmentItems({
      assortmentId: departmentDraftAssortmentId,
      selectedSupplier,
      selectedProductGroup,
      searchQuery,
      pagination,
      memberId: departmentId,
    });

  useEffect(() => {
    if (totalItems === 0) {
      handleSetIsNoItems(true);
    } else {
      handleSetIsNoItems(false);
    }
  }, [handleSetIsNoItems, totalItems]);

  return (
    <Paper
      variant="outlined"
      sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 0, overflow: 'hidden' }}
    >
      <Box pt={2}>
        <UnderlinedTabs id="assortment" tabs={tabs} currentTab={selectedTab} onChange={selectTab} />
      </Box>

      <AssortmentItems
        assortmentItems={assortmentItems}
        totalItems={totalItems}
        pagination={pagination}
        includeVat={includeVat}
        updateItemsIsLoading={updateItemsIsLoading}
        toggleBrandAssortmentsView={toggleBrandAssortmentsView}
        updatePriority={updatePriority}
        deleteItemsByDate={deleteItemsByDate}
      />

      <WelcomeToMyAssortmentDialogContainer
        open={isBrandAssortmentsDialogOpen}
        onClose={toggleBrandAssortmentsView}
        departmentId={departmentId}
        draftAssortmentId={departmentDraftAssortmentId}
      />
    </Paper>
  );
}
