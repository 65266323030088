import {
  QueryOptions,
  RequestMethods,
  useFetch,
  useMutateFetch,
  UseMutateFetchProps,
} from '@shared/fetch-utils';
import { QueryKey } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { Brand, Department, Member } from '@retail/auth/types';

export interface MPEFetchError {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
}

export interface UseMPEFetchOptions {
  queryKey: QueryKey;
  url: string;
  method: RequestMethods;
  body?: any;
  token?: string;
}

const headerMap = {
  LOCATION: 'X-Location-Id',
  DATAOWNER: 'X-Dataowner-Id',
  BRAND: 'X-Brand-Id',
} as const;

interface OrgUnitHeaderProps {
  department?: Department;
  member?: Member;
  brand?: Brand;
}

export function getOrgUnitHeader({
  department,
  member,
  brand,
}: OrgUnitHeaderProps): HeadersInit | undefined {
  if (department) {
    return {
      [headerMap.LOCATION]: department.locationId,
    };
  }
  if (member) {
    return {
      [headerMap.DATAOWNER]: member.dataownerId,
    };
  }
  if (brand) {
    return {
      [headerMap.BRAND]: brand.brandId,
    };
  }
  return undefined;
}

export function useSelectedOrgUnitHeader() {
  const { selectedDepartment, selectedMember, selectedBrandContext } = useSelectedOrgUnit();

  return useMemo(
    () =>
      getOrgUnitHeader({
        department: selectedDepartment,
        member: selectedMember,
        brand: selectedBrandContext,
      }),
    [selectedBrandContext, selectedDepartment, selectedMember]
  );
}

export function useMPEFetch<TData, TError = MPEFetchError>(
  options: UseMPEFetchOptions & QueryOptions
) {
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  const optionsWithHeader = useMemo(
    () => ({
      ...options,
      headers: selectedOrgUnitHeader,
    }),
    [options, selectedOrgUnitHeader]
  );
  return useFetch<TData, TError>(optionsWithHeader);
}

export function useMutateMPEFetch<ResultT, BodyT = unknown, URLVariables = any>(
  options: UseMutateFetchProps<ResultT, BodyT, URLVariables>
) {
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();
  return useMutateFetch<ResultT, BodyT, URLVariables>({
    headers: selectedOrgUnitHeader,
    ...options,
  });
}
