import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';

export const levelSelectSchema = (t: CalculusTFunction) => {
  return yup.object({
    level: yup.string().default('').required(t('calculus.error.chooseLevel')),
    productGroup: yup
      .object()
      .default(null)
      .nullable()
      .when('level', {
        is: 'productGroup',
        then: yup.object().nullable().required(t('calculus.error.chooseProductGroup')),
      }),
    item: yup
      .object()
      .default(null)
      .nullable()
      .when('level', {
        is: 'item',
        then: yup.object().nullable().required(t('calculus.error.chooseMgSupplierItem')),
      }),
  });
};

export type LevelSelectSchema = yup.InferType<ReturnType<typeof levelSelectSchema>>;
