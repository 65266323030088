import { SvgIcon } from '@mui/material';

interface Props {
  color?: string;
  fontSize?: 'small' | 'inherit' | 'large' | 'medium';
}

export const DotMenuIcon = ({ color = 'currentColor', fontSize = 'inherit' }: Props) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="7" viewBox="0 0 31 7" fill="none">
        <rect width="7" height="7" rx="3.5" fill={color} />
        <rect x="12" width="7" height="7" rx="3.5" fill={color} />
        <rect x="24" width="7" height="7" rx="3.5" fill={color} />
      </svg>
    </SvgIcon>
  );
};
