import { HierarchyLevels, SelectedLevel } from '@retail/calculus/types';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useLevelSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedProductGroupId: string | undefined = useMemo(() => {
    const value = searchParams.get('productGroupId');
    return value !== 'undefined' && value !== null ? value : undefined;
  }, [searchParams]);

  const selectedMgItemId = useMemo(
    () => Number(searchParams.get('mgItem')) || undefined,
    [searchParams]
  );

  const selectLevel = useCallback(
    (selectedLevel: SelectedLevel) => {
      setSearchParams(() => {
        if (selectedLevel.level === HierarchyLevels.supplier) {
          searchParams.delete('productGroupId');
          searchParams.delete('mgItem');
        } else if (selectedLevel.level === HierarchyLevels.item) {
          searchParams.delete('productGroupId');
          searchParams.set('mgItem', selectedLevel.mgSupplierItem?.id + '');
        } else {
          searchParams.set('productGroupId', selectedLevel.productGroup?.groupNumber + '');
          searchParams.delete('mgItem');
        }
        return searchParams;
      });

    },
    [searchParams, setSearchParams]
  );

  const selectedLevel: HierarchyLevels = useMemo(() => {
    if (selectedMgItemId) {
      return HierarchyLevels.item;
    } else if (selectedProductGroupId) {
      return selectedProductGroupId.length > 3
        ? HierarchyLevels.productGroup
        : selectedProductGroupId.length > 2
        ? HierarchyLevels.mainGroup
        : HierarchyLevels.overGroup;
    }
    return HierarchyLevels.supplier;
  }, [selectedMgItemId, selectedProductGroupId]);

  return {
    selectedProductGroupId,
    selectedMgItemId,
    selectedLevel: selectedLevel,
    selectLevel,
  };
};
