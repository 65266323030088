import { useMemo } from 'react';
import { TreeView } from '@mui/x-tree-view';
import { NavigationTreeItem } from './NavigationTreeItem';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

export interface TreeNode {
  id: string;
  label: React.ReactNode;
  onClick?: () => void;
  onSelect: () => void;
  childNodes?: TreeNode[];
  defaultExpanded?: boolean;
}

interface Props {
  nodes: TreeNode[];
  selectedNodeId?: string;
}

function getAllDefaultExpandedIds(nodes: TreeNode[]): string[] {
  return nodes
    .filter(({ defaultExpanded }) => defaultExpanded)
    .flatMap(({ id, childNodes }) => {
      if (childNodes?.length) {
        return getAllDefaultExpandedIds(childNodes).concat(id);
      }
      return [id];
    });
}

export function NavigationTreeView({ nodes, selectedNodeId }: Props) {
  const defaultExpandedList = useMemo(() => getAllDefaultExpandedIds(nodes), [nodes]);

  return (
    <TreeView
      defaultExpanded={defaultExpandedList}
      defaultCollapseIcon={<ExpandMore color="primary" />}
      defaultExpandIcon={<ChevronRight />}
      selected={selectedNodeId}
    >
      {nodes.map((node) => (
        <NavigationTreeItem key={node.id} depth={0} {...node} />
      ))}
    </TreeView>
  );
}
