import { FC, useCallback, useMemo, useState } from 'react';
import { useAssortmentDraftDrawer } from '@retail/my-assortment/context';
import { DraftAssortmentItemsTable } from '@retail/my-assortment/components';
import { ContainedButton } from '@shared/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { DraftActionsRowPortal } from '@retail/components';
import { ItemsDTO } from '@retail/my-assortment/types';

export const DraftAssortmentItemsContainer: FC = () => {
  const t = useMyAssortmentTFunction();
  const {
    deleteDraftItemIsLoading,
    draftAssortmentItems,
    timeoutLoading: assortmentTimeoutLoading,
    activateIsLoading: activateAssortmentIsLoading,
    onDeleteAssortmentDraftItems,
    onSubmitAssortmentDraft,
  } = useAssortmentDraftDrawer();
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);

  const submitActivateSelectedItems = useCallback(() => {
    // @ts-ignore
    const itemDtos: ItemsDTO[] = selectedItemIds
      .map((id) => {
        const item = draftAssortmentItems?.find((item) => item.id === id);
        if (!item) return null;

        return {
          mgSupplierId: item.mgSupplierId,
          mgItemNumber: item.mgItemNumber,
          priority: item.priority,
          validFrom: item.priorityValidFrom.toISOString(),
        };
      })
      .filter((item) => !!item);
    onSubmitAssortmentDraft(itemDtos);
  }, [draftAssortmentItems, onSubmitAssortmentDraft, selectedItemIds]);

  const submitDeleteSelectedItems = useCallback(() => {
    onDeleteAssortmentDraftItems(selectedItemIds);
  }, [onDeleteAssortmentDraftItems, selectedItemIds]);

  const isLoading = useMemo(
    () => assortmentTimeoutLoading || activateAssortmentIsLoading || deleteDraftItemIsLoading,
    [activateAssortmentIsLoading, assortmentTimeoutLoading, deleteDraftItemIsLoading]
  );

  return (
    <>
      <DraftAssortmentItemsTable
        items={draftAssortmentItems ?? []}
        isLoading={isLoading}
        onDeleteDraftItem={onDeleteAssortmentDraftItems}
        onSelectItems={setSelectedItemIds}
      />
      <DraftActionsRowPortal>
        <ContainedButton
          color="warning"
          size="small"
          disabled={!selectedItemIds.length}
          onClick={submitDeleteSelectedItems}
          loading={isLoading}
        >
          {t('myAssortment.actions.disapproveChanges', { count: selectedItemIds.length })}
        </ContainedButton>
        <ContainedButton
          onClick={submitActivateSelectedItems}
          size="small"
          disabled={!selectedItemIds.length}
          loading={isLoading}
        >
          {t('myAssortment.actions.addChanges', { count: selectedItemIds.length })}
        </ContainedButton>
      </DraftActionsRowPortal>
    </>
  );
};
