import { PpsMgPackageDTO } from '../../../dto';
import { MgPackageClassCode, MgPackageType } from '../MgPackage';
import { VilmaUnit } from '../VilmaUnit';

interface ConstructorProps {
  calculatedCount: number;
  gtinCode?: string;
  packageNumber: number;
  mgPackageId: number;
  packageType: MgPackageType;
  vilmaUnit?: VilmaUnit;
  basePriceUnit?: boolean;
  classCode?: MgPackageClassCode;
  priceComparisonUnit?: boolean;
  blacklisted?: boolean;
  createdAt?: string;
  updatedAt?: string;
}
export class PpsMgPackage {
  calculatedCount: number;
  gtinCode?: string;
  packageNumber: number;
  mgPackageId: number;
  packageType: MgPackageType;
  vilmaUnit?: VilmaUnit;
  basePriceUnit?: boolean;
  classCode?: MgPackageClassCode;
  priceComparisonUnit?: boolean;
  blacklisted?: boolean;
  createdAt?: string;
  updatedAt?: string;

  constructor({
    calculatedCount,
    gtinCode,
    packageNumber,
    mgPackageId,
    packageType,
    vilmaUnit,
    basePriceUnit,
    classCode,
    priceComparisonUnit,
    blacklisted,
    createdAt,
    updatedAt,
  }: ConstructorProps) {
    this.calculatedCount = calculatedCount;
    this.gtinCode = gtinCode;
    this.packageNumber = packageNumber;
    this.mgPackageId = mgPackageId;
    this.packageType = packageType;
    this.vilmaUnit = vilmaUnit;
    this.basePriceUnit = basePriceUnit;
    this.classCode = classCode;
    this.priceComparisonUnit = priceComparisonUnit;
    this.blacklisted = blacklisted;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromDto = (dto: PpsMgPackageDTO): PpsMgPackage => {
    return new PpsMgPackage({
      calculatedCount: dto.calculatedCount,
      gtinCode: dto.gtinCode,
      packageNumber: dto.packageNumber,
      mgPackageId: dto.mgPackageId,
      packageType: dto.packageType as MgPackageType,
      vilmaUnit: dto.vilmaUnit,
      basePriceUnit: dto.basePriceUnit,
      classCode: dto.classCode as MgPackageClassCode,
      priceComparisonUnit: dto.priceComparisonUnit,
      blacklisted: dto.blacklisted,
      createdAt: dto.createdAt,
      updatedAt: dto.updatedAt,
    });
  };
}
