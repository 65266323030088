import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog } from '@shared/components';
import { useCallback, useState } from 'react';
import { usePurchaseActions } from '@retail/purchase-agreements/context';
import { useAppTFunction } from '@retail/app/i18n';
import { PriceType, PurchaseCondition } from '@retail/calculus/types';
import { AgreementSelect } from '@retail/purchase-agreements/components';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { SelectItemAgreements } from './SelectItemAgreements';

interface Props {
  open: boolean;
  onClose: () => void;
  agreements: PurchaseAgreement[];
  agreementToEdit: PurchaseCondition;
  supplierId: number;
}

function ChangeSelectedAgreementDialog({
  open,
  agreements,
  agreementToEdit,
  onClose,
  supplierId,
}: Props) {
  const t = useAppTFunction();

  const { activateOrChangeCondition, isLoadingPostChanges } = usePurchaseActions({
    mgSupplierId: supplierId,
  });
  const [selectedAgreement, selectAgreement] = useState<number>(agreementToEdit.agreementId);
  const [priceType, setPriceType] = useState<PriceType | undefined>(agreementToEdit.priceType);
  const handleSelectAgreement = (agreementId: number, priceType?: PriceType) => {
    selectAgreement(agreementId);
    setPriceType(priceType);
  };

  const submitAgreements = useCallback(() => {
    activateOrChangeCondition({
      agreementId: selectedAgreement,
      groupNumber: agreementToEdit.productGroup,
      mgItemNumber: agreementToEdit.mgItemNumber,
      supplierLevel: !agreementToEdit.productGroup && !agreementToEdit.mgItemNumber,
      fromDate: new Date(),
      priceType,
    });

    onClose();
  }, [
    activateOrChangeCondition,
    agreementToEdit.mgItemNumber,
    agreementToEdit.productGroup,
    onClose,
    priceType,
    selectedAgreement,
  ]);

  const conditionLevelName =
    agreementToEdit.primaryText ??
    agreementToEdit.productGroupName ??
    agreementToEdit.mgSupplierName;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pb: 1 }}>
        {t('purchaseAgreements.agreement.actions.editConditionFor', { name: conditionLevelName })}
      </DialogTitle>

      <DialogContent>
        {agreementToEdit.mgItemNumber && priceType ? (
          <SelectItemAgreements
            supplierId={supplierId}
            mgItemNumber={agreementToEdit.mgItemNumber}
            selectedAgreement={selectedAgreement}
            selectedPriceType={priceType}
            selectAgreement={handleSelectAgreement}
          />
        ) : (
          <AgreementSelect
            agreements={agreements}
            selectedAgreement={selectedAgreement}
            selectAgreement={handleSelectAgreement}
          />
        )}
      </DialogContent>

      <DialogActions sx={{ display: 'flex', mt: 1 }}>
        <ContainedButton loading={isLoadingPostChanges} onClick={submitAgreements}>
          {t('purchaseAgreements.agreement.actions.setToApproval')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(ChangeSelectedAgreementDialog);
