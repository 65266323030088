import { useProductsTFunction } from '@retail/products/i18n';
import {TableBody, TableRow,  } from '@mui/material';
import { MgSupplierItem } from '@retail/products/types';
import { environmentKeys } from './helpers';
import { CheckIcon } from '@shared/custom-icons';
import { DocumentationKeyTableCell, DocumentationTableCell } from '../styles';

interface Props {
  suppliers: Partial<MgSupplierItem>[];
}

export const EnvironmentDocumentationTableBody = ({ suppliers }: Props) => {
  const t = useProductsTFunction();

  return (
    <TableBody>
      {environmentKeys.map((key, rowIndex) => (
        <TableRow key={key}>
          <DocumentationKeyTableCell index={rowIndex}>
            {/* @ts-ignore */}
            {t(`products.mgItem.environmentDocumentation.${key as keyof typeof environmentKeys}`)}
          </DocumentationKeyTableCell>

          {suppliers.map((supplier, supplierIndex) => (
            <DocumentationTableCell
              key={`${rowIndex}-${supplier.id || supplierIndex}`}
              align="center"
              index={rowIndex}
            >
              {supplier.environment?.[key] ? <CheckIcon fontSize="small" /> : '-'}
            </DocumentationTableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};
