import { Breakpoint, DialogContent, DialogTitle, Stack, Typography, alpha } from '@mui/material';
import { Dialog, Paper, TagHeading } from '@shared/components';
import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  header: string;
  subheader?: string | React.ReactNode;
  open: boolean;
  width?: Breakpoint;
  onClose: () => void;
}

export function DialogLayout({ children, header, onClose, open, width, subheader }: Props) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={width}>
      <Stack component={DialogTitle} gap={2} pb={subheader ? 2 : 0}>
        <TagHeading color="primary">
          <Typography variant="h6">{header}</Typography>
        </TagHeading>
        <Stack>{subheader}</Stack>
      </Stack>
      <DialogContent>
        <Paper
          variant="outlined"
          sx={{
            p: 0,
            bgcolor: ({ palette }) => alpha(palette.info.main, 0.1),
          }}
        >
          {children}
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
