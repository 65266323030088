import { Stack } from '@mui/material';
import { SearchInputField } from '@shared/components';
import { useSuppliersTranslation } from '@retail/suppliers/i18n';
import React, { FC, useMemo } from 'react';
import { ToggleGropOption, ToggleGroup, ToggleSwitch } from '@retail/components';

export type HasStandardAgreementOption = 'UNSPECIFIED' | 'NO' | 'YES';

interface Props {
  searchInput: string;
  onSearchChange: (searchInput: string) => void;
  hasAgreements: boolean;
  hasAgreementsToggle: () => void;
  hasStandardAgreementOption: HasStandardAgreementOption;
  onChangeHasStandardAgreementOption: (
    hasStandardAgreementOption: HasStandardAgreementOption
  ) => void;
}

export const SuppliersTableToolbar: FC<Props> = ({
  searchInput,
  onSearchChange,
  hasAgreementsToggle,
  hasAgreements,
  hasStandardAgreementOption,
  onChangeHasStandardAgreementOption,
}) => {
  const { t } = useSuppliersTranslation();

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value);
  };

  const hasStandardAgreementOptions: ToggleGropOption<HasStandardAgreementOption>[] = useMemo(
    () => [
      {
        label: t('suppliers.actions.hasStandardAgreement.unspecified'),
        value: 'UNSPECIFIED',
      },
      {
        label: t('suppliers.actions.hasStandardAgreement.yes'),
        value: 'YES',
      },
      {
        label: t('suppliers.actions.hasStandardAgreement.no'),
        value: 'NO',
      },
    ],
    [t]
  );

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      pt={3}
      pb={1}
      gap={2}
    >
      <SearchInputField
        placeholder={t('suppliers.actions.search')}
        value={searchInput}
        onChange={handleSearchInputChange}
        onReset={() => onSearchChange('')}
      />
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <ToggleSwitch
          toggle={hasAgreements}
          setToggle={hasAgreementsToggle}
          text={t('suppliers.actions.showWithAgreements')}
        />
        <ToggleGroup
          label={t('suppliers.actions.hasStandardAgreement.label')}
          options={hasStandardAgreementOptions}
          value={hasStandardAgreementOption}
          onChange={onChangeHasStandardAgreementOption}
        />
      </Stack>
    </Stack>
  );
};
