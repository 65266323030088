import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { Supplier } from '@retail/suppliers/types';

interface Props {
  suppliers: Supplier[];
  onSelectSupplier: (value: number) => void;
}

export function SelectSupplierAutocomplete({ suppliers, onSelectSupplier }: Props) {
  const t = useProductsTFunction();
  return (
    <Stack flexDirection="row" gap={1} alignItems="center">
      <Typography
        whiteSpace="nowrap"
        component="label"
        htmlFor="supplierSelect"
        variant="body1"
        fontWeight={500}
      >
        {t('products.supplier.changeSupplier')}
      </Typography>
      <Autocomplete
        id="supplierSelect"
        size="small"
        filterSelectedOptions
        disableClearable
        isOptionEqualToValue={(option, value) => option.mgSupplierId === value.mgSupplierId}
        onChange={(_, newValue) => onSelectSupplier(newValue.mgSupplierId)}
        options={suppliers}
        getOptionLabel={({ name }) => name}
        renderInput={(params) => (
          <TextField {...params} placeholder={t('products.supplier.searchForSuppliers')} />
        )}
      />
    </Stack>
  );
}
