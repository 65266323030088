import { IconButton } from '@mui/material';

interface Props {
  onClick: () => void;
  icon: JSX.Element;
}

export const CustomIconButton = ({ onClick, icon }: Props) => {
  return (
    <IconButton
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
    >
      {icon}
    </IconButton>
  );
};
