import { PurchaseConditionDTO } from '../dtos';
import { Operation, PriceType } from './Condition';
import { ConditionBase, ConditionBaseProps } from './ConditionBase';

interface PurchaseConditionProps extends ConditionBaseProps<PurchaseCondition> {
  agreementId: number;
  agreementText: string;
  priceType?: PriceType;
  standardAgreement: boolean;
}

export class PurchaseCondition extends ConditionBase<PurchaseCondition> {
  agreementId: number;
  agreementText: string;
  priceType?: PriceType;
  standardAgreement: boolean;

  constructor({
    agreementId,
    agreementText,
    priceType,
    standardAgreement,
    ...superProps
  }: PurchaseConditionProps) {
    super(superProps);
    this.agreementId = agreementId;
    this.agreementText = agreementText;
    this.priceType = priceType;
    this.standardAgreement = standardAgreement;
  }

  static fromDto = (dto: PurchaseConditionDTO): PurchaseCondition =>
    new PurchaseCondition({
      id: dto.id,
      assortmentId: dto.assortmentId,
      assortmentType: dto.assortmentType,
      mgSupplierId: dto.mgSupplierId,
      bindingType: dto.bindingType,
      levelProductGroup: dto.levelProductGroup,
      levelProductGroupName: dto.levelProductGroupName,
      productGroup: dto.productGroup,
      productGroupName: dto.productGroupName,
      mgItemNumber: dto.mgItemNumber,
      finfoNumber: dto.finfoNumber,
      mgSupplierName: dto.mgSupplierName,
      mgSupplierItemId: dto.mgSupplierItemId,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: new Date(dto.validFrom),
      validTo: dto.validTo ? new Date(dto.validTo) : undefined,
      active: dto.active,
      current: dto.current,
      agreementId: dto.agreementId,
      agreementText: dto.agreementText,
      priceType: dto.priceType,
      inherited: dto.inherited,
      source: dto.source,
      standardAgreement: dto.standardAgreement,
      futureConditions: dto.futureConditions?.map(PurchaseCondition.fromDto),
      subConditions: dto.subConditions?.map(PurchaseCondition.fromDto),
      replacesConditions: dto.replacesConditions?.map(PurchaseCondition.fromDto),
      newCondition: dto.newCondition && PurchaseCondition.fromDto(dto.newCondition),
      operation: dto.operation as Operation,
    });
}
