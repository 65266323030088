import { Priority } from '@retail/my-assortment/types';

interface SupplierWithPriorityProps {
  id: number;
  name: string;
  priority: Priority | null;
}

export class SupplierWithPriority {
  id: number;
  name: string;
  priority: Priority | null;

  constructor({ id, name, priority }: SupplierWithPriorityProps) {
    this.id = id;
    this.name = name;
    this.priority = priority;
  }

  static sortByPriority = (supplierA: SupplierWithPriority, supplierB: SupplierWithPriority) => {
    const valueA =
      supplierA.priority === Priority.TEN ? 2 : supplierA.priority === Priority.ZERO ? 1 : 0;
    const valueB =
      supplierB.priority === Priority.TEN ? 2 : supplierB.priority === Priority.ZERO ? 1 : 0;
    return valueB - valueA;
  };
}
