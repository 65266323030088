import { Menu, MenuItem } from '@mui/material';
import { Actions } from '@retail/calculus/types';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';

interface Props {
  open: boolean;
  id: string;
  anchorEl: HTMLElement | null;
  isActivated: boolean;
  isInherited: boolean;
  onClose: () => void;
  setAction: (action: Actions) => void;
}

export const AgreementTableActions = ({
  open,
  id,
  anchorEl,
  isActivated,
  isInherited,
  onClose,
  setAction,
}: Props) => {
  const t = usePurchaseAgreementsTFunction();

  return (
    <Menu open={open} id={id} anchorEl={anchorEl} onClose={onClose}>
      <MenuItem
        disabled={isActivated && isInherited}
        onClick={() => {
          if (!isActivated) {
            setAction('activate');
          } else setAction('deactivate');
          onClose();
        }}
      >
        {t(`purchaseAgreements.agreement.actions.${isActivated ? 'deactivate' : 'activate'}`)}
      </MenuItem>
    </Menu>
  );
};
