import { ExpandCircleDownRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material';

import { CarouselItem, IconButton } from './Carousel';

interface Props {
  items: Array<CarouselItem>;
  activeSlide: number;
  setActiveSlide: (num: number) => void;
  disabled?: boolean;
}

export function NextButton({ items, activeSlide, setActiveSlide, disabled }: Props) {
  const { palette } = useTheme();

  const isDisabled = activeSlide === items.length - 1;

  return (
    <IconButton
      disabled={isDisabled || disabled}
      onClick={() => setActiveSlide(activeSlide + 1)}
      as="a"
      sx={{
        zIndex: 2,
        cursor: disabled || isDisabled ? undefined : 'pointer',
        fontSize: '2rem',
      }}
    >
      <ExpandCircleDownRounded
        fontSize="inherit"
        sx={{
          transform: 'rotate(-90deg)',
          color: disabled ? palette.grey[50] : palette.grey[isDisabled ? 100 : 500],
        }}
      />
    </IconButton>
  );
}
