import { Close } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import { Chip } from '@shared/components';

interface Props {
  id: number | string;
  name: string;
  onDelete: () => void;
}

export const SearchTag = ({ id, name, onDelete }: Props) => {
  const { palette } = useTheme();
  return (
    <Chip
      key={id}
      sx={{ m: 0.3 }}
      deleteIcon={<Close />}
      onDelete={onDelete}
      label={
        <Typography color={palette.grey.A400} variant="body2" fontWeight="bold">
          {name}
        </Typography>
      }
      backgroundColor={palette.primary.A100}
    />
  );
};
