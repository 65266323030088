import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Select,
  Typography,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { ContainedButton, TagHeading } from '@shared/components';
import { DownloadFileIcon } from '@shared/custom-icons';
import { MgItem, MgItemEnvironment, MgItemEtim, MgSupplierItem } from '@retail/products/types';
import { useState } from 'react';

interface Props {
  suppliers: MgItem['supplierItems'];
  isOpen: boolean;
  onClose: () => void;
  isEnvironmental: boolean;
}

export const DownloadDocumentationDialog = ({
  suppliers,
  isOpen,
  onClose,
  isEnvironmental,
}: Props) => {
  const t = useProductsTFunction();
  const documentType = isEnvironmental ? 'environment' : 'etim';

  const [selectedSupplier, setSelectedSupplier] = useState<MgSupplierItem['id'] | undefined>(
    undefined
  );

  const [documents, setDocuments] = useState<MgItemEnvironment | MgItemEtim | undefined>(undefined);

  const handleSelectSupplier = (supplierId: MgSupplierItem['id']) => {
    setSelectedSupplier(supplierId);

    const supplier = suppliers.find((supplier) => supplier.id === supplierId);
    setDocuments(supplier ? (isEnvironmental ? supplier.environment : supplier.etim) : undefined);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <TagHeading>
          <Typography variant="h6">
            {t('products.mgItem.documentation.downloadDocumentation')}
          </Typography>
        </TagHeading>
      </DialogTitle>

      <DialogContent sx={{ overflowY: 'inherit' }}>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="supplier-select">
              <Typography>{t('products.mgItem.documentation.selectSupplier')}</Typography>
            </InputLabel>

            <Select
              labelId="supplier-select"
              data-test-id="documentation-download-select"
              required
              label={t('products.mgItem.documentation.selectSupplier')}
              placeholder={t('products.mgItem.documentation.selectSupplier')}
              fullWidth
              margin="none"
              size="small"
              onChange={(event) => handleSelectSupplier(String(event.target.value))}
            >
              {suppliers.map((supplier) => (
                <MenuItem value={supplier.mgParticipant.id} key={supplier.mgParticipant.id}>
                  {supplier.mgParticipant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedSupplier && (
            <DocumentList>
              <FormGroup>
                <DocumentListItem index={0} key="hei" disableGutters>
                  <Typography sx={{ fontWeight: 600 }}>
                    {t('products.mgItem.documentation.title')}
                  </Typography>
                </DocumentListItem>

                {Object.keys(documents ?? {})?.map((documentKey, index) => {
                  // @ts-ignore
                  const label = t(`products.mgItem.${documentType}.${documentKey}`);

                  return (
                    <DocumentListItem index={index} key="hei" disableGutters>
                      <ListItemText>
                        <FormControlLabel control={<Checkbox />} label={label} />
                      </ListItemText>
                    </DocumentListItem>
                  );
                })}
              </FormGroup>
            </DocumentList>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <ContainedButton color="secondary" loading={false}>
          <DownloadFileIcon />
          {t('products.mgItem.documentation.downloadSelected')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

const DocumentList = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[50]}`,
  borderRadius: theme.shape.borderRadius * 2,
}));

const DocumentListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'index',
})<{ index: number }>(({ theme, index }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: index > 0 ? theme.spacing(1, 2) : theme.spacing(2, 3),
  borderTopLeftRadius: index === 0 ? theme.shape.borderRadius : 'none',
  borderTopRightRadius: index === 0 ? theme.shape.borderRadius : 'none',
  backgroundColor: index % 2 === 0 ? theme.palette.common.white : theme.palette.info[200],
}));
