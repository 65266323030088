import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { urlConstant } from '@retail/utils';
import { QueryOptions } from '@shared/fetch-utils';
import { assortmentsPath } from './productGroupsApi';
import { useMutateMPEFetch } from '@retail/app/hooks';

export const deleteConditionsPath = 'delete-conditions';

export interface PostParams extends QueryOptions {
  assortmentId: number;
  departmentId: string;
}

export const useDeleteCondition = ({ assortmentId, departmentId, ...queryOptions }: PostParams) => {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<void, number[]>({
    url: `${mpe.url}${urlConstant}${assortmentsPath}/${assortmentId}/${deleteConditionsPath}?departmentId=${departmentId}`,
    method: 'DELETE',
    token,
    ...queryOptions,
  });
};
