import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { MgSupplierItemSearchResultDTO, PaginationResultDTO } from '@retail/products/types';
import { useMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';

export const suppliersPath = '/suppliers';
export const itemsPath = '/items';

interface ApiProps extends QueryOptions {
  supplierId: number;
  query?: string;
  page: number;
  size: number;
}

export function useFetchMgItemsForSupplier({
  supplierId,
  query,
  page,
  size,
  ...queryOptions
}: ApiProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<PaginationResultDTO<MgSupplierItemSearchResultDTO[]>>({
    method: 'GET',
    queryKey: ['product-groups', supplierId, query],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${suppliersPath}/${supplierId}${itemsPath}`,
      searchParams: { page, size, query },
    }),
    ...queryOptions,
  });
}
