import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { brandsRoute, membersRoute, useMpeAuthContext } from '@retail/auth/data-access';
import { fetchFile, QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { Assortment, AssortmentPriorityResponseDTO, ItemsDTO } from '@retail/my-assortment/types';
import { useMPEFetch, useMutateMPEFetch, useSelectedOrgUnitHeader } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { assortmentsPath } from '@retail/calculus/data-access';
import { useMutation } from '@tanstack/react-query';

export const assortmentPath = '/assortments';
export const departmentsPath = '/departments';
export const validatePrioritiesPath = '/validate-priorities';
export const assortmentXLSXPath = '/assortment.xlsx';

interface BrandProps extends QueryOptions {
  brandId: string;
}

interface MemberProps extends QueryOptions {
  memberId: string;
}

interface DepartmentProps extends QueryOptions {
  departmentId: string;
}

export function useFetchBrandAssortments({ brandId, ...queryOptions }: BrandProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<Assortment[]>({
    method: 'GET',
    queryKey: ['brandAssortments'],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${brandsRoute}${brandId}${assortmentPath}`,
    }),
    ...queryOptions,
  });
}

export function useFetchMemberAssortments({ memberId, ...queryOptions }: MemberProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<Assortment[]>({
    method: 'GET',
    queryKey: ['memberAssortments'],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${membersRoute}/${memberId}${assortmentPath}`,
    }),
    ...queryOptions,
  });
}

export function useFetchDepartmentAssortments({ departmentId, ...queryOptions }: DepartmentProps) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<Assortment[]>({
    method: 'GET',
    queryKey: ['departmentAssortments'],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${departmentsPath}/${departmentId}${assortmentPath}`,
    }),
    ...queryOptions,
  });
}

export function useMutateValidatePriorities({ ...queryOptions }) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<AssortmentPriorityResponseDTO, ItemsDTO[]>({
    method: 'POST',
    url: () => `${mpe.url}${urlConstant}${assortmentsPath}${validatePrioritiesPath}`,
    token,
    ...queryOptions,
  });
}

export interface FetchAssortmentProps extends QueryOptions {
  assortmentId: number;
  includeOwner?: boolean;
  supplierIds?: number[];
  groupNumbers?: string[];
  query?: string;
}

export function useExportAssortment() {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  return useMutation(
    ({
      assortmentId,
      includeOwner,
      supplierIds,
      groupNumbers,
      query,
      ...queryOptions
    }: FetchAssortmentProps) =>
      fetchFile({
        url: buildUrl({
          base: mpe.url,
          route: `${urlConstant}${assortmentsPath}/${assortmentId}${assortmentXLSXPath}`,
          searchParams: {
            includeOwner,
            supplierIds,
            groupNumbers,
            query,
          },
        }),
        token,
        method: 'GET',
        headers: { Accept: 'application/octet-stream', ...selectedOrgUnitHeader },
        ...queryOptions,
      })
  );
}
