export enum Source {
  MPE = 'MPE',
  SOLVE = 'SOLVE',
}

export enum Type {
  BASE = 'BASE',
  DRAFT = 'DRAFT',
}

export type AssortmentDTO = {
  id: number;
  title: string;
  source: Source;
  updatedAt: string;
  createdAt: string;
  type: Type;
  readOnly: boolean;
  parent?: number;
  orgUnitId: string;
  memberId?: string;
  departmentId?: string;
  fromDate?: string;
};
