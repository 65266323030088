import { Stack } from '@mui/material';
import { StandardPaper, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { Supplier } from '@retail/suppliers/types';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { UniversalFormProvider } from '@shared/components';
import { activateAgreementSchema } from '@retail/purchase-agreements/components';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useAgreements } from '@retail/purchase-agreements/context';
import { ConditionState } from '@retail/calculus/types';
import { ActivateAgreementDialog } from './ActivateAgreement';
import { ConditionsContainer } from './PurchaseConditions';

interface Props {
  typesToShow: ConditionState;
  selectedSupplier: Supplier;
  isCreateOpen: boolean;
  closeCreate: () => void;
}

export function PurchaseContainer({
  typesToShow,
  selectedSupplier,
  isCreateOpen,
  closeCreate,
}: Props) {
  const t = useCalculusTFunction();

  const { selectedDepartment } = useSelectedOrgUnit();

  const { agreements } = useAgreements({ supplierId: selectedSupplier.mgSupplierId });

  return (
    <Stack width="100%" flexGrow={1}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        {isCreateOpen && (
          <UniversalFormProvider
            schema={activateAgreementSchema(t)}
            defaultValues={activateAgreementSchema(t).getDefault()}
          >
            <ActivateAgreementDialog
              agreements={agreements}
              open={true}
              onClose={closeCreate}
              supplierId={selectedSupplier.mgSupplierId}
            />
          </UniversalFormProvider>
        )}
      </Stack>
      {selectedDepartment ? (
        <SuspenseWithSentryErrorBoundary>
          <ConditionsContainer
            supplier={selectedSupplier}
            typesToShow={typesToShow}
            agreements={agreements}
          />
        </SuspenseWithSentryErrorBoundary>
      ) : (
        <StandardPaper>
          <div>brandContext</div>
        </StandardPaper>
      )}
    </Stack>
  );
}
