import { QueryOptions } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { buildUrl } from '@shared/utils';
import { ItemAgreementDTO } from '@retail/purchase-agreements/types';
import { useMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { assortmentsPath } from '@retail/calculus/data-access';
import { PaginationResultDTO } from '@retail/products/types';
import { agreementPricesPath } from './itemAgreementsApi';

interface SearchParams {
  groupNumbers?: string[];
  page?: number;
  size?: number;
  query?: string;
}

interface SearchParamsPriceList extends SearchParams {
  agreementId: number;
}

export const purchaseAgreementsPath = 'purchase-agreements';
const priceListPath = '/prices';

export function useFetchPriceList(
  { agreementId, groupNumbers, page, size, query }: SearchParamsPriceList,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<PaginationResultDTO<ItemAgreementDTO[]>>({
    method: 'GET',
    queryKey: ['priceList', page, agreementId, groupNumbers, size, query],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}/${purchaseAgreementsPath}/${agreementId}${priceListPath}`,
      searchParams: { page, size, query, groupNumbers: groupNumbers, active: true },
    }),
    ...queryOptions,
  });
}

interface SearchParamsActiveItemPrices extends SearchParams {
  assortmentId: number;
  mgSupplierId: number;
  baseAssortment?: boolean;
  inherited?: boolean;
}

export function useFetchActiveItemPrices(
  {
    assortmentId,
    mgSupplierId,
    groupNumbers,
    page,
    size,
    query,
    baseAssortment = true,
    inherited = false,
  }: SearchParamsActiveItemPrices,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<PaginationResultDTO<ItemAgreementDTO[]>>({
    method: 'GET',
    queryKey: [
      'active-item-prices',
      page,
      assortmentId,
      groupNumbers,
      size,
      query,
      baseAssortment,
      inherited,
    ],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${assortmentsPath}/${assortmentId}${agreementPricesPath}`,
      searchParams: {
        mgSupplierId,
        page,
        size,
        query,
        groupNumbers: groupNumbers,
        baseAssortment,
        inherited,
      },
    }),
    ...queryOptions,
  });
}
