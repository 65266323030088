import { Stack, Typography, styled } from '@mui/material';
import { ImageIconLarge, ImageIconSmall } from '@shared/custom-icons';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props {
  isPreview?: boolean;
}

export const NoImageElement = ({ isPreview = true }: Props) => {
  const t = useProductsTFunction();

  return (
    <NoImageElementStack isPreview={isPreview}>
      {isPreview ? <ImageIconSmall /> : <ImageIconLarge />}

      {!isPreview && (
        <Typography
          variant="h5"
          fontWeight={500}
          sx={{ marginTop: (theme) => theme.spacing(2), color: (theme) => theme.palette.grey[100] }}
        >
          {t('products.mgItem.notFound.noImage')}
        </Typography>
      )}
    </NoImageElementStack>
  );
};

const NoImageElementStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isPreview',
})<{ isPreview: boolean }>(({ theme, isPreview }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: isPreview ? 137 : '100%',
  height: isPreview ? 137 : '100%',
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: isPreview ? theme.palette.grey[50] : theme.palette.common.white,
}));
