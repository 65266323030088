import { useAppTFunction } from '@retail/app/i18n';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { Breadcrumbs, BreadcrumbsProps, TagHeading } from '@shared/components';

interface Props extends BreadcrumbsProps {
  marginBottom?: number;
}

export function TagBreadcrumbs({ items, marginBottom }: Props) {
  const t = useAppTFunction();
  return (
    <TagHeading color="primary" marginBottom={marginBottom || 0}>
      <Breadcrumbs
        fontSize="sm"
        items={[{ label: t('app.appName'), href: mgPrisRoutes.root.getFullLinkPath() }, ...items]}
      />
    </TagHeading>
  );
}
