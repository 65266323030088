import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { Supplier } from '@retail/suppliers/types';

interface Props {
  suppliers: Supplier[];
  selectedSupplier: Supplier | null;
  selectSupplier: (supplierId: Supplier | null) => void;
}

export function SelectSupplier({ suppliers, selectedSupplier, selectSupplier }: Props) {
  const t = useProductsTFunction();
  return (
    <Stack gap={0.5}>
      <Typography
        component="label"
        htmlFor="supplierSelect"
        variant="h6"
        color={({ palette }) => palette.grey.A400}
      >
        {t('products.supplier.name')}
      </Typography>
      <Autocomplete
        id="supplierSelect"
        options={suppliers}
        sx={{ width: 320 }}
        size="small"
        isOptionEqualToValue={(option, value) => option.mgSupplierId === value.mgSupplierId}
        value={
          suppliers.find((supplier) => supplier.mgSupplierId === selectedSupplier?.mgSupplierId) ||
          null
        }
        onChange={(_, newValue) => {
          selectSupplier(newValue ? newValue : null);
        }}
        getOptionLabel={({ name, mgSupplierId }) => `${name} ${mgSupplierId}`}
        renderOption={(props, option) => (
          <Stack
            {...props}
            component="li"
            key={option.mgSupplierId}
            borderBottom={({ palette }) => `1px solid ${palette.divider}`}
          >
            <Typography>{option.name}</Typography> {option.mgSupplierId}
          </Stack>
        )}
        renderInput={(params) => (
          <TextField {...params} placeholder={t('products.supplier.searchForSuppliers')} />
        )}
      />
    </Stack>
  );
}
