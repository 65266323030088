import { useParams } from 'react-router-dom';
import { useAppTFunction } from '@retail/app/i18n';
import { NotFoundError, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { Stack } from '@mui/material';
import { Supplier } from '@retail/suppliers/types';
import { DepartmentMarkupContainer } from './DepartmentMarkupContainer';
import { ConditionState } from '@retail/calculus/types';

interface Props {
  selectedSupplier: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  closeCreate: () => void;
}

export function MarkupContainer({
  selectedSupplier,
  typesToShow,
  isCreateOpen,
  closeCreate,
}: Props) {
  const t = useAppTFunction();
  const { selectedDepartment } = useSelectedOrgUnit();
  const { supplierId } = useParams<{ supplierId: string }>();

  return (
    <Stack width="100%">
      {selectedSupplier ? (
        selectedDepartment ? (
          <SuspenseWithSentryErrorBoundary>
            <DepartmentMarkupContainer
              supplier={selectedSupplier}
              typesToShow={typesToShow}
              isCreateOpen={isCreateOpen}
              closeCreate={closeCreate}
            />
          </SuspenseWithSentryErrorBoundary>
        ) : (
          <div>brandContext</div>
        )
      ) : (
        <NotFoundError
          title={t('markup.supplier.notFound.title')}
          subtitle={t('markup.supplier.notFound.subtitle', { supplierId })}
          buttonText={t('markup.supplier.notFound.goBack')}
        />
      )}
    </Stack>
  );
}
