import { useCallback, useMemo } from 'react';
import { Box, IconButton, Link, Stack, TableCell, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { dataGridBoldClass } from '@shared/styles';
import { StackedTableCellWithChangeInValue, StackedTextCell } from '@retail/components';
import { Operation, PurchaseCondition } from '@retail/calculus/types';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { TrashIcon } from '@sanity/icons';
import { useMountEffect } from '@shared/hooks';

interface Props {
  draftConditions: PurchaseCondition[];
  isLoading: boolean;
  handleDeleteConditions: (ids: number[]) => void;
  handleSetRowToSeePrices: () => void;
  onSelectConditions: (conditionIds: number[]) => void;
}

type GridRowCondition = GridColDef<PurchaseCondition>;

const baseColumnProps: Partial<GridRowCondition> = {
  width: 150,
  sortable: false,
  headerAlign: 'center',
  align: 'center',
};

export const DraftDrawerPurchaseTable = ({
  draftConditions,
  isLoading,
  handleDeleteConditions,
  handleSetRowToSeePrices,
  onSelectConditions,
}: Props) => {
  const t = useAppTFunction();
  const apiRef = useGridApiRef();

  useMountEffect(() => {
    apiRef.current.selectRows(draftConditions.map(({ id }) => id));
  });

  const getConditionResponseDate = (date?: Date) => (date ? dayjs(date).format('ll') : undefined);

  const agreementColumns: GridRowCondition[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'supplier',
        width: 180,
        headerName: t('markup.supplierItems.supplier'),
        renderCell: ({ row }: { row: PurchaseCondition }) => {
          return (
            <StackedTextCell
              centerAlign
              topLine={row.mgSupplierName}
              bottomLine={row.mgSupplierId}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'agreementId',
        headerName: t('purchaseAgreements.agreement.columns.agreementId'),
        renderCell: ({ row }: { row: PurchaseCondition }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          return (
            <StackedTableCellWithChangeInValue
              newValue={newCondition.agreementId}
              prevValue={oldCondition?.agreementId}
              lineThrough={!!row.operation}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'agreementText',
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
        width: 180,
        renderCell: ({ row }: { row: PurchaseCondition }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          return (
            <StackedTableCellWithChangeInValue
              newValue={newCondition.agreementText}
              prevValue={oldCondition?.agreementText}
              lineThrough={!!row.operation}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'primaryText',
        width: 300,
        headerName: t('freight.levelOrItemName'),
        renderCell: ({ row }: { row: PurchaseCondition }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          const newValue = newCondition.getDisplayText();
          const prevValue = oldCondition?.getDisplayText() || '';

          return (
            <StackedTableCellWithChangeInValue
              newValue={newValue}
              prevValue={prevValue}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'mgItemFinfoNumber',
        cellClassName: () => dataGridBoldClass,
        headerName: t('myAssortment.columns.mgItemFinfoNumber'),
        renderCell: ({ row }: { row: PurchaseCondition }) => {
          const isDelete = row.operation === 'DELETE';
          const hasLineThrough = !row.newCondition && isDelete;

          return (
            <StackedTextCell
              centerAlign
              topLine={row.mgItemNumber}
              bottomLine={row.finfoNumber}
              lineThroughTopLine={hasLineThrough}
              lineThroughBottomLine={hasLineThrough}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'priceList',
        cellClassName: () => dataGridBoldClass,
        headerName: t('purchaseAgreements.priceList.priceList'),
        renderCell: ({ row }: { row: PurchaseCondition }) => {
          if (handleSetRowToSeePrices) {
            return (
              <TableCell component="span" align="center">
                <Link component="button" onClick={() => handleSetRowToSeePrices()}>
                  {t('purchaseAgreements.priceList.lookUpPrices')}
                </Link>
              </TableCell>
            );
          }

          return null;
        },
      },
    ],
    [t, handleSetRowToSeePrices]
  );

  // Common for all calculus areas
  const basicEndColumns: GridRowCondition[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'validFrom',
        headerName: t('markup.activeFrom'),
        renderCell: ({ row }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          const newValue =
            row.operation === Operation.DELETE ||
            dayjs(newCondition.validFrom).isSame(dayjs().startOf('D'))
              ? undefined
              : newCondition.validFrom;

          return (
            <StackedTableCellWithChangeInValue
              newValue={getConditionResponseDate(newValue)}
              prevValue={getConditionResponseDate(oldCondition?.validFrom)}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        headerName: t('markup.activeTo'),
        renderCell: ({ row }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          return (
            <StackedTableCellWithChangeInValue
              newValue={getConditionResponseDate(newCondition?.validTo) ?? '->'}
              prevValue={getConditionResponseDate(oldCondition?.validTo) ?? '->'}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'source',
        headerName: t('markup.source'),
        renderCell: ({ row }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          return (
            <StackedTableCellWithChangeInValue
              newValue={newCondition.source}
              prevValue={oldCondition?.source}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        renderCell: ({ row }) => (
          <IconButton disabled={isLoading} onClick={() => handleDeleteConditions([row.id])}>
            <TrashIcon fontSize="25px" />
          </IconButton>
        ),
      },
    ],
    [handleDeleteConditions, isLoading, t]
  );

  const columns = useMemo(
    () => [...agreementColumns, ...basicEndColumns],
    [agreementColumns, basicEndColumns]
  );

  const onRowsSelectionHandler = useCallback(
    (selections: GridSelectionModel) => {
      onSelectConditions(selections as number[]);
    },
    [onSelectConditions]
  );

  return (
    <Box height="100%">
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        getRowId={(row) => row.id}
        columns={columns}
        rows={draftConditions ?? []}
        rowHeight={70}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        hideFooter
        checkboxSelection
        onSelectionModelChange={onRowsSelectionHandler}
        components={{
          NoRowsOverlay: () => (
            <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
              <Typography variant="h3" color={(theme) => theme.palette.grey[200]}>
                {t('calculus.messages.noUpdates')}
              </Typography>
            </Stack>
          ),
        }}
      />
    </Box>
  );
};
