import { Clear } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { SearchIcon } from '@shared/custom-icons';
import { Dispatch, KeyboardEventHandler, SetStateAction, useCallback, useState } from 'react';
import { SearchTag } from '../../MgSupplierItem';

interface Props {
  articleIdSearch: string[];
  isDisabled: boolean;
  setArticleIdSearch: Dispatch<SetStateAction<string[]>>;
  setPage?: (newPage: number) => void;
  onSubmitSearch: (search: string) => void;
}

export function ArticleIdSearchField({
  articleIdSearch,
  isDisabled,
  setArticleIdSearch,
  setPage,
  onSubmitSearch,
}: Props) {
  const [searchFieldInput, setSearchFieldInput] = useState('');
  const t = useProductsTFunction();
  const submitSearch = useCallback(() => {
    onSubmitSearch(searchFieldInput);
    setSearchFieldInput('');
    setPage && setPage(0);
  }, [onSubmitSearch, searchFieldInput, setPage]);

  const onKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        submitSearch();
      }
    },
    [submitSearch]
  );

  return (
    <Stack gap={0.5}>
      <Typography
        component="label"
        htmlFor="freeSearch"
        variant="h6"
        pl={1}
        color={({ palette }) => palette.grey.A400}
      >
        {t('products.search.articleId')}
      </Typography>

      <TextField
        id="freeSearch"
        sx={{ width: 320 }}
        variant="outlined"
        disabled={isDisabled}
        placeholder={t('products.search.seachArticleIds')}
        value={searchFieldInput}
        onKeyDown={onKeyDown}
        InputProps={{
          startAdornment: (
            <Box p={0.5} alignSelf="center">
              {articleIdSearch.length === 0 && (
                <Stack>
                  <SearchIcon fontSize="small" />
                </Stack>
              )}
              <Stack>
                {articleIdSearch.map((id) => {
                  return (
                    <SearchTag
                      key={id}
                      id={id}
                      name={id}
                      onDelete={() =>
                        setArticleIdSearch((prevIds) => prevIds.filter((prevId) => prevId !== id))
                      }
                    />
                  );
                })}
              </Stack>
            </Box>
          ),
          endAdornment: searchFieldInput ? (
            <Stack flexDirection="row">
              <Button onClick={submitSearch}>{t('products.search.openSearch.search')}</Button>
              <IconButton
                size="small"
                onClick={() => {
                  setSearchFieldInput('');
                }}
              >
                <Clear />
              </IconButton>
            </Stack>
          ) : undefined,
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchFieldInput(event.target.value);
        }}
      />
    </Stack>
  );
}
