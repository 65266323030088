import { useFetchMpeMgItem } from '@retail/products/data-access';
import { MgItem } from '@retail/products/types';

import { MgItemDialog } from './MgItemDialog';

interface Props {
  itemNumber: number;
  isOpen: boolean;
  onClose: () => void;
}

export function MgItemDialogContainer({ itemNumber, isOpen, onClose }: Props) {
  const { data: mgItemDto } = useFetchMpeMgItem(
    {
      mgItemNumber: itemNumber,
    },
    { suspense: true }
  );

  const mgItem = MgItem.fromDto(mgItemDto!);

  return <MgItemDialog isOpen={isOpen} mgItem={mgItem} onClose={onClose} />;
}
