export enum CalculusAreas {
  PURCHASE = 'PURCHASE',
  FREIGHT = 'FREIGHT',
  MARKUP = 'MARKUP',
}

export enum CalculusTabOptions {
  PURCHASE = 'PURCHASE',
  FREIGHT = 'FREIGHT',
  MARKUP = 'MARKUP',
  ITEMS = 'ITEMS',
}
