import { Box, useTheme } from '@mui/material';
import { MGPrisIcon } from '@shared/custom-icons';

export function MGPrisLogo() {
  const { palette } = useTheme();

  return (
    <Box position="relative" height="100%">
      <Box
        sx={{
          position: 'absolute',
          top: 8,
          left: 115,
          zIndex: 1,
          color: palette.primary.main,
          fontSize: '42px',
        }}
      >
        <MGPrisIcon fontSize="inherit" />
      </Box>
      <Box
        sx={{
          width: '120px',
          height: '100%',
          backgroundColor: palette.common.white,
          transform: 'skew(-43deg)',
          marginLeft: '5rem',
          marginRight: '5rem',
        }}
      ></Box>
    </Box>
  );
}
