import { Box, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import {
  assortmentSpreadsheetValidatedAtom,
  assortmentSpreadsheetValidationAtom,
  isValidItem,
  UploadAssortmentContainer,
  useImportedAssortmentState,
  useResetImportedAssortmentState,
} from '@retail/my-assortment/components';
import {
  useMutateAddItemsToDraft,
  useMutateValidatePriorities,
} from '@retail/my-assortment/data-access';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { AssortmentUploadValidation, ItemsDTO } from '@retail/my-assortment/types';
import { getImportedItems } from '@retail/my-assortment/utils';
import { MgSupplierItemSearchContainer } from '@retail/products/containers';
import { MgSupplierItemSearchResult } from '@retail/products/types';
import { ContainedButton, Dialog, UnderlinedTabs } from '@shared/components';
import { mgprisPageHeight } from '@shared/styles';
import { useQueryClient } from '@tanstack/react-query';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

enum DraftTabs {
  ADD_ITEMS,
  IMPORT,
}

interface Props {
  memberId: string;
  draftAssortmentId: number;
  closeDialog: () => void;
}

export function AddItemsToDraft({ memberId, draftAssortmentId, closeDialog }: Props) {
  const t = useMyAssortmentTFunction();
  const queryClient = useQueryClient();

  const { selectedDepartment } = useSelectedOrgUnit();
  const importState = useImportedAssortmentState();
  const importedItems = getImportedItems(importState);

  const resetImportedAssortmentStates = useResetImportedAssortmentState();

  const [validationState, setValidationState] = useRecoilState(assortmentSpreadsheetValidationAtom);
  const [isValidated, setIsValidated] = useRecoilState(assortmentSpreadsheetValidatedAtom);

  const [checkedItems, setCheckedItems] = useState<MgSupplierItemSearchResult[]>([]);

  const [selectedTab, setSelectedTab] = useState<DraftTabs>(DraftTabs.ADD_ITEMS);
  const selectTab = (_: any, newTab: DraftTabs) => setSelectedTab(newTab);

  const tabs = useMemo(
    () => [
      { label: t('myAssortment.actions.addItems'), value: DraftTabs.ADD_ITEMS },
      { label: t('myAssortment.actions.importItemsToDraft'), value: DraftTabs.IMPORT },
    ],
    [t]
  );

  const { mutateAsync: postItems, isLoading: loadingPostItems } = useMutateAddItemsToDraft({
    memberId,
    assortmentId: draftAssortmentId,
  });

  const { mutateAsync: validateItemsAsync, isLoading: loadingValidateItems } =
    useMutateValidatePriorities({
      memberId: selectedDepartment?.memberId,
      assortmentId: draftAssortmentId,
    });

  const handleSubmitSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
    closeDialog();
  }, [closeDialog, queryClient]);

  const onSubmit = useCallback(
    (selectedItems: MgSupplierItemSearchResult[]) => {
      const items = selectedItems.map((item) => {
        return {
          mgSupplierId: item.mgParticipantAccountId,
          mgItemNumber: item.mgItemNumber,
          priority: 'ZERO',
        } as ItemsDTO;
      });
      postItems({
        body: items,
      })
        .then(() => {
          setCheckedItems([]);
          handleSubmitSuccess();
        })
        .catch((err) => console.error(err));
    },
    [handleSubmitSuccess, postItems]
  );

  const onValidateItems = useCallback(async () => {
    validateItemsAsync({ body: importedItems })
      .catch((err) => console.error(err))
      .then((response) => {
        const res = response as AssortmentUploadValidation;
        setValidationState(res);
      });
  }, [importedItems, setValidationState, validateItemsAsync]);

  const onImportItems = useCallback(() => {
    const validItems = importedItems.filter((item) => isValidItem(item, validationState));
    postItems({
      body: validItems,
    })
      .then(() => handleSubmitSuccess())
      .catch((err) => console.error(err));
  }, [handleSubmitSuccess, importedItems, postItems, validationState]);

  useEffect(() => {
    if (selectedTab !== DraftTabs.IMPORT) resetImportedAssortmentStates();
  }, [resetImportedAssortmentStates, selectedTab]);

  useEffect(() => {
    if (importState.status !== 'none' && importedItems.length > 0 && !isValidated) {
      onValidateItems();
      setIsValidated(true);
    }
  }, [importState.status, importedItems.length, isValidated, onValidateItems, setIsValidated]);

  return (
    <Dialog open onClose={closeDialog} fullScreen sx={{ padding: 2, height: mgprisPageHeight }}>
      <DialogTitle>
        <UnderlinedTabs id="draft" tabs={tabs} currentTab={selectedTab} onChange={selectTab} />
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexGrow: 1,
          width: '100%',
          justifyContent: 'center',
        }}
      >
        {selectedTab === DraftTabs.ADD_ITEMS && (
          <Box display="flex" flexGrow={1}>
            <MgSupplierItemSearchContainer
              clickableRow={false}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
          </Box>
        )}

        {selectedTab === DraftTabs.IMPORT && (
          <Stack mt={4} alignItems="center">
            <UploadAssortmentContainer isLarge />
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <ContainedButton
          loading={loadingValidateItems || loadingPostItems}
          onClick={() =>
            selectedTab === DraftTabs.ADD_ITEMS ? onSubmit(checkedItems) : onImportItems()
          }
        >
          {selectedTab === DraftTabs.IMPORT
            ? t('myAssortment.actions.addApprovedItemsToDraft')
            : t('myAssortment.actions.addItemsToDraft')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}
