import { MenuItem, Select } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  currentPageSize: number;
  updatePageSize: (newPageSize: number) => void;
  pageSizeOptions?: number[];
}

export function SelectPageSize({
  currentPageSize,
  updatePageSize,
  pageSizeOptions = [100, 200, 300, 400],
}: Props) {
  const t = useAppTFunction();

  return (
    <Select
      aria-label={t('common.updatePageSize')}
      size="small"
      sx={{ width: 80 }}
      value={currentPageSize}
      onChange={(event) => updatePageSize(Number(event.target.value))}
    >
      {pageSizeOptions.map((option) => {
        return (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
}
