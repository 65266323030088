import { DialogActions, DialogContent, Stack, Typography, alpha } from '@mui/material';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { ContainedButton, Dialog, Paper } from '@shared/components';
import { WarehouseAssortmentsList } from './WarehouseAssortmentsList';
import { UploadAssortmentContainer } from '../UploadAssortment';
import { Assortment } from '@retail/my-assortment/types';
import {
  assortmentSpreadsheetValidationAtom,
  useImportedAssortmentState,
} from '../UploadAssortment/assortmentImportState';
import { useRecoilValue } from 'recoil';

interface Props {
  open: boolean;
  onClose: () => void;
  baseAssortment: Assortment;
  nrOfItems: number;
  isLoadingAddItems: boolean;
  onSubmitItems: () => void;
}

export const AddFirstItemsToBaseAssortmentDialog = ({
  open,
  onClose,
  baseAssortment,
  nrOfItems,
  isLoadingAddItems,
  onSubmitItems,
}: Props) => {
  const { t } = useMyAssortmentTranslation();

  const importState = useImportedAssortmentState();
  const validationState = useRecoilValue(assortmentSpreadsheetValidationAtom);

  const hasValidationWarnings =
    !!validationState?.invalidDates.length ||
    !!validationState?.invalidItemNumbers.length ||
    !!validationState?.invalidPriorities.length ||
    !!validationState?.invalidSupplierIds.length;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack alignItems="center">
          <Typography variant="h4" pb={2}>
            {t('myAssortment.welcomeToMyAssortment.title')}
          </Typography>

          <Typography variant="body2">
            {t('myAssortment.welcomeToMyAssortment.descPart1')}
          </Typography>
          <Typography variant="body2">
            {t('myAssortment.welcomeToMyAssortment.descPart2')}
          </Typography>
        </Stack>

        <Stack mt={3} mx={6} gap={3}>
          <Paper
            variant="outlined"
            sx={{ p: 0, bgcolor: ({ palette }) => alpha(palette.info.main, 0.1) }}
          >
            <WarehouseAssortmentsList assortments={[baseAssortment]} nrOfItems={nrOfItems} />
          </Paper>

          <Stack alignItems="center">
            <UploadAssortmentContainer />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <ContainedButton
          disabled={importState.status === 'none'}
          onClick={onSubmitItems}
          loading={isLoadingAddItems}
          sx={{ alignSelf: 'flex-end' }}
          size="small"
        >
          {hasValidationWarnings
            ? t('myAssortment.actions.addApprovedItemsToDraft')
            : t('myAssortment.actions.addItemsToDraft')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
