import { AssortmentUploadValidation, ItemsDTO } from '@retail/my-assortment/types';
import { SpreadSheetCell, SpreadsheetStructure } from '@retail/monitoring-assortment/types';
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

export const assortmentSpreadsheetAtom = atom<SpreadsheetStructure | undefined>({
  key: 'importedAssortmentSpreadsheet',
  default: undefined,
});
export const useSpreadsheetState = () => useRecoilState(assortmentSpreadsheetAtom);

export const assortmentSpreadsheetValidationAtom = atom<AssortmentUploadValidation | undefined>({
  key: 'importedAssortmentSpreadsheetValidation',
  default: undefined,
});

export const assortmentSpreadsheetValidatedAtom = atom<boolean>({
  key: 'importedAssortmentSpreadsheetValidatedAtom',
  default: false,
});

export const assortmentFileAtom = atom<File | undefined>({
  key: 'importedAssortmentFileAtom',
  default: undefined,
});

const recognizedMgItemNrHeadings = [
  'mg-item-nummer',
  'mg-item nummer',
  'mgitem-nummer',
  'mgitem nummer',
  'mgitem-nr',
  'mgitem nr',
  'mgitemnr',
];
const recognizedMgSupplierItemNrHeadings = [
  'mg-supplier-nummer',
  'mg-supplier nummer',
  'mgsupplier-nummer',
  'mgsupplier nummer',
  'mgsupplier-nr',
  'mgsupplier nr',
  'mgsuppliernr',
  'mgsupplierid',
  'mgsupplier-id',
  'mgsupplier id',
  'supplierid',
  'supplier-id',
];
const recognizedPriorityHeading = ['prioritized supplier', 'prioritized', 'main supplier'];

const getIsRecognizedHeaderPredicate = (header: string) => (recHeading: string) =>
  header.toLowerCase().includes(recHeading);

export type ImportStatus = 'none' | 'success' | 'error';

export interface AssortmentImportState {
  status: ImportStatus;
  mgItemNumbers?: number[];
  mgSupplierItemNumbers?: number[];
  prioritization?: number[];
}

const getColumn = (
  spreadsheet: SpreadsheetStructure,
  recognizedColumnHeadings: string[]
): SpreadSheetCell[] | undefined => {
  const { headerRow, dataRows } = spreadsheet;
  const columnIndex = headerRow.findIndex((header) =>
    recognizedColumnHeadings.some(getIsRecognizedHeaderPredicate(`${header}`))
  );
  if (columnIndex < 0) return undefined;

  return dataRows.map((row) => row[columnIndex]);
};

const getNumbersForColumn = (
  spreadsheet: SpreadsheetStructure,
  recognizedColumnHeadings: string[]
): number[] | undefined => {
  const column = getColumn(spreadsheet, recognizedColumnHeadings);

  const numbers = column?.map((nr) => Number(nr)).filter((nr) => Number.isInteger(nr));
  return numbers;
};

const getPrioritationForColumn = (
  spreadsheet: SpreadsheetStructure,
  recognizedColumnHeadings: string[]
): number[] | undefined => {
  const column = getColumn(spreadsheet, recognizedColumnHeadings);

  return column?.map((pri) => (pri !== undefined && pri !== 'no' ? 10 : 0));
};

export const useImportedAssortmentState = (): AssortmentImportState => {
  const spreadsheet = useRecoilValue(assortmentSpreadsheetAtom);

  if (!spreadsheet) return { status: 'none' };

  const mgItemNumbers = getNumbersForColumn(spreadsheet, recognizedMgItemNrHeadings);
  const mgSupplierItemNumbers = getNumbersForColumn(
    spreadsheet,
    recognizedMgSupplierItemNrHeadings
  );
  const prioritization = getPrioritationForColumn(spreadsheet, recognizedPriorityHeading);

  const status: ImportStatus =
    mgItemNumbers &&
    mgSupplierItemNumbers &&
    prioritization &&
    prioritization.length > 0 &&
    mgItemNumbers.length > 0 &&
    mgSupplierItemNumbers.length > 0 &&
    mgItemNumbers.length === mgSupplierItemNumbers.length
      ? 'success'
      : 'error';

  return {
    status,
    mgItemNumbers,
    mgSupplierItemNumbers,
    prioritization,
  };
};

export const useResetImportedAssortmentState = () => {
  const resetSpreadsheet = useResetRecoilState(assortmentSpreadsheetAtom);
  const resetSpreadsheetValidation = useResetRecoilState(assortmentSpreadsheetValidationAtom);
  const resetSpreadsheetValidated = useResetRecoilState(assortmentSpreadsheetValidatedAtom);
  const resetAcceptedFile = useResetRecoilState(assortmentFileAtom);

  const resetImportedAssortmentStates = () => {
    resetSpreadsheet();
    resetSpreadsheetValidation();
    resetSpreadsheetValidated();
    resetAcceptedFile();
  };

  return resetImportedAssortmentStates;
};

export const isValidItem = (
  item: ItemsDTO,
  validationState: AssortmentUploadValidation | undefined
) => {
  return (
    !validationState?.invalidSupplierIds.some(
      (invalidItem) =>
        (invalidItem.mgSupplierId === item.mgSupplierId &&
          invalidItem.mgItemNumber === item.mgItemNumber &&
          invalidItem.priority === item.priority) ||
        !item.mgSupplierId
    ) &&
    !validationState?.invalidItemNumbers.some(
      (invalidItem) =>
        invalidItem.mgItemNumber === item.mgItemNumber &&
        invalidItem.mgSupplierId === item.mgSupplierId &&
        invalidItem.priority === item.priority
    ) &&
    !validationState?.invalidPriorities.some(
      (invalidItem) =>
        invalidItem.priority === item.priority &&
        invalidItem.mgItemNumber === item.mgItemNumber &&
        invalidItem.mgSupplierId === item.mgSupplierId
    ) &&
    !validationState?.invalidDates.some(
      (invalidItem) =>
        invalidItem.validFrom === item.validFrom &&
        invalidItem.mgItemNumber === item.mgItemNumber &&
        invalidItem.priority === item.priority
    )
  );
};
