import React, { FC, useCallback, useMemo, useState } from 'react';
import { DraftDrawerMarkupTable } from '@retail/calculus/components';
import { useCalculusConditions } from '@retail/calculus/context';
import { ContainedButton } from '@shared/components';
import { DraftActionsRowPortal } from '@retail/components';
import { useCalculusTFunction } from '@retail/calculus/i18n';

export const DraftMarkupsContainer: FC = () => {
  const {
    draftMarkupConditions,
    removeConditionsIsLoading,
    handleDeleteConditions,
    activateDraftMarkups,
    activateConditionIsLoading,
    timeoutLoading,
  } = useCalculusConditions();
  const t = useCalculusTFunction();

  const [selectedConditionIds, setSelectedConditionIds] = useState<number[]>([]);

  const submitDeleteConditions = useCallback(() => {
    handleDeleteConditions(selectedConditionIds);
  }, [handleDeleteConditions, selectedConditionIds]);

  const submitActivateDraftPurchaseAgreements = useCallback(() => {
    activateDraftMarkups(selectedConditionIds);
  }, [activateDraftMarkups, selectedConditionIds]);

  const isLoading = useMemo(
    () => activateConditionIsLoading || timeoutLoading || removeConditionsIsLoading,
    [activateConditionIsLoading, removeConditionsIsLoading, timeoutLoading]
  );

  return (
    <>
      <DraftDrawerMarkupTable
        draftConditions={draftMarkupConditions}
        handleDeleteConditions={handleDeleteConditions}
        isLoading={isLoading}
        onSelectConditions={setSelectedConditionIds}
      />
      <DraftActionsRowPortal>
        <ContainedButton
          color="warning"
          size="small"
          disabled={!selectedConditionIds.length}
          onClick={submitDeleteConditions}
          loading={isLoading}
        >
          {t('calculus.actions.disapproveChanges', { count: selectedConditionIds.length })}
        </ContainedButton>
        <ContainedButton
          onClick={submitActivateDraftPurchaseAgreements}
          size="small"
          disabled={!selectedConditionIds.length}
          loading={isLoading}
        >
          {t('calculus.actions.approveChanges', { count: selectedConditionIds.length })}
        </ContainedButton>
      </DraftActionsRowPortal>
    </>
  );
};
