import { OutlinedButton } from '@shared/components';

import { ButtonProps, useTheme } from '@mui/material';
import { ComponentProps, ElementType, forwardRef, PropsWithChildren } from 'react';

function OutlinedButtonBase<C extends ElementType = 'button'>(
  { children, loading = false, ...rest }: PropsWithChildren<ComponentProps<C>>,
  ref: ButtonProps['ref']
) {
  const { color, ...remainingProps } = rest;
  const { palette } = useTheme();

  return (
    <OutlinedButton
      sx={{
        borderRadius: 50,
        backgroundColor: palette.grey[200],
      }}
      ref={ref}
      {...remainingProps}
      size="small"
      color={color}
    >
      {children}
    </OutlinedButton>
  );
}

const OutlinedMgButton = forwardRef(OutlinedButtonBase);

export { OutlinedMgButton };
