import { useProductsTFunction } from '@retail/products/i18n';

import { MenuElementProps, TriggerElementProps } from '@shared/hooks';
import { Add } from '@mui/icons-material';
import { MgItem } from '@retail/products/types';
import { OutlinedButton } from '@shared/components';
import { MonitoringActionsMenu } from './MonitoringActionsMenu';

interface Props {
  mgItem: MgItem;
  menuElementProps: MenuElementProps;
  triggerElementProps: TriggerElementProps;
}

export function MgItemMonitoring({ mgItem, menuElementProps, triggerElementProps }: Props) {
  const t = useProductsTFunction();

  return (
    <>
      <OutlinedButton {...triggerElementProps} size="small" color="secondary" startIcon={<Add />}>
        {t('products.mgItem.supplierItem.addItem')}
      </OutlinedButton>

      <MonitoringActionsMenu mgItem={mgItem} menuElementProps={menuElementProps} />
    </>
  );
}
