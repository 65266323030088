import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { ColumnHeaderWithHelpText, DataGridPagination, StackedTextCell } from '@retail/components';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { alpha } from '@mui/material';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { ItemAgreement, PriceListPageResult } from '@retail/purchase-agreements/types';

export interface PriceTableProps {
  result: PriceListPageResult;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

export function PriceListTable({ result, updatePage, updatePageSize }: PriceTableProps) {
  const t = usePurchaseAgreementsTFunction();

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      width: 150,
      headerName: '',
      headerAlign: 'center',
      align: 'center',
    }),
    []
  );

  const columns: GridColDef[] = useMemo(() => {
    const nameColDef: GridColDef<ItemAgreement> = {
      ...baseColumnProps,
      field: 'name',
      width: 280,
      headerAlign: 'left',
      align: 'left',
      headerName: t('purchaseAgreements.agreement.columns.primaryText'),
      renderCell: ({ row }) => (
        <StackedTextCell topLine={row.primaryText} bottomLine={row.secondaryText} />
      ),
    };

    const mgItemIdColDef: GridColDef<ItemAgreement> = {
      ...baseColumnProps,
      field: 'mgItemId',
      width: 180,
      headerName: `
        ${t('purchaseAgreements.agreement.columns.mgItemNr')} /
        ${t('purchaseAgreements.agreement.columns.finfoNr')}`,
      renderCell: ({ row }) => (
        <StackedTextCell topLine={row.mgItemNumber} bottomLine={row.finfoNumber} />
      ),
    };

    const priceColDef: GridColDef<ItemAgreement> = {
      ...baseColumnProps,
      field: 'price',
      headerName: t('purchaseAgreements.agreement.columns.price'),
      renderCell: ({ row: { price, priceUnit } }) =>
        t('purchaseAgreements.pricePerUnit', {
          price: price.toFixed(2),
          unit: priceUnit,
        }),
    };

    const basePriceUnitColDef: GridColDef<ItemAgreement> = {
      field: 'gtinPriceUnit',
      width: 150,
      renderHeader: () => (
        <ColumnHeaderWithHelpText
          header={t('purchaseAgreements.agreement.columns.basePriceUnit')}
          tooltipContent={t('purchaseAgreements.agreement.columns.basePriceUnitDesc')}
        />
      ),
      renderCell: ({ row }) => row.gtinPriceUnit || row.priceUnit,
    };

    return [nameColDef, mgItemIdColDef, priceColDef, basePriceUnitColDef];
  }, [baseColumnProps, t]);

  return (
    <DataGridPro
      autoHeight
      disableSelectionOnClick
      rows={result.items}
      getRowId={(row) => `${row.mgSupplierItemId} ${row.price} ${row.gtinPrice}`}
      columns={columns}
      pagination={true}
      paginationMode="server"
      components={{
        Pagination: DataGridPagination,
      }}
      rowCount={result.totalItems}
      page={result.page}
      pageSize={result.pageSize}
      onPageChange={updatePage}
      onPageSizeChange={updatePageSize}
      sx={{
        '& .MuiDataGrid-row': {
          ':last-of-type': { borderBottomLeftRadius: 24, borderBottomRightRadius: 24 },
          ':nth-of-type(odd)': {
            backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
            ':hover': {
              backgroundColor: ({ palette }) => alpha(palette.info.main, 0.2),
            },
          },
        },
      }}
    />
  );
}
