import { GroupTypes } from '../../GroupTypes';
import { OverGroupDTO } from '../../dto';
import { MainGroup } from './MainGroup';
import { ProductGroup, ProductGroupProps } from './ProductGroup';

interface OverGroupProps extends ProductGroupProps {
  subGroups: MainGroup[];
}

export class OverGroup {
  id: number;
  type: GroupTypes;
  groupNumber: string;
  name: string;
  active?: boolean;
  numberOfItems?: number;
  subGroups: MainGroup[];

  constructor({ id, type, groupNumber, name, active, numberOfItems, subGroups }: OverGroupProps) {
    this.id = id;
    this.type = type;
    this.groupNumber = groupNumber;
    this.name = name;
    this.active = active;
    this.numberOfItems = numberOfItems;
    this.subGroups = subGroups;
  }
  getLabel = (): string => (this.name ? `${this.groupNumber} ${this.name}` : this.groupNumber);

  static flatten = (overGroups: OverGroup[]): ProductGroup[] =>
    overGroups
      .sort((o1, o2) => Number(o1.groupNumber) - Number(o2.groupNumber))
      .flatMap(({ id, type, groupNumber, name, active, numberOfItems, getLabel, subGroups }) => [
        { id, type, groupNumber, name, active, numberOfItems, getLabel },
        ...subGroups
          .sort((h1, h2) => Number(h1.groupNumber) - Number(h2.groupNumber))
          .flatMap(
            ({ id, type, groupNumber, name, active, numberOfItems, getLabel, subGroups }) => [
              { id, type, groupNumber, name, active, numberOfItems, getLabel },
              ...subGroups
                .sort((v1, v2) => Number(v1.groupNumber) - Number(v2.groupNumber))
                .map((underGroup) => ({ ...underGroup })),
            ]
          ),
      ]);

  static fromDto = (dto: OverGroupDTO): OverGroup =>
    new OverGroup({
      id: dto.id,
      type: dto.type,
      groupNumber: dto.groupNumber,
      name: dto.name,
      active: dto.active,
      numberOfItems: dto.numberOfItems,
      subGroups: dto.subGroups.map(MainGroup.fromDto),
    });
}
