import { BaseConfig, envType, MgPrisConfig } from '@retail/mgpris/config-types';

const env = (process.env['NX_PUBLIC_REACT_APP_ENV'] || 'dev') as envType;
const version = process.env['NX_PUBLIC_REACT_APP_VERSION'] || 'local';
const buildTime = process.env['NX_PUBLIC_BUILD_TIMESTAMP'];

const baseConfig: BaseConfig = {
  env,
  version,
  buildTime: buildTime ? Number(buildTime) : undefined,
  appInfo: {
    name: 'MG Pris',
    key: 'mgpris',
  },
  country: 'SE',
  sentryDsn: 'https://e3cd88ae61f54917bb65c433bc5f7317@o344472.ingest.sentry.io/4504948992573440',
  muiLicenseKey:
    '37d24028aa82caa294effbc034e42797T1JERVI6MzY5NTEsRVhQSVJZPTE2NzUyNTIzMDgwMDAsS0VZVkVSU0lPTj0x',
  retailerLogoBaseUrl: 'https://www.byggmaterialpriser.se/static/images/logos',
};

const devConfig: MgPrisConfig = {
  ...baseConfig,
  pps: { url: 'https://pps.datahub-dev.mestergruppen.cloud' },
  mpe: { url: 'https://mg-price-engine.datahub-dev.mestergruppen.cloud' },
  auth0: {
    clientId: 'UYjBrp5ppcwJUL5b5UxvRdOfmZFs5Buo',
    domain: 'dev.id.mestergruppen.cloud',
    audience: 'mg.pps',
    mpeAudience: 'mg.mpe',
  },
  gaMeasurementId: 'G-VKQD1381K7',
};

const localDevConfig: MgPrisConfig = {
  ...devConfig,
  pps: { url: 'http://localhost:8082' },
  mpe: { url: 'http://localhost:8080' },
  gaMeasurementId: undefined,
  sentryDsn: undefined,
};

const stageConfig: MgPrisConfig = {
  ...baseConfig,
  pps: { url: 'https://pps.datahub-stage.mestergruppen.cloud' },
  mpe: { url: 'https://mg-price-engine.datahub-stage.mestergruppen.cloud' },
  auth0: {
    clientId: 'ZrXG1bqDMluUcStracHu4ICTb6GOjIxm',
    domain: 'stage.id.mestergruppen.cloud',
    audience: 'mg.pps',
    mpeAudience: 'mg.mpe',
  },
  gaMeasurementId: 'G-L50QSGM4KC',
  clarity: {
    id: 'nva1srvcoy',
  },
};

const localStageConfig: MgPrisConfig = {
  ...stageConfig,
  pps: { url: 'http://localhost:8082' },
  mpe: { url: 'http://localhost:8080' },
  gaMeasurementId: undefined,
  sentryDsn: undefined,
};

const prodConfig: MgPrisConfig = {
  ...baseConfig,
  pps: { url: 'https://pps.datahub-prod.mestergruppen.cloud' },
  mpe: { url: 'https://mg-price-engine.datahub-prod.mestergruppen.cloud' },
  auth0: {
    clientId: 'Ee7lS0kFJ2p4etdsMj1rlNyHOnYuTaqE',
    domain: 'id.mestergruppen.cloud',
    audience: 'mg.pps',
    mpeAudience: 'mg.mpe',
  },
  gaMeasurementId: 'G-KWYZQBT33Z',
  hotjar: {
    id: 3465595,
    version: 6,
  },
  clarity: {
    id: 'jmqq9u2kpx',
  },
};

const envConfigs: { [key in envType]: MgPrisConfig } = {
  localDev: localDevConfig,
  dev: devConfig,
  localStage: localStageConfig,
  stage: stageConfig,
  prod: prodConfig,
};

export const mgPrisConfig: MgPrisConfig = envConfigs[env];
