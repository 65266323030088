import { CalculusTFunction } from '@retail/calculus/i18n';
import {
  decimalNumberRegex,
  numberWithOptionalDecimalRegex,
  percentageIncludingNegativeRegex,
} from '@retail/form/validation';
import * as yup from 'yup';

export const createItemLevelMarkupSchema = (t: CalculusTFunction) =>
  yup.object({
    salesFactor: yup
      .string()
      .default('')
      .matches(numberWithOptionalDecimalRegex, t('calculus.error.mustBeNumberBetweenZeroAndNine')),
    salesCoverage: yup
      .string()
      .default('')
      .nullable()
      .matches(percentageIncludingNegativeRegex, t('calculus.error.mustBeNumberBetween')),
    salesPrice: yup
      .string()
      .default('')
      .matches(decimalNumberRegex, t('calculus.error.mustBeNumber'))
      .nullable(),
    name: yup.string().default('').required(),
    fromDate: yup.date().optional().default(new Date()),
    toDate: yup.date().default(null).nullable().optional(),
  });

export type CreateItemLevelMarkupSchema = yup.InferType<
  ReturnType<typeof createItemLevelMarkupSchema>
>;
