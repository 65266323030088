import { fetcher, QueryOptions, useQueryFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { useCallback } from 'react';
import { MgItemDTO } from '@retail/products/types';
import { buildUrl } from '@shared/utils';
import { useSelectedOrgUnitHeader } from '@retail/app/hooks';

export const mpegItemsApiRoute = 'api/v1/mg-items';

interface FetchProps {
  baseUrl: string;
  mgItemNumber: number;
  assortmentId?: number;
  token: string;
  headers?: HeadersInit;
}

export async function fetchMpeMgItem({
  baseUrl,
  mgItemNumber,
  assortmentId,
  token,
  headers,
}: FetchProps) {
  return fetcher<MgItemDTO>({
    url: buildUrl({
      base: baseUrl,
      route: `${mpegItemsApiRoute}/${mgItemNumber}`,
      searchParams: { assortmentId },
    }),
    token,
    headers,
  });
}

interface Props {
  mgItemNumber: number;
  assortmentId?: number;
}

export function useFetchMpeMgItem({ mgItemNumber, assortmentId }: Props, options?: QueryOptions) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();
  const selectedOrgUnitHeader = useSelectedOrgUnitHeader();

  const fetcher = useCallback(
    () =>
      fetchMpeMgItem({
        baseUrl: mpe.url,
        mgItemNumber,
        assortmentId,
        token,
        headers: selectedOrgUnitHeader,
      }),
    [mpe.url, mgItemNumber, assortmentId, token, selectedOrgUnitHeader]
  );

  return useQueryFetch({
    fetcher,
    queryKey: ['mg-item', mgItemNumber],
    ...options,
  });
}
