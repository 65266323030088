import { Grid, Stack, TextField, Typography, alpha, styled, useTheme } from '@mui/material';


import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { Chip } from '@shared/components';

interface Props {
  label: string;
  chipLabel?: number | string;
  primaryUnit?: string;
  approximatelyEqual?: boolean;
}

export function CalculationEntry({ label, chipLabel, primaryUnit }: Props) {
  const { palette } = useTheme();
  const t = useMyAssortmentTFunction();

  return (
    <Stack component={Grid} container flexDirection="row" alignItems="center">
      <Grid item xs={5}>
        <Typography fontWeight={500}>{label}</Typography>
      </Grid>
      <Grid item display="flex" flexDirection="row" alignItems="center">
        <Chip
          backgroundColor={alpha(palette.grey.A100, 0.4)}
          label={chipLabel ?? '-'}
          sx={{ border: `1px solid ${palette.divider}`, minWidth: 68 }}
        />

        {primaryUnit && (
          <Typography pl={1} color="text.secondary" variant="body2">
            {t('myAssortment.productCard.pricePerUnit', { unit: primaryUnit })}
          </Typography>
        )}
      </Grid>
    </Stack>
  );
}

export const StyledTextField = styled(TextField)(({ theme: { palette } }) => ({
  minWidth: 68,
  '& .MuiOutlinedInput-root': {
    height: 32,
    width: 92,
    maxWidth: 125,
    border: `1 px solid ${palette.divider}`,
  },
}));
