import { useCalculusTFunction } from '@retail/calculus/i18n';
import { useToast } from '@retail/hooks';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { useMutateCreateOrEditConditions } from '@retail/purchase-agreements/data-access';
import { ActivateCondition, DeactivateCondition } from '@retail/purchase-agreements/types';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

interface Props {
  mgSupplierId: number;
}

export function usePurchaseActions({ mgSupplierId }: Props) {
  const t = useCalculusTFunction();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();

  const invalidateAgreements = () => {
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['purchase-condition'] });
    queryClient.invalidateQueries({ queryKey: ['purchase-agreements'] });
    queryClient.invalidateQueries({ queryKey: ['agreement-prices'] });
  };

  const { mutateAsync: mutatePostPurchaseChanges, isLoading: isLoadingPostChanges } =
    useMutateCreateOrEditConditions({
      assortmentId: draftAssortmentId,
      departmentId: departmentId,
    });

  const deactivatePurchaseCondition = (deactivateCondition: DeactivateCondition) => {
    mutatePostPurchaseChanges({
      body: {
        mgSupplierId,
        mgSupplierConditions: deactivateCondition.supplierLevel
          ? [
              {
                agreementId: null,
                validFrom: dayjs(new Date()).format('YYYY-MM-DD'),
              },
            ]
          : null,
        productGroupConditions: deactivateCondition.groupNumber
          ? [
              {
                agreementId: null,
                validFrom: dayjs(new Date()).format('YYYY-MM-DD'),
                groupNumber: deactivateCondition.groupNumber,
              },
            ]
          : [],
        mgSupplierItemConditions: deactivateCondition.mgItemNumber
          ? [
              {
                agreementId: null,
                validFrom: dayjs(new Date()).format('YYYY-MM-DD'),
                mgItemNumber: deactivateCondition.mgItemNumber,
                priceType: null,
              },
            ]
          : [],
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionDeleteInDrawer'));
        invalidateAgreements();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('calculus.error.generic'));
      });
  };

  const activateOrChangeCondition = (activateCondition: ActivateCondition) => {
    mutatePostPurchaseChanges({
      body: {
        mgSupplierId,
        mgSupplierConditions: activateCondition.supplierLevel
          ? [
              {
                agreementId: activateCondition.agreementId,
                validFrom: dayjs(activateCondition.fromDate ?? Date()).format('YYYY-MM-DD'),
              },
            ]
          : null,
        productGroupConditions: activateCondition.groupNumber
          ? [
              {
                agreementId: activateCondition.agreementId,
                validFrom: dayjs(activateCondition.fromDate ?? Date()).format('YYYY-MM-DD'),
                groupNumber: activateCondition.groupNumber,
              },
            ]
          : [],
        mgSupplierItemConditions: activateCondition.mgItemNumber
          ? [
              {
                agreementId: activateCondition.agreementId,
                validFrom: dayjs(activateCondition.fromDate ?? Date()).format('YYYY-MM-DD'),
                mgItemNumber: activateCondition.mgItemNumber,
                priceType: activateCondition.priceType ?? null,
              },
            ]
          : [],
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionCreateInDrawer'));
        invalidateAgreements();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('calculus.error.generic'));
      });
  };

  return {
    deactivatePurchaseCondition,
    activateOrChangeCondition,
    isLoadingPostChanges,
  };
}
