import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ActiveTagPaper } from '@retail/components';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { MarkupCondition } from '@retail/markup/types';
import { EditableCalculationEntry } from './EditableCalculationEntry';
import { EditedMarkupField } from './ProductCard';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  markupCondition?: MarkupCondition;
  costPrice?: number;
  editSalesFactor: boolean;
  editedMarkupField: EditedMarkupField;
  setEditedMarkupField: Dispatch<SetStateAction<EditedMarkupField>>;
  toggleEditSalesFactor: () => void;
  resetMarkupValues: () => void;
}

export function MarkupAccordion({
  markupCondition,
  costPrice,
  editSalesFactor,
  editedMarkupField,
  setEditedMarkupField,
  toggleEditSalesFactor,
  resetMarkupValues,
}: Props) {
  const t = useAppTFunction();

  return (
    <Accordion>
      <AccordionSummary>
        <EditableCalculationEntry
          label={t('myAssortment.markup')}
          costPrice={costPrice}
          chipLabel={markupCondition?.salesFactor ?? '-'}
          editEntry={editSalesFactor}
          editedMarkupField={editedMarkupField}
          resetMarkupValues={resetMarkupValues}
          setEditedMarkupField={setEditedMarkupField}
          setEditEntry={toggleEditSalesFactor}
        />
      </AccordionSummary>
      <AccordionDetails>
        <ActiveTagPaper>
          {markupCondition ? (
            <Grid container gap={2} flexDirection="column">
              <Stack gap={3} flexDirection="row" fontSize={14}>
                <Grid item fontWeight={600}>
                  {t('markup.markupName')}
                </Grid>
                <Grid item>
                  {markupCondition.id
                    ? markupCondition.markupTemplateName
                    : t('markup.defaultMarkup')}
                </Grid>
              </Stack>
              <Stack gap={4} flexDirection="row" fontSize={14}>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('markup.fromDate')}
                  </Grid>
                  <Grid item>
                    {markupCondition.validFrom
                      ? dayjs(markupCondition.validFrom).format('ll')
                      : '-'}
                  </Grid>
                </Stack>
                <Stack flexDirection="row" gap={2}>
                  <Grid item fontWeight={600}>
                    {t('markup.toDate')}
                  </Grid>
                  <Grid item>
                    {markupCondition.validTo ? dayjs(markupCondition.validTo).format('ll') : '->'}
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Typography>{t('myAssortment.productCard.noActiveMarkupCondition')}</Typography>
          )}
        </ActiveTagPaper>
      </AccordionDetails>
    </Accordion>
  );
}
