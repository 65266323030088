import { Box, styled, useTheme } from '@mui/material';
import { CarouselItem } from './Carousel';

interface Props {
  items: Array<CarouselItem>;
  activeSlide: number;
  setActiveSlide: (num: number) => void;
}

export function IndicatorButtons({ items, activeSlide, setActiveSlide }: Props) {
  const { spacing } = useTheme();

  const hasItems = items?.length > 0;

  return (
    <Box display="flex" justifyContent="center" mt={3}>
      {items?.map((item, index) => {
        const isActive = activeSlide === index;

        const isFirstItem = index === 0;
        const isNotLastItem = index !== items.length - 1;

        return (
          <IndicatorButton
            key={item.url}
            ml={isFirstItem ? 0 : spacing(0.5)}
            mr={isNotLastItem ? spacing(0.5) : 0}
            isActive={isActive}
            onClick={() => setActiveSlide(index)}
          />
        );
      })}

      {!hasItems &&
        Array(4)
          .fill(null)
          .map((_, index) => (
            <IndicatorButton
              key={index}
              ml={index === 0 ? 0 : spacing(0.5)}
              mr={index < 4 ? spacing(0.5) : 0}
              isActive={false}
              disabled
            />
          ))}
    </Box>
  );
}

interface IndicatorButtonProps {
  isActive: boolean;
  disabled?: boolean;
}

const IndicatorButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'disabled',
})<IndicatorButtonProps>(({ theme, isActive, disabled }) => ({
  backgroundColor: isActive ? undefined : theme.palette.grey[200],
  background: isActive
    ? `linear-gradient(292deg, ${theme.palette.primary[800]} 15.01%,  ${theme.palette.primary[700]} 32.03%,  ${theme.palette.primary[600]} 50.5%,  ${theme.palette.primary[500]} 98.63%)`
    : undefined,
  width: isActive ? theme.spacing(4.5) : 11,
  height: 11,
  borderRadius: theme.shape.borderRadius * 5,
  cursor: disabled ? undefined : 'pointer',
}));
