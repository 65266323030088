import { MgSupplierItemSearchResult } from '@retail/products/types';

import { Box, Pagination, Stack, Typography } from '@mui/material';
import { SelectPageSize, StackedTextCell } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';
import { useNavigate } from 'react-router-dom';
import { Dispatch, useCallback, useMemo, useRef } from 'react';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { DataGridPro, GridColDef, GridSelectionModel } from '@mui/x-data-grid-pro';
import { Pagination as PaginationType } from '@shared/hooks';
import {
  dataGridCellHoverClass,
  dataGridColorRowClass,
  dataGridWhiteRowClass,
} from '@shared/styles';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params';
import clsx from 'clsx';

interface Props {
  totalPages: number;
  items: MgSupplierItemSearchResult[];
  clickableRow?: boolean;
  hasDraftDrawerValues?: boolean;
  selection?: {
    selectedItems: MgSupplierItemSearchResult[];
    selectItem: Dispatch<MgSupplierItemSearchResult[]>;
  };
}

type GridColItem = GridColDef<MgSupplierItemSearchResult>;

const baseColumnProps: Partial<GridColItem> = {
  width: 150,
  headerName: '',
  headerAlign: 'center',
  align: 'center',
};

export function MgSupplierItemsTableWithCheckbox({
  items,
  totalPages,
  clickableRow = true,
  selection,
  hasDraftDrawerValues,
  page,
  pageSize,
  setPage,
  setPageSize,
}: Props & PaginationType) {
  const t = useProductsTFunction();
  const lastMgItemNumber = useRef<number | undefined>(items[0]?.mgItemNumber);
  const rowColor = useRef<string>(dataGridColorRowClass);

  const navigate = useNavigate();
  const navigateToItem = useCallback(
    (mgItemNumber: string) => navigate(`${mgPrisRoutes.items.item.getFullLinkPath(mgItemNumber)}`),
    [navigate]
  );

  const onRowsSelectionHandler = (selections: GridSelectionModel) => {
    const rowSelections: MgSupplierItemSearchResult[] = selections
      .map((selection) =>
        items.find(
          (item) =>
            `${item.mgSupplierItemId}_${item.mgParticipantAccountId}_${item.primaryText}` ===
            selection
        )
      )
      .filter((selection): selection is MgSupplierItemSearchResult => selection !== undefined);

    selection?.selectItem(rowSelections);
  };

  const handleSetPage = (page: number) => {
    setPage(page);
    selection?.selectItem([]);
  };

  const columns: GridColItem[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'primaryText',
        width: 310,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        headerName: t('products.mgItem.supplierItem.primaryText'),
        renderCell: ({ row }) => (
          <StackedTextCell
            topLine={<Typography fontWeight="600">{row.primaryText}</Typography>}
            bottomLine={row.secondaryText}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'supplier',
        width: 220,
        sortable: false,
        headerName: t('products.supplier.name'),
        renderCell: ({ row }) => (
          <StackedTextCell
            centerAlign
            topLine={row.participantAccountName}
            bottomLine={row.mgParticipantAccountId}
          />
        ),
      },
      {
        ...baseColumnProps,
        sortable: false,
        field: 'finfoNumber',
        headerName: t('products.mgItem.supplierItem.finfoNumber.label'),
        renderCell: ({ row }) => row.finfoNumber,
      },
      {
        ...baseColumnProps,
        sortable: false,
        field: 'mgItemNumber',
        headerName: t('products.mgItem.number.short'),
        renderCell: ({ row }) => row.mgItemNumber,
      },
      {
        ...baseColumnProps,
        sortable: false,
        field: 'productGroup',
        width: 220,
        headerName: t('products.mgItem.supplierItem.bk04ProductCategory'),
        renderCell: ({ row }) => (
          <StackedTextCell
            centerAlign
            topLine={row.bk04Varegruppe?.name}
            bottomLine={row.bk04Varegruppe?.groupNumber}
          />
        ),
      },
      {
        minWidth: 0,
        flex: 1,
        headerName: '',
        field: 'fillWidth',
      },
    ],
    [t]
  );

  const getRowClassName = useCallback(
    ({ row }: GridRowClassNameParams<MgSupplierItemSearchResult>) => {
      if (row.mgItemNumber !== lastMgItemNumber.current) {
        rowColor.current =
          rowColor.current === dataGridWhiteRowClass
            ? dataGridColorRowClass
            : dataGridWhiteRowClass;
        lastMgItemNumber.current = row.mgItemNumber;
      }

      return rowColor.current;
    },
    []
  );

  return (
    <Box display="flex" height="100%">
      <DataGridPro<MgSupplierItemSearchResult>
        rows={items}
        columns={columns}
        getRowId={(row) =>
          `${row.mgSupplierItemId}_${row.mgParticipantAccountId}_${row.primaryText}`
        }
        // @ts-ignore
        getRowClassName={getRowClassName}
        rowHeight={65}
        checkboxSelection
        disableSelectionOnClick
        disableColumnMenu
        pagination
        paginationMode="server"
        rowCount={items.length}
        page={page}
        onSelectionModelChange={(selection) => onRowsSelectionHandler(selection)}
        onRowClick={(params) =>
          clickableRow ? navigateToItem(params.row.mgItemNumber) : selection?.selectItem(params.row)
        }
        getCellClassName={({ field }) =>
          clsx({
            [dataGridCellHoverClass]: !['__check__'].includes(field),
          })
        }
        components={{
          Pagination: () => (
            <Stack
              px={4}
              py={2}
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              position="sticky"
              zIndex={1}
              bottom={(theme) => (hasDraftDrawerValues ? theme.spacing(4.5) : 0)}
              bgcolor={(theme) => theme.palette.common.white}
            >
              <SelectPageSize currentPageSize={pageSize} updatePageSize={setPageSize} />
              <Pagination
                count={totalPages}
                page={page + 1}
                onChange={(_, newPage) => handleSetPage(newPage - 1)}
              />
            </Stack>
          ),
          NoRowsOverlay: () => (
            <Stack
              alignItems="center"
              justifyContent="center"
              gap={2}
              height="100%"
              sx={{ position: 'relative', zIndex: 1, pointerEvents: 'all' }} // https://github.com/mui/mui-x/issues/5725
            >
              <Typography variant="h3" color={(theme) => theme.palette.grey[100]}>
                {t('products.mgItem.noItems')}
              </Typography>
            </Stack>
          ),
        }}
      />
    </Box>
  );
}
