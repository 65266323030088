import { CalculusTFunction } from '@retail/calculus/i18n';
import * as yup from 'yup';

export const patchMarkupSchema = (t: CalculusTFunction) =>
  yup.object({
    markupName: yup.string().default('').required(t('calculus.error.nameRequired')),
    toDate: yup.date().default(null).nullable().optional(),
  });

export type PatchMarkupSchema = yup.InferType<ReturnType<typeof patchMarkupSchema>>;
