import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './app/App';
import { setupi18n } from './i18n/i18nConfig';
import './index.css';
import { mgPrisConfig } from '@retail/mgpris/config';
import * as Sentry from '@sentry/react';
import GA4React from 'ga-4-react';
import { MgPrisContextProvider } from '@retail/app/stores/app-config';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import 'dayjs/locale/nb';
import 'dayjs/locale/sv';
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import Hotjar from '@hotjar/browser';
import { setCustomDimensions } from '@retail/utils';

LicenseInfo.setLicenseKey(mgPrisConfig.muiLicenseKey);
dayjs.extend(isLeapYear);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.updateLocale('nb', {
  weekdaysShort: ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
});
dayjs.locale('sv');
setupi18n();

if (mgPrisConfig.sentryDsn && mgPrisConfig.env.includes('local')) {
  Sentry.init({
    dsn: mgPrisConfig.sentryDsn,
    environment: mgPrisConfig.env,
    release: mgPrisConfig.version,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

if (mgPrisConfig.hotjar && mgPrisConfig.env.includes('local')) {
  Hotjar.init(mgPrisConfig.hotjar.id, mgPrisConfig.hotjar.version);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const renderDOM = () =>
  root.render(
    <StrictMode>
      <MgPrisContextProvider config={mgPrisConfig}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MgPrisContextProvider>
    </StrictMode>
  );

if (mgPrisConfig.env.includes('local') && mgPrisConfig.gaMeasurementId) {
  const ga4React = new GA4React(mgPrisConfig.gaMeasurementId);

  ga4React
    .initialize()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .then((ga) => setCustomDimensions(mgPrisConfig.gaMeasurementId!, ga))
    .catch((err) => {
      console.error(
        'gtag script failed to load. Most likely due to an ad/tracker blocker. Full error: ',
        err
      );
    })
    .finally(() => {
      renderDOM();
    });
} else {
  renderDOM();
}
