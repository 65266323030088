import { Actions, AssortmentType } from '@retail/calculus/types';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { MarkupCondition } from '@retail/calculus/types';
import { Menu, MenuButton } from '@retail/components';
import { IconButton, MenuItem } from '@mui/material';
import { DotMenuIcon } from '@shared/custom-icons';

interface Props {
  markupClicked: MarkupCondition;
  setMarkupClicked: () => void;
  setAction: (action: Actions) => void;
}

export const MarkupTableActions = ({ markupClicked, setMarkupClicked, setAction }: Props) => {
  const t = useMarkupTFunction();

  const isLocal = markupClicked?.source === 'MPE';
  const isCondition = markupClicked.assortmentType === AssortmentType.BASE;

  return (
    <Menu>
      {({ onClose }) => (
        <>
          <MenuButton component={IconButton}>
            <DotMenuIcon fontSize="small" />
          </MenuButton>
          {isCondition && (
            <MenuItem
              onClick={() => {
                setAction('delete');
                setMarkupClicked();
                onClose();
              }}
            >
              {t('markup.actions.deactivate')}
            </MenuItem>
          )}

          {isLocal && (
            <MenuItem
              onClick={() => {
                setAction('edit');
                setMarkupClicked();
                onClose();
              }}
            >
              {t('markup.actions.edit')}
            </MenuItem>
          )}
        </>
      )}
    </Menu>
  );
};
