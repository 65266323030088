import { Dispatch, SetStateAction } from 'react';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { ToggleSwitch } from '@retail/components';
import { useMountEffect } from '@shared/hooks';

interface Props {
  toggleVat: boolean;
  handleToggleVat: Dispatch<SetStateAction<boolean>>;
}

export const ExcludeVatToggleSwitch = ({ toggleVat, handleToggleVat }: Props) => {
  const t = useMyAssortmentTFunction();

  useMountEffect(() =>
    handleToggleVat(localStorage.getItem('excludeVat') === 'true' ? true : false)
  );

  return (
    <ToggleSwitch
      toggle={toggleVat}
      setToggle={() => {
        handleToggleVat(!toggleVat);
        localStorage.setItem('excludeVat', !toggleVat + '');
      }}
      text={t('myAssortment.actions.includeVat')}
    />
  );
};
