import { AssortmentType, Operation } from './Condition';
import { HierarchyLevels } from './HierarchyLevels';

export interface ConditionBaseProps <T extends ConditionBase<T>> {
  id: number;
  assortmentId?: number;
  assortmentType: AssortmentType;
  mgSupplierId: number;
  bindingType?: HierarchyLevels;
  levelProductGroup?: string;
  levelProductGroupName?: string;
  productGroup?: string;
  productGroupName?: string;
  mgItemNumber?: number;
  finfoNumber?: number;
  mgSupplierName?: string;
  mgSupplierItemId?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom: Date;
  validTo?: Date;
  active?: boolean;
  current?: boolean;
  inherited?: boolean;
  source?: string;
  futureConditions?: T[];
  subConditions?: T[];
  replacesConditions?: T[];
  newCondition?: T;
  operation?: Operation;
}

export class ConditionBase<T extends ConditionBase<T>> {
  id: number;
  assortmentId?: number;
  assortmentType: AssortmentType;
  mgSupplierId: number;
  bindingType?: HierarchyLevels;
  levelProductGroup?: string;
  levelProductGroupName?: string;
  productGroup?: string;
  productGroupName?: string;
  mgItemNumber?: number;
  finfoNumber?: number;
  mgSupplierName?: string;
  mgSupplierItemId?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom: Date;
  validTo?: Date;
  active?: boolean;
  current?: boolean;
  inherited?: boolean;
  source?: string;
  futureConditions?: T[];
  subConditions?: T[];
  replacesConditions?: T[];
  newCondition?: T;
  operation?: Operation;

  constructor(
    {
      id,
      assortmentType,
      mgSupplierId,
      validFrom,
      assortmentId,
      bindingType,
      levelProductGroup,
      levelProductGroupName,
      productGroup,
      productGroupName,
      mgItemNumber,
      finfoNumber,
      mgSupplierName,
      mgSupplierItemId,
      primaryText,
      secondaryText,
      validTo,
      active,
      current,
      inherited,
      source,
      futureConditions,
      subConditions,
      replacesConditions,
      newCondition,
      operation,
    }: ConditionBaseProps<T>
  ) {
    this.id = id;
    this.assortmentId = assortmentId;
    this.assortmentType = assortmentType;
    this.mgSupplierId = mgSupplierId;
    this.bindingType = bindingType;
    this.levelProductGroup = levelProductGroup;
    this.levelProductGroupName = levelProductGroupName;
    this.productGroup = productGroup;
    this.productGroupName = productGroupName;
    this.mgItemNumber = mgItemNumber;
    this.finfoNumber = finfoNumber;
    this.mgSupplierName = mgSupplierName;
    this.mgSupplierItemId = mgSupplierItemId;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.active = active;
    this.current = current;
    this.inherited = inherited;
    this.source = source;
    this.futureConditions = futureConditions;
    this.subConditions = subConditions;
    this.replacesConditions = replacesConditions;
    this.newCondition = newCondition;
    this.operation = operation;
  }

  getPreviousCondition = () => this.replacesConditions?.[this.replacesConditions?.length - 1];

  getDisplayText = () => {
    const bottomLine =
      this?.secondaryText ?? this?.productGroup ?? this?.mgSupplierId;

    return (
      `${this?.primaryText ?? this?.productGroupName ?? this?.mgSupplierName} ${
        bottomLine ? `(${bottomLine})` : ''
      }` ?? '-'
    );
  }
}
