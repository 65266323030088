import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { CalculusResources } from '../types/CalculusLanguageResources';

export const useCalculusTranslation = () => useMessagesTranslation<CalculusResources>();

export type CalculusMessageKey = MessagePaths<CalculusResources>;

export const useCalculusTFunction = () => useCalculusTranslation().t;

export type CalculusTFunction = ReturnType<typeof useCalculusTFunction>;
