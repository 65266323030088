import { Box, Stack, styled, useTheme } from '@mui/material';
import { CarouselItem } from './Carousel';
import { NextButton } from './NextButton';
import { PreviousButton } from './PreviousButton';
import { SnapItem, SnapList } from 'react-snaplist-carousel';
import { Paper } from '@shared/components';
import { MutableRefObject } from 'react';
import { PreviewImage } from './PreviewImage';
import { NoImageElement } from './NoImageElement';

export interface CarouselPreviewProps {
  activeSlide: number;
  items: Array<CarouselItem>;
  snapListRef: MutableRefObject<null>;

  handlePreviousClick: (num: number) => void;
  handleNextClick: (num: number) => void;

  itemsToShow?: number;
  size?: number;
  buttonXPosition?: number;
  buttonYSpacing?: number;
}

export function CarouselPreview({
  items,
  activeSlide,
  snapListRef,

  itemsToShow = 3,
  size = 137,
  buttonXPosition = size / 3.25,
  buttonYSpacing = 3,

  handlePreviousClick,
  handleNextClick,
}: CarouselPreviewProps) {
  const { spacing } = useTheme();

  const hasItems = items?.length > 0;
  const hasFewItems = items.length < itemsToShow;

  return (
    <Stack alignItems="center" position="relative">
      <Paper
        paddingY="none"
        sx={{
          height: hasFewItems ? '100%' : undefined,
          position: 'relative',
          display: 'flex',
          alignItems: hasFewItems ? 'flex-start' : 'center',
          padding: spacing(1.5),
        }}
      >
        <CarouselButton
          hidden={activeSlide === 0}
          sx={{
            top: `-${spacing(buttonYSpacing)}`,
            left: `${buttonXPosition - 5}%`,
            transform: 'rotate(90deg)',
          }}
        >
          <PreviousButton activeSlide={activeSlide} setActiveSlide={handlePreviousClick} />
        </CarouselButton>

        <SnapList
          ref={snapListRef}
          direction="vertical"
          style={{ maxHeight: size * itemsToShow }}
          disableScroll={!hasItems}
        >
          {items?.map((item, index) => {
            return (
              <SnapItem
                key={item.url}
                snapAlign="start"
                margin={{
                  top: `${index === 0 ? 0 : 0.5}rem`,
                  bottom: `${index === items.length - 1 ? 0 : 0.5}rem`,
                }}
              >
                <SnapItemContent size={size}>
                  <PreviewImage
                    item={item}
                    index={index}
                    size={size}
                    isCurrentSlide={index === activeSlide}
                    handleClick={() => handlePreviousClick(index)}
                  />
                </SnapItemContent>
              </SnapItem>
            );
          })}

          {!items.length &&
            Array(3)
              .fill(null)
              .map((_, index) => (
                <SnapItem
                  key={index}
                  snapAlign="start"
                  margin={{
                    top: `${index === 0 ? 0 : 0.5}rem`,
                    bottom: `${index === items.length - 1 ? 0 : 0.5}rem`,
                  }}
                >
                  <SnapItemContent size={size}>
                    <NoImageElement />
                  </SnapItemContent>
                </SnapItem>
              ))}
        </SnapList>

        <CarouselButton
          hidden={activeSlide === items?.length - 1 && !hasItems}
          sx={{
            bottom: `-${spacing(buttonYSpacing)}`,
            right: `${buttonXPosition}%`,
            transform: 'rotate(90deg)',
          }}
        >
          <NextButton
            items={items}
            activeSlide={activeSlide}
            disabled={!hasItems}
            setActiveSlide={handleNextClick}
          />
        </CarouselButton>
      </Paper>
    </Stack>
  );
}

const CarouselButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hidden',
})<{ hidden: boolean }>(({ hidden }) => ({
  zIndex: 1,
  position: 'absolute',
  display: hidden ? 'none' : 'inherit',
}));

const SnapItemContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: number }>(({ theme: { shape }, size }) => ({
  position: 'relative',
  display: 'inline-block',
  borderRadius: shape.borderRadius * 2,
  height: size,
  width: size,
}));
