import { Stack, Typography, useTheme } from '@mui/material';
import { CopyToClipboard } from '@retail/components';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { Chip } from '@shared/components';

interface Props {
  mgItemNr: number;
  finfoNumber: number;
  direction?: 'row' | 'column';
}

export function ItemInfo({ mgItemNr, finfoNumber, direction = 'row' }: Props) {
  const t = usePurchaseAgreementsTFunction();
  const { palette } = useTheme();
  return (
    <Stack flexDirection={direction} gap={direction === 'row' ? 2 : 0} alignItems="center">
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="body2" fontWeight={600}>
          {t('purchaseAgreements.agreement.columns.mgItemNr')}
        </Typography>

        <CopyToClipboard value={mgItemNr}>
          <Chip size="small" backgroundColor={palette.primary[100]} label={mgItemNr} />
        </CopyToClipboard>
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="body2" fontWeight={600}>
          {t('purchaseAgreements.agreement.columns.finfoNr')}
        </Typography>

        <CopyToClipboard value={finfoNumber}>
          <Chip size="small" backgroundColor={palette.primary[100]} label={finfoNumber} />
        </CopyToClipboard>
      </Stack>
    </Stack>
  );
}
