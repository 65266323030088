import { AssortmentBase, AssortmentType } from '@retail/monitoring-assortment/types';

export type GroupedAssortments = {
  [key in AssortmentType]: AssortmentBase[];
};

type ErrorTypes = {
  NO_TYPE: AssortmentBase[];
  UNKNOWN_TYPE: AssortmentBase[];
};

export const assortmentGroups: GroupedAssortments & ErrorTypes = {
  MONITORING: [],
  CAMPAIGN: [],
  INCLUSION: [],
  EXCLUSION: [],
  ECOM: [],
  NO_TYPE: [],
  UNKNOWN_TYPE: [],
};

export const groupAndSortAssortments = (assortments: AssortmentBase[]): AssortmentBase[] => {
  const groupedAssortments: GroupedAssortments = assortments.reduce((tmpGrouped, assortment) => {
    if (!assortment.type) {
      return {
        ...tmpGrouped,
        NO_TYPE: tmpGrouped.NO_TYPE.concat(assortment),
      };
    }
    if (!tmpGrouped[assortment.type]) {
      return {
        ...tmpGrouped,
        UNKNOWN_TYPE: tmpGrouped.UNKNOWN_TYPE.concat(assortment),
      };
    }
    return {
      ...tmpGrouped,
      [assortment.type]: tmpGrouped[assortment.type].concat(assortment),
    };
  }, assortmentGroups);

  return Object.values(groupedAssortments)
    .map((assortmentGroupList) =>
      assortmentGroupList.sort((a1, a2) => a1.title.localeCompare(a2.title, 'no'))
    )
    .flatMap((assortmentGroupList) => assortmentGroupList);
};
