import { useCallback, useMemo } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { StackedTableCellWithChangeInValue, StackedTextCell } from '@retail/components';
import { MarkupCondition, Operation } from '@retail/calculus/types';
import { useAppTFunction } from '@retail/app/i18n';
import dayjs from 'dayjs';
import { TrashIcon } from '@sanity/icons';
import { calculateDGFromSalesFactor } from '@retail/utils';
import { useMountEffect } from '@shared/hooks';

interface Props {
  draftConditions: MarkupCondition[];
  isLoading: boolean;
  handleDeleteConditions: (ids: number[]) => void;
  onSelectConditions: (conditionIds: number[]) => void;
}

type GridRowCondition = GridColDef<MarkupCondition>;

export const DraftDrawerMarkupTable = ({
  draftConditions,
  isLoading,
  handleDeleteConditions,
  onSelectConditions,
}: Props) => {
  const t = useAppTFunction();
  const apiRef = useGridApiRef();

  useMountEffect(() => {
    apiRef.current.selectRows(draftConditions.map(({ id }) => id));
  });

  const getConditionResponseDate = (date?: Date) => (date ? dayjs(date).format('ll') : undefined);

  const baseColumnProps: Partial<GridRowCondition> = useMemo(
    () => ({
      width: 150,
      sortable: false,
      headerName: '',
      headerAlign: 'center',
      align: 'center',
    }),
    []
  );

  const markupColumns: GridRowCondition[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'supplier',
        width: 180,
        headerName: t('markup.supplierItems.supplier'),
        renderCell: ({ row }: { row: MarkupCondition }) => {
          const isDelete = row.operation === 'DELETE';
          const hasLineThrough = !!row.newCondition && isDelete;

          return (
            <StackedTextCell
              centerAlign
              topLine={row.mgSupplierName}
              bottomLine={row.mgSupplierId}
              lineThroughTopLine={hasLineThrough}
              lineThroughBottomLine={hasLineThrough}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'markupTemplate',
        headerName: t('markup.markupName'),
        width: 180,
        renderCell: ({ row }: { row: MarkupCondition }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          const newValue = newCondition.markupTemplateId
            ? `${newCondition.markupTemplateName}`
            : '-';
          const prevValue =
            oldCondition && oldCondition.markupTemplateId
              ? `${oldCondition?.markupTemplateName}`
              : '-';

          return (
            <StackedTableCellWithChangeInValue
              newValue={newValue}
              prevValue={prevValue}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'primaryText',
        width: 300,
        headerName: t('freight.levelOrItemName'),
        renderCell: ({ row }: { row: MarkupCondition }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          const newValue = newCondition.getDisplayText();
          const prevValue = oldCondition?.getDisplayText() || '';

          return (
            <StackedTableCellWithChangeInValue
              newValue={newValue}
              prevValue={prevValue}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'salesCoverage',
        width: 180,
        headerName: t('markup.salesCoverage'),
        renderCell: ({ row }: { row: MarkupCondition }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          return (
            <StackedTableCellWithChangeInValue
              newValue={
                newCondition.salesFactor
                  ? `${calculateDGFromSalesFactor(newCondition.salesFactor).toFixed(2)}%`
                  : '-'
              }
              prevValue={
                oldCondition?.salesFactor
                  ? `${calculateDGFromSalesFactor(oldCondition?.salesFactor).toFixed(2)}%`
                  : '-'
              }
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'salesFactor',
        width: 180,
        headerName: t('markup.salesFactor'),
        renderCell: ({ row }: { row: MarkupCondition }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          return (
            <StackedTableCellWithChangeInValue
              newValue={newCondition.salesFactor}
              prevValue={oldCondition?.salesFactor}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
    ],
    [baseColumnProps, t]
  );

  // Common for all calculus areas
  const basicEndColumns: GridRowCondition[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'validFrom',
        headerName: t('markup.activeFrom'),
        renderCell: ({ row }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          const newValue = row.operation === Operation.DELETE ? undefined : newCondition.validFrom;

          return (
            <StackedTableCellWithChangeInValue
              newValue={getConditionResponseDate(newValue)}
              prevValue={getConditionResponseDate(oldCondition?.validFrom)}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        headerName: t('markup.activeTo'),
        renderCell: ({ row }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          return (
            <StackedTableCellWithChangeInValue
              newValue={getConditionResponseDate(newCondition?.validTo) ?? '->'}
              prevValue={getConditionResponseDate(oldCondition?.validTo) ?? '->'}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'source',
        headerName: t('markup.source'),
        renderCell: ({ row }) => {
          const oldCondition = row.getPreviousCondition();
          const newCondition = row.newCondition ?? row;

          return (
            <StackedTableCellWithChangeInValue
              newValue={newCondition.source}
              prevValue={oldCondition?.source}
              lineThrough={row.operation === Operation.DELETE}
            />
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        renderCell: ({ row }) => {
          return (
            <IconButton disabled={isLoading} onClick={() => handleDeleteConditions([row.id])}>
              <TrashIcon fontSize="25px" />
            </IconButton>
          );
        },
      },
    ],
    [baseColumnProps, handleDeleteConditions, isLoading, t]
  );

  const columns = useMemo(
    () => [...markupColumns, ...basicEndColumns],
    [basicEndColumns, markupColumns]
  );

  const onRowsSelectionHandler = useCallback(
    (selections: GridSelectionModel) => {
      onSelectConditions(selections as number[]);
    },
    [onSelectConditions]
  );

  return (
    <Box height="100%">
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        getRowId={(row) => row.id}
        columns={columns}
        rows={draftConditions ?? []}
        rowHeight={70}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        hideFooter
        checkboxSelection
        onSelectionModelChange={onRowsSelectionHandler}
        components={{
          NoRowsOverlay: () => (
            <Stack alignItems="center" justifyContent="center" gap={2} height="100%">
              <Typography variant="h3" color={(theme) => theme.palette.grey[200]}>
                {t('calculus.messages.noUpdates')}
              </Typography>
            </Stack>
          ),
        }}
      />
    </Box>
  );
};
