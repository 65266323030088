import { MessagePaths, useMessagesTranslation } from '@shared/i18n';
import { FreightResources } from '../types/FreightResources';

export const useFreightTranslation = () => useMessagesTranslation<FreightResources>();

export type FreightMessageKey = MessagePaths<FreightResources>;

export const useFreightTFunction = () => useFreightTranslation().t;

export type FreightTFunction = ReturnType<typeof useFreightTFunction>;
