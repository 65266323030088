import { alpha, Grid, Stack, Typography, useTheme } from '@mui/material';
import { CreateItemLevelMarkupSchema } from '@retail/markup/components';
import { useController, useFormContext } from 'react-hook-form';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { calculateDGFromSalesFactor, calculatePriceFromSalesFactor } from '@retail/utils';
import { StyledTextField } from './CalculationEntry';
import { EditButtonIcon } from '@shared/custom-icons';
import { CustomIconButton, StackedTextCell } from '@retail/components';
import { Chip, HelperText } from '@shared/components';
import { Check, Clear } from '@mui/icons-material';
import { EditedMarkupField } from './ProductCard';

interface Props {
  label: string;
  chipLabel?: number | string;
  costPrice?: number;
  editEntry: boolean;
  editedMarkupField: EditedMarkupField;
  setEditedMarkupField: Dispatch<SetStateAction<EditedMarkupField>>;
  setEditEntry: () => void;
  resetMarkupValues: () => void;
}

export function EditableCalculationEntry({
  label,
  chipLabel,
  costPrice,
  editEntry,
  editedMarkupField,
  setEditedMarkupField,
  setEditEntry,
  resetMarkupValues,
}: Props) {
  const { palette } = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateItemLevelMarkupSchema>();

  const salesFactorController = useController({ name: 'salesFactor', control });
  const salesPriceController = useController({ name: 'salesPrice', control });
  const salesCoverageController = useController({ name: 'salesCoverage', control });

  const onChangeSalesFactor = useCallback(
    (value: string) => {
      salesFactorController.field.onChange(value);
      setEditedMarkupField('factor');
      costPrice
        ? salesPriceController.field.onChange(
            calculatePriceFromSalesFactor(costPrice, Number(value)).toFixed(2)
          )
        : salesPriceController.field.onChange(null);
      salesCoverageController.field.onChange(calculateDGFromSalesFactor(Number(value)).toFixed(2));
    },
    [
      salesFactorController.field,
      setEditedMarkupField,
      costPrice,
      salesPriceController.field,
      salesCoverageController.field,
    ]
  );

  return (
    <Stack component={Grid} container flexDirection="row" alignItems="center">
      <Grid item xs={5}>
        <Typography fontWeight={500}>{label}</Typography>
      </Grid>
      <Grid item>
        <Stack flexDirection="row" alignItems="center">
          {editEntry ? (
            <StyledTextField
              {...salesFactorController.field}
              onChange={(e) => onChangeSalesFactor(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              size="small"
            />
          ) : (
            <Chip
              backgroundColor={alpha(palette.grey.A100, 0.4)}
              label={
                Number(salesFactorController.field.value) !== chipLabel &&
                !isNaN(Number(salesFactorController.field.value)) ? (
                  <StackedTextCell
                    centerAlign
                    gap="small"
                    topLine={
                      (editedMarkupField !== 'factor' ? '≈' : '') +
                      salesFactorController.field.value
                    }
                    bottomLine={
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{ textDecoration: 'line-through' }}
                      >
                        {chipLabel}
                      </Typography>
                    }
                  />
                ) : (
                  chipLabel ?? '-'
                )
              }
              sx={{
                border: `1px solid ${palette.divider}`,
                minWidth: 68,
                height: salesFactorController.field.value ? 42 : 32,
              }}
            />
          )}

          {editEntry ? (
            <Stack flexDirection="row">
              <CustomIconButton onClick={setEditEntry} icon={<Check />} />
              <CustomIconButton
                onClick={() => {
                  setEditEntry();
                  resetMarkupValues();
                }}
                icon={<Clear />}
              />
            </Stack>
          ) : (
            <CustomIconButton onClick={setEditEntry} icon={<EditButtonIcon />} />
          )}
        </Stack>
        {errors.salesFactor?.message && (
          <HelperText id="markup" status="error">
            {errors.salesFactor?.message}
          </HelperText>
        )}
      </Grid>
    </Stack>
  );
}
