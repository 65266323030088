import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { useMPEFetch, useMutateMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { assortmentPath } from '@retail/my-assortment/data-access';
import {
  PurchaseConditionsDTO,
  PurchaseConditionsPostDTO,
} from '@retail/purchase-agreements/types';
import { assortmentsPath } from '@retail/calculus/data-access';
import { useMpeAuthContext } from '@retail/auth/data-access';

export const purchaseConditionApiRoute = '/purchase-condition/';
export const purchaseConditionsApiRoute = '/purchase-conditions/';
export const createConditionsPath = '/create-purchase-conditions';

export interface Params extends QueryOptions {
  assortmentId: number;
  mgSupplierId: number;
  groupNumber?: string;
  mgItemNumber?: number;
  includeSubConditions?: boolean;
}

export function useFetchPurchaseCondition({
  assortmentId,
  mgSupplierId,
  groupNumber,
  mgItemNumber,
  includeSubConditions = true,
  ...queryOptions
}: Params) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<PurchaseConditionsDTO>({
    method: 'GET',
    queryKey: ['purchase-condition', assortmentId, mgSupplierId, groupNumber, mgItemNumber],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${assortmentPath}/${assortmentId}${purchaseConditionApiRoute}`,
      searchParams: { mgSupplierId, groupNumber, mgItemNumber, includeSubConditions },
      ...queryOptions,
    }),
  });
}

export function useFetchPurchaseConditions({
  assortmentId,
  mgSupplierId,
  ...queryOptions
}: Params) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<PurchaseConditionsDTO[]>({
    method: 'GET',
    queryKey: ['purchase-conditions', assortmentId, mgSupplierId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${assortmentPath}/${assortmentId}${purchaseConditionsApiRoute}`,
      searchParams: { mgSupplierId },
      ...queryOptions,
    }),
  });
}

export interface PostParams extends QueryOptions {
  assortmentId: number;
  departmentId: string;
}

export function useMutateCreateOrEditConditions({
  assortmentId,
  departmentId,
  ...queryOptions
}: PostParams) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<void, PurchaseConditionsPostDTO>({
    method: 'POST',
    url: () =>
      `${mpe.url}${urlConstant}${assortmentsPath}/${assortmentId}${createConditionsPath}?departmentId=${departmentId}`,
    token,
    ...queryOptions,
  });
}
