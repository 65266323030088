/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { Brand, Department, Member, OrgUnitRole } from "@retail/auth/types";
import { useUserBrands } from "./BrandContext";
import { OrgUnitRoleDTO, useFetchMyOrgUnitsApi } from "@retail/auth/data-access";

type MyOrgUnitsState = {
  brand: Brand | undefined;
  members: Member[];
  departments: Department[];
};

const MyOrgUnitsContext = createContext<MyOrgUnitsState>({} as MyOrgUnitsState);

const MyOrgUnitsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { selectedBrand } = useUserBrands();

  const { data: availableOrgUnits } = useFetchMyOrgUnitsApi(selectedBrand.brandId);

  /**
   * TODO: Comment in members when we support more fine-grained access control
   */
  // const members: Member[] = useMemo(
  //   () =>
  //     availableOrgUnits!.members
  //       .filter(({ role }) => role === OrgUnitRoleDTO.ADMIN)
  //       .map(({ role, ...member }) => ({
  //         ...member,
  //         role: OrgUnitRole[role!],
  //       })),
  //   [availableOrgUnits]
  // );

  const departments: Department[] = useMemo(
    () =>
      availableOrgUnits!.departments
        .filter(({ role }) => role === OrgUnitRoleDTO.ADMIN)
        .map(({ role, ...department }) => ({
          ...department,
          role: OrgUnitRole[role!],
        })),
    [availableOrgUnits]
  );

  const value: MyOrgUnitsState = useMemo(
    () => ({
      brand: undefined,
      members: [],
      departments,
    }),
    [departments]
  );

  return <MyOrgUnitsContext.Provider value={value}>{children}</MyOrgUnitsContext.Provider>;
};

const useMyOrgUnits = () => {
  return useContext(MyOrgUnitsContext);
};

export { MyOrgUnitsProvider, useMyOrgUnits };
