import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { useMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { QueryOptions } from '@shared/fetch-utils';
import { assortmentPath } from './assortmentsApi';
import { OverGroupDTO } from '@retail/products/types';

const productGroupsPath = '/product-groups';

interface Props extends QueryOptions {
  assortmentId: number;
}

export function useFetchProductGroupsInAssortment({ assortmentId, ...queryOptions }: Props) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<OverGroupDTO[]>({
    method: 'GET',
    queryKey: ['assortmentProductGroups', assortmentId],
    token,
    url: `${mpe.url}${urlConstant}${assortmentPath}/${assortmentId}${productGroupsPath}`,
    ...queryOptions,
  });
}
