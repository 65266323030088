import { IconButton, Tooltip } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { CalculatorVariantIcon, DeleteIcon } from '@shared/custom-icons';
import { Link } from 'react-router-dom';

interface Props {
  deleteItem: () => void;
  calculationLink: string;
}

export const AssortmentTableActions = ({ deleteItem, calculationLink }: Props) => {
  const t = useMyAssortmentTFunction();
  return (
    <>
      <Tooltip title={t('myAssortment.actions.goToCalculation')}>
        <IconButton component={Link} to={calculationLink}>
          <CalculatorVariantIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('myAssortment.actions.deleteItem')}>
        <IconButton onClick={deleteItem}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
