import { useMutateCreateOrEditFreightCondition } from '@retail/freight/data-access';
import { FreightCondition } from '@retail/calculus/types';
import dayjs from 'dayjs';
import { Department } from '@retail/auth/types';
import { HierarchyLevels } from '@retail/calculus/types';
import { useToast } from '@retail/hooks';
import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  baseAssortmentId: number;
  draftAssortmentId: number;
  departmentId: Department['departmentId'];
}

export const useDeleteFreightCondition = ({
  baseAssortmentId,
  draftAssortmentId,
  departmentId,
}: Props) => {
  const t = useAppTFunction();

  const toast = useToast();
  const queryClient = useQueryClient();

  const [timeoutLoading, setTimeoutLoading] = useState<boolean>(false);

  const { mutateAsync: postFreightDraftConditions } = useMutateCreateOrEditFreightCondition(
    draftAssortmentId,
    departmentId
  );

  const handleInvalidateFreight = useCallback(() => {
    setTimeoutLoading(true);
    queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
    queryClient.invalidateQueries({ queryKey: ['freight', baseAssortmentId] });

    // Backend has a pending delay, so frontend needs setTimeout
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['freights'] });
      queryClient.invalidateQueries({ queryKey: ['product-groups'] });
      setTimeoutLoading(false);
    }, 2500);
  }, [baseAssortmentId, queryClient]);

  const deleteFreightCondition = (
    supplierId: number,
    freightCondition: FreightCondition,
    closeDeleteDialog: () => void
  ) => {
    postFreightDraftConditions({
      body: {
        mgSupplierId: supplierId,
        mgSupplierConditions:
          freightCondition.bindingType === HierarchyLevels.supplier
            ? [
                {
                  agreementId: null,
                  freight: null,
                  validFrom: dayjs(new Date()).format('YYYY-MM-DD'),
                },
              ]
            : null,
        productGroupConditions: freightCondition.productGroup
          ? [
              {
                agreementId: null,
                freight: null,
                validFrom: dayjs(new Date()).format('YYYY-MM-DD'),
                groupNumber: freightCondition.productGroup,
              },
            ]
          : null,
        mgSupplierItemConditions: freightCondition.mgItemNumber
          ? [
              {
                agreementId: null,
                freight: null,
                validFrom: dayjs(new Date()).format('YYYY-MM-DD'),
                mgItemNumber: freightCondition?.mgItemNumber,
              },
            ]
          : null,
      },
    })
      .then(() => {
        toast.success(t('calculus.actions.conditionDeleteInDrawer'));
        handleInvalidateFreight();
        closeDeleteDialog();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('common.genericError'));
      });
  };

  return {
    timeoutLoading,
    deleteFreightCondition,
  };
};
