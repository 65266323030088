import { useState } from 'react';

interface Props {
  initPageSize: number;
  initPage?: number;
}

export interface Pagination {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
}

export function usePagination({ initPageSize, initPage = 0 }: Props) {
  const [pageSize, setPageSize] = useState(initPageSize);
  const [page, setPage] = useState(initPage);
  return {
    pageSize,
    page,
    setPageSize,
    setPage,
    pagination: {
      pageSize,
      page,
      setPageSize,
      setPage,
    } as Pagination,
  };
}
