import { useProductsTFunction } from '@retail/products/i18n';
import { useMemo } from 'react';
import { lighten, Stack, Typography } from '@mui/material';

interface Props {
  liProps: React.HTMLAttributes<HTMLLIElement>;
  searchQuery: string;
  mgItemNumber: number;
  primaryText: string;
  secondaryText?: string;
  finfoNumber: number;
}

export function SearchResultItem({
  searchQuery,
  mgItemNumber,
  primaryText,
  secondaryText,
  finfoNumber,
  liProps,
}: Props) {
  const t = useProductsTFunction();

  const { topText, bottomText } = useMemo(() => {
    if (`${finfoNumber}` === searchQuery) {
      return {
        topText: primaryText,
        bottomText: `${t('products.mgItem.supplierItem.finfoNumber.label')}: ${finfoNumber}`,
      };
    }

    const matchingTextSearchItem =
      primaryText.toLowerCase().includes(searchQuery.toLowerCase()) ||
      secondaryText?.toLowerCase().includes(searchQuery.toLowerCase());

    if (matchingTextSearchItem) {
      return {
        topText: primaryText,
        bottomText: `${t('products.mgItem.supplierItem.finfoNumber.label')}: ${finfoNumber}`,
      };
    }

    // Default to mg item head
    return {
      topText: primaryText,
      bottomText: `${t('products.mgItem.number.label')}: ${mgItemNumber}`,
    };
  }, [finfoNumber, mgItemNumber, primaryText, searchQuery, secondaryText, t]);

  return (
    <Stack component="li" {...liProps} alignItems="start !important">
      <Typography variant="body1">{topText}</Typography>
      <Typography variant="body2" color={({ palette }) => lighten(palette.text.primary, 0.2)}>
        {bottomText}
      </Typography>
    </Stack>
  );
}
