import { useProductsTFunction } from '@retail/products/i18n';
import { PanelProps, ProductInformationAccordion } from './ProductInformationAccordion';
import { ProductInformationAccordionRow } from './ProductInformationAccordionRow';
import { PANELS, ProductInformationAccordionsProps } from './ProductInformationAccordions';

export const MgInfoPanel = ({
  expanded,
  selectedSupplierItem,
  handleChange,
}: PanelProps & ProductInformationAccordionsProps) => {
  const t = useProductsTFunction();

  return (
    <ProductInformationAccordion
      panelType={PANELS.MGINFO}
      expanded={expanded}
      handleChange={handleChange}
    >
      <ProductInformationAccordionRow title={t('products.mgItem.details.mgSupplierItemId')}>
        {selectedSupplierItem.mgSupplierItemId}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.supplierId')}>
        {selectedSupplierItem.mgSupplierId}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.orgNr')}>
        {selectedSupplierItem.supplierOrgNumber}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.GLN')}>
        {selectedSupplierItem.supplierGln}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.finfoSupplierId')}>
        {selectedSupplierItem.finfoSupplierId}
      </ProductInformationAccordionRow>
    </ProductInformationAccordion>
  );
};
