import dayjs from 'dayjs';
import { Bk04Varegruppe, FinfoPrice } from '../../finfo';
import { MgSupplierItemPackage } from '../MgSupplierItemPackage';
import {
  FinfoAgreementPriceDTO,
  FinfoPriceDTO,
  MgSupplierItemDetailedDTO,
  MgSupplierItemMinimalDTO,
  PpsMgPackageDTO,
} from '../../../dto';
import { PpsMgPackage } from './PpsMgPackage';
import { Supplier } from '@retail/suppliers/types';

export interface PpsMgSupplierItemBase {
  finfoNumber?: number;
  primaryText: string;
  isMainSupplier: boolean;
}

interface ConstructorProps {
  id: string;
  finfoNumber?: number;
  primaryText: string;
  secondaryText: string;
  bk04Varegruppe?: Bk04Varegruppe;
  mgParticipant: Supplier;
  packages: MgSupplierItemPackage[];
  finfoPrices: FinfoPrice[];
  isMainSupplier: boolean;
}

export class MgSupplierItemDetailed implements PpsMgSupplierItemBase {
  id: string;
  finfoNumber?: number;
  primaryText: string;
  secondaryText: string;
  bk04Varegruppe?: Bk04Varegruppe;
  mgParticipant: Supplier;
  packages: MgSupplierItemPackage[];
  finfoPrices: FinfoPrice[];
  isMainSupplier: boolean;

  constructor({
    id,
    finfoNumber,
    primaryText,
    secondaryText,
    bk04Varegruppe,
    mgParticipant,
    packages,
    finfoPrices,
    isMainSupplier,
  }: ConstructorProps) {
    this.id = id;
    this.finfoNumber = finfoNumber;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.bk04Varegruppe = bk04Varegruppe;
    this.mgParticipant = mgParticipant;
    this.packages = packages;
    this.finfoPrices = finfoPrices;
    this.isMainSupplier = isMainSupplier;
  }

  getCurrentFinfoPrice = (): FinfoPrice | undefined => {
    return this.finfoPrices.reduce((tmpCurrent: FinfoPrice | undefined, finfoPrice) => {
      if (!tmpCurrent) {
        return finfoPrice;
      }
      const tmpLatest = dayjs(tmpCurrent.priceDate);
      return dayjs(finfoPrice.priceDate).isAfter(tmpLatest) ? finfoPrice : tmpCurrent;
    }, undefined);
  };

  static fromDto = (
    supplierItem: MgSupplierItemDetailedDTO,
    mgPackages: PpsMgPackageDTO[]
  ): MgSupplierItemDetailed => {
    return new MgSupplierItemDetailed({
      bk04Varegruppe:
        supplierItem.productGroupBk04 && Bk04Varegruppe.fromDto(supplierItem.productGroupBk04),
      id: supplierItem.supplierItemId,
      finfoNumber: supplierItem.finfoNumber,
      primaryText: supplierItem.primaryText,
      secondaryText: supplierItem.secondaryText || '',
      mgParticipant: Supplier.fromDTO(supplierItem.mgParticipantAccount),
      finfoPrices:
        supplierItem.finfoPrices?.map((finfoPrice) => FinfoPrice.fromDto(finfoPrice)) || [],
      isMainSupplier: !!supplierItem.mainSupplier,
      packages: supplierItem.mgSupplierItemPackages.map(
        ({
          mgPackageId,
          mgSupplierItemPackageId,
          calculatedCount,
          vilmaUnit,
          basePriceUnit,
          priceComparisonUnit,
          deleted,
          type,
          deliverable,
        }) => {
          const mgPackageDto = mgPackages.find(
            (mgPackage) => mgPackage.mgPackageId === mgPackageId
          );
          const mgPackage: PpsMgPackage | undefined = mgPackageDto && {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            calculatedCount: mgPackageDto.calculatedCount,
            mgPackageId: mgPackageDto.mgPackageId,
            packageNumber: mgPackageDto.packageNumber,
            gtinCode: mgPackageDto.gtinCode,
            vilmaUnit: mgPackageDto.vilmaUnit,
            packageType: mgPackageDto.packageType,
          };

          const supplierItemPackage: MgSupplierItemPackage = {
            calculatedCount,
            vilmaUnit,
            basePriceUnit,
            priceComparisonUnit,
            deleted,
            mgPackage,
            mgSupplierItemPackageId,
            type,
            deliverable,
          };

          return supplierItemPackage;
        }
      ),
    });
  };
}

interface MgSupplierItemsMinimalProps {
  finfoAgreementPrices?: FinfoAgreementPriceDTO[];
  finfoNumber?: number;
  finfoPrices?: FinfoPriceDTO[];
  mgSupplierItemId: number;
  primaryText: string;
  mainSupplier?: boolean;
}

export class MgSupplierItemMinimal {
  finfoAgreementPrices?: FinfoAgreementPriceDTO[];
  finfoNumber?: number;
  finfoPrices?: FinfoPriceDTO[];
  mgSupplierItemId: number;
  primaryText: string;
  mainSupplier?: boolean;

  constructor({
    finfoAgreementPrices,
    finfoNumber,
    finfoPrices,
    mgSupplierItemId,
    primaryText,
    mainSupplier,
  }: MgSupplierItemsMinimalProps) {
    this.finfoAgreementPrices = finfoAgreementPrices;
    this.finfoNumber = finfoNumber;
    this.finfoPrices = finfoPrices;
    this.mgSupplierItemId = mgSupplierItemId;
    this.primaryText = primaryText;
    this.mainSupplier = mainSupplier;
  }

  static fromDto = (dto: MgSupplierItemMinimalDTO): MgSupplierItemMinimal =>
    new MgSupplierItemMinimal({
      finfoAgreementPrices: dto.finfoAgreementPrices,
      finfoNumber: dto.finfoNumber,
      finfoPrices: dto.finfoPrices,
      mgSupplierItemId: dto.mgSupplierItemId,
      primaryText: dto.primaryText,
      mainSupplier: dto.mainSupplier,
    });
}
