import { Box } from '@mui/material';
import { MgItem, MgSupplierItem } from '@retail/products/types';
import { Paper } from '@shared/components';
import { Dispatch, SetStateAction, useState } from 'react';
import { SupplierTabSelect } from '../../Supplier';
import { JsonViewer } from '@textea/json-viewer';
import { Comparison } from './Comparison';

interface Props {
  mgItem: MgItem;
}

export interface SelectedSupplierProps {
  selectedSupplierItem: MgSupplierItem;
  setSelectedSupplierItem: Dispatch<SetStateAction<MgSupplierItem>>;
}

export const JSON = ({
  mgItem,
  selectedSupplierItem,
  setSelectedSupplierItem,
}: Props & SelectedSupplierProps) => {
  const [comparisonSupplier, setComparisonSupplier] = useState<MgSupplierItem | null>(null);

  const unselectedSupplierItems = mgItem.supplierItems.filter(
    (supplierItems) => supplierItems.id !== selectedSupplierItem.id
  );

  return (
    <Paper paddingY="dense">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        pb={2}
      >
        <SupplierTabSelect
          suppliers={mgItem.supplierItems}
          selectedSupplier={selectedSupplierItem}
          selectSupplier={setSelectedSupplierItem}
          getSupplierName={(supplier) => supplier.mgParticipant.name}
          getSupplierId={(supplier) => supplier.mgParticipant.id + ''}
        />
      </Box>

      <Comparison
        comparisonSupplier={comparisonSupplier}
        setComparisonSupplier={setComparisonSupplier}
        selectedSupplierItem={selectedSupplierItem}
        supplierItems={unselectedSupplierItems}
      />

      <Box
        sx={{
          display: comparisonSupplier ? 'none' : 'block',
          border: (theme) => `1px solid ${theme.palette.grey[50]}`,
          padding: (theme) => theme.spacing(2),
        }}
      >
        <JsonViewer value={selectedSupplierItem} style={{ fontSize: 12 }} />
      </Box>
    </Paper>
  );
};
