/* eslint-disable @nx/enforce-module-boundaries */
import { useCallback, useState } from 'react';
import { MgAssortmentDTO } from '@retail/monitoring-assortment/types';

import { SuccessAddingItemsDialog } from '@retail/products/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { useProductsTFunction } from '@retail/products/i18n';
import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';

import { useNavigate } from 'react-router-dom';
import { MenuElementProps, useDisclosure } from '@shared/hooks';
import { MgItem, MgSupplierItem } from '@retail/products/types';
import { useMutateAddItemsToDraft } from '@retail/my-assortment/data-access';
import { ItemsDTO } from '@retail/my-assortment/types';
import { MonitoringItemSelectionDialog } from './MonitoringItemSelectionDialog';
import { useDepartmentAssortments, useMemberAssortments } from '@retail/my-assortment/context';
import { useRefetchDraftDrawer } from '@retail/context';
import { AddMgItemToMgAssortmentMenu } from './AddMgItemToMgAssortmentMenu';

interface Props {
  mgItem: MgItem;
  menuElementProps: MenuElementProps;
}

export function MonitoringActionsMenu({ mgItem, menuElementProps }: Props) {
  //const toast = useToast();

  const t = useProductsTFunction();
  //const queryClient = useQueryClient();

  const { draftAssortment } = useMemberAssortments();
  const { selectedDepartment } = useSelectedOrgUnit();
  const { baseAssortmentId: departmentBaseAssortmentId } = useDepartmentAssortments();

  const { invalidateAssortmentDraft } = useRefetchDraftDrawer({
    departmentBaseAssortmentId,
    memberDraftAssortment: draftAssortment,
  });
  /*
  const { data: allAssortmentsDtos = [] } = useFetchMgAssortments();
  const allAssortments = useMemo(
    () => allAssortmentsDtos.map(MgAssortmentBase.fromDto),
    [allAssortmentsDtos]
  );

  const { data: assortmentsItemExistsInDtos = [] } = useFetchMgAssortments([mgItem.id]);
  const assortmentsItemExistsIn = useMemo(
    () => assortmentsItemExistsInDtos.map(MgAssortmentBase.fromDto),
    [assortmentsItemExistsInDtos]
  );

  const availableAssortments = useMemo(() => {
    const idsToExclude = assortmentsItemExistsIn.map(({ id }) => id);

    return allAssortments.filter(
      (assortment: MgAssortmentBase) => !idsToExclude.includes(assortment.id)
    );
  }, [allAssortments, assortmentsItemExistsIn]);
*/
  const firstSupplierItem = mgItem.getPrimarySupplierItem();

  const [selectedAssortment] = useState<MgAssortmentDTO | null>(null);
  const [selectedItem, setSelectedItem] = useState<MgSupplierItem | null>(null);
  const [successModal, setSuccessModal] = useState<boolean>(false);

  const {
    isOpen: isSelectItemModalOpen,
    onOpen: openSelectItemModal,
    onClose: closeSelectItemModal,
  } = useDisclosure(false);
  /*
  const {
    isOpen: isCreateManuallyModalOpen,
    onClose: closeCreateManuallyModal,
    onOpen: openCreateManuallyModal,
  } = useDisclosure(false);

  const {
    isOpen: isAddToExistingModalOpen,
    onClose: closeAddToExistingModal,
    onOpen: openAddToExistingModal,
  } = useDisclosure(false);

  const { handleAddItemsToMgAssortment, handleDeleteItemsFromMgAssortment } =
    useMgAssortmentActions();
  const { mutateAsync: createMgAssortmentAsync, isLoading: isLoadingCreate } =
    useCreateMgAssortment(selectedContext);
*/
  const { mutateAsync: postItems } = useMutateAddItemsToDraft({
    memberId: selectedDepartment?.departmentId ?? '',
    assortmentId: draftAssortment.id,
  });

  /*
  const submitNewAssortmentForm = ({ title, assortmentType }: NewAssortment) => {
    menuElementProps.onClose();
    createMgAssortmentAsync({
      body: {
        title,
        assortmentType,
        itemCodes: {
          mgItemNumbers: [mgItem.id],
        },
      },
    })
      .then((assortment) => {
        closeCreateManuallyModal();
        queryClient.invalidateQueries(getMgAssortmentsQueryKey());
        setSelectedAssortment(assortment);
        setSuccessModal(true);
      })
      .catch((err: FetchError) => {
        console.error(err);
        toast.error(t('products.mgItem.search.error.addingItemToAssortment'));
      });
  };*/

  const navigate = useNavigate();
  const navigateToAssortment = useCallback(
    (assortmentId: number) => {
      navigate(
        selectedAssortment
          ? mgPrisRoutes.marketInsight.assortments.assortment.getFullLinkPath(assortmentId + '')
          : '/myAssortment'
      );
    },
    [navigate, selectedAssortment]
  );
  /*
  const handlePpsItemSuccess = useCallback(
    (assortmentId: number) => {
      const currentAssortment = availableAssortments.find(
        (assortment) => assortment.id === assortmentId
      )!;

      setSelectedAssortment({
        title: currentAssortment.title,
        id: currentAssortment.id,
        description: currentAssortment.description,
        type: String(currentAssortment.type),
        size: 0,
        readOnly: currentAssortment.readOnly,
      });
    },
    [availableAssortments]
  );

  const addItemToAssortment = useCallback(
    (assortmentId: number) => {
      menuElementProps.onClose();
      handleAddItemsToMgAssortment({
        assortmentId,
        mgItemNumbers: [mgItem.id],
      })
        .then(() => {
          queryClient.invalidateQueries(getMgAssortmentsQueryKey([mgItem.id]));
          setSuccessModal(true);
          handlePpsItemSuccess(assortmentId);
        })
        .catch(() => {
          toast.error(t('products.mgItem.addItemsToAssortment.error.addItemToAssortment'));
        });
    },
    [
      handleAddItemsToMgAssortment,
      handlePpsItemSuccess,
      menuElementProps,
      mgItem.id,
      queryClient,
      t,
      toast,
    ]
  );

  const deleteItemFromAssortment = useCallback(
    (assortmentId: number) => {
      menuElementProps.onClose();
      handleDeleteItemsFromMgAssortment({
        assortmentId,
        mgItemNumbers: [mgItem.id],
      })
        .then(() => {
          queryClient.invalidateQueries(getMgAssortmentsQueryKey([mgItem.id]));
        })
        .catch(() => {
          toast.error(t('products.mgItem.addItemsToAssortment.error.deleteItemFromAssortment'));
        });
    },
    [handleDeleteItemsFromMgAssortment, menuElementProps, mgItem.id, queryClient, t, toast]
  );
*/
  const addItemToMyAssortment = useCallback(
    (selectedSupplier: MgSupplierItem | null) => {
      setSelectedItem(selectedSupplier);
      menuElementProps.onClose();

      postItems({
        body: [
          {
            mgSupplierId: selectedSupplier?.mgSupplierId,
            mgItemNumber: mgItem.id,
            priority: 'ZERO',
          } as ItemsDTO,
        ],
      })
        .then(() => {
          invalidateAssortmentDraft();
          closeSelectItemModal();
          setSuccessModal(true);
        })
        .catch((err) => {
          console.error(err);
          setSuccessModal(false);
        });
    },
    [closeSelectItemModal, invalidateAssortmentDraft, menuElementProps, mgItem.id, postItems]
  );

  return (
    <>
      <MonitoringItemSelectionDialog
        mgItem={mgItem}
        open={isSelectItemModalOpen}
        onClose={closeSelectItemModal}
        addItemToMyAssortment={addItemToMyAssortment}
      />

      <AddMgItemToMgAssortmentMenu
        {...menuElementProps}
        //mgAssortments={availableAssortments}
        openSelectItemModal={openSelectItemModal}
        //addItemsToNewAssortment={openCreateManuallyModal}
        //openAddToExistingModal={openAddToExistingModal}
      />
      {/*
      <CreateAssortmentManuallyDialog
        open={isCreateManuallyModalOpen}
        onClose={closeCreateManuallyModal}
        onSubmit={submitNewAssortmentForm}
        isLoading={isLoadingCreate}
        hideAddItemsLater
        hideAssortmentType
      />

      <AddItemToAssortmentDialog
        open={isAddToExistingModalOpen}
        onClose={closeAddToExistingModal}
        assortmentsItemExistsIn={assortmentsItemExistsIn}
        availableAssortments={availableAssortments}
        addItemToAssortment={addItemToAssortment}
        deleteItemFromAssortment={deleteItemFromAssortment}
      />
*/}
      {successModal && (
        <SuccessAddingItemsDialog
          isOpen={successModal}
          closeDialog={() => setSuccessModal(false)}
          successText={t('products.mgItem.addItemsToAssortment.added', {
            itemName: selectedItem?.primaryText || firstSupplierItem?.primaryText || mgItem.id,
          })}
          assortmentId={selectedAssortment?.id ?? draftAssortment.id}
          assortmentTitle={
            selectedAssortment?.title ?? t('products.mgItem.addItemsToAssortment.myAssortment')
          }
          navigate={navigateToAssortment}
        />
      )}
    </>
  );
}
