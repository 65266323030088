import { SvgIcon, SvgIconProps } from '@mui/material';

export const ActivateIcon = ({ ...props }: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10.875 12V4.5C10.875 4.20163 10.9935 3.91548 11.2045 3.7045C11.4155 3.49353 11.7016 3.375 12 3.375C12.2984 3.375 12.5845 3.49353 12.7955 3.7045C13.0065 3.91548 13.125 4.20163 13.125 4.5V12C13.125 12.2984 13.0065 12.5845 12.7955 12.7955C12.5845 13.0065 12.2984 13.125 12 13.125C11.7016 13.125 11.4155 13.0065 11.2045 12.7955C10.9935 12.5845 10.875 12.2984 10.875 12ZM17.1141 4.3125C16.8643 4.15868 16.5642 4.10853 16.278 4.17274C15.9917 4.23694 15.7419 4.41043 15.5817 4.6562C15.4215 4.90197 15.3637 5.2006 15.4205 5.4884C15.4774 5.77621 15.6444 6.03043 15.8859 6.19687C17.9447 7.53469 19.125 9.65625 19.125 12C19.125 13.8897 18.3743 15.7019 17.0381 17.0381C15.7019 18.3743 13.8897 19.125 12 19.125C10.1103 19.125 8.29806 18.3743 6.96186 17.0381C5.62567 15.7019 4.875 13.8897 4.875 12C4.875 9.65625 6.05531 7.53469 8.11406 6.19219C8.34278 6.02077 8.49768 5.76871 8.54729 5.48722C8.5969 5.20573 8.5375 4.9159 8.38116 4.67662C8.22482 4.43734 7.98326 4.26653 7.70555 4.1989C7.42783 4.13127 7.13479 4.17189 6.88594 4.3125C4.1775 6.07313 2.625 8.87719 2.625 12C2.625 14.4864 3.61272 16.871 5.37087 18.6291C7.12903 20.3873 9.5136 21.375 12 21.375C14.4864 21.375 16.871 20.3873 18.6291 18.6291C20.3873 16.871 21.375 14.4864 21.375 12C21.375 8.87719 19.8225 6.07313 17.1141 4.3125Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
