import { Stack, Typography } from '@mui/material';
import { DeleteItemsButton } from '@retail/my-assortment/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

interface Props {
  noItemsChecked: boolean;
  totalItems: number;
  deleteCheckedItems: () => void;
  deletionIsLoading: boolean;
}

export const AssortmentItemsTableToolbar = ({
  noItemsChecked,
  totalItems,
  deleteCheckedItems,
  deletionIsLoading,
}: Props) => {
  const t = useMyAssortmentTFunction();

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={2} px={4}>
      <Typography>
        <b>{totalItems}</b> {t('myAssortment.itemsInList')}
      </Typography>

      <Stack flexDirection="row" justifyContent="space-between" gap={1}>
        <DeleteItemsButton
          noItemsChecked={noItemsChecked}
          deleteCheckedItems={deleteCheckedItems}
          deletionIsLoading={deletionIsLoading}
        />
      </Stack>
    </Stack>
  );
};
