import { GroupTypes } from '../../GroupTypes';
import { MainGroupDTO, ProductGroupDTO } from '../../dto';
import { ProductGroup } from './ProductGroup';

interface MainGroupProps extends ProductGroupDTO {
  subGroups: ProductGroup[];
}

export class MainGroup {
  id: number;
  type: GroupTypes;
  groupNumber: string;
  name: string;
  active?: boolean;
  numberOfItems?: number;
  subGroups: ProductGroup[];

  constructor({ id, type, groupNumber, name, active, numberOfItems, subGroups }: MainGroupProps) {
    this.id = id;
    this.type = type;
    this.groupNumber = groupNumber;
    this.name = name;
    this.active = active;
    this.numberOfItems = numberOfItems;
    this.subGroups = subGroups;
  }

  getLabel = (): string => (this.name ? `${this.groupNumber} ${this.name}` : this.groupNumber);

  static fromDto = (dto: MainGroupDTO): MainGroup =>
    new MainGroup({
      id: dto.id,
      type: dto.type,
      groupNumber: dto.groupNumber,
      name: dto.name,
      active: dto.active,
      numberOfItems: dto.numberOfItems,
      subGroups: dto.subGroups.map(ProductGroup.fromDto),
    });
}
