import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { IconButton, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { SupplierWithPriority } from '@retail/suppliers/types';

interface Props {
  underprioritizedSuppliers: SupplierWithPriority[];
  setMainSupplier: (supplier: SupplierWithPriority) => void;
  setNoPrioSupplier: (supplier: SupplierWithPriority) => void;
}

export const UnderPrioritizedSuppliers = ({
  underprioritizedSuppliers,
  setMainSupplier,
  setNoPrioSupplier,
}: Props) => {
  const t = useMyAssortmentTFunction();
  return (
    <List>
      {underprioritizedSuppliers.length > 0 ? (
        underprioritizedSuppliers.map((supplier) => (
          <Stack
            component={ListItem}
            key={supplier.id}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">{supplier.name}</Typography>
            <Stack flexDirection="row">
              <Tooltip title={t('myAssortment.priority.setToMainPrio')}>
                <IconButton onClick={() => setMainSupplier(supplier)}>
                  <ArrowUpward fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('myAssortment.priority.removePriority')}>
                <IconButton onClick={() => setNoPrioSupplier(supplier)}>
                  <ArrowDownward fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        ))
      ) : (
        <Typography textAlign="center" variant="body2" fontStyle="italic">
          {t('myAssortment.priority.noUnderPrio')}
        </Typography>
      )}
    </List>
  );
};
