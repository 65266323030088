import { Typography, useTheme } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';
import { OutlinedButton } from '@shared/components';
import { CheckIcon } from '@shared/custom-icons';

export const ActivatedButton = () => {
  const { palette } = useTheme();
  const t = useAppTFunction();
  return (
    <OutlinedButton
      sx={{
        bgcolor: `${palette.common.white} !important`,
        border: `1px solid ${palette.success[900]} !important`,
      }}
      size="small"
      disabled
      startIcon={<CheckIcon color={palette.success[900]} />}
    >
      <Typography variant="body2" color={palette.success[900]}>
        {t('common.actions.activated')}
      </Typography>
    </OutlinedButton>
  );
};

