import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { CalculusTabOptions, SupplierWithProductGroupsDTO } from '@retail/calculus/types';
import { useMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';

export const hierarchyPath = '/condition-hierarchy';
export const assortmentsPath = '/assortments';
export const productGroupsPath = '/product-groups';
export const orgUnitPath = '/org-unit';

interface SearchProps {
  assortmentId?: number;
  includeOwner?: boolean;
  baseAssortment?: boolean;
  filterConditions: CalculusTabOptions[];
}

export function useFetchProductGroupsForSupplier(
  supplierId: number,
  {
    assortmentId,
    includeOwner = true,
    baseAssortment = false,
    filterConditions = []
  }: SearchProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();
  return useMPEFetch<SupplierWithProductGroupsDTO>({
    method: 'GET',
    queryKey: [
      'product-groups',
      supplierId,
      filterConditions
    ],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${assortmentsPath}/${assortmentId}${hierarchyPath}`,
      searchParams: {
        mgParticipantAccountId: supplierId,
        includeOwner,
        baseAssortment,
        filterConditions
      },
    }),
    ...queryOptions,
  });
}
