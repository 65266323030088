import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { ActivatedMarkupConditionDTO, MarkupConditionDTO } from '@retail/markup/types';
import { useMPEFetch, useMutateMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';

export const orgUnitPath = 'org-unit';
export const assortmentsPath = 'assortments';
export const markupConditionPath = 'markup-condition';
export const markupConditionsPath = 'markup-conditions';
export const createMarkupConditionPath = 'create-markup-conditions';
export const activateMarkupConditionPath = 'activate-markup-conditions';

interface SearchProps {
  assortmentId: number;
  mgSupplierId?: number;
  groupNumber?: string;
  mgItemNumber?: number;
  includeSubConditions?: boolean;
}

export function useFetchActiveMarkupCondition(
  {
    assortmentId,
    mgSupplierId,
    groupNumber,
    mgItemNumber,
    includeSubConditions = true,
  }: SearchProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<MarkupConditionDTO>({
    method: 'GET',
    queryKey: ['markup-condition', assortmentId, mgSupplierId, groupNumber, mgItemNumber],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}/${assortmentsPath}/${assortmentId}/${markupConditionPath}/`,
      searchParams: {
        mgSupplierId,
        groupNumber,
        mgItemNumber,
        includeSubConditions,
      },
    }),
    ...queryOptions,
  });
}

export function useFetchActiveMarkupConditions(
  { assortmentId, mgSupplierId }: SearchProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<MarkupConditionDTO[]>({
    method: 'GET',
    queryKey: ['markup-conditions', assortmentId, mgSupplierId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}/${assortmentsPath}/${assortmentId}/${markupConditionsPath}/`,
      searchParams: {
        mgSupplierId,
      },
    }),
    ...queryOptions,
  });
}

export function useMutateActivateDraftMarkupCondition(
  assortmentId: number,
  departmentId: string,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<MarkupConditionDTO, ActivatedMarkupConditionDTO>({
    method: 'POST',
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}/${assortmentsPath}/${assortmentId}/${activateMarkupConditionPath}`,
      searchParams: {
        departmentId,
      },
    }),
    token,
    ...queryOptions,
  });
}

export function useMutateCreateOrEditMarkupCondition(
  assortmentId: number,
  departmentId: string,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMutateMPEFetch<ActivatedMarkupConditionDTO>({
    method: 'POST',
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}/${assortmentsPath}/${assortmentId}/${createMarkupConditionPath}`,
      searchParams: {
        departmentId,
      },
    }),
    token,
    ...queryOptions,
  });
}
