import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { QueryOptions } from '@shared/fetch-utils';
import { PurchaseAgreementDTO } from '@retail/purchase-agreements/types';
import { buildUrl } from '@shared/utils';
import { useMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';

export const purchaseAgreementsApiRoute = '/purchase-agreements';

export interface PurchaseAgreementParams extends QueryOptions {
  supplierId: number;
}

export function useFetchPurchaseAgreements({
  supplierId,
  ...queryOptions
}: PurchaseAgreementParams) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<PurchaseAgreementDTO[]>({
    method: 'GET',
    queryKey: ['purchase-agreements', supplierId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${purchaseAgreementsApiRoute}/`,
      searchParams: {
        mgParticipantAccountId: supplierId,
      },
      ...queryOptions,
    }),
  });
}
