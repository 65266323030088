import { SxProps, Theme } from '@mui/material';
import { treeItemClasses } from '@mui/x-tree-view';

export const childNodeClassName = 'childnode';

export const navTreeSx: SxProps<Theme> = (theme) => ({
  [`& .${treeItemClasses.label}`]: {
    color: theme.palette.common.black,
  },
  [`&.${childNodeClassName} .${treeItemClasses.label}`]: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    padding: 0.5,
    fontWeight: 'medium',
  },

  [`.${treeItemClasses.selected}, .${treeItemClasses.focused}`]: {
    backgroundColor: 'inherit !important',
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'bold',
      color: theme.palette.primary.light,
    },
  },
  [`.${treeItemClasses.content}`]: {
    padding: 0,
    margin: 0,
  },
  [`.${treeItemClasses.group}`]: {
    padding: 0,
    margin: 0,
    marginLeft: 1,
  },
  [`button.${treeItemClasses.iconContainer}`]: {
    width: 'auto',
    padding: 0,
  },
});


