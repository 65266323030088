import { useCallback, useMemo, useState } from 'react';
import { Supplier } from '@retail/suppliers/types';
import { FreightConditionsTable } from './FreightConditionsTable';
import { Actions, CalculusAreas, ConditionState, FreightCondition } from '@retail/calculus/types';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { EditFreightConditionDialog } from './EditFreightCondition';
import { useDeleteFreightCondition } from '@retail/freight/context';
import { useLevelSearchParams, useTableActions } from '@retail/calculus/hooks';
import { Paper } from '@shared/components';
import { CreateFreightConditionContainer } from './CreateFreightCondition';
import { DeleteFreightDialog } from '@retail/freight/components';
import { AffectedItemsContainer } from '@retail/calculus/containers';
import { useFetchConditions } from '@retail/calculus/data-access';

interface Props {
  supplier: Supplier;
  typesToShow: ConditionState;
  isCreateOpen: boolean;
  closeCreate: () => void;
}

export const FreightConditions = ({ supplier, typesToShow, isCreateOpen, closeCreate }: Props) => {
  const { departmentId, baseAssortmentId, draftAssortmentId } = useDepartmentAssortments();
  const { selectedProductGroupId, selectedMgItemId } = useLevelSearchParams();

  const { deleteFreightCondition } = useDeleteFreightCondition({
    baseAssortmentId,
    draftAssortmentId,
    departmentId,
  });

  const { action, setAction } = useTableActions();

  const [freightConditionClicked, setFreightConditionClicked] = useState<
    FreightCondition | undefined
  >(undefined);

  const { data: conditionDtos = [] } = useFetchConditions({
    conditionTypes: 'FREIGHT',
    conditionState: typesToShow !== ConditionState.DRAFT ? typesToShow : ConditionState.ALL,
    assortmentId: draftAssortmentId,
    mgSupplierIds: [supplier.mgSupplierId],
    groupNumbers: selectedProductGroupId ? [selectedProductGroupId] : [],
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : [],
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
  });
  const freightCondition = useMemo(
    () => (conditionDtos?.length > 0 ? conditionDtos[0].freightConditions : []),
    [conditionDtos]
  );

  const freightConditions = useMemo(
    () => freightCondition.map(FreightCondition.fromDto),
    [freightCondition]
  );

  const closeAction = useCallback(() => {
    setAction('none');
    setFreightConditionClicked(undefined);
  }, [setAction]);

  const handleFreightConditionClicked = useCallback(
    (freight: FreightCondition, action: Actions) => {
      setAction(action);
      setFreightConditionClicked(freight);
    },
    [setAction]
  );

  const handleDeleteCondition = () => {
    if (freightConditionClicked) {
      deleteFreightCondition(supplier.mgSupplierId, freightConditionClicked, closeAction);
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        overflow: 'auto',
        padding: 0,
        borderRadius: 0,
      }}
    >
      {isCreateOpen && (
        <CreateFreightConditionContainer
          supplierId={supplier.mgSupplierId}
          closeCreateDialog={closeCreate}
        />
      )}

      {freightConditionClicked && action === 'delete' && (
        <DeleteFreightDialog
          conditionToDelete={freightConditionClicked}
          isOpen={!!freightConditionClicked}
          onDelete={handleDeleteCondition}
          onClose={closeAction}
        />
      )}

      <FreightConditionsTable
        supplier={supplier}
        freightConditions={freightConditions}
        setAction={setAction}
        handleFreightConditionClicked={handleFreightConditionClicked}
      />

      {freightConditionClicked && (
        <AffectedItemsContainer
          open={action === 'view'}
          onClose={closeAction}
          conditionType={CalculusAreas.FREIGHT}
          productGroup={freightConditionClicked.productGroup}
          mgSupplierId={supplier.mgSupplierId}
        />
      )}

      {freightConditionClicked && (
        <EditFreightConditionDialog
          supplierId={supplier.mgSupplierId}
          freightConditionClicked={freightConditionClicked}
          isOpen={action === 'edit'}
          onClose={closeAction}
        />
      )}
    </Paper>
  );
};
