import { Actions } from '@retail/calculus/types';
import { useState } from 'react';

export interface TableActions {
  onClose: () => void;
  handleEditDialogOpen: () => void;
  handleDeleteDialogOpen: () => void;
  handleActivateDialogOpen: () => void;
}

export const useTableActions = () => {
  const [action, setAction] = useState<Actions>('none');

  const handleViewDialogOpen = () => setAction('view');
  const handleEditDialogOpen = () => setAction('edit');
  const handleDeleteDialogOpen = () => setAction('delete');
  const handleActivateDialogOpen = () => setAction('activate');
  const handleResetAction = () => setAction('none');

  return {
    action,
    handleViewDialogOpen,
    handleEditDialogOpen,
    handleDeleteDialogOpen,
    handleActivateDialogOpen,
    handleResetAction,
    setAction,
  };
};
