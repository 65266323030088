import { SelectChangeEvent, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { CreateMarkupSchema } from './createMarkupSchema';
import { ProductGroup, MgSupplierItemSearchResult } from '@retail/products/types';
import { MarkupCondition } from '@retail/markup/types';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { CreateMarkup } from './CreateMarkup';
import { LevelSelectForm } from '@retail/calculus/components';

interface Props {
  productGroups: ProductGroup[];
  activeCondition: MarkupCondition;
  mgSupplierItems: MgSupplierItemSearchResult[] | undefined;
  setMgSupplierItemSearch: Dispatch<SetStateAction<string>>;
  isLoadingFetchItems: boolean;
  supplierName: string;
}

export function CreateMarkupForm({
  productGroups,
  activeCondition,
  mgSupplierItems,
  setMgSupplierItemSearch,
  isLoadingFetchItems,
  supplierName,
}: Props) {
  const { control, resetField } = useFormContext<CreateMarkupSchema>();

  const levelController = useController({ name: 'level', control });
  const productGroupController = useController({ name: 'productGroup', control });
  const itemController = useController({ name: 'item', control });
  const markupNameController = useController({ name: 'markupName', control });

  const handleLevelSelectChange = (value: SelectChangeEvent<unknown>) => {
    resetField('item');
    resetField('productGroup');
    resetField('markupName');
    resetField('salesCoverage');
    resetField('salesFactor');
    resetField('fromDate');
    resetField('toDate');
    levelController.field.onChange(value);
  };

  useEffect(() => {
    if (levelController.field.value === 'supplier') {
      markupNameController.field.onChange(supplierName);
    } else if (
      levelController.field.value === 'productGroup' &&
      !!productGroupController.field.value
    ) {
      markupNameController.field.onChange(productGroupController.field.value.name);
    } else if (levelController.field.value === 'item' && !!itemController.field.value) {
      markupNameController.field.onChange(itemController.field.value.primaryText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelController.field.value, productGroupController.field.value, itemController.field.value]);

  return (
    <form>
      <Stack pb={1} gap={1}>
        <LevelSelectForm
          productGroups={productGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          handleLevelSelectChange={handleLevelSelectChange}
          setMgSupplierItemSearch={setMgSupplierItemSearch}
        />
        <Stack pt={1}>
          {(levelController.field.value === 'supplier' ||
            productGroupController.field.value ||
            itemController.field.value) && <CreateMarkup markupCondition={activeCondition} />}
        </Stack>
      </Stack>
    </form>
  );
}
