import { Stack } from '@mui/material';
import { AddItemsButton, ExcludeVatToggleSwitch } from '@retail/my-assortment/components';
import { AssortmentTabs } from '../AssortmentTabs';
import { useDisclosure, usePagination } from '@shared/hooks';
import { AddItemsToDraft } from './AddItemsToDraft';
import { useDebounce } from '@retail/hooks';
import { useState } from 'react';
import { ItemFilters } from '../AssortmentItems';
import {
  useAssortmentFilters,
  useDepartmentAssortments,
  useMemberAssortments,
} from '@retail/my-assortment/context';
import { ExportAssortment } from './ExportAssortment';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';

interface Props {
  memberId: string;
  departmentId: string;
}

export function AssortmentForDepartment({ memberId, departmentId }: Props) {
  // Everything that includes conditions will use departmentId
  const { draftAssortmentId: departmentDraftAssortmentId } = useDepartmentAssortments();
  const { draftAssortment: memberDraftAssortment } = useMemberAssortments();

  const { pagination } = usePagination({ initPageSize: 200 });

  const { isOpen: isAddItemsDialogOpen, onToggle: toggleAddItemsDialog } = useDisclosure(false);

  const {
    suppliers,
    selectedSupplier,
    selectSupplier,
    productGroups,
    selectedProductGroup,
    selectProductGroup,
  } = useAssortmentFilters({
    assortmentId: departmentDraftAssortmentId,
  });

  const [toggleVat, handleToggleVat] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery, 350);

  const [isNoItems, setIsNoItems] = useState(false);
  const handleSetIsNoItems = (noItems: boolean) => setIsNoItems(noItems);

  return (
    <Stack flexGrow={1} overflow="auto">
      <Stack flexDirection="row" justifyContent="space-between" alignItems="baseline">
        <ItemFilters
          selectedSupplier={selectedSupplier}
          selectedProductGroup={selectedProductGroup}
          suppliers={suppliers}
          productGroups={productGroups}
          selectProductGroup={selectProductGroup}
          selectSupplier={selectSupplier}
          setSearchQuery={setSearchQuery}
        />
        <Stack flexDirection="row" gap={1}>
          <ExcludeVatToggleSwitch toggleVat={toggleVat} handleToggleVat={handleToggleVat} />

          <ExportAssortment
            noItems={isNoItems}
            selectedSupplier={selectedSupplier?.mgSupplierId}
            selectedProductGroup={selectedProductGroup?.groupNumber}
            query={debouncedSearch}
          />

          <AddItemsButton onClick={toggleAddItemsDialog} />
          {isAddItemsDialogOpen && (
            <AddItemsToDraft
              memberId={memberId}
              draftAssortmentId={memberDraftAssortment.id}
              closeDialog={toggleAddItemsDialog}
            />
          )}
        </Stack>
      </Stack>
      <SuspenseWithSentryErrorBoundary>
        <AssortmentTabs
          selectedSupplier={selectedSupplier?.mgSupplierId}
          selectedProductGroup={selectedProductGroup?.groupNumber}
          searchQuery={debouncedSearch}
          departmentDraftAssortmentId={departmentDraftAssortmentId}
          departmentId={departmentId}
          pagination={pagination}
          includeVat={toggleVat}
          handleSetIsNoItems={handleSetIsNoItems}
        />
      </SuspenseWithSentryErrorBoundary>
    </Stack>
  );
}
