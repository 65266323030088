import { useMemo } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useFreightTFunction } from '@retail/freight/i18n';
import { AssortmentType, FreightCondition } from '@retail/calculus/types';
import { dataGridBoldClass } from '@shared/styles';
import { getBindingValue } from '@retail/calculus/utils';
import { Box, IconButton } from '@mui/material';
import { FreightTableActions } from '@retail/freight/components';
import { usePopupElement } from '@shared/hooks';
import { DotMenuIcon } from '@shared/custom-icons';
import { Actions, CalculusAreas } from '@retail/calculus/types';
import { Supplier } from '@retail/suppliers/types';
import { StackedTextCell } from '@retail/components';
import { ItemsAffected } from '@retail/calculus/containers';
import { AgreementStatusIcon } from '@retail/calculus/components';
import { useLevelNavigation } from '@retail/calculus/hooks';
import { LevelChip } from '@retail/markup/components';

interface Props {
  supplier: Supplier;
  freightConditions: FreightCondition[];
  setAction: (action: Actions) => void;
  handleFreightConditionClicked: (freightConditions: FreightCondition, action: Actions) => void;
}

export const FreightConditionsTable = ({
  supplier,
  freightConditions,
  handleFreightConditionClicked,
  setAction,
}: Props) => {
  const t = useFreightTFunction();

  const { menuElementProps, triggerElementProps } = usePopupElement();
  const { navigateToInheritedCondition } = useLevelNavigation();

  const baseColumnProps: Partial<GridColDef> = useMemo(
    () => ({
      width: 140,
      sortable: false,
      headerName: '',
      headerAlign: 'center',
      align: 'center',
    }),
    []
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'bindingType',
        headerName: t('freight.columns.level'),
        headerAlign: 'left',
        align: 'left',
        width: 180,
        cellClassName: () => dataGridBoldClass,
        renderCell: ({ row }) => {
          const label = getBindingValue(row.bindingType);

          return (
            <LevelChip
              level={row.bindingType}
              label={label}
              active={row.assortmentType === AssortmentType.BASE}
            />
          );
        },
      },
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('freight.columns.name'),
        headerAlign: 'left',
        align: 'left',
        width: 240,
        cellClassName: () => dataGridBoldClass,
        renderCell: ({ row }) => {
          const name = row.productGroupName ?? row.primaryText ?? supplier.name;
          const id = row.productGroup ?? row.mgItemNumber ?? supplier.mgSupplierId;

          return <StackedTextCell topLine={name} bottomLine={id} />;
        },
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        headerName: t('freight.columns.fromDate'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        headerName: t('freight.columns.toDate'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'freightSurchange',
        headerName: t('freight.columns.freightSurcharge'),
        width: 120,
        renderCell: ({ value }) => value || '-',
      },
      {
        ...baseColumnProps,
        field: 'percentAddon',
        headerName: t('freight.form.percent'),
        width: 120,
        renderCell: ({ value }) => (value !== 'undefined' ? value + '%' : '-'),
      },
      {
        ...baseColumnProps,
        field: 'itemsAffected',
        headerName: t('freight.columns.itemsAffected'),
        width: 130,
        renderCell: ({ row }) => (
          <ItemsAffected
            mgSupplierId={supplier.mgSupplierId}
            productGroup={row.productGroup}
            type={CalculusAreas.FREIGHT}
            openItemsAffectedModal={() => handleFreightConditionClicked(row, 'view')}
          />
        ),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        width: 50,
        renderCell: ({ row }) => (
          <IconButton
            {...triggerElementProps}
            onClick={(event) => {
              triggerElementProps.onClick(event);
              handleFreightConditionClicked(row, 'none');
            }}
          >
            <DotMenuIcon fontSize="small" />
          </IconButton>
        ),
      },
    ],
    [
      baseColumnProps,
      t,
      supplier.name,
      supplier.mgSupplierId,
      handleFreightConditionClicked,
      triggerElementProps,
    ]
  );

  return (
    <Box width="100%">
      <DataGridPro
        autoHeight
        getRowId={(row) => row.id}
        columns={columns}
        rows={freightConditions}
        rowHeight={70}
        disableSelectionOnClick
        initialState={{
          pinnedColumns: { right: ['actions'] },
        }}
        disableColumnMenu
        disableColumnResize
        disableColumnReorder
        hideFooter
      />

      <FreightTableActions {...menuElementProps} setAction={setAction} />
    </Box>
  );
};
