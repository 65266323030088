import { Box, Stack, Typography } from '@mui/material';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';

interface Props {
  topLine: string | number | React.ReactNode;
  bottomLine?: string | number | React.ReactNode;
  centerAlign?: boolean;
  lineThroughTopLine?: boolean;
  lineThroughBottomLine?: boolean;
  isActive?: boolean;
  gap?: 'small' | 'medium';
}

export const StackedTextCell = ({
  topLine,
  bottomLine,
  centerAlign,
  lineThroughTopLine = false,
  lineThroughBottomLine = false,
  isActive = false,
  gap = 'medium',
}: Props) => {
  const t = usePurchaseAgreementsTFunction();

  return (
    <Stack flexDirection="row">
      <Box position="relative" pb={2} sx={{ display: isActive ? 'initial' : 'none' }}>
        <Typography
          variant="body2"
          position="absolute"
          color={({ palette }) => palette.common.white}
          textAlign="center"
          width={80}
          fontWeight={500}
          top={-20}
          right={-28}
          sx={{
            background: ({ palette }) => palette.gradient.tertiary,
            transform: 'rotate(320deg)',
          }}
        >
          {t('purchaseAgreements.active')}
        </Typography>
      </Box>

      <Stack
        textAlign={centerAlign ? 'center' : 'start'}
        overflow="hidden"
        textOverflow="ellipsis"
        gap={gap === 'medium' ? 0.5 : 0}
      >
        <Typography
          lineHeight={1.2}
          variant="subtitle2"
          fontWeight={600}
          color={(theme) => theme.palette.grey[900]}
          sx={{
            textDecoration: lineThroughTopLine ? 'line-through' : undefined,
          }}
        >
          {topLine}
        </Typography>

        <Typography
          lineHeight={1.2}
          variant="subtitle2"
          sx={{
            textDecoration: bottomLine && lineThroughBottomLine ? 'line-through' : undefined,
          }}
        >
          {bottomLine ?? '-'}
        </Typography>
      </Stack>
    </Stack>
  );
};
