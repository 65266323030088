import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useProductsTFunction } from '@retail/products/i18n';
import { MgSupplierItemPackagesTableRow } from './MgSupplierItemPackagesTableRow';
import Tooltip from '@mui/material/Tooltip';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Stack, styled } from '@mui/material';
import { SupplierItemPackage } from './SupplierItems';
import { MgSupplierItem } from '@retail/products/types';
import {
  getClassCodeLabel,
  getPackageTypeLabel,
  sortSupplierItemPackageType,
} from '@retail/products/utils';
import { Fragment } from 'react';

interface Props {
  packages: SupplierItemPackage[];
  selectedSupplier: Partial<MgSupplierItem> | null;
}

export function MgSupplierItemPackagesTable({ packages, selectedSupplier }: Props) {
  const t = useProductsTFunction();

  // Helper const to separate different packaging types with a packaging title/row
  let lastPackageType: SupplierItemPackage['type'] = '';

  const basePackage = packages.find(({ basePriceUnit }) => basePriceUnit);
  const basePackageUnit = basePackage?.mgPackage?.vilmaUnit || basePackage?.vilmaUnit;

  return (
    <Box>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>{t('products.mgItem.supplierItem.packageType.label')}</TableCell>
              <TableCell>{t('products.supplier.name')}</TableCell>
              <TableCell>{t('products.mgItem.supplierItem.gtin.label')}</TableCell>
              <TableCell>{t('products.mgItem.supplierItem.supplierUnit')}</TableCell>
              <TableCell>
                <Stack direction="row" gap="4px" alignItems="start">
                  {t('products.mgItem.supplierItem.mgUnit.label')}
                  <Tooltip arrow title={t('products.mgItem.supplierItem.mgUnit.description')}>
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                </Stack>
              </TableCell>

              <TableCell>{t('products.mgItem.supplierItem.consistsOf')}</TableCell>
              <TableCell align="right">
                {t('products.mgItem.supplierItem.finfoBasePrice')}
              </TableCell>
              <TableCell align="right">
                {t('products.mgItem.supplierItem.finfoRecommendedPrice')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            sx={{
              '& td p': {
                fontSize: 'inherit',
                lineHeight: 'inherit',
              },
            }}
          >
            {packages.sort(sortSupplierItemPackageType).map((supplierItemPackage, index) => {
              const isNewPackageType = lastPackageType !== supplierItemPackage.type;
              lastPackageType = supplierItemPackage.type;

              return (
                <Fragment
                  key={`${supplierItemPackage.recommendedSalesPrice}${supplierItemPackage.basePrice}${supplierItemPackage.mgSupplierId}`}
                >
                  {isNewPackageType && (
                    <TableRow>
                      <Tooltip title={getPackageTypeLabel(t, supplierItemPackage.type ?? '')}>
                        <TableCell sx={{ fontWeight: '600 !important' }}>
                          {getClassCodeLabel(supplierItemPackage.type ?? '')}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  )}

                  <MgSupplierItemPackagesTableRow
                    index={index}
                    {...supplierItemPackage}
                    basePackageUnit={basePackageUnit}
                    selectedSupplier={selectedSupplier}
                  />
                </Fragment>
              );
            })}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Box>
  );
}

const StyledTable = styled(Table)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[50]}`,
  borderRadius: theme.shape.borderRadius * 2,

  '& .MuiTableHead-root': {
    backgroundColor: theme.palette.success[50],

    '& .MuiTableCell-root': { ...theme.typography.body2, fontWeight: 'bold' },
  },
}));
