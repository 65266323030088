import { useCallback, useMemo } from 'react';
import { useAppTranslation } from '@retail/app/i18n';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { PageLayout } from '@retail/components';
import { useNavigate } from 'react-router-dom';
import { SelectSupplierContainer } from '@retail/calculus/containers';
import { useFetchAllSuppliers } from '@retail/products/data-access';
import { Supplier } from '@retail/suppliers/types';
import { useDepartmentAssortments } from "@retail/my-assortment/context";

export function CalculusPage() {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { draftAssortmentId } = useDepartmentAssortments();

  const { data: participantDtos } = useFetchAllSuppliers({ assortmentId: draftAssortmentId });
  const suppliers = useMemo(
    () => participantDtos?.map(Supplier.fromMinimalDTO) || [],
    [participantDtos]
  );

  const selectSupplier = useCallback(
    (selectedSupplier: Supplier | null) => {
      navigate(mgPrisRoutes.calculus.supplier.getFullLinkPath(`${selectedSupplier?.mgSupplierId}`));
    },
    [navigate]
  );

  return (
    <PageLayout maxWidth="full" fixedHeight spacing={2}>
      <SelectSupplierContainer
        label={t('calculus.supplier.choose')}
        selectSupplier={selectSupplier}
        suppliers={suppliers}
        breadcrumb={t('app.routes.calculus')}
      />
    </PageLayout>
  );
}
