import { CalculusTabOptions } from '@retail/calculus/types';
import { useAppTFunction } from '@retail/app/i18n';
import { RecoilState, useRecoilState } from 'recoil';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

interface Props {
  currentCalculusAreaAtom: RecoilState<CalculusTabOptions>;
}
const calculusTabKey = 'calculusTab';
export const useCalculusTabs = ({ currentCalculusAreaAtom }: Props) => {
  const t = useAppTFunction();
  const [searchParams, setSearchParams] = useSearchParams();

  const { supplierId } = useParams<{ supplierId: string }>();

  const [currentTab, setCurrentTab] = useRecoilState(currentCalculusAreaAtom);

  const tabs = [
    { label: t('markup.itemPrices'), value: CalculusTabOptions.ITEMS },
    { label: t('app.routes.agreements'), value: CalculusTabOptions.PURCHASE },
    { label: t('app.routes.freight'), value: CalculusTabOptions.FREIGHT },
    { label: t('app.routes.markup'), value: CalculusTabOptions.MARKUP },
  ];

  useEffect(() => {
    if (supplierId && !searchParams.get(calculusTabKey)) {
      return setSearchParams(() => {
        searchParams.set(calculusTabKey, CalculusTabOptions.ITEMS);
        return searchParams;
      });
    }
    if (searchParams.get(calculusTabKey)) {
      setCurrentTab(searchParams.get(calculusTabKey) as CalculusTabOptions);
    }
  }, [searchParams, setCurrentTab, setSearchParams, supplierId]);

  const selectedTab = useMemo(() => {
    return searchParams.get(calculusTabKey) || currentTab;
  }, [currentTab, searchParams]);

  const selectTab = (tab: CalculusTabOptions) => {
    setSearchParams(() => {
      searchParams.set(calculusTabKey, tab);
      searchParams.delete('tab');
      return searchParams;
    });

    setCurrentTab(tab);
  };

  return { calculusTabKey, selectTab, selectedTab, tabs };
};
