import { Stack, Tooltip } from '@mui/material';
import { IconButton } from '@retail/products/components';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import { ActivateIcon, DeactivateIcon, EditButtonIcon, TrashIcon } from '@shared/custom-icons';

interface Props {
  handleActivate?: () => void;
  handleDeactivate?: () => void;
  handleEdit?: () => void;
  handleDelete?: () => void;
}

export const PurchaseConditionsTableActions = ({
  handleActivate,
  handleDeactivate,
  handleEdit,
  handleDelete,
}: Props) => {
  const { t } = usePurchaseAgreementsTranslation();
  return (
    <Stack flexDirection="row" alignItems="center">
      {handleActivate && (
        <Tooltip title={t('purchaseAgreements.agreement.actions.activateOnThisLevel')}>
          <IconButton onClick={handleActivate}>
            <ActivateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {handleDeactivate && (
        <Tooltip title={t('purchaseAgreements.agreement.actions.deactivate')}>
          <IconButton
            onClick={() => {
              handleDeactivate();
            }}
          >
            <DeactivateIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {handleEdit && (
        <Tooltip title={t('purchaseAgreements.agreement.actions.editCondition')}>
          <IconButton
            onClick={() => {
              handleEdit();
            }}
          >
            <EditButtonIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}
      {handleDelete && (
        <Tooltip title={t('purchaseAgreements.agreement.actions.deleteFromDraft')}>
          <IconButton
            onClick={() => {
              handleDelete();
            }}
          >
            <TrashIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
