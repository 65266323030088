import { fetcher, RequestContext, RequestMethods } from './fetcher';

export interface ExportXLSXProps {
  token: string;
  context?: RequestContext;
  url: string;
  body?: Record<string | number, any>;
  method?: RequestMethods;
  headers?: HeadersInit;
}

export async function fetchFile({ token, context, url, body, method, headers }: ExportXLSXProps) {
  return fetcher<Response>({
    url,
    token,
    body: body && JSON.stringify(body),
    context,
    options: {
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/json',
      },
      method,
    },
    parsedAs: 'blob',
    headers,
  });
}

export async function getFileBlob(response: Response) {
  const blob = await response.blob();
  return new Blob([blob], { type: 'application/octet-stream' });
}

const filenameRegex = /filename="([^"]*)"/;

export function getFileName(response: Response) {
  const contentDispositionHeader = response.headers.get('content-disposition');
  const fileNameMatch = contentDispositionHeader?.match(filenameRegex);
  return fileNameMatch && fileNameMatch[1];
}

export const downloadResponseFile =
  ({ filenameFallback }: { filenameFallback: string }) =>
  async (response: Response) => {
    const newBlob = await getFileBlob(response);
    const filename = getFileName(response) || filenameFallback;

    // @ts-ignore MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // @ts-ignore
      window.navigator.msSaveOrOpenBlob(newBlob);
    } else {
      // For other browsers: create a link pointing to the ObjectURL containing the blob.
      const objUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      link.href = objUrl;
      link.download = filename;
      link.click();

      // For Firefox, it is necessary to delay revoking the ObjectURL.
      setTimeout(() => {
        window.URL.revokeObjectURL(objUrl);
      }, 250);
    }
  };
