import { useController, useFormContext } from 'react-hook-form';
import { SelectChangeEvent, Stack } from '@mui/material';
import { MgSupplierItemSearchResult, ProductGroup } from '@retail/products/types';
import { Dispatch, SetStateAction } from 'react';
import { FreightConditionFormBase, CreateFreightConditionSchema } from '../FreightConditionForm';
import { LevelSelectForm } from '@retail/calculus/components';

interface Props {
  productGroups: ProductGroup[];
  mgSupplierItems?: MgSupplierItemSearchResult[];
  isLoadingFetchItems: boolean;
  setMgSupplierItemSearch: Dispatch<SetStateAction<string>>;
}

export function CreateFreightConditionForm({
  productGroups,
  mgSupplierItems,
  isLoadingFetchItems,
  setMgSupplierItemSearch,
}: Props) {
  const { control, resetField } = useFormContext<CreateFreightConditionSchema>();
  const levelController = useController({ name: 'level', control });
  const productGroupController = useController({ name: 'productGroup', control });
  const itemController = useController({ name: 'item', control });

  const handleLevelSelectChange = (value: SelectChangeEvent<unknown>) => {
    resetField('percentAddon');
    resetField('freightFactor');
    resetField('item');
    resetField('productGroup');
    resetField('fromDate');
    resetField('toDate');
    levelController.field.onChange(value);
  };

  return (
    <form>
      <Stack gap={3} pb={1}>
        <LevelSelectForm
          productGroups={productGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          setMgSupplierItemSearch={setMgSupplierItemSearch}
          handleLevelSelectChange={handleLevelSelectChange}
        />

        {levelController.field.value === 'supplier' && <FreightConditionFormBase />}

        {levelController.field.value === 'productGroup' && !!productGroupController.field.value && (
          <FreightConditionFormBase />
        )}

        {levelController.field.value === 'item' && !!itemController.field.value && (
          <FreightConditionFormBase />
        )}
      </Stack>
    </form>
  );
}
