import { AssortmentItem } from '@retail/my-assortment/types';
import { MgSupplierItem, MgSupplierItemPackage, RecommendedPrice } from '@retail/products/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RecommendedPrices } from './RecommendedPrices';
import { Calculations } from './Calculations';
import { Packages } from './Packages';
import { getPrice, getPrices } from '@retail/my-assortment/utils';
import { SalesPriceSummary } from './SalesPriceSummary';
import { useDisclosure } from '@shared/hooks';
import { CreateItemLevelMarkupSchema } from '@retail/markup/components';
import { useController, useFormContext } from 'react-hook-form';
import CreateConditionDialog from './CreateConditionDialog';
import { Divider } from '@mui/material';
import { calculateNewDG, calculateNewSalesFactor } from '@retail/utils';

export type EditedMarkupField = 'price' | 'factor' | 'coverage' | undefined;

interface Props {
  assortmentItem: AssortmentItem;
  selectedSupplierItem?: MgSupplierItem;
  recommendedPrice?: RecommendedPrice;
  includeVat: boolean;
  isLoadingCreate: boolean;
  createConditionDialogIsOpen: boolean;
  toggleCreateConditionDialog: () => void;
  createCondition: (
    data: CreateItemLevelMarkupSchema,
    editedMarkupField: EditedMarkupField
  ) => void;
}

export function ProductCard({
  assortmentItem,
  selectedSupplierItem,
  recommendedPrice,
  isLoadingCreate,
  createConditionDialogIsOpen,
  toggleCreateConditionDialog,
  createCondition,
}: Props) {
  const mgPackages = MgSupplierItem.getPackages(selectedSupplierItem?.packages || []);

  const itemPrices = getPrices(assortmentItem);

  const purchaseCondition = selectedSupplierItem?.purchaseCondition;
  const freightCondition = selectedSupplierItem?.freightCondition;
  const markupCondition = selectedSupplierItem?.markupCondition;

  const { isOpen: editSalesFactor, onToggle: toggleEditSalesFactor } = useDisclosure();

  const [selectedPackage, setSelectedPackage] = useState<MgSupplierItemPackage['type']>('PRIMARY');
  const [editedMarkupField, setEditedMarkupField] = useState<EditedMarkupField>(undefined);

  const packageUnit = mgPackages[selectedPackage][0].unit;
  const [prices, setPrices] = useState(getPrice(itemPrices ?? [], packageUnit));

  useEffect(() => {
    setPrices(getPrice(itemPrices ?? [], packageUnit));
  }, [itemPrices, packageUnit]);

  const primaryPackagePrice = useMemo(
    () => itemPrices?.find((price) => price.primaryUnit),
    [itemPrices]
  );

  const { control, getValues } = useFormContext<CreateItemLevelMarkupSchema>();
  const salesFactorController = useController({ name: 'salesFactor', control });
  const salesPriceController = useController({ name: 'salesPrice', control });
  const salesCoverageController = useController({ name: 'salesCoverage', control });

  const resetMarkupValues = useCallback(() => {
    setEditedMarkupField(undefined);
    salesFactorController.field.onChange(markupCondition?.salesFactor + '');
    salesPriceController.field.onChange(
      primaryPackagePrice?.salesPrice ? primaryPackagePrice?.salesPrice.toFixed(2) + '' : null
    );
    salesCoverageController.field.onChange(
      primaryPackagePrice?.salesPriceCoverage
        ? primaryPackagePrice?.salesPriceCoverage.toFixed(2) + ''
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markupCondition?.salesFactor,
    primaryPackagePrice?.salesPrice,
    primaryPackagePrice?.salesPriceCoverage,
  ]);

  const onChangeSalesPrice = useCallback(
    (value: string) => {
      salesPriceController.field.onChange(value);
      setEditedMarkupField('price');
      primaryPackagePrice?.purchasePrice &&
        salesCoverageController.field.onChange(
          calculateNewDG(primaryPackagePrice?.purchasePrice, Number(value)).toFixed(2)
        );
      primaryPackagePrice?.purchasePrice &&
        salesFactorController.field.onChange(
          calculateNewSalesFactor(primaryPackagePrice?.purchasePrice, Number(value)).toFixed(2)
        );
    },
    [
      salesPriceController.field,
      setEditedMarkupField,
      primaryPackagePrice?.purchasePrice,
      salesCoverageController.field,
      salesFactorController.field,
    ]
  );

  useEffect(() => {
    resetMarkupValues();
  }, [resetMarkupValues]);

  const handleCreateCondition = useCallback(() => {
    createCondition(getValues(), editedMarkupField);
    resetMarkupValues();
  }, [createCondition, editedMarkupField, getValues, resetMarkupValues]);

  return (
    <>
      <Packages
        packages={mgPackages}
        selectedPackage={selectedPackage}
        basePriceUnit={assortmentItem.basePriceUnit}
        setSelectedPackage={setSelectedPackage}
      />

      {recommendedPrice && (
        <>
          <RecommendedPrices
            {...recommendedPrice}
            setSelectedRecommendedPrice={onChangeSalesPrice}
            resetPrice={resetMarkupValues}
          />
          <Divider />
        </>
      )}

      <Calculations
        primaryUnit={assortmentItem.primaryPackageUnit}
        costPrice={primaryPackagePrice?.costPrice}
        purchasePrice={primaryPackagePrice?.purchasePrice}
        purchaseCondition={purchaseCondition}
        freightCondition={freightCondition}
        markupCondition={markupCondition}
        editSalesFactor={editSalesFactor}
        editedMarkupField={editedMarkupField}
        setEditedMarkupField={setEditedMarkupField}
        toggleEditSalesFactor={toggleEditSalesFactor}
        resetMarkupValues={resetMarkupValues}
      />

      <SalesPriceSummary
        prices={prices}
        packageUnit={packageUnit}
        costPrice={primaryPackagePrice?.costPrice}
        isPrimaryPackage={assortmentItem.primaryPackageUnit === packageUnit}
        editSalesFactor={editSalesFactor}
        editedMarkupField={editedMarkupField}
        setEditedMarkupField={setEditedMarkupField}
        resetMarkupValues={resetMarkupValues}
        toggleCreateConditionDialog={toggleCreateConditionDialog}
      />
      <CreateConditionDialog
        mgItem={assortmentItem.primaryText}
        open={createConditionDialogIsOpen}
        isLoadingCreate={isLoadingCreate}
        onClose={toggleCreateConditionDialog}
        resetMarkupValues={resetMarkupValues}
        handleCreateCondition={handleCreateCondition}
      />
    </>
  );
}
