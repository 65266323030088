import { Stack, Typography } from '@mui/material';
import { CreateMarkupDialogRow } from './CreateMarkupDialogRow';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { MarkupCondition } from '@retail/markup/types';
import { calculateSalesCoverage } from '@retail/utils';
import { useMemo } from 'react';
import { HierarchyLevels } from '@retail/calculus/types';

interface Props {
  markupCondition: MarkupCondition;
}

export function CurrentActiveMarkup({ markupCondition }: Props) {
  const t = useMarkupTFunction();

  const inheritedFrom = useMemo(() => {
    if (markupCondition.bindingType === HierarchyLevels.supplier) {
      return markupCondition.mgSupplierName;
    } else if (markupCondition.bindingType === HierarchyLevels.item) {
      return markupCondition.primaryText;
    }
    return markupCondition.productGroupName ?? '';
  }, [markupCondition]);

  return (
    <Stack gap={2}>
      <Typography fontWeight={600}>
        {t('markup.activeMarkup')} ({inheritedFrom})
      </Typography>
      <CreateMarkupDialogRow
        name={t('markup.markupName')}
        value={markupCondition.markupTemplateName}
      />

      <CreateMarkupDialogRow
        name={t('markup.salesCoverage')}
        value={
          markupCondition.salesFactor
            ? `${calculateSalesCoverage(markupCondition?.salesFactor)}%`
            : '-'
        }
      />

      <CreateMarkupDialogRow name={t('markup.salesFactor')} value={markupCondition.salesFactor} />
      <CreateMarkupDialogRow name={t('markup.fromDate')} value={markupCondition.validFrom} />
      <CreateMarkupDialogRow name={t('markup.toDate')} value={markupCondition.validTo || '-'} />
    </Stack>
  );
}
