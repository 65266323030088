import {
  createContext,
  forwardRef,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ButtonProps, Menu as MuiMenu } from '@mui/material';
import { ContainedButton } from '@shared/components';

interface MenuState {
  anchorElement: HTMLButtonElement | null;
  onClose: () => void;
  onOpen: (anchorElement: HTMLButtonElement) => void;
}

const MenuContext = createContext<MenuState | null>(null);

const useMenuContext = (): MenuState => {
  const context = useContext(MenuContext);
  if (!context) throw new Error('useMenuContext must be used within a Menu');
  return context;
};

type MenuProps = {
  children:
    | ReactElement[]
    | ((context: { isOpen: boolean; onClose: () => void }) => ReactElement<{
        children: ReactElement[];
      }>);
};

/**
 * Menu component that manages the state and rendering of a dropdown menu.
 *
 * This component wraps around Material-UI's MuiMenu, providing additional
 * state management for the menu's anchor element and open/close behavior.
 * It expects that the first direct child element is the trigger
 * button. The rest of the children are treated as the content of the menu.
 *
 * @param {MenuProps} props - The props for the Menu component.
 * @param {React.ReactNode} props.children - The child elements, where the first
 * child is the trigger button and the remaining children are the menu content.
 * Alternatively, a function can be provided that receives the internal state
 * as params, and returns a Fragment with the trigger button ass the first child,
 * and the content as the remaining.
 * @param {React.Ref<HTMLDivElement>} ref - A ref object to be attached to the
 * MuiMenu component.
 *
 * @returns {JSX.Element} The rendered Menu component.
 */
export const Menu = forwardRef<HTMLDivElement, MenuProps>(({ children }, ref) => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const onClose = useCallback(() => setAnchorElement(null), []);
  const isOpen = Boolean(anchorElement);

  const contextValue: MenuState = useMemo(
    () => ({
      anchorElement,
      onClose,
      onOpen: setAnchorElement,
    }),
    [anchorElement, onClose]
  );

  const { triggerButton, menuContent } = useMemo(() => {
    const childElements =
      typeof children === 'function' ? children({ isOpen, onClose }).props.children : children;
    const _triggerButton = childElements[0];
    const _menuContent = childElements.slice(1);

    return { triggerButton: _triggerButton, menuContent: _menuContent };
  }, [children, isOpen, onClose]);

  return (
    <MenuContext.Provider value={contextValue}>
      {triggerButton}
      <MuiMenu open={isOpen} onClose={onClose} ref={ref} anchorEl={anchorElement}>
        {menuContent}
      </MuiMenu>
    </MenuContext.Provider>
  );
});

type MenuButtonProps = Omit<ButtonProps, 'onClick'>;

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  ({ component = ContainedButton, ...props }, ref) => {
    const { onOpen } = useMenuContext();
    const handleOpen: MouseEventHandler<HTMLButtonElement> = useCallback(
      ({ currentTarget }) => {
        onOpen(currentTarget);
      },
      [onOpen]
    );

    const Component = component;
    return <Component onClick={handleOpen} {...props} ref={ref} />;
  }
);
