import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { itemsPath, suppliersPath } from '@retail/calculus/data-access';
import { PaginationResultDTO } from '@retail/products/types';
import { ItemAgreementDTO } from '@retail/purchase-agreements/types';
import { urlConstant } from '@retail/utils';
import { QueryOptions } from '@shared/fetch-utils';
import { buildUrl } from '@shared/utils';
import { useMPEFetch } from '@retail/app/hooks';

export const agreementPricesPath = '/agreement-prices';

interface ApiProps {
  supplierId: number;
  mgItemId: number;
  active?: boolean;
}

export function useFetchItemAgreements(
  { supplierId, mgItemId, active = true }: ApiProps,
  queryOptions: QueryOptions = {}
) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useMPEFetch<PaginationResultDTO<ItemAgreementDTO[]>>({
    method: 'GET',
    queryKey: ['item-agreements', supplierId, mgItemId],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${suppliersPath}/${supplierId}${itemsPath}/${mgItemId}${agreementPricesPath}/`,
      searchParams: {
        active,
      },
    }),
    ...queryOptions,
  });
}
