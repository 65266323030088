import { MpeMgSupplierItemDTO } from '../../dto';
import { MpeMgSupplierItemPackage } from './MgSupplierItemPackage';
import { MgItemBk04Varegruppe } from './MgItemBk04Varegruppe';
import { MgItemEtim } from './MgItemEtim';
import { MgItemEnvironment } from './MgItemEnvironment';
import { MgItemMarketing } from './MgItemMarketing';
import { MgPackage, MgPackageType } from './MgPackage';
import { PurchaseCondition } from '@retail/purchase-agreements/types';
import { FreightCondition } from '@retail/freight/types';
import { MarkupCondition } from '@retail/markup/types';
import { MgItemPackage, MgItemPackages } from './MgItemPackages';
import { Priority } from '@retail/my-assortment/types';

export interface MgSupplierItemBase {
  finfoNumber?: number;
  primaryText: string;
  mainSupplier?: boolean;
}

interface MgSupplierItemSupplier {
  id: number;
  name: string;
}

interface ConstructorProps {
  id: string;
  mainSupplier: boolean;

  primaryText: string;
  secondaryText: string;
  bk04Varegruppe?: MgItemBk04Varegruppe;
  prevMgItemNumber?: number;
  registrationDate?: string;
  discontinuationDate?: string;
  vilmaDiscountGroup?: string;
  vilmaDiscountGroupDescription?: string;
  dangerousGoods?: string;
  fscPefcClaim?: string;

  articleType?: number;
  finfoNumber?: number;
  nobbNumber?: number;

  packages: MpeMgSupplierItemPackage[];
  mgParticipant: MgSupplierItemSupplier;
  mgSupplierId: number;
  mgSupplierName: string;
  mgSupplierItemId?: number;
  supplierProductName?: string;
  replacedBySupplierItemId?: string;

  basePrice?: number;
  basePriceDate?: string;
  basePriceUnit?: boolean;
  recommendedSalesPrice?: number;
  vatCode?: string;
  commodityCode?: string;

  etim?: MgItemEtim;
  environment?: MgItemEnvironment;
  marketing?: MgItemMarketing;

  supplierGln?: number;
  supplierOrgNumber?: number;
  finfoSupplierId?: number;

  originCountry?: string;

  webProductName?: string;
  textIngress?: string;
  textFull?: string;
  aiDescription?: string;

  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  priority: Priority;
}

export class MgSupplierItem implements MgSupplierItemBase {
  id: string;
  mainSupplier: boolean;

  primaryText: string;
  secondaryText: string;
  bk04Varegruppe?: MgItemBk04Varegruppe;
  mgParticipant: MgSupplierItemSupplier;
  packages: MpeMgSupplierItemPackage[];
  prevMgItemNumber?: number;
  registrationDate?: string;
  discontinuationDate?: string;
  vilmaDiscountGroup?: string;
  vilmaDiscountGroupDescription?: string;
  dangerousGoods?: string;
  fscPefcClaim?: string;

  articleType?: number;
  finfoNumber?: number;
  nobbNumber?: number;

  mgSupplierId: number;
  mgSupplierName: string;
  mgSupplierItemId?: number;
  supplierProductName?: string;
  replacedBySupplierItemId?: string;

  basePrice?: number;
  basePriceDate?: string;
  basePriceUnit?: boolean;
  recommendedSalesPrice?: number;
  vatCode?: string;
  commodityCode?: string;

  etim?: MgItemEtim;
  environment?: MgItemEnvironment;
  marketing?: MgItemMarketing;

  supplierGln?: number;
  supplierOrgNumber?: number;
  finfoSupplierId?: number;

  originCountry?: string;

  webProductName?: string;
  textIngress?: string;
  textFull?: string;
  aiDescription?: string;

  purchaseCondition?: PurchaseCondition;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  priority: Priority;

  constructor({
    id,
    mainSupplier,

    primaryText,
    secondaryText,
    bk04Varegruppe,
    mgParticipant,
    packages,
    prevMgItemNumber,
    registrationDate,
    discontinuationDate,
    vilmaDiscountGroup,
    vilmaDiscountGroupDescription,
    dangerousGoods,
    fscPefcClaim,

    articleType,
    finfoNumber,
    nobbNumber,

    mgSupplierId,
    mgSupplierName,
    mgSupplierItemId,
    supplierProductName,
    replacedBySupplierItemId,

    basePrice,
    basePriceDate,
    basePriceUnit,
    recommendedSalesPrice,
    vatCode,
    commodityCode,

    etim,
    environment,
    marketing,

    supplierGln,
    supplierOrgNumber,
    finfoSupplierId,

    originCountry,

    webProductName,
    textIngress,
    textFull,
    aiDescription,

    purchaseCondition,
    freightCondition,
    markupCondition,
    priority,
  }: ConstructorProps) {
    this.id = id;
    this.mainSupplier = mainSupplier;

    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.bk04Varegruppe = bk04Varegruppe;
    this.prevMgItemNumber = prevMgItemNumber;
    this.registrationDate = registrationDate;
    this.discontinuationDate = discontinuationDate;
    this.vilmaDiscountGroup = vilmaDiscountGroup;
    this.vilmaDiscountGroupDescription = vilmaDiscountGroupDescription;
    this.dangerousGoods = dangerousGoods;
    this.fscPefcClaim = fscPefcClaim;

    this.articleType = articleType;
    this.finfoNumber = finfoNumber;
    this.nobbNumber = nobbNumber;

    this.packages = packages;
    this.mgParticipant = mgParticipant;
    this.mgSupplierId = mgSupplierId;
    this.mgSupplierName = mgSupplierName;
    this.mgSupplierItemId = mgSupplierItemId;
    this.supplierProductName = supplierProductName;
    this.replacedBySupplierItemId = replacedBySupplierItemId;

    this.basePrice = basePrice;
    this.basePriceDate = basePriceDate;
    this.basePriceUnit = basePriceUnit;
    this.recommendedSalesPrice = recommendedSalesPrice;
    this.vatCode = vatCode;
    this.commodityCode = commodityCode;

    this.etim = etim;
    this.environment = environment;
    this.marketing = marketing;

    this.supplierGln = supplierGln;
    this.supplierOrgNumber = supplierOrgNumber;
    this.finfoSupplierId = finfoSupplierId;

    this.originCountry = originCountry;

    this.webProductName = webProductName;
    this.textIngress = textIngress;
    this.textFull = textFull;
    this.aiDescription = aiDescription;

    this.purchaseCondition = purchaseCondition;
    this.freightCondition = freightCondition;
    this.markupCondition = markupCondition;
    this.priority = priority;
  }

  getEnvironmentValues = () => this.environment;

  capitalizeFirstLetter(text?: string): string | undefined {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  static getPackages = (packages: MpeMgSupplierItemPackage[]): MgItemPackages => {
    const uniquePackages: MgItemPackages = {};

    for (const pkg of packages) {
      const vilmaUnit = pkg.vilmaUnit;
      const packageType = pkg.type;

      if (packageType) {
        if (!uniquePackages[packageType]) uniquePackages[packageType] = [];

        const newPackage = {
          gtinCode: pkg.mgPackage?.gtinCode,
          unit: pkg.vilmaUnit.unit,
          description: vilmaUnit?.description,
          basePriceUnit: pkg.basePriceUnit,
          calculatedCount: pkg.calculatedCount,
          packageType: pkg.type,
          classCode: pkg.classCode,
        };

        // Check if the package already exists in the array
        const packageExists = uniquePackages[packageType].some(
          (existingPackage) => existingPackage.gtinCode === newPackage.gtinCode
        );

        if (!packageExists) uniquePackages[packageType].push(newPackage as MgItemPackage);
      }
    }

    return uniquePackages;
  };

  static fromDto = (supplierItem: MpeMgSupplierItemDTO): MgSupplierItem => {
    return new MgSupplierItem({
      id: String(supplierItem.mgSupplierItemId),
      mainSupplier: supplierItem.mainSupplier,

      primaryText: supplierItem.primaryText || '',
      secondaryText: supplierItem.secondaryText || '',
      bk04Varegruppe: MgItemBk04Varegruppe.fromDto(supplierItem),
      prevMgItemNumber: supplierItem.prevMgItemNumber,
      registrationDate: supplierItem.registrationDate,
      discontinuationDate: supplierItem.discontinuationDate,
      vilmaDiscountGroup: supplierItem.vilmaDiscountGroup,
      vilmaDiscountGroupDescription: supplierItem.vilmaDiscountGroupDescription,
      dangerousGoods: supplierItem.dangerousGoods,
      fscPefcClaim: supplierItem.fscPefcClaim,

      articleType: supplierItem.articleType,
      finfoNumber: supplierItem.finfoNumber,
      nobbNumber: supplierItem.nobbNumber,
      mgParticipant: {
        id: supplierItem.mgSupplierId,
        name: supplierItem.mgSupplierName,
      },
      mgSupplierId: supplierItem.mgSupplierId,
      mgSupplierName: supplierItem.mgSupplierName,
      mgSupplierItemId: supplierItem.mgSupplierItemId,
      supplierProductName: supplierItem.supplierProductName,
      replacedBySupplierItemId: supplierItem.replacedBySupplierItemId,

      basePrice: supplierItem.basePrice,
      basePriceDate: supplierItem.basePriceDate,
      basePriceUnit: supplierItem.basePriceUnit,
      recommendedSalesPrice: supplierItem.recommendedSalesPrice,
      vatCode: supplierItem.vatCode,
      commodityCode: supplierItem.commodityCode,

      originCountry: supplierItem.originCountry,

      webProductName: supplierItem.webProductName,
      textIngress: supplierItem.textIngress,
      textFull: supplierItem.textFull,
      aiDescription: supplierItem.aiDescription,

      etim: supplierItem.etim ? MgItemEtim.fromDto(supplierItem.etim) : undefined,
      marketing: supplierItem.marketing
        ? MgItemMarketing.fromDto(supplierItem.marketing)
        : undefined,
      environment: supplierItem.environment
        ? MgItemEnvironment.fromDto(supplierItem.environment)
        : undefined,

      supplierGln: supplierItem.supplierGln,
      supplierOrgNumber: supplierItem.supplierOrgNumber,
      finfoSupplierId: supplierItem.finfoSupplierId,
      packages: supplierItem.mgSupplierItemPackages?.map(
        ({
          gtinCode,
          calculatedCount,
          vilmaUnit,
          basePriceUnit,
          priceComparisonUnit,
          type,
          deliverable,
          basePrice,
          recommendedSalesPrice,
          classCode,
        }) => {
          const mgPackage: MgPackage | undefined = {
            calculatedCount: calculatedCount,
            gtinCode: gtinCode,
            vilmaUnit: vilmaUnit,
            packageType: type as MgPackageType,
          };

          const supplierItemPackage: MpeMgSupplierItemPackage = {
            calculatedCount,
            vilmaUnit,
            priceComparisonUnit,
            mgPackage,
            type,
            deliverable,
            basePrice,
            basePriceUnit,
            recommendedSalesPrice,
            classCode,
          };

          return supplierItemPackage;
        }
      ),
      purchaseCondition:
        supplierItem.purchaseCondition && PurchaseCondition.fromDto(supplierItem.purchaseCondition),
      freightCondition:
        supplierItem.freightCondition && FreightCondition.fromDto(supplierItem.freightCondition),
      markupCondition:
        supplierItem.markupCondition && MarkupCondition.fromDto(supplierItem.markupCondition),
      priority: supplierItem.priority,
    });
  };
}
