import { Fragment, MouseEvent, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { useDisclosure } from '@shared/hooks';
import { NavSubItem } from './NavSubItem';
import { NavWithSubRoutes } from './types';
import { getListItemButtonStyles, getListItemIconStyles, getListItemTextStyles } from './styles';

interface Props extends NavWithSubRoutes {
  onClick?: () => void;
  expandView: () => void;
  expandedView: boolean;
}

export function NavItemWithSubRoutes({
  to,
  onClick,
  isActive,
  title,
  Icon,
  subRoutes,
  expandView,
  expandedView,
}: Props) {
  const { isOpen: showSubRoutes, onToggle: toggleShowSubRoutes, onOpen } = useDisclosure(true);

  const handleToggleSubRoutes = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    toggleShowSubRoutes();
  };

  const clickTopRoute = useCallback(() => {
    if (onClick) onClick();

    if (expandedView) {
      toggleShowSubRoutes();
    } else {
      expandView();
      onOpen();
    }
  }, [onClick, expandView, expandedView, onOpen, toggleShowSubRoutes]);

  const anySubRouteActive = useMemo(() => subRoutes.some((sub) => sub.isActive), [subRoutes]);

  const listItemStyles = useMemo(() => getListItemStyles(expandedView), [expandedView]);
  const listItemButtonStyles = useMemo(() => {
    return {
      justifyContent: expandedView ? 'initial' : 'center',
      ...getListItemButtonStyles(isActive || anySubRouteActive),
    };
  }, [isActive, anySubRouteActive, expandedView]);
  const listItemIconStyles = useMemo(
    () => getListItemIconStyles(isActive || anySubRouteActive),
    [isActive, anySubRouteActive]
  );
  const listItemTextStyles = useMemo(() => getListItemTextStyles(expandedView), [expandedView]);
  const listItemArrowStyles = useMemo(() => getListItemArrowStyles(expandedView), [expandedView]);
  const listCollapseStyles = useMemo(
    () => getListCollapseStyles(expandedView, showSubRoutes),
    [expandedView, showSubRoutes]
  );

  const listItemContent = (
    <Fragment>
      <ListItemIcon sx={listItemIconStyles}>
        <Icon fontSize="small" />
      </ListItemIcon>

      <ListItemText
        disableTypography
        primary={<Typography sx={{ fontWeight: 600 }}>{title}</Typography>}
        sx={listItemTextStyles}
      />

      <Box onClick={handleToggleSubRoutes} sx={listItemArrowStyles}>
        {showSubRoutes ? <ExpandLessRounded /> : <ExpandMoreRounded />}
      </Box>
    </Fragment>
  );

  return (
    <Fragment>
      <ListItem disablePadding sx={listItemStyles}>
        <Tooltip title={expandedView ? '' : title} placement="right" arrow>
          {to ? (
            <ListItemButton
              to={to}
              onClick={clickTopRoute}
              component={Link}
              selected={isActive || anySubRouteActive}
              sx={listItemButtonStyles}
            >
              {listItemContent}
            </ListItemButton>
          ) : (
            <ListItemButton
              onClick={clickTopRoute}
              selected={isActive || anySubRouteActive}
              sx={listItemButtonStyles}
            >
              {listItemContent}
            </ListItemButton>
          )}
        </Tooltip>
      </ListItem>

      <Box sx={listCollapseStyles}>
        <Collapse in={expandedView && showSubRoutes} timeout="auto">
          <List disablePadding sx={{ pl: 3, pb: 2 }}>
            {subRoutes.map((subRoute) => (
              <NavSubItem
                {...subRoute}
                expandedView={expandedView}
                key={subRoute.to}
                onClick={onClick}
              />
            ))}
          </List>
        </Collapse>
      </Box>
    </Fragment>
  );
}

const getListItemStyles = (expandedView: boolean): SxProps<Theme> => ({
  height: '48px',
  width: expandedView ? '100%' : '48px',
});

const getListItemArrowStyles = (expandedView: boolean): SxProps<Theme> => ({
  display: expandedView ? 'flex' : 'none',
});

const getListCollapseStyles = (expandedView: boolean, showSubRoutes: boolean): SxProps<Theme> => ({
  pb: expandedView && showSubRoutes ? 0 : '0.5rem',
});
