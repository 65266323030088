import { AssortmentItemDTO, SalesPriceDTO } from '../dto';
import { Status } from './StatusOptions';

export interface SalesPriceProps {
  priceDate: Date;
  purchasePrice: number;
  freightPercentAddon?: number;
  freightFactor?: number;
  freightFixedAddOn?: number;
  markupCostFactor?: number;
  markupSalesFactor?: number;
  costPrice: number;
  salesPrice: number;
  salesPriceCoverage: number;
  status: Status;
  unit: string;
  basePriceUnit: boolean;
  primaryUnit: boolean;
}

export class SalesPrice {
  priceDate: Date;
  purchasePrice: number;
  freightPercentAddon?: number;
  freightFactor?: number;
  freightFixedAddOn?: number;
  markupCostFactor?: number;
  markupSalesFactor?: number;
  costPrice: number;
  salesPrice: number;
  salesPriceCoverage: number;
  status: Status;
  unit: string;
  basePriceUnit: boolean;
  primaryUnit: boolean;

  constructor({
    priceDate,
    purchasePrice,
    freightPercentAddon,
    freightFactor,
    freightFixedAddOn,
    markupCostFactor,
    markupSalesFactor,
    costPrice,
    salesPrice,
    salesPriceCoverage,
    status,
    unit,
    basePriceUnit,
    primaryUnit,
  }: SalesPriceProps) {
    this.priceDate = priceDate;
    this.purchasePrice = purchasePrice;
    this.freightPercentAddon = freightPercentAddon;
    this.freightFactor = freightFactor;
    this.freightFixedAddOn = freightFixedAddOn;
    this.markupCostFactor = markupCostFactor;
    this.markupSalesFactor = markupSalesFactor;
    this.costPrice = costPrice;
    this.salesPrice = salesPrice;
    this.salesPriceCoverage = salesPriceCoverage;
    this.status = status;
    this.unit = unit;
    this.basePriceUnit = basePriceUnit;
    this.primaryUnit = primaryUnit;
  }

  static fromDto = (dto: SalesPriceDTO) =>
    new SalesPrice({
      priceDate: new Date(dto.priceDate),
      purchasePrice: dto.purchasePrice,
      freightPercentAddon: dto.freightPercentAddon,
      freightFactor: dto.freightFactor,
      freightFixedAddOn: dto.freightFixedAddOn,
      markupCostFactor: dto.markupCostFactor,
      markupSalesFactor: dto.markupSalesFactor,
      costPrice: dto.costPrice,
      salesPrice: dto.salesPrice,
      salesPriceCoverage: dto.salesPriceCoverage,
      status: dto.status,
      unit: dto.unit,
      basePriceUnit: dto.basePriceUnit,
      primaryUnit: dto.primaryUnit,
    });
}

export interface SalesPricesByDate {
  priceDate: Date;
  prices: SalesPrice[];
}

export type AssortmentItemProps = {
  id: number;
  assortmentId: number;
  mgItemNumber: number;
  assortmentItemId: number;
  mgSupplierItemId: number;
  priority: string;
  priorityValidFrom: Date;
  primaryText: string;
  secondaryText: string;
  mgSupplierId: number;
  mgSupplierName: string;
  finfoNumber: number;
  groupNumber: string;
  groupDescription: string;
  basePriceUnit: string;
  primaryPackageUnit: string;
  simulatedPrices: SalesPricesByDate[];
  calculatedPrices: SalesPrice[];
  futurePriorities: AssortmentItem[];
  status: Status;
  addedToAssortmentDate?: Date;
};

export class AssortmentItem {
  id: number;
  assortmentId: number;
  mgItemNumber: number;
  assortmentItemId: number;
  mgSupplierItemId: number;
  priority: string;
  priorityValidFrom: Date;
  primaryText: string;
  secondaryText: string;
  mgSupplierId: number;
  mgSupplierName: string;
  finfoNumber: number;
  groupNumber: string;
  groupDescription: string;
  basePriceUnit: string;
  primaryPackageUnit: string;
  simulatedPrices: SalesPricesByDate[];
  calculatedPrices: SalesPrice[];
  futurePriorities: AssortmentItem[];
  status: Status;
  addedToAssortmentDate?: Date;

  constructor({
    id,
    assortmentId,
    mgItemNumber,
    assortmentItemId,
    mgSupplierItemId,
    priority,
    priorityValidFrom,
    primaryText,
    secondaryText,
    mgSupplierId,
    mgSupplierName,
    finfoNumber,
    groupNumber,
    groupDescription,
    basePriceUnit,
    primaryPackageUnit,
    simulatedPrices,
    calculatedPrices,
    futurePriorities,
    status,
    addedToAssortmentDate,
  }: AssortmentItemProps) {
    this.id = id;
    this.assortmentId = assortmentId;
    this.mgItemNumber = mgItemNumber;
    this.assortmentItemId = assortmentItemId;
    this.mgSupplierItemId = mgSupplierItemId;
    this.priority = priority;
    this.priorityValidFrom = priorityValidFrom;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.mgSupplierId = mgSupplierId;
    this.mgSupplierName = mgSupplierName;
    this.finfoNumber = finfoNumber;
    this.groupNumber = groupNumber;
    this.groupDescription = groupDescription;
    this.basePriceUnit = basePriceUnit;
    this.primaryPackageUnit = primaryPackageUnit;
    this.simulatedPrices = simulatedPrices;
    this.calculatedPrices = calculatedPrices;
    this.futurePriorities = futurePriorities;
    this.status = status;
    this.addedToAssortmentDate = addedToAssortmentDate;
  }

  static fromDTO = (dto: AssortmentItemDTO): AssortmentItem =>
    new AssortmentItem({
      id: dto.id,
      assortmentId: dto.assortmentId,
      mgItemNumber: dto.mgItemNumber,
      assortmentItemId: dto.assortmentItemId,
      mgSupplierItemId: dto.mgSupplierItemId,
      priority: dto.priority,
      priorityValidFrom: new Date(dto.priorityValidFrom),
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      mgSupplierId: dto.mgSupplierId,
      mgSupplierName: dto.mgSupplierName,
      finfoNumber: dto.finfoNumber,
      groupNumber: dto.groupNumber,
      groupDescription: dto.groupDescription,
      basePriceUnit: dto.basePriceUnit,
      primaryPackageUnit: dto.primaryPackageUnit,
      simulatedPrices:
        dto.simulatedPrices?.map((dto) => ({
          priceDate: new Date(dto.priceDate),
          prices: dto.prices.map(SalesPrice.fromDto),
        })) || [],
      calculatedPrices: dto.calculatedPrices?.map(SalesPrice.fromDto) || [],
      futurePriorities: dto.futurePriorities?.map(AssortmentItem.fromDTO) || [],
      status: dto.status,
      addedToAssortmentDate: dto.addedToAssortmentDate
        ? new Date(dto.addedToAssortmentDate)
        : undefined,
    });
}
