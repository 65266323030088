import { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { ItemAgreementsForProductGroupTable } from '@retail/purchase-agreements/components';
import { Paper, UniversalFormProvider } from '@shared/components';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import ChangeSelectedItemAgreementDialog from './ChangeSelectedItemAgreementDialog';
import { ToggleSwitch } from '@retail/components';
import { usePagination, usePopupElement } from '@shared/hooks';
import { useFetchActiveItemPrices } from '@retail/purchase-agreements/data-access';
import { ItemAgreement, PriceType } from '@retail/purchase-agreements/types';
import { useAgreements } from '@retail/purchase-agreements/context';
import { MgSupplierItemSearchField } from '@retail/products/components';
import { Supplier } from '@retail/suppliers/types';
import { MgItemDialogContainer } from '@retail/products/containers';
import { useTableActions } from '@retail/calculus/hooks';
import { conditionDatesSchema } from '@retail/calculus/components';
import { ActivateSelectedAgreementDialog } from '../ActivateAgreement';

interface Props {
  baseAssortmentId: number;
  supplier: Supplier;
  groupNumber?: string;
}

export const ItemAgreementsForProductGroup = ({
  baseAssortmentId,
  supplier,
  groupNumber,
}: Props) => {
  const t = usePurchaseAgreementsTFunction();

  const { nrOfAgreements } = useAgreements({ supplierId: supplier.mgSupplierId });
  const { action, setAction, handleResetAction } = useTableActions();
  const { menuElementProps, triggerElementProps } = usePopupElement();

  const pagination = usePagination({
    initPageSize: 200,
    initPage: 0,
  });

  const [mgItemClicked, setMgItemClicked] = useState<ItemAgreement | undefined>(undefined);
  const handleMgItemClicked = (mgItem: ItemAgreement) => setMgItemClicked(mgItem);
  const [inAssortment, setInAssortment] = useState<boolean>(true);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const { data: itemConditionsPageDto } = useFetchActiveItemPrices({
    assortmentId: baseAssortmentId,
    mgSupplierId: supplier.mgSupplierId,
    groupNumbers: groupNumber ? [groupNumber] : [],
    size: pagination.pageSize,
    page: pagination.page,
    query: searchQuery,
    baseAssortment: inAssortment,
  });
  const itemsDto = useMemo(() => itemConditionsPageDto?.result || [], [itemConditionsPageDto]);
  const items = itemsDto.map(ItemAgreement.fromDto);

  const activeItemAgreement = items.find(
    (item) => item.mgItemNumber === mgItemClicked?.mgItemNumber
  );

  const closeAction = useCallback(() => {
    handleResetAction();
    setMgItemClicked(undefined);
  }, [handleResetAction]);

  const onlyOneAgreement = useMemo(
    () => !activeItemAgreement?.multiplePrices && nrOfAgreements === 1,
    [activeItemAgreement?.multiplePrices, nrOfAgreements]
  );
  const isInherited = useMemo(
    () => activeItemAgreement?.inherited || false,
    [activeItemAgreement?.inherited]
  );

  return (
    <Paper
      variant="outlined"
      sx={{
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: 1,
        p: 0,
        borderRadius: 0,
      }}
    >
      {mgItemClicked && action === 'edit' && (
        <ChangeSelectedItemAgreementDialog
          open
          onClose={closeAction}
          mgItemNr={mgItemClicked.mgItemNumber}
          supplier={supplier}
        />
      )}

      {mgItemClicked && action === 'view' && (
        <MgItemDialogContainer
          isOpen={true}
          itemNumber={mgItemClicked.mgItemNumber}
          onClose={closeAction}
        />
      )}

      {mgItemClicked && activeItemAgreement && action === 'activate' && (
        <UniversalFormProvider
          schema={conditionDatesSchema()}
          defaultValues={conditionDatesSchema().getDefault()}
        >
          <ActivateSelectedAgreementDialog
            isOpen={true}
            onClose={closeAction}
            agreementId={activeItemAgreement.agreementId}
            agreementText={activeItemAgreement.agreementText}
            supplierId={supplier.mgSupplierId}
            priceType={activeItemAgreement.priceType as PriceType}
          />
        </UniversalFormProvider>
      )}

      <Stack flexDirection="row" gap={1} py={1} pl={2}>
        <MgSupplierItemSearchField
          isDisabled={false}
          onSubmitSearch={setSearchQuery}
          size="small"
          includeLabel={false}
        />

        <ToggleSwitch
          text={t('purchaseAgreements.priceList.inAssortment')}
          toggle={inAssortment}
          setToggle={() => setInAssortment(!inAssortment)}
        />
      </Stack>

      <ItemAgreementsForProductGroupTable
        {...pagination}
        itemConditions={items}
        totalItems={itemConditionsPageDto?.totalCount ?? 0}
        supplier={supplier}
        groupNumber={groupNumber}
        onlyOneAgreement={onlyOneAgreement}
        isInherited={isInherited}
        menuElementProps={menuElementProps}
        triggerElementProps={triggerElementProps}
        setAction={setAction}
        mgItemClicked={mgItemClicked}
        handleMgItemClicked={handleMgItemClicked}
      />
    </Paper>
  );
};
