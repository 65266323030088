import { Add } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  id: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  //mgAssortments: MgAssortmentBase[];
  onClose: () => void;
  openSelectItemModal: () => void;
  //addItemsToNewAssortment: () => void;
  //openAddToExistingModal: () => void;
}

export function AddMgItemToMgAssortmentMenu({
  id,
  open,
  anchorEl,
  //mgAssortments,
  onClose,
  openSelectItemModal,
}: //addItemsToNewAssortment,
//openAddToExistingModal,
Props) {
  const t = useAppTFunction();

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transitionDuration={600}
    >
      <MenuItem LinkComponent={Button} onClick={openSelectItemModal}>
        {t('assortment.addToMyAssortment')}
        <Add />
      </MenuItem>

      {/*
      <MenuItem LinkComponent={Button} onClick={addItemsToNewAssortment}>
        {t('assortment.addToNewAssortment')}
        <Add />
      </MenuItem>

      {mgAssortments.length > 0 && (
        <MenuItem LinkComponent={Button} onClick={openAddToExistingModal}>
          {t('assortment.chooseExistingAssortment')}
        </MenuItem>
      )}
        */}
    </Menu>
  );
}
