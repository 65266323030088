import { HierarchyLevels } from '@retail/calculus/types';
import { MarkupConditionDTO } from '../dtos';
interface MarkupConditionProps {
  id: number;
  markupTemplateName: string;
  mgSupplierId?: number;
  mgSupplierName: string;
  productGroup?: string;
  productGroupName?: string;
  levelProductGroup?: string;
  levelProductGroupName?: string;
  mgItemNumber?: number;
  mgSupplierItemId?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom: string;
  validTo?: string;
  active?: true;
  markupTemplateId: number;
  costFactor?: number;
  salesFactor: number;
  bindingType: HierarchyLevels;
  inherited?: boolean;
  source?: string;
  subConditions?: MarkupCondition[];
  replacesConditions?: MarkupCondition[];
  futureConditions?: MarkupCondition[];
}

export class MarkupCondition {
  id: number;
  markupTemplateName: string;
  mgSupplierId?: number;
  mgSupplierName: string;
  productGroup?: string;
  productGroupName?: string;
  levelProductGroup?: string;
  levelProductGroupName?: string;
  mgItemNumber?: number;
  mgSupplierItemId?: number;
  primaryText?: string;
  secondaryText?: string;
  validFrom: string;
  validTo?: string;
  active?: true;
  markupTemplateId: number;
  costFactor?: number;
  salesFactor: number;
  bindingType: HierarchyLevels;
  inherited?: boolean;
  source?: string;
  subConditions?: MarkupCondition[];
  replacesConditions?: MarkupCondition[];
  futureConditions?: MarkupCondition[];

  constructor({
    id,
    markupTemplateName,
    mgSupplierId,
    mgSupplierName,
    productGroup,
    productGroupName,
    levelProductGroup,
    levelProductGroupName,
    mgItemNumber,
    primaryText,
    secondaryText,
    validFrom,
    validTo,
    active,
    markupTemplateId,
    costFactor,
    salesFactor,
    bindingType,
    inherited,
    subConditions,
    replacesConditions,
    futureConditions,
  }: MarkupConditionProps) {
    this.id = id;
    this.markupTemplateName = markupTemplateName;
    this.mgSupplierId = mgSupplierId;
    this.mgSupplierName = mgSupplierName;
    this.productGroup = productGroup;
    this.productGroupName = productGroupName;
    this.levelProductGroup = levelProductGroup;
    this.levelProductGroupName = levelProductGroupName;
    this.mgItemNumber = mgItemNumber;
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.active = active;
    this.markupTemplateId = markupTemplateId;
    this.costFactor = costFactor;
    this.salesFactor = salesFactor;
    this.bindingType = bindingType;
    this.inherited = inherited;
    this.subConditions = subConditions;
    this.replacesConditions = replacesConditions;
    this.futureConditions = futureConditions;
  }

  static fromDto = (dto: MarkupConditionDTO): MarkupCondition =>
    new MarkupCondition({
      id: dto.id,
      markupTemplateName: dto.markupTemplateName,
      mgSupplierId: dto.mgSupplierId,
      mgSupplierName: dto.mgSupplierName,
      productGroup: dto.productGroup,
      productGroupName: dto.productGroupName,
      levelProductGroup: dto.levelProductGroup,
      levelProductGroupName: dto.levelProductGroupName,
      mgItemNumber: dto.mgItemNumber,
      primaryText: dto.primaryText,
      secondaryText: dto.secondaryText,
      validFrom: dto.validFrom,
      validTo: dto.validTo,
      active: dto.active,
      markupTemplateId: dto.markupTemplateId,
      costFactor: dto.costFactor,
      salesFactor: dto.salesFactor,
      bindingType: dto.bindingType,
      inherited: dto.inherited,
      source: dto.source,
      subConditions: dto.subConditions?.map(this.fromDto),
      replacesConditions: dto.replacesConditions?.map(this.fromDto),
      futureConditions: dto.futureConditions?.map(this.fromDto),
    });
}
