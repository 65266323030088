import { Chip } from '@shared/components';
import { AssortmentPriorityProps } from '@retail/my-assortment/types';
import { Stack, Typography } from '@mui/material';

interface Props {
  error: AssortmentPriorityProps;
}

export const ErrorChip = ({ error }: Props) => {
  return (
    <Chip
      backgroundColor="white"
      sx={{ mr: 1, width: 310, mb: 1 }}
      label={
        <Stack>
          <Typography variant="caption">
            <Typography variant="caption" display="inline" fontWeight={500}>
              mgItemNumber:{' '}
            </Typography>
            {error.mgItemNumber},
            <Typography variant="caption" display="inline" fontWeight={500}>
              {' '}
              mgSupplierId:{' '}
            </Typography>
            {error.mgSupplierId}
          </Typography>
        </Stack>
      }
    />
  );
};
