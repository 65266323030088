import { ErrorBoundary } from '@sentry/react';
import { ErrorBoundaryProps } from '@sentry/react/types/errorboundary';
import React, { Suspense, SuspenseProps } from 'react';
import { BodyLoader } from '../Loader';
import { ErrorFallbackPage } from '../ErrorFallback';

interface Props
  extends Omit<ErrorBoundaryProps, 'children' | 'fallback'>,
    Omit<SuspenseProps, 'fallback'> {
  suspenseFallback?: SuspenseProps['fallback'];
  errorFallback?: ErrorBoundaryProps['fallback'];
}

export function SuspenseWithSentryErrorBoundary({
  children,
  suspenseFallback = <BodyLoader />,
  errorFallback = (props) => <ErrorFallbackPage {...props} />,
  ...errorProps
}: Props) {
  return (
    <ErrorBoundary fallback={errorFallback} {...errorProps}>
      <Suspense fallback={suspenseFallback}>{children}</Suspense>
    </ErrorBoundary>
  );
}
