import { Stack, Tooltip, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { SalesPrice } from '@retail/my-assortment/types';
import { CreateItemLevelMarkupSchema } from '@retail/markup/components';
import { useController, useFormContext } from 'react-hook-form';
import { EditableField } from './EditableField';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { calculateNewPriceFromDG, calculateSalesFactorFromDG } from '@retail/utils';
import { EditIconButton } from '@retail/components';
import { HelperText } from '@shared/components';
import { EditedMarkupField } from './ProductCard';

interface Props {
  prices?: SalesPrice;
  editPriceCoverage: boolean;
  isPrimaryPackage?: boolean;
  editedMarkupField: EditedMarkupField;
  setEditedMarkupField: Dispatch<SetStateAction<EditedMarkupField>>;
  toggleEditPriceCoverage: () => void;
  resetMarkupValues: () => void;
}

export function SalesCoverageView({
  prices,
  editPriceCoverage,
  isPrimaryPackage,
  editedMarkupField,
  setEditedMarkupField,
  toggleEditPriceCoverage,
  resetMarkupValues,
}: Props) {
  const t = useMyAssortmentTFunction();

  const {
    control,
    formState: { errors },
  } = useFormContext<CreateItemLevelMarkupSchema>();
  const salesFactorController = useController({ name: 'salesFactor', control });
  const salesPriceController = useController({ name: 'salesPrice', control });
  const salesCoverageController = useController({ name: 'salesCoverage', control });

  const onChangeSalesCoverage = useCallback(
    (value: string) => {
      salesCoverageController.field.onChange(value);
      setEditedMarkupField('coverage');
      prices &&
        salesPriceController.field.onChange(
          calculateNewPriceFromDG(
            prices.salesPrice,
            prices.salesPriceCoverage,
            Number(value)
          ).toFixed(2)
        );
      salesFactorController.field.onChange(calculateSalesFactorFromDG(Number(value)).toFixed(2));
    },
    [
      salesCoverageController.field,
      setEditedMarkupField,
      prices,
      salesPriceController.field,
      salesFactorController.field,
    ]
  );

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography fontWeight={600}>{t('myAssortment.salesCoverage')}</Typography>
      <Stack alignItems="flex-end">
        {prices?.salesPriceCoverage && isPrimaryPackage ? (
          <EditableField
            editMode={editPriceCoverage}
            chipLabel={prices.salesPriceCoverage}
            controller={salesCoverageController}
            approximatelyEqual={editedMarkupField !== 'coverage'}
            valueType="percent"
            resetMarkupValues={resetMarkupValues}
            toggleEditMode={toggleEditPriceCoverage}
            onSumbitValue={onChangeSalesCoverage}
          />
        ) : (
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Typography variant="body2" fontWeight={600}>
              {prices?.salesPriceCoverage ? prices?.salesPriceCoverage.toFixed(2) + '%' : '- %'}
            </Typography>
            {prices?.salesPriceCoverage ? (
              <Tooltip
                title={t('myAssortment.productCard.editOnlyBasePrice')}
                placement="top"
                arrow
              >
                <div>
                  <EditIconButton />
                </div>
              </Tooltip>
            ) : (
              <EditIconButton />
            )}
          </Stack>
        )}
        {errors.salesCoverage?.message && (
          <HelperText id="salesCoverage" status="error">
            {errors.salesCoverage?.message}
          </HelperText>
        )}
      </Stack>
    </Stack>
  );
}
