import { Divider, Stack } from '@mui/material';
import { FreightCondition } from '@retail/freight/types';
import { FreightAccordion } from './FreightAccordion';
import { PurchaseAccordion } from './PurchaseAccordion';
import { MarkupCondition } from '@retail/markup/types';
import { MarkupAccordion } from './MarkupAccordion';
import { PurchaseCondition } from '@retail/purchase-agreements/types';
import { EditedMarkupField } from './ProductCard';
import { Dispatch, SetStateAction } from 'react';
import { CalculationEntry } from './CalculationEntry';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

interface Props {
  primaryUnit?: string;
  purchaseCondition?: PurchaseCondition;
  costPrice?: number;
  purchasePrice?: number;
  freightCondition?: FreightCondition;
  markupCondition?: MarkupCondition;
  editSalesFactor: boolean;
  editedMarkupField: EditedMarkupField;
  setEditedMarkupField: Dispatch<SetStateAction<EditedMarkupField>>;
  toggleEditSalesFactor: () => void;
  resetMarkupValues: () => void;
}

export function Calculations({
  primaryUnit,
  purchaseCondition,
  costPrice,
  purchasePrice,
  freightCondition,
  markupCondition,
  editSalesFactor,
  editedMarkupField,
  setEditedMarkupField,
  toggleEditSalesFactor,
  resetMarkupValues,
}: Props) {
  const t = useMyAssortmentTFunction();
  return (
    <Stack pt={1}>
      <PurchaseAccordion
        purchaseCondition={purchaseCondition}
        primaryUnit={primaryUnit}
        purchasePrice={purchasePrice}
      />
      <FreightAccordion freightCondition={freightCondition} />

      <Stack px={2} py={1} flexDirection="row">
        <CalculationEntry
          label={t('myAssortment.columns.costPriceInSalesUnit')}
          chipLabel={costPrice}
          primaryUnit={primaryUnit}
        />
        <Stack width={24} />
      </Stack>

      <Divider />

      <MarkupAccordion
        markupCondition={markupCondition}
        costPrice={costPrice}
        editSalesFactor={editSalesFactor}
        editedMarkupField={editedMarkupField}
        resetMarkupValues={resetMarkupValues}
        setEditedMarkupField={setEditedMarkupField}
        toggleEditSalesFactor={toggleEditSalesFactor}
      />
    </Stack>
  );
}
