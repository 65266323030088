import { useProductsTFunction } from '@retail/products/i18n';
import { PanelProps, ProductInformationAccordion } from './ProductInformationAccordion';
import { ProductInformationAccordionRow } from './ProductInformationAccordionRow';
import { PANELS, ProductInformationAccordionsProps } from './ProductInformationAccordions';

export const MarketingPanel = ({
  expanded,
  selectedSupplierItem,
  handleChange,
}: PanelProps & ProductInformationAccordionsProps) => {
  const t = useProductsTFunction();

  return (
    <ProductInformationAccordion
      panelType={PANELS.MARKETING}
      expanded={expanded}
      handleChange={handleChange}
    >
      <ProductInformationAccordionRow title={t('products.mgItem.details.webProductName')}>
        {selectedSupplierItem.webProductName}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.textIngress')}>
        {selectedSupplierItem.textIngress}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.textFull')}>
        {selectedSupplierItem.textFull}
      </ProductInformationAccordionRow>

      <ProductInformationAccordionRow title={t('products.mgItem.details.aiDescription')}>
        {selectedSupplierItem.aiDescription}
      </ProductInformationAccordionRow>
    </ProductInformationAccordion>
  );
};
