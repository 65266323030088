import { PurchaseAgreementDTO } from '../dto';

export class PurchaseAgreement {
  id: number;
  name: string;
  standardAgreement: boolean;
  supplierId: number;
  mpeCreatedAt: Date;
  mpeUpdatedAt: Date;
  sourceAgreementId: number;
  source: string;

  constructor(
    id: number,
    name: string,
    standardAgreement: boolean,
    supplierId: number,
    mpeCreatedAt: Date,
    mpeUpdatedAt: Date,
    sourceAgreementId: number,
    source: string
  ) {
    this.id = id;
    this.name = name;
    this.standardAgreement = standardAgreement;
    this.supplierId = supplierId;
    this.mpeCreatedAt = mpeCreatedAt;
    this.mpeUpdatedAt = mpeUpdatedAt;
    this.sourceAgreementId = sourceAgreementId;
    this.source = source;
  }

  static fromDto = (dto: PurchaseAgreementDTO): PurchaseAgreement =>
    new PurchaseAgreement(
      dto.agreementId,
      dto.agreementText,
      dto.standardAgreement,
      dto.mgSupplierId,
      dto.mpeCreatedAt,
      dto.mpeUpdatedAt,
      dto.sourceAgreementId,
      dto.source
    );
}
