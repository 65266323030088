import { Add } from '@mui/icons-material';
import { ContainedButton } from '@shared/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

interface Props {
  onClick: () => void;
}

export const AddItemsButton = ({ onClick }: Props) => {
  const t = useMyAssortmentTFunction();

  return (
    <ContainedButton
      sx={{ whiteSpace: 'nowrap' }}
      color="secondary"
      startIcon={<Add />}
      size="small"
      onClick={onClick}
    >
      {t('myAssortment.actions.addItems')}
    </ContainedButton>
  );
};
