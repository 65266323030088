import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ContainedButton, Dialog, TextButton } from '@shared/components';
import { useAppTFunction } from '@retail/app/i18n';
import { useController, useFormContext } from 'react-hook-form';
import {
  CreateFreightConditionForm,
  CreateFreightConditionSchema,
} from '@retail/freight/components';
import { useCallback } from 'react';
import { useToast } from '@retail/hooks';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import { useMutateCreateOrEditFreightCondition } from '@retail/freight/data-access';
import { useLevelSelect } from '@retail/calculus/context';
import { getDTODate } from '@retail/utils';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import {
  CreateFreightConditionDTO,
  CreateOrEditFreightCondition,
  MgSupplierItemFreightConditionDTO,
  ProductGroupFreightConditionDTO,
} from '@retail/freight/types';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  onClose: () => void;
  open: boolean;
  supplierId: number;
}

function CreateFreightConditionDialog({ onClose, supplierId }: Props) {
  const t = useAppTFunction();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { draftAssortmentId, departmentId } = useDepartmentAssortments();

  const { handleSubmit, control } = useFormContext<CreateFreightConditionSchema>();
  const levelController = useController({ name: 'level', control });

  const { flattenedProductGroups, mgSupplierItems, isLoadingFetchItems, setMgSupplierItemSearch } =
    useLevelSelect({
      supplierId,
      selectedProductGroup: levelController.field.value === 'productGroup',
    });

  const { mutateAsync: postFreightConditions, isLoading } = useMutateCreateOrEditFreightCondition(
    draftAssortmentId,
    departmentId
  );

  const onSubmit = useCallback(
    (data: CreateFreightConditionSchema) => {
      const freightCondition: CreateOrEditFreightCondition = {
        validFrom: getDTODate(data.fromDate) ?? '',
        freight: {
          percentAddon: Number(data.percentAddon),
        },
      };

      if (data.item) freightCondition.mgItemNumber = data.item.mgItemNumber;
      else if (data.productGroup) freightCondition.groupNumber = data.productGroup.groupNumber;

      let mgSupplierConditions = null;
      let productGroupConditions = null;
      let mgSupplierItemConditions = null;

      if ('mgItemNumber' in freightCondition) {
        mgSupplierItemConditions = [freightCondition as MgSupplierItemFreightConditionDTO];
      } else if ('groupNumber' in freightCondition) {
        productGroupConditions = [freightCondition as ProductGroupFreightConditionDTO];
      } else {
        mgSupplierConditions = [freightCondition as CreateFreightConditionDTO];
      }

      postFreightConditions({
        body: {
          mgSupplierId: supplierId,
          mgSupplierConditions,
          productGroupConditions,
          mgSupplierItemConditions,
        },
      })
        .catch((err) => {
          console.error(err);
          toast.error(t('markup.error.generic'));
        })
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['draft-conditions'] });
          queryClient.invalidateQueries({ queryKey: ['freight'] });
          queryClient.invalidateQueries({ queryKey: ['freights'] });

          onClose();
        });
    },
    [postFreightConditions, supplierId, toast, t, queryClient, onClose]
  );

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onClose}>
      <DialogTitle>{t('freight.newFreightCondition')}</DialogTitle>

      <DialogContent>
        <CreateFreightConditionForm
          productGroups={flattenedProductGroups}
          mgSupplierItems={mgSupplierItems}
          setMgSupplierItemSearch={setMgSupplierItemSearch}
          isLoadingFetchItems={isLoadingFetchItems}
        />
      </DialogContent>

      <DialogActions>
        <TextButton
          sx={{ textDecoration: 'none', color: ({ palette }) => palette.common.black }}
          size="small"
          onClick={onClose}
        >
          {t('common.close')}
        </TextButton>

        <ContainedButton
          onClick={handleSubmit((data) => onSubmit(data))}
          disabled={isLoading}
          size="small"
        >
          {t('common.save')}
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
}

export default withDialogSuspenseErrorBoundary(CreateFreightConditionDialog);
