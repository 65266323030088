import { ArrowUpward } from '@mui/icons-material';
import { IconButton, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { SupplierWithPriority } from '@retail/suppliers/types';

interface Props {
  unprioritizedSuppliers: SupplierWithPriority[];
  setUnderPrioSupplier: (supplier: SupplierWithPriority) => void;
}

export const UnprioritizedSuppliers = ({ unprioritizedSuppliers, setUnderPrioSupplier }: Props) => {
  const t = useMyAssortmentTFunction();
  return (
    <List>
      {unprioritizedSuppliers.length > 0 ? (
        unprioritizedSuppliers.map((supplier) => (
          <Stack
            component={ListItem}
            key={supplier.id}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">{supplier.name}</Typography>
            <Stack flexDirection="row">
              <Tooltip title={t('myAssortment.priority.setUnderPrio')}>
                <IconButton onClick={() => setUnderPrioSupplier(supplier)}>
                  <ArrowUpward fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        ))
      ) : (
        <Typography textAlign="center" variant="body2" fontStyle="italic">
          {t('myAssortment.priority.noSuppliersUnprioritized')}
        </Typography>
      )}
    </List>
  );
};
