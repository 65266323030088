import { conditionDatesSchema, levelSelectSchema } from '@retail/calculus/components';
import { CalculusTFunction } from '@retail/calculus/i18n';
import { numberBetween1And2Regex, percentageRegex } from '@retail/form/validation';
import * as yup from 'yup';

export const percentFreightUnit = '%';

export const createFreightConditionSchema = (t: CalculusTFunction) =>
  levelSelectSchema(t)
    .concat(conditionDatesSchema())
    .concat(
      yup.object({
        freightFactor: yup
          .string()
          .matches(numberBetween1And2Regex, t('calculus.error.mustBeNumberBetween1And2'))
          .default(''),
        percentAddon: yup
          .string()
          .matches(percentageRegex, t('calculus.error.mustBeNumberBetween'))
          .required(t('calculus.error.mustAddFreightSurcharge'))
          .default(''),
      })
    );

export const editFreightConditionSchema = (t: CalculusTFunction) =>
  conditionDatesSchema().concat(
    yup.object({
      percentAddon: yup
        .string()
        .matches(percentageRegex, t('calculus.error.mustBeNumberBetween'))
        .required(t('calculus.error.mustAddFreightSurcharge'))
        .default(''),
    })
  );

export type CreateFreightConditionSchema = yup.InferType<
  ReturnType<typeof createFreightConditionSchema>
>;
export type EditFreightConditionSchema = yup.InferType<
  ReturnType<typeof editFreightConditionSchema>
>;
