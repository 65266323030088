import { List, ListItem, ListItemText } from '@mui/material';
import { useMyAssortmentTranslation } from '@retail/my-assortment/i18n';
import { Assortment } from '@retail/my-assortment/types';
import { TextButton } from '@shared/components';

interface Props {
  assortments: Assortment[];
  nrOfItems: number;
}

export const WarehouseAssortmentsList = ({ assortments, nrOfItems }: Props) => {
  const { t } = useMyAssortmentTranslation();

  return (
    <List>
      {assortments.map(({ id, title }) => (
        <ListItem key={id}>
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              fontWeight: 600,
            }}
          />
          <ListItemText primary={`${nrOfItems} ${t('myAssortment.welcomeToMyAssortment.items')}`} />
          <TextButton color="primary">
            {t('myAssortment.welcomeToMyAssortment.selectWarehouseAssortment')}
          </TextButton>
        </ListItem>
      ))}
    </List>
  );
};
