import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useProductsTFunction } from '@retail/products/i18n';
import { ProductGroup } from '@retail/products/types';
import { SearchTag } from './SearchTag';

interface Props {
  productGroups: ProductGroup[];
  selectedProductGroups: ProductGroup[];
  isFetching: boolean;
  setPage: (page: number) => void;
  selectProductGroups: (productGroup: ProductGroup[]) => void;
}

export function SelectProductGroupsAutocomplete({
  productGroups,
  selectedProductGroups,
  isFetching,
  setPage,
  selectProductGroups,
}: Props) {
  const t = useProductsTFunction();
  return (
    <Stack gap={0.5}>
      <Typography
        component="label"
        htmlFor="productGroups"
        variant="h6"
        pl={1}
        color={({ palette }) => palette.grey.A400}
      >
        {t('products.search.productGroup.label')}
      </Typography>
      <Autocomplete
        id="productGroups"
        disabled={isFetching}
        multiple
        filterSelectedOptions
        disableClearable
        renderTags={(productGroups) =>
          productGroups.map((productGroup) => (
            <SearchTag
              key={productGroup.groupNumber}
              id={productGroup.groupNumber}
              name={productGroup.name}
              onDelete={() => {
                selectProductGroups(
                  selectedProductGroups.filter(
                    ({ groupNumber }) => groupNumber !== productGroup.groupNumber
                  )
                );
              }}
            />
          ))
        }
        value={selectedProductGroups}
        onChange={(_, newValue) => {
          selectProductGroups(newValue);
          setPage(0);
        }}
        options={productGroups}
        getOptionLabel={({ name, groupNumber }) => (name ? `${groupNumber} ${name}` : groupNumber)}
        renderInput={(params) => (
          <TextField
            sx={{ width: 360 }}
            {...params}
            placeholder={t('products.search.productGroup.searchInProductgroup')}
          />
        )}
      />
    </Stack>
  );
}
