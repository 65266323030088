import { PageLayout, SuspenseWithSentryErrorBoundary } from '@retail/components';
import { useParams } from 'react-router-dom';
import { useFetchMpeMgItem } from '@retail/products/data-access';
import { MgItem } from '@retail/products/types';
import { MgItemDetails, MgItemNotFound } from '@retail/products/containers';
import { Stack } from '@mui/material';

export function MgItemPage() {
  const { itemNumber } = useParams<{ itemNumber: string }>();

  const { data: mgItemDto } = useFetchMpeMgItem(
    {
      mgItemNumber: Number(itemNumber),
    },
    { suspense: true }
  );

  const mgItem = mgItemDto && MgItem.fromDto(mgItemDto);

  return (
    <PageLayout maxWidth="full" spacing={2}>
      {mgItem ? (
        <Stack>
          <SuspenseWithSentryErrorBoundary>
            <MgItemDetails mgItem={mgItem} />
          </SuspenseWithSentryErrorBoundary>
        </Stack>
      ) : (
        <MgItemNotFound mgItemNr={itemNumber} />
      )}
    </PageLayout>
  );
}
