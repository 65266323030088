import { useCallback, useState } from 'react';
import { PurchaseAgreement } from '@retail/purchase-agreements/types';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import PriceListDialogContainer from '../PriceListDialogContainer';
import { Conditions } from '../Conditions';
import { useDisclosure } from '@shared/hooks';
import { useLevelSearchParams } from '@retail/calculus/hooks';
import { useFetchConditions } from '@retail/calculus/data-access';
import { useDepartmentAssortments } from '@retail/my-assortment/context';
import { ConditionState, PurchaseCondition } from '@retail/calculus/types';
import { Paper } from '@shared/components';
import { Supplier } from '@retail/suppliers/types';
interface Props {
  supplier: Supplier;
  typesToShow: ConditionState;
  agreements: PurchaseAgreement[];
}

export function ConditionsContainer({ supplier, typesToShow, agreements }: Props) {
  const { draftAssortmentId } = useDepartmentAssortments();
  const [agreement, setAgreement] = useState<PurchaseAgreement['id'] | undefined>(undefined);
  const [productGroup, setProductGroup] = useState<string | undefined>(undefined);

  const { selectedProductGroupId, selectedMgItemId } = useLevelSearchParams();

  const { isOpen: isPriceListDialogOpen, onToggle: togglePriceListDialog } = useDisclosure();

  const setPriceListValues = useCallback(
    (agreementId?: number, productGroup?: string) => {
      togglePriceListDialog();
      setAgreement(agreementId);
      setProductGroup(productGroup);
    },
    [togglePriceListDialog]
  );

  const { data: draftConditionsDTO = [] } = useFetchConditions({
    assortmentId: draftAssortmentId,
    conditionTypes: 'PURCHASE',
    conditionState: typesToShow === ConditionState.DRAFT ? ConditionState.ALL : typesToShow,
    mgSupplierIds: [supplier.mgSupplierId],
    groupNumbers: selectedProductGroupId ? [selectedProductGroupId] : undefined,
    mgItemNumbers: selectedMgItemId ? [selectedMgItemId] : undefined,
    includeOwnerConditions: typesToShow !== ConditionState.DRAFT,
    includeAgreements: typesToShow === ConditionState.ALL,
  });
  const purchaseConditionsDto =
    draftConditionsDTO.length > 0 ? draftConditionsDTO[0].purchaseConditions : [];

  const purchaseConditions = purchaseConditionsDto.map(PurchaseCondition.fromDto);

  const activePurchaseConditions = purchaseConditions.filter(
    (condition) => condition.operation !== 'DELETE'
  );

  return (
    <Paper
      paddingY="dense"
      variant="outlined"
      sx={{
        overflow: 'auto',
        display: 'flex',
        flexGrow: 1,
        p: 0,
        borderRadius: 0,
      }}
    >
      {agreement && (
        <PriceListDialogContainer
          open={isPriceListDialogOpen}
          onClose={() => setPriceListValues()}
          productGroupId={productGroup || selectedProductGroupId}
          agreementId={agreement}
        />
      )}

      <SuspenseWithSentryErrorBoundary>
        <Conditions
          supplier={supplier}
          agreements={agreements}
          conditions={activePurchaseConditions}
          setPriceListValues={setPriceListValues}
        />
      </SuspenseWithSentryErrorBoundary>
    </Paper>
  );
}
