import { styled, TableCell, Typography } from '@mui/material';

interface Props {
  prevValue?: string | number;
  newValue?: string | number | React.ReactNode;
  lineThrough?: boolean;
}

export function StackedTableCellWithChangeInValue({
  prevValue,
  newValue,
  lineThrough = false,
}: Props) {
  const newValueTrimmed = newValue ? String(newValue).trim() : '';
  const prevValueTrimmed = prevValue ? String(prevValue).trim() : '';

  if (!newValueTrimmed && !prevValueTrimmed) {
    return <TableCell align="center">-</TableCell>;
  }

  if (!newValueTrimmed && prevValueTrimmed) {
    return (
      <StyledPrevValueTypography variant="subtitle2" lineThrough>
        {prevValueTrimmed}
      </StyledPrevValueTypography>
    );
  }

  if (prevValueTrimmed !== newValueTrimmed) {
    return (
      <TableCell align="center">
        <StyledNewValueTypography variant="body2">{newValueTrimmed}</StyledNewValueTypography>
        <StyledPrevValueTypography variant="subtitle2" lineThrough={lineThrough}>
          {prevValueTrimmed}
        </StyledPrevValueTypography>
      </TableCell>
    );
  }

  if (prevValueTrimmed === newValueTrimmed && lineThrough) {
    return (
      <TableCell align="center">
        <StyledPrevValueTypography variant="body2" lineThrough={lineThrough}>
          {newValueTrimmed}
        </StyledPrevValueTypography>
      </TableCell>
    );
  }

  return (
    <TableCell align="center">
      <StyledNewValueTypography variant="body2">{newValueTrimmed}</StyledNewValueTypography>
    </TableCell>
  );
}

export const StyledNewValueTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.grey[900],
}));

export const StyledPrevValueTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'lineThrough',
})<{ lineThrough?: boolean }>(({ lineThrough }) => ({
  textDecoration: lineThrough ? 'line-through' : undefined,
}));
