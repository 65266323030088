import { Check, Clear } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { CustomIconButton, StackedTextCell } from '@retail/components';
import { EditButtonIcon } from '@shared/custom-icons';
import { useCallback } from 'react';
import { StyledTextField } from './CalculationEntry';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';

interface Props {
  editMode: boolean;
  chipLabel?: number;
  controller: any;
  approximatelyEqual?: boolean;
  valueType: 'price' | 'percent';
  toggleEditMode: () => void;
  onSumbitValue: (value: string) => void;
  resetMarkupValues: () => void;
}

export function EditableField({
  editMode,
  chipLabel,
  controller,
  approximatelyEqual,
  valueType,
  toggleEditMode,
  onSumbitValue,
  resetMarkupValues,
}: Props) {
  const t = useMyAssortmentTFunction();

  const onSubmit = useCallback(() => {
    toggleEditMode();
  }, [toggleEditMode]);

  const chipValue = chipLabel ? chipLabel.toFixed(2) : '-';

  const value = Number(controller.field.value).toFixed(2);

  return (
    <Stack flexDirection="row" alignItems="center">
      {editMode ? (
        <StyledTextField
          {...controller.field}
          onChange={(e) => {
            onSumbitValue(e.target.value);
          }}
          onClick={(e) => e.stopPropagation()}
          size="small"
        />
      ) : controller.field.value !== chipValue && !isNaN(controller.field.value) ? (
        <StackedTextCell
          centerAlign
          gap="small"
          topLine={
            <Typography variant="body2" fontWeight={600}>
              {approximatelyEqual && '≈'}
              {valueType === 'price'
                ? t('myAssortment.recommendedPrices.value', { value: value })
                : `${controller.field.value}%`}
            </Typography>
          }
          bottomLine={
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{ textDecoration: 'line-through' }}
            >
              {valueType === 'price'
                ? t('myAssortment.recommendedPrices.value', { value: chipValue })
                : `${chipValue}%`}
            </Typography>
          }
        />
      ) : (
        <Typography variant="body2" fontWeight={600}>
          {valueType === 'price'
            ? t('myAssortment.recommendedPrices.value', { value: chipValue })
            : `${chipValue}%`}
        </Typography>
      )}

      {editMode ? (
        <Stack flexDirection="row">
          <CustomIconButton onClick={onSubmit} icon={<Check />} />
          <CustomIconButton
            onClick={() => {
              toggleEditMode();
              resetMarkupValues();
            }}
            icon={<Clear />}
          />
        </Stack>
      ) : (
        <CustomIconButton onClick={toggleEditMode} icon={<EditButtonIcon />} />
      )}
    </Stack>
  );
}
