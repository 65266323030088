import { QueryKey, useQuery } from '@tanstack/react-query';
import { fetcher, PPSFetchError, RequestContext, RequestMethods } from './fetcher';
import { useCallback } from 'react';

export interface UseTanstackFetchError<TError = PPSFetchError> {
  status: number;
  text: string;
  payload: TError;
}

export interface QueryOptions {
  suspense?: boolean;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  retry?: number;
  keepPreviousData?: boolean;
}

export interface UseFetchOptions {
  queryKey: QueryKey;
  url: string;
  method: RequestMethods;
  body?: any;
  context?: RequestContext;
  token?: string;
  headers?: HeadersInit;
}

export function useFetch<TData, TError = PPSFetchError>({
  queryKey,
  url,
  method,
  body,
  context,
  token,
  headers,
  ...queryOptions
}: UseFetchOptions & QueryOptions) {
  const fetch = useCallback(async () => {
    return fetcher<TData>({
      url,
      method,
      token,
      body: body ? JSON.stringify(body) : undefined,
      context,
      headers,
    });
  }, [body, context, headers, method, token, url]);

  return useQuery<TData, UseTanstackFetchError<TError>>(queryKey, fetch, queryOptions);
}

export function useQueryFetch<TData, TError = PPSFetchError>({
  queryKey,
  fetcher,
  ...queryOptions
}: {
  fetcher: () => Promise<TData>;
  queryKey: QueryKey;
} & QueryOptions) {
  return useQuery<TData, UseTanstackFetchError<TError>>(queryKey, fetcher, queryOptions);
}
