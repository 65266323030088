import { ReactQueryContainer } from '@retail/containers';
import { MpeAuthContextProvider, MpeAuthProvider } from '@retail/auth/data-access';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { RecoilRoot } from 'recoil';
import { Helmet } from 'react-helmet';
import { mgPrisConfig } from '@retail/mgpris/config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { mgPrisTheme } from '@shared/styles';
import { AppRoutes } from './AppRoutes';

const globalStyles = (
  <GlobalStyles
    styles={{
      code: {
        color: mgPrisTheme.palette.error.main,
        backgroundColor: mgPrisTheme.palette.grey['100'],
        padding: '4px 6px',
        borderRadius: '7px',
      },
    }}
  />
);

export function App() {
  const { env } = useMgPrisConfig();

  return (
    <ThemeProvider theme={mgPrisTheme}>
      {mgPrisConfig.clarity?.id && (
        <Helmet>
          <script type="text/javascript">
            {`(function(c, l, a, r, i, t, y) {
                 c[a] = c[a] || function() {(c[a].q = c[a].q || []).push(arguments)};
                 t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
                 y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
               })(window, document, "clarity", "script", "${mgPrisConfig.clarity.id}");`}
          </script>
        </Helmet>
      )}
      {globalStyles}
      <CssBaseline />
      <ReactQueryContainer showDevtools={env.includes('local')}>
        <RecoilRoot>
          <MpeAuthProvider>
            <MpeAuthContextProvider>
              <AppRoutes />
              <ToastContainer />
            </MpeAuthContextProvider>
          </MpeAuthProvider>
        </RecoilRoot>
      </ReactQueryContainer>
    </ThemeProvider>
  );
}

export default App;
