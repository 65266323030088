import { useAppTFunction } from '@retail/app/i18n';
import { useToast } from '@retail/hooks';
import {
  useFetchAssortmentItems,
  useUpdateAssortmentItems,
} from '@retail/my-assortment/data-access';
import { AssortmentItem, ItemsDTO } from '@retail/my-assortment/types';
import { Pagination } from '@shared/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

interface Props {
  assortmentId: number;
  selectedSupplier?: number;
  selectedProductGroup?: string;
  searchQuery?: string;
  pagination: Pagination;
  memberId: string;
}

export const useAssortmentItems = ({
  assortmentId,
  selectedSupplier,
  selectedProductGroup,
  searchQuery,
  pagination,
  memberId,
}: Props) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const t = useAppTFunction();

  const { data: itemsDTO } = useFetchAssortmentItems({
    assortmentId,
    supplierIds: selectedSupplier ? [selectedSupplier] : [],
    groupNumbers: selectedProductGroup ? [selectedProductGroup] : [],
    query: searchQuery,
    includeOwner: true,
    page: pagination.page,
    size: pagination.pageSize,
  });

  const totalItems = useMemo(() => itemsDTO?.totalCount || 0, [itemsDTO]);
  const itemsDto = useMemo(() => itemsDTO?.result || [], [itemsDTO]);
  const assortmentItems = itemsDto.map(AssortmentItem.fromDTO);

  const { mutateAsync: mutateUpdateItems, isLoading: updateItemsIsLoading } =
    useUpdateAssortmentItems({
      assortmentId,
      memberId,
    });

  const deleteItemsByDate = useCallback(
    async (itemIds: number[], date: string | undefined) => {
      // Using null as priority works as "deleting"
      const itemsWithUpdatedNullPriority: ItemsDTO[] = itemIds.map((checkedItemId) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const itemObject = assortmentItems.find(
          (assortmentItem) => checkedItemId === assortmentItem.id
        )!;
        return {
          mgSupplierId: itemObject.mgSupplierId,
          mgItemNumber: itemObject.mgItemNumber,
          priority: null,
          validFrom: date,
        };
      });

      return await mutateUpdateItems({
        body: itemsWithUpdatedNullPriority,
      })
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
          toast.success(t('assortment.deleteItems.success'));
        })
        .catch((error) => {
          console.error(error);
          toast.error(t('common.genericError'));
        });
    },
    [assortmentItems, mutateUpdateItems, queryClient, t, toast]
  );

  const updatePriority = useCallback(
    (items: ItemsDTO[]) =>
      mutateUpdateItems({
        body: items,
      })
        .then(() => {
          queryClient.invalidateQueries({ queryKey: ['mg-item'] });
          queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
        })
        .catch((e) => console.error(e)),
    [mutateUpdateItems, queryClient]
  );

  return {
    assortmentItems,
    totalItems,
    deleteItemsByDate,
    updatePriority,
    mutateUpdateItems,
    updateItemsIsLoading,
  };
};
