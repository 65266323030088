import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppBar, Divider, Stack, Toolbar, useTheme } from '@mui/material';
import { useLanguageSelector } from '@retail/app/hooks';
import { MgItemSearchAutocomplete } from '@retail/products/containers';
import { BrandSelect, BrandSelectOption, LanguageSwitch } from '@retail/components';
import { mgPrisRoutes } from '@retail/mgpris/config';
import { MGPrisLogo } from './MGPrisLogo';
import { useUserBrands } from '@retail/app/stores/selected-context';
import { mgprisHeaderHeight } from '@shared/styles';
import { AvailableOrgUnitsContainer } from '@retail/app/containers';

export function Header() {
  const { palette } = useTheme();
  const {
    i18n: { language },
  } = useTranslation();
  const { switchLanguage } = useLanguageSelector();
  const { availableBrands, selectedBrand, switchBrand } = useUserBrands();

  const brandOptions: BrandSelectOption[] = useMemo(
    () =>
      availableBrands.map(({ name, brandId }) => ({
        name,
        key: brandId,
      })),
    [availableBrands]
  );

  const changeBrand = useCallback(
    (brandKey: string) => {
      const newBrand = availableBrands.find(({ brandId }) => brandId === brandKey);
      if (newBrand) {
        switchBrand(newBrand);
      }
    },
    [availableBrands, switchBrand]
  );

  const navigate = useNavigate();
  const onMgItemSearchResultClick = useCallback(
    (mgItemId: number) => navigate(mgPrisRoutes.items.item.getFullLinkPath(`${mgItemId}`)),
    [navigate]
  );

  return (
    <AppBar
      position="fixed"
      sx={{ height: mgprisHeaderHeight, background: palette.gradient.primary }}
    >
      <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Stack direction="row" alignItems="center" alignSelf="stretch" paddingY={2}>
          <BrandSelect
            brands={brandOptions}
            selectedBrandKey={selectedBrand.brandId}
            onBrandSelect={changeBrand}
          />

          <Divider
            orientation="vertical"
            sx={{ borderColor: palette.background.default, marginX: 3 }}
          />

          <MgItemSearchAutocomplete
            onItemClick={onMgItemSearchResultClick}
            sx={{ width: '300px' }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" marginLeft={2} height="100%">
          <AvailableOrgUnitsContainer />
          <MGPrisLogo />
          <LanguageSwitch
            selectedLanguage={language}
            switchLanguage={switchLanguage}
            languages={['no', 'se']}
          />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
