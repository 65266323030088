import { useUserBrands } from '@retail/app/stores/selected-context';
import { withDialogSuspenseErrorBoundary } from '@retail/components';
import {
  AddFirstItemsToBaseAssortmentDialog,
  assortmentSpreadsheetValidatedAtom,
  assortmentSpreadsheetValidationAtom,
  isValidItem,
  useImportedAssortmentState,
  useResetImportedAssortmentState,
} from '@retail/my-assortment/components';
import {
  useFetchAssortmentItems,
  useFetchBrandAssortments,
  useMutateAddItemsToDraft,
  useMutateValidatePriorities,
} from '@retail/my-assortment/data-access';
import { AssortmentUploadValidation } from '@retail/my-assortment/types';
import { useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { getImportedItems } from '@retail/my-assortment/utils';

interface Props {
  open: boolean;
  onClose: () => void;
  departmentId: string;
  draftAssortmentId: number;
}

function WelcomeToMyAssortmentDialogContainer({
  open,
  onClose,
  departmentId,
  draftAssortmentId,
}: Props) {
  const queryClient = useQueryClient();

  const importState = useImportedAssortmentState();
  const items = getImportedItems(importState);
  const resetImportedAssortmentStates = useResetImportedAssortmentState();

  const [validationState, setValidationState] = useRecoilState(assortmentSpreadsheetValidationAtom);
  const [isValidated, setIsValidated] = useRecoilState(assortmentSpreadsheetValidatedAtom);

  const { selectedBrand } = useUserBrands();
  const { data: brandAssortmentsDTO } = useFetchBrandAssortments({
    brandId: selectedBrand.brandId,
  });
  const baseAssortment = brandAssortmentsDTO!.find((assortment) => assortment.type === 'BASE')!;

  const { data: brandAssortmentPageDto } = useFetchAssortmentItems({
    assortmentId: baseAssortment.id,
    page: 0,
    size: 100,
  });

  const { mutateAsync: validateItemsAsync, isLoading: loadingValidateItems } =
    useMutateValidatePriorities({
      departmentId,
      assortmentId: draftAssortmentId,
    });

  const { mutateAsync: addItemsAsync, isLoading: loadingAddItems } = useMutateAddItemsToDraft({
    memberId: departmentId,
    assortmentId: draftAssortmentId,
  });

  const onSubmitItems = useCallback(() => {
    const validItems = items.filter((item) => isValidItem(item, validationState));

    addItemsAsync({ body: validItems })
      .catch((err) => console.error(err))
      .then(() => {
        onClose();
        queryClient.invalidateQueries({ queryKey: ['assortmentItems'] });
      });
  }, [addItemsAsync, items, onClose, queryClient, validationState]);

  const onValidateItems = useCallback(async () => {
    validateItemsAsync({ body: items })
      .catch((err) => console.error(err))
      .then((response) => {
        const res = response as AssortmentUploadValidation;
        setValidationState(res);
      });
  }, [items, setValidationState, validateItemsAsync]);

  useEffect(() => {
    resetImportedAssortmentStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (importState.status !== 'none' && items.length > 0 && !isValidated) {
      onValidateItems();
      setIsValidated(true);
    }
  }, [importState.status, isValidated, items.length, onValidateItems, setIsValidated]);

  return (
    <AddFirstItemsToBaseAssortmentDialog
      open={open}
      onClose={onClose}
      baseAssortment={baseAssortment}
      nrOfItems={brandAssortmentPageDto!.totalCount}
      onSubmitItems={onSubmitItems}
      isLoadingAddItems={loadingValidateItems || loadingAddItems}
    />
  );
}

export default withDialogSuspenseErrorBoundary(WelcomeToMyAssortmentDialogContainer);
