import { useFetchPriceList } from '@retail/purchase-agreements/data-access';
import { ItemAgreement, PriceListPageResult } from '@retail/purchase-agreements/types';
import { usePagination } from '@shared/hooks';

interface Props {
  initPageSize: number;
  productGroupId?: string;
  query?: string;
  agreementId: number;
}

export const usePriceList = ({ productGroupId, agreementId, query, initPageSize }: Props) => {
  const { pageSize, page, setPageSize, setPage } = usePagination({
    initPageSize: initPageSize,
    initPage: 0,
  });

  const { data: priceListWithPaginationDTO } = useFetchPriceList(
    {
      agreementId: agreementId,
      groupNumbers: productGroupId ? [productGroupId] : undefined,
      page,
      size: pageSize,
      query,
    },
    {
      suspense: true,
    }
  );

  const items = priceListWithPaginationDTO!.result.map(ItemAgreement.fromDto);

  return {
    result: {
      totalItems: priceListWithPaginationDTO?.totalCount,
      items: items,
      page,
      pageSize,
    } as PriceListPageResult,
    setPage,
    setPageSize,
  };
};
