import React, { Fragment, useMemo } from 'react';
import { Brand, Department, Member } from '@retail/auth/types';
import { ChevronLeft } from '@mui/icons-material';
import { Box, ListSubheader, Menu, MenuItem, useTheme } from "@mui/material";
import { usePopupElement } from '@shared/hooks';
import { OrgUnitHeading } from './OrgUnitHeading';
import { useAppTFunction } from '@retail/app/i18n';

interface Props {
  brand?: Brand;
  members: Member[];
  departments: Department[];
  selectedBrand?: Brand;
  selectedMember?: Member;
  selectedDepartment?: Department;
  selectBrand: (brand: Brand) => void;
  selectMember: (member: Member) => void;
  selectDepartment: (department: Department) => void;
}

export const OrgUnitSelect: React.FC<Props> = ({
  brand,
  members,
  departments,
  selectedBrand,
  selectedMember,
  selectedDepartment,
  selectBrand,
  selectMember,
  selectDepartment,
}) => {
  const { palette } = useTheme();
  const { menuElementProps, triggerElementProps } = usePopupElement();
  const selectedOrgUnitName = useMemo(() => {
    if (selectedBrand) {
      return selectedBrand.name;
    }
    if (selectedMember) {
      return selectedMember.name;
    }
    return selectedDepartment?.name || '';
  }, [selectedBrand, selectedDepartment, selectedMember]);
  const t = useAppTFunction();

  return (
    <Fragment>
      <OrgUnitHeading
        name={selectedOrgUnitName}
        sx={{ cursor: 'pointer' }}
        endIcon={
          <ChevronLeft
            sx={{
              transform: menuElementProps.open ? 'rotate(90deg)' : 'rotate(-90deg)',
              transition: 'transform 0.2s ease-in-out',
              color: palette.common.black,
            }}
          />
        }
        {...triggerElementProps}
      />
      {
        <Menu {...menuElementProps} elevation={0}>
          {brand && (
            <Box pt={2}>
              <ListSubheader>{t('app.orgUnit.brand.singular')}</ListSubheader>
              <MenuItem
                key={brand.brandId}
                value={brand.brandId}
                onClick={() => selectBrand(brand)}
                selected={!!selectedBrand}
              >
                {brand.name}
              </MenuItem>
            </Box>
          )}
          {members.length > 0 && (
            <Box pt={2}>
              <ListSubheader>{t('app.orgUnit.member.plural')}</ListSubheader>
              {members.map((member) => (
                <MenuItem
                  key={member.memberId}
                  value={member.memberId}
                  onClick={() => selectMember(member)}
                  selected={member.memberId === selectedMember?.memberId}
                >
                  {member?.name}
                </MenuItem>
              ))}
            </Box>
          )}
          {departments.length > 0 && (
            <Box pt={2}>
              <ListSubheader>{t('app.orgUnit.department.plural')}</ListSubheader>
              {departments.map((department) => (
                <MenuItem
                  key={department.departmentId}
                  value={department.departmentId}
                  onClick={() => selectDepartment(department)}
                  selected={department.departmentId === selectedDepartment?.departmentId}
                >
                  {department?.name}
                </MenuItem>
              ))}
            </Box>
          )}
        </Menu>
      }
    </Fragment>
  );
};
