import { QueryOptions } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from '@retail/auth/data-access';
import { useMPEFetch } from '@retail/app/hooks';
import { urlConstant } from '@retail/utils';
import { SupplierMinimalDTO } from '@retail/suppliers/types';
import { buildUrl } from '@shared/utils';

export const suppliersApiPath = '/suppliers/';

export interface SuppliersSearchParams {
  assortmentId?: number;
  inAssortment?: boolean;
  withPurchaseAgreements?: boolean;
  withStandardAgreement?: boolean;
}

export function useFetchAllSuppliers(searchParams?: SuppliersSearchParams, queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();
  return useMPEFetch<SupplierMinimalDTO[]>({
    method: 'GET',
    queryKey: ['suppliers', searchParams],
    token,
    url: buildUrl({
      base: mpe.url,
      route: `${urlConstant}${suppliersApiPath}`,
      searchParams: {
        ...searchParams,
      },
    }),
    ...queryOptions,
  });
}
