import { FormHelperText, FormLabel, SelectChangeEvent, Stack } from '@mui/material';
import { ConditionDatesForm, LevelSelectForm } from '@retail/calculus/components';
import { MgSupplierItemSearchResult, ProductGroup } from '@retail/products/types';
import { ActivateAgreementSchema, AgreementSelect } from '@retail/purchase-agreements/components';
import { PriceType, PurchaseAgreement } from '@retail/purchase-agreements/types';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { SelectItemAgreements } from './SelectItemAgreements';
import { SuspenseWithSentryErrorBoundary } from '@retail/components';
import { usePurchaseAgreementsTFunction } from '@retail/purchase-agreements/i18n';
import { ErrorOutlineRounded } from '@mui/icons-material';

interface Props {
  productGroups: ProductGroup[];
  mgSupplierItems?: MgSupplierItemSearchResult[];
  setMgSupplierItemSearch: Dispatch<SetStateAction<string>>;
  isLoadingFetchItems: boolean;
  agreements: PurchaseAgreement[];
  supplierId: number;
}

export function ActivateAgreementForm({
  productGroups,
  mgSupplierItems,
  setMgSupplierItemSearch,
  isLoadingFetchItems,
  agreements,
  supplierId,
}: Props) {
  const t = usePurchaseAgreementsTFunction();

  const {
    control,
    formState: { errors },
    resetField,
  } = useFormContext<ActivateAgreementSchema>();
  const levelController = useController({ name: 'level', control });
  const productGroupController = useController({ name: 'productGroup', control });
  const itemController = useController({ name: 'item', control });
  const agreementIdController = useController({ name: 'agreementId', control });
  const priceTypeController = useController({ name: 'priceType', control });
  const setAgreementValue = useCallback(
    (agreementId: number, priceType?: PriceType) => {
      agreementIdController.field.onChange(agreementId);
      priceTypeController.field.onChange(priceType);
    },
    [agreementIdController.field, priceTypeController.field]
  );

  const handleLevelSelectChange = (value: SelectChangeEvent<unknown>) => {
    resetField('item');
    resetField('productGroup');
    resetField('fromDate');
    resetField('agreementId');
    resetField('priceType');
    levelController.field.onChange(value);
  };
  return (
    <form>
      <Stack pb={1}>
        <LevelSelectForm
          productGroups={productGroups}
          mgSupplierItems={mgSupplierItems}
          isLoadingFetchItems={isLoadingFetchItems}
          handleLevelSelectChange={handleLevelSelectChange}
          setMgSupplierItemSearch={setMgSupplierItemSearch}
        />

        {(levelController.field.value === 'supplier' ||
          productGroupController.field.value ||
          !!itemController.field.value) && (
          <>
            <ConditionDatesForm excludeToDate />
            <FormLabel sx={{ pt: 2 }} error={!!errors.agreementId} required>
              {t('purchaseAgreements.agreement.chooseAgreement')}
            </FormLabel>
          </>
        )}

        {!!itemController.field.value && (
          <SuspenseWithSentryErrorBoundary>
            <SelectItemAgreements
              supplierId={supplierId}
              mgItemNumber={itemController.field.value.mgItemNumber}
              selectedAgreement={Number(agreementIdController.field.value)}
              selectedPriceType={priceTypeController.field.value as PriceType}
              selectAgreement={setAgreementValue}
            />
          </SuspenseWithSentryErrorBoundary>
        )}
        {(levelController.field.value === 'supplier' || !!productGroupController.field.value) && (
          <AgreementSelect
            agreements={agreements}
            selectedAgreement={Number(agreementIdController.field.value)}
            selectAgreement={setAgreementValue}
          />
        )}
        {errors.agreementId && (
          <Stack direction="row" spacing={1} pt={1} flexWrap="nowrap" alignItems="center">
            <ErrorOutlineRounded color="warning" fontSize="small" />
            <FormHelperText sx={{ color: ({ palette }) => palette.warning.main }}>
              {t('purchaseAgreements.agreement.actions.chooseAgreement')}
            </FormHelperText>
          </Stack>
        )}
      </Stack>
    </form>
  );
}
