import { Box } from '@mui/material';
import { TextButton } from '@retail/components';
import { useProductsTFunction } from '@retail/products/i18n';

interface Props<T> {
  suppliers: T[];
  selectedSupplier: T | null;
  selectSupplier: (supplier: T) => void;
  getSupplierName: (supplier: T) => string | undefined;
  getSupplierId: (supplier: T) => string;
}

export const SupplierTabSelect = <T,>({
  suppliers,
  selectedSupplier,
  selectSupplier,
  getSupplierName,
  getSupplierId,
}: Props<T>) => {
  const t = useProductsTFunction();

  const onChange = (id: string) => {
    selectSupplier(suppliers.find((supplier) => getSupplierId(supplier) === id) as T);
  };

  return (
    <Box>
      {suppliers.map((supplier, index) => {
        const id = getSupplierId(supplier);

        return (
          <TextButton
            key={id}
            handleClick={() => onChange(id)}
            isBold={getSupplierId(selectedSupplier as T) === id}
            hasMarginRight={index < suppliers.length - 1}
          >
            {getSupplierName(supplier) || t('products.supplier.unknown')}
          </TextButton>
        );
      })}
    </Box>
  );
};
