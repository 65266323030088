import { useFetchMgAssortment } from '@retail/monitoring-assortment/data-access';
import { MgAssortment } from '@retail/monitoring-assortment/types';
import { usePagination } from '@shared/hooks';
import { Competitor } from '@retail/retailer/types';

interface Props {
  assortmentId: number;
  competitors: Competitor[];
}

export const useMgAssortment = ({ assortmentId, competitors }: Props) => {
  const { pageSize, page, setPageSize, setPage } = usePagination({
    initPageSize: 100,
    initPage: 0,
  });

  const { data, isFetching, refetch } = useFetchMgAssortment(
    {
      assortmentId,
      competitorStoreIds: competitors.map((competitor) => competitor.id),
      page,
      pageSize,
    },
    {
      suspense: true,
    }
  );

  const assortmentPageDto = data!;
  const assortment = MgAssortment.fromDto(assortmentPageDto.result);

  return {
    result: {
      totalItems: assortmentPageDto.totalCount,
      assortment,
      page,
      pageSize,
    },
    refetch,
    isFetching,
    setPageSize,
    setPage,
  };
};
