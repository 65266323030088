import { Box, FormHelperText, FormLabel, Stack, Typography } from '@mui/material';
import { useMarkupTFunction } from '@retail/markup/i18n';
import { MarkupCondition } from '@retail/markup/types';
import { TextInputField } from '@shared/components';
import { CreateMarkupSchema } from './createMarkupSchema';
import { useController, useFormContext } from 'react-hook-form';
import { ErrorOutlineRounded, Percent } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { calculateDGFromSalesFactor, calculateSalesFactorFromDG } from '@retail/utils';
import { CurrentActiveMarkup } from './CurrentActiveMarkup';

interface Props {
  markupCondition: MarkupCondition;
}

export function CreateMarkup({ markupCondition }: Props) {
  const t = useMarkupTFunction();
  const { control } = useFormContext<CreateMarkupSchema>();

  const fromDateController = useController({ name: 'fromDate', control });
  const toDateController = useController({ name: 'toDate', control });
  const nameController = useController({ name: 'markupName', control });
  const salesCoverageController = useController({ name: 'salesCoverage', control });
  const salesFactorController = useController({ name: 'salesFactor', control });

  return (
    <Stack flexDirection="row" gap={4}>
      <CurrentActiveMarkup markupCondition={markupCondition} />

      <Stack gap={2}>
        <Typography fontWeight={600}>{t('markup.create.newMarkup')}</Typography>

        <Stack width={342} gap={2}>
          <TextInputField
            {...nameController.field}
            required
            fullWidth
            labelWidth={142}
            labelDirection="row"
            margin="none"
            label={t('markup.markupName')}
            size="small"
            placeholder={t('markup.markupName')}
          />

          <TextInputField
            {...salesCoverageController.field}
            error={salesCoverageController.fieldState.error?.message}
            required
            fullWidth
            labelWidth={142}
            labelDirection="row"
            margin="none"
            label={t('markup.salesCoverage')}
            size="small"
            placeholder={t('markup.salesCoverage')}
            endAdornment={<Percent />}
            onChange={(e) => {
              const value = e.target.value;
              if (Number(value)) {
                salesFactorController.field.onChange(
                  calculateSalesFactorFromDG(Number(value)).toFixed(2)
                );
              }
              salesCoverageController.field.onChange(value);
            }}
          />

          <TextInputField
            {...salesFactorController.field}
            error={salesFactorController.fieldState.error?.message}
            required
            fullWidth
            labelWidth={142}
            labelDirection="row"
            value={salesFactorController.field.value}
            onChange={(e) => {
              const value = e.target.value;
              if (Number(value)) {
                salesCoverageController.field.onChange(
                  calculateDGFromSalesFactor(Number(value)).toFixed(2)
                );
              }
              salesFactorController.field.onChange(value.replace(',', '.'));
            }}
            margin="none"
            label={t('markup.salesFactor')}
            size="small"
            placeholder={t('markup.salesFactor')}
          />
        </Stack>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box display="flex" alignItems="center">
            <FormLabel error={!!fromDateController.fieldState.error} sx={{ width: 150 }} required>
              {t('markup.fromDate')}
            </FormLabel>

            <Stack gap={1} maxWidth="210px" overflow="hidden">
              <DatePicker
                {...fromDateController.field}
                minDate={new Date()}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: { sx: { ...inputSx, width: 192 } },
                }}
              />
              {!!fromDateController.fieldState.error && (
                <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                  <ErrorOutlineRounded color="warning" fontSize="small" />
                  <FormHelperText sx={{ color: ({ palette }) => palette.warning.main }}>
                    {fromDateController.fieldState.error.message}
                  </FormHelperText>
                </Stack>
              )}
            </Stack>
          </Box>

          <Box display="flex" alignItems="center">
            <FormLabel sx={{ width: 150 }}>{t('markup.toDate')}</FormLabel>
            <DatePicker
              {...toDateController.field}
              minDate={new Date()}
              format="dd/MM/yyyy"
              slotProps={{
                field: { clearable: true },
                textField: {
                  sx: { ...inputSx, width: 192 },
                  helperText: undefined,
                },
              }}
            />
          </Box>
        </LocalizationProvider>
      </Stack>
    </Stack>
  );
}

export const inputSx = {
  '& .MuiTextField-root': {
    minWidth: 192,
  },

  '& .MuiInputBase-root': {
    height: 40,
    width: 192,
    minWidth: 192,
    borderRadius: 2,
  },
};
