import { Stack, Typography, alpha, useTheme } from '@mui/material';
import { Chip } from '@shared/components';

interface Props {
  name: string;
  value?: string | number;
  titleWidth?: number;
  chipWidth?: number;
}

export function CreateMarkupDialogRow({ name, value, titleWidth, chipWidth }: Props) {
  const { palette } = useTheme();

  return (
    <Stack flexDirection="row" gap={1} alignItems="center">
      <Typography width={titleWidth || 125} fontWeight={600}>
        {name}
      </Typography>

      <Chip
        backgroundColor={alpha(palette.info.main, 0.4)}
        sx={{ minWidth: 120, width: chipWidth, height: 40 }}
        label={value ?? '-'}
      />
    </Stack>
  );
}
