import { Stack, Typography, alpha, useTheme } from '@mui/material';
import { Chip } from '@shared/components';
import { Dispatch, SetStateAction } from 'react';
import { DialogLayout, ToggleSwitch } from '@retail/components';
import { MgSupplierItemConditionsPageResult } from '@retail/products/types';
import { useCalculusTFunction } from '@retail/calculus/i18n';
import { AffectedItemsTable } from './AffectedItemsTable';

interface Props {
  open: boolean;
  items: MgSupplierItemConditionsPageResult;
  onlyItemsInAssortment: boolean;
  onClose: () => void;
  toggleOnlyItemsInAssortment: () => void;
  updatePage: Dispatch<SetStateAction<number>>;
  updatePageSize: Dispatch<SetStateAction<number>>;
}

export function AffectedItemsDialog({
  open,
  items,
  onlyItemsInAssortment,
  onClose,
  toggleOnlyItemsInAssortment,
  updatePage,
  updatePageSize,
}: Props) {
  const t = useCalculusTFunction();
  const { palette } = useTheme();
  return (
    <DialogLayout
      open={open}
      onClose={onClose}
      width="lg"
      header={t('calculus.itemsAffected.priceList')}
    >
      <Stack flexDirection="row" py={2} px={3} justifyContent="space-between">
        <Stack flexDirection="row" gap={1}>
          <Typography fontWeight={700}>{t('calculus.itemsAffected.affectedItems')}:</Typography>
          <Chip
            size="small"
            backgroundColor={alpha(palette.info.main, 0.6)}
            label={items.totalItems}
          />
        </Stack>
        <ToggleSwitch
          toggle={onlyItemsInAssortment}
          setToggle={toggleOnlyItemsInAssortment}
          text={t('calculus.itemsAffected.onlyInAssortment')}
        />
      </Stack>
      <AffectedItemsTable {...items} updatePage={updatePage} updatePageSize={updatePageSize} />
    </DialogLayout>
  );
}
