import { MgItem, MgSupplierItem } from '@retail/products/types';
import { useProductsTFunction } from '@retail/products/i18n';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { DiffFile } from 'diff2html/lib/types';
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
import { CopyToClipboardButton } from '@retail/components';

interface Props {
  selectedSupplierItem: MgSupplierItem;
  comparisonSupplier: MgSupplierItem | null;
  supplierItems: MgItem['supplierItems'];
  setComparisonSupplier: Dispatch<SetStateAction<MgSupplierItem | null>>;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Diff = require('diff');

export const Comparison = ({
  selectedSupplierItem,
  supplierItems,
  comparisonSupplier,
  setComparisonSupplier,
}: Props) => {
  const t = useProductsTFunction();

  const [diff, setDiff] = useState<string | DiffFile[]>([]);

  const updateDiff = useCallback(() => {
    const selectedSupplierItemJson = JSON.stringify(selectedSupplierItem, null, 2);

    const comparisonSupplierJson = comparisonSupplier
      ? JSON.stringify(comparisonSupplier, null, 2)
      : '{}';

    const newDiff = Diff.createTwoFilesPatch(
      selectedSupplierItem?.mgSupplierName ?? '',
      comparisonSupplier?.mgSupplierName ?? '',
      selectedSupplierItemJson,
      comparisonSupplierJson
    );

    setDiff(newDiff);
  }, [selectedSupplierItem, comparisonSupplier]);

  const outputHtml = Diff2Html.html(diff, {
    outputFormat: 'line-by-line',
    drawFileList: false,
    matching: 'lines',
  });

  useEffect(() => {
    updateDiff();
  }, [selectedSupplierItem, comparisonSupplier, updateDiff]);

  return (
    <Stack>
      <Box display="flex" justifyContent="space-between">
        <Autocomplete
          size="small"
          disablePortal
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t('products.mgItem.json.compare')} ${t(
                'products.supplier.name'
              ).toLowerCase()}`}
            />
          )}
          noOptionsText={t('products.mgItem.json.noSuppliers')}
          options={supplierItems}
          value={comparisonSupplier}
          onChange={(_, val) => setComparisonSupplier(val)}
          getOptionLabel={(option) => option.mgSupplierName}
          renderOption={(props, { mgSupplierName }) => (
            <Box component="li" {...props} key={mgSupplierName}>
              {mgSupplierName}
            </Box>
          )}
          isOptionEqualToValue={(option, value) =>
            option.mgSupplierItemId === value.mgSupplierItemId
          }
          sx={{
            width: 200,
          }}
        />

        <CopyToClipboardButton
          value={JSON.stringify(selectedSupplierItem)}
          disabled={!!comparisonSupplier}
        />
      </Box>

      <Box
        dangerouslySetInnerHTML={{ __html: outputHtml }}
        sx={{ mt: 2, maxWidth: 1700, '.d2h-tag': { display: 'none' }, '> tbody': { fontSize: 12 } }}
      />

      {selectedSupplierItem.id === comparisonSupplier?.id && (
        <Box display="flex" width={1700} justifyContent="center" alignItems="center" height={200}>
          <Typography
            variant="h4"
            sx={{
              color: (theme) => theme.palette.grey[200],
            }}
          >
            {t('products.mgItem.json.identicalSuppliers')}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
