import { EtimKeys } from '@retail/products/types';

export const etimKeys: EtimKeys[] = [
  'buildingProductDeclarationUrl',
  'informationSheetUrl',
  'safetyDataSheetUrl',
  'userManualUrl',
  'declarationOfPerformanceUrl',
  'assemblyInstructionsUrl',
  // 'spaceImageFrontUrl',
  // 'spaceImageAboveUrl',
  // 'spaceImageLeftUrl',
  'threeDimensionalImageUrl',
  'explodedViewImageUrl',
  'lineSketchDrawingUrl',
];
