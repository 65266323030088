import { useFetchProductGroupsInAssortment } from '@retail/my-assortment/data-access';
import { OverGroup, ProductGroup } from '@retail/products/types';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Supplier } from '@retail/suppliers/types';
import { useFetchAllSuppliers } from '@retail/products/data-access';

const productGroupSearchParamKey = 'productGroupNr';
const supplierSearchParamKey = 'supplierId';

interface Props {
  assortmentId: number;
}

export const useAssortmentFilters = ({ assortmentId }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: supplierDtos } = useFetchAllSuppliers({
    assortmentId,
    inAssortment: true,
  });
  const suppliers = supplierDtos ? supplierDtos.map(Supplier.fromMinimalDTO) : [];

  const searchParamsSupplier =
    suppliers.find(
      (supplier) => supplier.mgSupplierId === Number(searchParams.get(supplierSearchParamKey))
    ) ?? null;

  const [selectedSupplier, selectSupplier] = useState<Supplier | null>(searchParamsSupplier);

  const { data: productGroupDtos } = useFetchProductGroupsInAssortment({
    assortmentId,
  });
  const productGroups = productGroupDtos ? productGroupDtos.map(OverGroup.fromDto) : [];
  const flattenedProductGroups = OverGroup.flatten(productGroups);

  const searchParamsProductGroup =
    flattenedProductGroups.find(
      (productGroup) => productGroup.groupNumber === searchParams.get(productGroupSearchParamKey)
    ) ?? null;

  const [selectedProductGroup, selectProductGroup] = useState<ProductGroup | null>(
    searchParamsProductGroup
  );

  useEffect(
    () =>
      setSearchParams(() => {
        if (selectedSupplier) {
          searchParams.set(supplierSearchParamKey, selectedSupplier.mgSupplierId + '');
        } else {
          searchParams.delete(supplierSearchParamKey);
        }
        if (selectedProductGroup) {
          searchParams.set(productGroupSearchParamKey, selectedProductGroup.groupNumber);
        } else {
          searchParams.delete(productGroupSearchParamKey);
        }

        return searchParams;
      }),
    [searchParams, selectedProductGroup, selectedSupplier, setSearchParams]
  );

  return {
    suppliers,
    selectedSupplier,
    selectSupplier,
    productGroups: flattenedProductGroups,
    selectedProductGroup,
    selectProductGroup,
  };
};
