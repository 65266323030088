import { Stack, Switch, Typography, useTheme } from '@mui/material';

interface SwitchProps {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
  text: string;
}

export const ToggleSwitch = ({ toggle, setToggle, text }: SwitchProps) => {
  const { palette } = useTheme();
  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography
        component="label"
        htmlFor="switch"
        color={palette.grey.A400}
        pr={1}
        variant="body2"
        fontWeight={500}
        noWrap
      >
        {text}
      </Typography>
      <Switch id="switch" value={toggle} checked={toggle} onClick={() => setToggle(!toggle)} />
    </Stack>
  );
};
