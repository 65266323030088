import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { dataGridBoldClass } from '@shared/styles';
import { useMemo } from 'react';
import { usePurchaseAgreementsTranslation } from '@retail/purchase-agreements/i18n';
import dayjs from 'dayjs';
import { StackedTextCell } from '@retail/components';
import { getBindingValue } from '@retail/calculus/utils';
import { Supplier } from '@retail/suppliers/types';
import {  Link, Stack, Typography } from '@mui/material';
import {  MGPrisIcon } from '@shared/custom-icons';
import { AssortmentType, HierarchyLevels, PurchaseCondition } from '@retail/calculus/types';
import { LevelChip } from '@retail/markup/components';
import { PurchaseConditionsTableActions } from './PurchaseConditionsTableActions';

interface Props {
  supplier: Supplier;
  conditions: PurchaseCondition[];
  handleActivate: (agreement: PurchaseCondition) => void;
  handleDeactivate: (agreement: PurchaseCondition) => void;
  handleEdit: (agreement: PurchaseCondition) => void;
  handleDelete: (conditionId: number) => void;
  openPriceListModal: (agreement: number, productGroupId: string) => void;
}

const baseColumnProps: Partial<GridColDef> = {
  width: 150,
  sortable: false,
  headerName: '',
  headerAlign: 'center',
  align: 'center',
};

export function ConditionsTable({
  supplier,
  conditions,
  handleActivate,
  handleDeactivate,
  handleEdit,
  handleDelete,
  openPriceListModal,
}: Props) {
  const { t } = usePurchaseAgreementsTranslation();

  const hasActiveSupplierAgreement = useMemo(
    () => conditions.some((condition) => condition.bindingType === HierarchyLevels.supplier),
    [conditions]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumnProps,
        field: 'level',
        headerName: t('purchaseAgreements.agreement.columns.level'),
        headerAlign: 'left',
        align: 'left',
        width: 180,
        renderCell: ({ row }) => {
          const label = row.bindingType
            ? getBindingValue(row.bindingType)
            : t('purchaseAgreements.availableAgreement');
          return (
            <LevelChip
              height={row.standardAgreement ? 45 : undefined}
              level={row.bindingType ?? HierarchyLevels.supplier}
              label={
                row.standardAgreement && !row.active ? (
                  <Stack alignItems="center">
                    {label}
                    <Typography variant="body3" justifyContent="center">
                      <Stack flexDirection="row" gap={0.5}>
                        <MGPrisIcon fontSize="small" />
                        {t('purchaseAgreements.agreement.columns.standardAgreement')}
                      </Stack>
                    </Typography>
                  </Stack>
                ) : (
                  label
                )
              }
              active={
                row.assortmentType === AssortmentType.BASE ||
                (row.standardAgreement && !hasActiveSupplierAgreement)
              }
            />
          );
        },
      },
      {
        ...baseColumnProps,
        width: 240,
        field: 'name',
        cellClassName: () => dataGridBoldClass,
        renderCell: ({ row }) => {
          const name =
            row.bindingType === HierarchyLevels.supplier
              ? supplier.name
              : row.productGroupName ?? row.primaryText;
          const id =
            row.bindingType === HierarchyLevels.supplier
              ? supplier.mgSupplierId
              : row.productGroup ?? row.mgItemNumber;
          return <StackedTextCell topLine={name} bottomLine={id} />;
        },
        headerName: t('purchaseAgreements.agreement.columns.levelOrItemName'),
        headerAlign: 'left',
        align: 'left',
      },

      {
        ...baseColumnProps,
        field: 'agreementId',
        cellClassName: () => dataGridBoldClass,
        headerName: t('purchaseAgreements.agreement.columns.agreementId'),
        renderCell: ({ row }) => row.agreementId,
      },
      {
        ...baseColumnProps,
        width: 180,
        field: 'agreementText',
        cellClassName: () => dataGridBoldClass,
        headerName: t('purchaseAgreements.agreement.columns.agreementText'),
        renderCell: ({ row }) => row.agreementText,
      },
      {
        ...baseColumnProps,
        field: 'totalItems',
        headerName: t('purchaseAgreements.agreement.columns.priceList'),
        renderCell: ({ row }) => (
          <Link
            component="button"
            onClick={() => openPriceListModal(row.agreementId, row.productGroup)}
            fontWeight={600}
          >
            {t('purchaseAgreements.priceList.lookUpPrices')}
          </Link>
        ),
      },
      {
        ...baseColumnProps,
        field: 'validFrom',
        headerName: t('purchaseAgreements.agreement.columns.fromDate'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '-'),
      },
      {
        ...baseColumnProps,
        field: 'validTo',
        headerName: t('purchaseAgreements.agreement.columns.toDate'),
        renderCell: ({ value }) => (value ? dayjs(value).format('ll') : '->'),
      },
      {
        ...baseColumnProps,
        field: 'actions',
        width: 80,
        renderCell: ({ row }) => (
          <PurchaseConditionsTableActions
            handleActivate={!row.id ? () => handleActivate(row) : undefined}
            handleDeactivate={
              row.id && row.assortmentType !== AssortmentType.DRAFT
                ? () => handleDeactivate(row)
                : undefined
            }
            handleEdit={row.id ? () => handleEdit(row) : undefined}
            handleDelete={
              row.assortmentType === AssortmentType.DRAFT ? () => handleDelete(row.id) : undefined
            }
          />
        ),
      },
    ],
    [
      t,
      hasActiveSupplierAgreement,
      supplier.name,
      supplier.mgSupplierId,
      openPriceListModal,
      handleActivate,
      handleDeactivate,
      handleEdit,
      handleDelete,
    ]
  );

  return (
    <DataGridPro
      autoHeight
      getRowId={(row) => `${row.agreementId}${row.id}`}
      columns={columns}
      rows={conditions}
      rowHeight={70}
      disableSelectionOnClick
      disableColumnMenu
      disableColumnResize
      disableColumnReorder
      initialState={{
        pinnedColumns: { right: ['actions'] },
      }}
      hideFooter
    />
  );
}
