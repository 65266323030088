import * as yup from 'yup';
import { ValidationError } from '@retail/form/validation';
import { AssortmentMessageKey } from '@retail/monitoring-assortment/i18n';

interface ExportFormMeta {
  isAnyFilterActive: boolean;
}

export enum YesNo {
  yes = 'yes',
  no = 'no',
}

export interface ExportForm {
  includeVAT: YesNo;
  includeVariantPrices: YesNo;
  onlyFilteredProducts: YesNo;
  meta: ExportFormMeta;
}

type ProductValidationError = ValidationError<AssortmentMessageKey>;

const includeVatError: ProductValidationError = {
  key: 'assortment.export.form.required',
};

const includeVariantPricesError: ProductValidationError = {
  key: 'assortment.export.form.required',
};

const onlyFilteredProductsError: ProductValidationError = {
  key: 'assortment.export.form.required',
};

const yesNoType = yup.mixed().oneOf(Object.values(YesNo));
export const exportSchema = yup.object().shape({
  includeVAT: yesNoType.required({ message: includeVatError }),
  includeVariantPrices: yesNoType.required({ message: includeVariantPricesError }),
  onlyFilteredProducts: yesNoType.when('meta', {
    is: ({ isAnyFilterActive }: ExportFormMeta) => isAnyFilterActive,
    then: yesNoType.required({ message: onlyFilteredProductsError }),
  }),
  isAnyFilterActive: yup.boolean(),
});
