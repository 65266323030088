import { Box, Stack, Tooltip } from '@mui/material';
import { useAssortmentTFunction } from '@retail/monitoring-assortment/i18n';
import { Image } from '@retail/components';

interface Props {
  selectedBrandLogo: string;
}

export function CentralAssortmentLogo({ selectedBrandLogo }: Props) {
  const t = useAssortmentTFunction();
  return (
    <Tooltip
      title={t('assortment.assortmentsOverview.centralAssortmentDescription')}
      arrow
      placement="right"
    >
      <Box display="flex" flexDirection="column" alignItems="end" mb={0.5}>
        <Stack
          mb={-0.5}
          zIndex={0}
          borderRadius={({ shape }) => shape.borderRadius * 6}
          height={10}
          width={10}
          bgcolor={({ palette }) => palette.warning[900]}
          sx={{ outline: '2px solid white' }}
        />
        <Image src={selectedBrandLogo} height={24} width={24} />
      </Box>
    </Tooltip>
  );
}
