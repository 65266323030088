import { MgItemPackage, MpeMgSupplierItemPackage } from '@retail/products/types';

export const desiredPackagingTypeOrder: MpeMgSupplierItemPackage['type'][] = [
  'PRIMARY',
  'SECONDARY',
  'TERTIARY',
  'BASE_PRICE',
  'PRICE_COMPARISON',
  'ADDITIONAL_GTIN',
];
export const desiredPackagingOrder = ['PRIMARY', 'SECONDARY', 'TERTIARY', 'PRICE_COMPARISON'];

export const sortSupplierItemPackageType = (
  a: MpeMgSupplierItemPackage,
  b: MpeMgSupplierItemPackage
) => desiredPackagingTypeOrder.indexOf(a.type) - desiredPackagingTypeOrder.indexOf(b.type);

export const sortMgItemPackages = (a: [string, MgItemPackage[]], b: [string, MgItemPackage[]]) => {
  const indexA = desiredPackagingTypeOrder.indexOf(a[0]);
  const indexB = desiredPackagingTypeOrder.indexOf(b[0]);

  // If a type is not found, it should be sorted to the end
  const orderA = indexA !== -1 ? indexA : desiredPackagingTypeOrder.length;
  const orderB = indexB !== -1 ? indexB : desiredPackagingTypeOrder.length;

  return orderA - orderB;
};
