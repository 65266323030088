import { QueryOptions, useFetch } from '@shared/fetch-utils';
import { useMgPrisConfig } from '@retail/app/stores/app-config';
import { useMpeAuthContext } from './AuthContext';
import { UserOrgUnitsDTO } from './dto';
import { urlConstant } from '@retail/utils';
import { brandsRoute } from './brandsApi';

export const myOrgUnitsRoute = '/my-org-units';

export function useFetchMyOrgUnitsApi(brandId: string, queryOptions: QueryOptions = {}) {
  const { mpe } = useMgPrisConfig();
  const { token } = useMpeAuthContext();

  return useFetch<UserOrgUnitsDTO>({
    method: 'GET',
    queryKey: ['my-org-units', brandId, token],
    url: `${mpe.url}${urlConstant}${brandsRoute}${brandId}${myOrgUnitsRoute}`,
    token,
    ...queryOptions,
  });
}
