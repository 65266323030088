import { FilterList } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { OutlinedMgButton } from '@retail/components';
import { FiltersPopover, SelectedFilterChip } from '@retail/my-assortment/components';
import { useMyAssortmentTFunction } from '@retail/my-assortment/i18n';
import { MgSupplierItemSearchField } from '@retail/products/components';
import { ProductGroup } from '@retail/products/types';
import { usePopupElement } from '@shared/hooks';
import { useCallback, useState } from 'react';
import { Supplier } from '@retail/suppliers/types';

interface Props {
  selectedSupplier: Supplier | null;
  selectedProductGroup: ProductGroup | null;
  suppliers: Supplier[];
  productGroups: ProductGroup[];
  selectSupplier: (supplier: Supplier | null) => void;
  selectProductGroup: (productGroup: ProductGroup | null) => void;
  setSearchQuery: (query: string) => void;
}

export const ItemFilters = ({
  selectedSupplier,
  selectedProductGroup,
  suppliers,
  productGroups,
  selectSupplier,
  selectProductGroup,
  setSearchQuery,
}: Props) => {
  const t = useMyAssortmentTFunction();
  const { triggerElementProps, menuElementProps } = usePopupElement();

  const [currentSelectedSupplier, setCurrentSelectedSupplier] = useState<Supplier | null>(
    selectedSupplier
  );
  const [currentSelectedProductGroup, setCurrentSelectedProductGroup] =
    useState<ProductGroup | null>(selectedProductGroup);

  const resetFilters = useCallback(() => {
    menuElementProps.onClose();
    selectSupplier(null);
    selectProductGroup(null);
    setCurrentSelectedSupplier(null);
    setCurrentSelectedProductGroup(null);
  }, [menuElementProps, selectProductGroup, selectSupplier]);

  const saveFilters = useCallback(() => {
    selectSupplier(currentSelectedSupplier);
    selectProductGroup(currentSelectedProductGroup);
    menuElementProps.onClose();
  }, [
    currentSelectedProductGroup,
    currentSelectedSupplier,
    menuElementProps,
    selectProductGroup,
    selectSupplier,
  ]);

  return (
    <Stack gap={2}>
      <Stack flexDirection="row" gap={1}>
        <MgSupplierItemSearchField
          isDisabled={false}
          includeLabel={false}
          size="small"
          onSubmitSearch={setSearchQuery}
        />
        <OutlinedMgButton
          {...triggerElementProps}
          size="small"
          color="primary"
          endIcon={<FilterList />}
        >
          {t('myAssortment.filters.allFilters')}
        </OutlinedMgButton>
      </Stack>
      <Stack flexDirection="row" gap={1}>
        {selectedSupplier && (
          <Box pb={1}>
            <SelectedFilterChip
              label={
                suppliers.find(
                  (supplier) => selectedSupplier.mgSupplierId === supplier.mgSupplierId
                )?.name ?? ''
              }
              onClick={() => {
                selectSupplier(null);
                setCurrentSelectedSupplier(null);
              }}
            />
          </Box>
        )}
        {selectedProductGroup && (
          <SelectedFilterChip
            label={
              productGroups.find(
                (productGroup) => selectedProductGroup.groupNumber === productGroup.groupNumber
              )?.name ?? ''
            }
            onClick={() => {
              selectProductGroup(null);
              setCurrentSelectedProductGroup(null);
            }}
          />
        )}
      </Stack>
      <FiltersPopover
        {...menuElementProps}
        suppliers={suppliers}
        productGroups={productGroups}
        selectedSupplier={currentSelectedSupplier}
        selectedProductGroup={currentSelectedProductGroup}
        selectSupplier={setCurrentSelectedSupplier}
        selectProductGroup={setCurrentSelectedProductGroup}
        resetFilters={resetFilters}
        saveFilters={saveFilters}
      />
    </Stack>
  );
};
