import { useSelectedOrgUnit } from '@retail/app/stores/selected-context';
import { ReactNode } from 'react';
import { DraftDrawer } from './DraftDrawer';

interface Props {
  children: ReactNode;
}

export const GlobalDrawer = ({ children }: Props) => {
  const { selectedDepartment } = useSelectedOrgUnit();

  if (!selectedDepartment) return children;

  return (
    <>
      {children}
      <DraftDrawer />
    </>
  );
};
